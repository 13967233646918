import clone from "just-clone";
import { LuciNotification } from "../../components/HeaderMenu/Notifications/types";
import {
  RESET,
  SET_NOTIFICATIONS,
  SET_REMINDERS,
  REMOVE_NOTIFICATION,
  REMOVE_REMINDER,
  CLEAR_ALL_NOTIFICATIONS,
  CLEAR_ALL_REMINDERS,
  SET_NOTIFICATION_IS_READ,
  SET_REMINDER_IS_READ,
} from "../actionTypes";

export type User = {
  notifications: [] | Array<LuciNotification>;
  reminders: [] | Record<string, any>;
};

const initialState: User = {
  notifications: [],
  reminders: [],
};

const user = (state: User = initialState, action: { type: string; payload: any }) => {
  switch (action.type) {
    case SET_NOTIFICATIONS: {
      return { ...state, notifications: action.payload };
    }
    case SET_REMINDERS: {
      return { ...state, reminders: action.payload };
    }
    case SET_NOTIFICATION_IS_READ: {
      const _notifications = state?.notifications && clone(state?.notifications);
      const existing = _notifications?.find((n: LuciNotification) => n.id === action.payload);
      const index = existing && _notifications?.indexOf(existing);
      if (_notifications !== null && index !== undefined && existing) {
        _notifications[index] = { ...existing, isRead: true };
        return { ...state, notifications: _notifications };
      }
      return { ...state };
    }
    case SET_REMINDER_IS_READ: {
      const _reminders = state?.reminders && clone(state?.reminders);
      const existing = _reminders?.find((n: Record<string, any>) => n.id === action.payload);
      const index = existing && _reminders?.indexOf(existing);
      if (_reminders !== null && index !== undefined && existing) {
        _reminders[index] = { ...existing, isRead: true };
        return { ...state, notifications: _reminders };
      }
      return { ...state };
    }
    case REMOVE_NOTIFICATION: {
      const _notifications = state.notifications.filter((n) => n.id !== action.payload);
      return { ...state, notifications: _notifications };
    }
    case REMOVE_REMINDER: {
      const _reminders = state.notifications.filter((n) => n.id !== action.payload);
      return { ...state, notifications: _reminders };
    }
    case CLEAR_ALL_NOTIFICATIONS: {
      return { ...state, notifications: [] };
    }
    case CLEAR_ALL_REMINDERS: {
      return { ...state, reminders: [] };
    }
    case RESET:
      return initialState;
    default:
      return state;
  }
};

export default user;
