export enum TutorialClasses {
  firstTutorialStep = "firstStep",
  secondTutorialStep = "secondStep",
  thirdTutorialStep = "thirdStep",
  fourthTutorialStep = "fourthStep",
  fifthTutorialStep = "fifthStep",
  sixthTutorialStep = "sixthStep",
  seventhTutorialStep = "seventhStep",
  eighthTutorialStep = "eighthStep",
  ninthTutorialStep = "ninthStep",
  tenthTutorialStep = "tenthStep",
  eleventhTutorialStep = "eleventhStep",
}