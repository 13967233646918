type ValidationResult = string | boolean;

export const email = (value: string): ValidationResult => {
  if (!value.match(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/)) {
    return 'Enter valid email';
  }
  return true;
};

export const charsOnly = (value: string): ValidationResult => {
  if (!value.match(/^[a-zA-Z]+$/)) {
    return 'Enter valid name';
  }
  return true;
};

export const charsWithSpecial = (value: string): ValidationResult => {
  if (!value.match(/^[a-zA-Z ,.'-]+$/)) {
    return 'Enter valid name';
  }
  return true;
}

export const required = (value: string): ValidationResult => {
  if (value.length <= 0) return 'Field is required';
  return true;
};

export const numberOnly = (value: string): ValidationResult => {
  return /^[0-9]+$/.test(value) || 'This field should contain numbers only';
};

export const maxLength =
  (maxLength: number) =>
  (value: string): ValidationResult => {
    return value.length <= maxLength || `Max input length is ${maxLength}`;
  };

export const valuesAreNotEqual =
  (firstValue: string, message?: string) =>
  (value: string): ValidationResult => {
    return firstValue !== value || message || 'Values can not be equal';
  };

export const valuesAreEqual =
  (firstValue: string, message?: string) =>
  (value: string): ValidationResult => {
    return firstValue === value || message || 'Values can not be equal';
  };

export const minLength =
  (minLength: number, message?: string) =>
  (value: string): ValidationResult => {
    return value.length >= minLength || message || `Minimum input lenth is ${minLength}`;
  };

export const includeNumber =
  (message?: string) =>
  (value: string): ValidationResult => {
    return value.search(/[0-9]/) !== -1 || message || 'Value should contain at least one number';
  };

export const includeLoweCaseChar =
  (message?: string) =>
  (value: string): ValidationResult => {
    return (
      value.search(/[a-z]/) !== -1 ||
      message ||
      'Value should contain at least one lowercase letter'
    );
  };

export const includeUpperCaseChar =
  (message?: string) =>
  (value: string): ValidationResult => {
    return (
      value.search(/[A-Z]/) !== -1 ||
      message ||
      'Value should contain at least one uppercase letter'
    );
  };
export const includeSpecialChar =
  (message?: string) =>
  (value: string): ValidationResult => {
    return (
      // eslint-disable-next-line no-useless-escape
      value.search(/[!"`'#%&,:;<>=@{}~\$\(\)\*\+\/\\\?\[\]\^\|]+/) !== -1 ||
      message ||
      'Value should contain at least one special character'
    );
  };

export const isDate = (value: string, fromToday: boolean): ValidationResult => {
  if (!value.length) {
    return 'Field is required';
  }
  if (!fromToday) {
    return typeof Date.parse(value) === 'number' ? true : 'The date is invalid';
  } else {
    if (typeof Date.parse(value) === 'number') {
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      return new Date(value) >= today ? true : 'Date should be greater than the current date';
    }
    return 'Date is invalid';
  }
};

export const isLink = (link: string): ValidationResult => {
  if(!link.match(/https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/)) {
    return "Paste the valid link"
  }
  return true;
}
