import React, { useEffect, useState } from "react";
import { Box, Button, Link, Modal, Tooltip, Typography, useMediaQuery } from "@mui/material";
import { useNavigate } from "react-router-dom";
import clone from "just-clone";
import { NewLetterControls } from "../Controls";
import { FileUploadWithProgress } from "../../../components/FileUploadWithProgress";
import { LetterRequestDocument } from "../../../components/FileUploadWithProgress/types";
import { DocumentItem } from "../../../components/FileUploadWithProgress/DocumentItem";
import { FILE_MAX_SIZE, getFileSizeNumber } from "../../../utils/fileSize";
import { Disclaimer } from "../../../components/Disclaimer";
import { ROUTES } from "../../../constants/routes";
import { showDisclaimer } from "../../../api/profile";
import { DisclaimerData } from "../../../components/Disclaimer/types";

type AdditionalInformationProps = {
  onNext?: (args?: Record<string, any>) => void;
  onCancel?: () => void;
  loading?: boolean;
  editable?: boolean;
  letterDocuments?: Array<LetterRequestDocument> | [];
  onUpdateDocuments?: (docs: Array<LetterRequestDocument> | []) => void;
  handleSetIsUnsavedChanges?: (isSaved: boolean | null) => void;
};

const TOOLTIP_SUPPORTED_TITLE = "PDF, JPEG, PNG, DOC, DOCX, GIF, XLSX, PPTX, TXT, RTF";

export const AdditionalInformation = ({
  onNext,
  onCancel,
  loading = false,
  letterDocuments = [],
  editable = true,
  onUpdateDocuments,
  handleSetIsUnsavedChanges,
}: AdditionalInformationProps) => {
  const [documents, setDocuments] = useState<Array<LetterRequestDocument> | []>(letterDocuments);
  const [open, setOpen] = useState(false);
  const [isOpenDisclaimer, setIsOpenDisclaimer] = useState(false);
  const [disclaimerData, setDisclaimerData] = useState<DisclaimerData | null>({
    is_first: false,
    is_letter_exists: false,
  });
  const isDesktop = useMediaQuery("(min-width:900px)");

  const navigate = useNavigate();

  const getShowDisclaimer = async () => {
    const data = await showDisclaimer();
    setDisclaimerData(data);
  };

  const handleNext = () => {
    handleOpenDisclaimer();
    onNext &&
    onNext({
      links: documents.filter((d: LetterRequestDocument) => d.type === "link"),
      files: documents.filter((d: LetterRequestDocument) => d.type === "file"),
    });
  };

  const handleOpenDisclaimer = () => setIsOpenDisclaimer(true);
  const handleCloseDisclaimer = () => {
    setIsOpenDisclaimer(false);
    navigate(ROUTES.HOME);
  };

  const onDocumentsUpdate = (d: Array<LetterRequestDocument> | []) => {
    setDocuments(d);
    onUpdateDocuments && onUpdateDocuments(d);
  };

  const updateItem = (i: LetterRequestDocument) => {
    const docs: Array<LetterRequestDocument> = clone(documents);
    const current = docs.find((d: LetterRequestDocument) => d.id === i.id);
    if (current) {
      current.description = i.description;
    }
    setDocuments(docs);
    onUpdateDocuments && onUpdateDocuments(docs);
  };

  const deleteItem = (i: LetterRequestDocument) => {
    const docs: Array<LetterRequestDocument> = clone(documents);
    const newDocs = docs.filter((d: LetterRequestDocument) => d.id !== i.id);
    setDocuments(newDocs);
    onUpdateDocuments && onUpdateDocuments(newDocs);
  };

  const closeModal = () => setOpen(false);

  const files = documents.filter((d: LetterRequestDocument) => d.type === "file");
  const links = documents.filter((d: LetterRequestDocument) => d.type === "link");

  useEffect(() => {
    if (letterDocuments !== documents && letterDocuments.length) {
      setDocuments(letterDocuments);
    }
  }, [letterDocuments, documents]);

  useEffect(() => {
    getShowDisclaimer();
  }, []);

  return (
    <>
      {!disclaimerData?.is_first && (
        <Modal open={isOpenDisclaimer} onClose={handleCloseDisclaimer}>
          <Box width="100%" height="100%" display="flex" alignItems="center" justifyContent="center">
            <Disclaimer
              onSubmit={handleNext}
              onCancel={handleCloseDisclaimer}
              title="Disclaimer"
              actionName="I agree"
            >
              By clicking Accept you confirm that you have read and agree to the Lucinetic <Link
              color="secondary" underline="hover" onClick={() => window.open(ROUTES.TERMS_OF_SERVICE)}>Terms
              of Use</Link> and <Link
              color="secondary" underline="hover" onClick={() => window.open(ROUTES.PRIVACY_POLICY)}>Privacy
              Policy</Link>,
              and that you are willingly providing your personal information
              through our secure and encrypted platform to facilitate the completion of your letter of recommendation,
              to which you waive the right of access.
            </Disclaimer>
          </Box>
        </Modal>
      )}
      <Box display="flex" flexDirection="column" px={5} width="100%" boxSizing="border-box">
        <Modal open={open} onClose={closeModal}>
          <Box
            width="100%"
            height="100%"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <FileUploadWithProgress
              onClose={closeModal}
              onDocumentsUpdate={onDocumentsUpdate}
              documents={documents}
              onUpdate={updateItem}
              onDelete={deleteItem}
              tooltipSupportedFormatsTitle={TOOLTIP_SUPPORTED_TITLE}
            />
          </Box>
        </Modal>
        <Typography variant="title" fontWeight={500}>
          Additional information
        </Typography>
        <Typography variant="caption" color="GrayText">
            e.g. resume/CV, cover letter, transcripts, etc.
            <br></br>
            Uploaded information is not considered by the AI when generating text.
        </Typography>
        {editable && (
          <>
            <Box mt={10} mb={2}>
              <Button
                variant="contained"
                color="secondary"
                style={{ paddingLeft: 50, paddingRight: 50 }}
                onClick={() => setOpen(true)}
              >
                Attach Info
              </Button>
            </Box>
            <Typography variant="caption" color="GrayText">
              The file size should be lower than {getFileSizeNumber(FILE_MAX_SIZE)}
            </Typography>
            <Typography display="flex" flexDirection="row" gap={0.5} variant="caption" color="GrayText">
              Files supported:
              <Tooltip
                title={TOOLTIP_SUPPORTED_TITLE}
                arrow
              >
                <Typography variant="caption" sx={{ cursor: "pointer" }}>PDF, JPEG, PNG, DOCX...</Typography>
              </Tooltip>
            </Typography>
          </>
        )}

        <Box maxWidth={1000} mt={2}>
          <Box
            display={isDesktop ? "grid" : "flex"}
            flexDirection={isDesktop ? "unset" : "column"}
            gridTemplateColumns={isDesktop ? "1fr 1fr" : "1fr"}
            gap={4}
          >
            {files?.length > 0 && (
              <Box>
                <Typography variant="text" fontWeight={600}>
                  FILES
                </Typography>
                <Box maxWidth={450} py={1} overflow="auto" boxSizing="border-box">
                  {files.map((item: LetterRequestDocument, index: number) => (
                    <DocumentItem
                      key={item.id + item.description + index}
                      item={item}
                      onChange={editable ? updateItem : undefined}
                      onDelete={editable ? deleteItem : undefined}
                      isLast={index === files.length - 1}
                      handleSetIsUnsavedChanges={handleSetIsUnsavedChanges}
                    />
                  ))}
                </Box>
              </Box>
            )}
            {links?.length > 0 && (
              <Box>
                <Typography variant="text" fontWeight={600}>
                  LINKS
                </Typography>
                <Box maxWidth={450} py={1} overflow="auto" boxSizing="border-box">
                  {links
                    .filter((d: LetterRequestDocument) => d.type === "link")
                    .map((item: LetterRequestDocument, index: number) => (
                      <DocumentItem
                        key={item.id + item.description + index}
                        item={item}
                        onChange={editable ? updateItem : undefined}
                        onDelete={editable ? deleteItem : undefined}
                        isLast={index === links.length - 1}
                      />
                    ))}
                </Box>
              </Box>
            )}
          </Box>
        </Box>
        {onCancel && (
          <NewLetterControls
            onNext={!disclaimerData?.is_first ? handleOpenDisclaimer : handleNext}
            onCancel={onCancel}
            nextTitle="Finish request"
            loading={loading}
          />
        )}
      </Box>
    </>
  );
};
