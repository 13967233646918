import React, { useEffect, useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import { CloseOutlined } from "@mui/icons-material";
import { useSelector } from "react-redux";
import { RequestMoreInfoModalProps } from "../../types";
import { RootState } from "../../../../redux/reducers";
import { LetterRequest, RequestStatus } from "../../../../views/LetterStatus/types";
import Loader from "../../../Loader";
import { ProfessorMoreInfoRequest } from "./Professor";
import { StudentMoreInfoResponse } from "./Student";
import { formatDate } from "../../../../utils/joinFormatDate";

export const RequestMoreInfoModal = React.forwardRef(
  ({ onSubmit, onCancel, request }: RequestMoreInfoModalProps, ref: React.Ref<any>) => {
    const [storedRequest, setStoredRequest] = useState<LetterRequest | null>(null);

    const { requests } = useSelector((state: RootState) => state.requests);

    useEffect(() => {
      const r =
        request &&
        requests.length &&
        requests.find((r: LetterRequest) => r.letter_id === request?.letter_id);
      if (r) {
        setStoredRequest(r);
      }
    }, [requests]);

    return (
      <Box
        ref={ref}
        width="100%"
        height="100%"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Box
          maxWidth={650}
          bgcolor="white"
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          {storedRequest ? (
            <>
              <Box
                height={60}
                width="100%"
                display="flex"
                alignItems="center"
                justifyContent="flex-end"
                paddingX={2}
                py={1}
                boxSizing="border-box"
              >
                <Button variant="text" color="secondary" onClick={onCancel}>
                  <Typography>Close</Typography>
                  <CloseOutlined fontSize="small" />
                </Button>
              </Box>
              <Box px={7} py={2} display="flex" flexDirection="column">
                {storedRequest?.request_display_name && storedRequest?.letter_due_date && (
                  <Typography variant="text" mb={2}>
                    Recommendation letter request by {storedRequest.request_display_name} - due
                    date: {formatDate(storedRequest?.letter_due_date)}
                  </Typography>
                )}
                <Typography variant="mediumTitle" mb={3} fontWeight={500}>
                  {storedRequest.status === RequestStatus.mi ? "More information: " : "Request more information: "}
                </Typography>
                <ProfessorMoreInfoRequest
                  request={storedRequest}
                  onCancel={onCancel}
                  onSubmit={onSubmit}
                />
                {storedRequest.moreInfoRequest && (
                  <StudentMoreInfoResponse
                    request={storedRequest}
                    onCancel={onCancel}
                    onSubmit={onSubmit}
                  />
                )}
              </Box>
            </>
          ) : (
            <Loader size={30} />
          )}
        </Box>
      </Box>
    );
  },
);
