import React, { useEffect, useState } from "react";
import { DeleteForever, LinkOutlined, ModeEdit, UploadFileRounded } from "@mui/icons-material";
import { Box, Button, Link, Modal, Typography } from "@mui/material";
import { colors } from "../../theme";
import { InputWithLabel } from "../InputWithLabel";
import { formatDate } from "../LetterRequestCard/utils";
import { LetterRequestDocument } from "./types";
import { ConfirmationModal } from "../ConfirmationModal";

type DocumentItemProps = {
  item: LetterRequestDocument;
  isLast: boolean;
  onChange?: (item: LetterRequestDocument) => void;
  onDelete?: (item: LetterRequestDocument) => void;
  handleSetIsUnsavedChanges?: (isSaved: boolean | null) => void;
};

const s = {
  color: colors.gray[40],
};

export const DocumentItem = ({
  item,
  onChange,
  onDelete,
  isLast,
  handleSetIsUnsavedChanges,
}: DocumentItemProps) => {
  const [editing, setEditing] = useState(false);
  const [description, setDescription] = useState(item.description);
  const [title, setTitle] = useState("");
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);

  const { type } = item;

  const handleOpenModal = () => setIsOpenModal(true);
  const handleCloseModal = () => setIsOpenModal(false);

  const handleSave = (e: any) => {
    e.stopPropagation();
    const itemCopy = { ...item };
    itemCopy.description = description;
    onChange && onChange(itemCopy);
    setEditing(false);
  };

  const handleCancel = (e: any) => {
    e.stopPropagation();
    setDescription(item.description);
    setEditing(false);
  };

  const handleDelete = () => {
    onDelete && onDelete(item);
  };

  const handleChangeDescription = (e:
    React.ChangeEvent<HTMLInputElement |
    HTMLTextAreaElement> |
    React.SyntheticEvent<HTMLInputElement, Event>) => {
    const target = e.target as HTMLInputElement | HTMLTextAreaElement;
    setDescription(target?.value);
  };

  const openInNewTab = (url: string | undefined) => window && window?.open(url, "_blank")?.focus();

  const handleDownload = async () => {
    if (type === "link" || !item.fileUrl) {
      return;
    }
    openInNewTab(item.fileUrl);
  };

  useEffect(() => {
    if (description) {
      const title = type === "file" ? "Description" : "Link Title";
      setTitle(title);
    }
  }, [description]);

  useEffect(() => {
    if (description && handleSetIsUnsavedChanges) {
      const isSaved = Boolean(editing && description !== item.description);
      handleSetIsUnsavedChanges(isSaved);
    } else {
      handleSetIsUnsavedChanges && handleSetIsUnsavedChanges(null);
    }
    return () => {
      handleSetIsUnsavedChanges && handleSetIsUnsavedChanges(null);
    };
  }, [description, editing]);

  return (
    <>
      <Modal open={isOpenModal} onClose={handleCloseModal}>
        <Box width="100%" height="100%" display="flex" alignItems="center" justifyContent="center">
          <ConfirmationModal
            onSubmit={handleDelete}
            onCancel={handleCloseModal}
            title={`Do you really want to delete this ${item.type}?`}
            actionName="Delete"
          />
        </Box>
      </Modal>
      <Box
        width="100%"
        display="flex"
        flexDirection="row"
        alignItems="flex-start"
        py={2.5}
        boxSizing="border-box"
        borderTop={`1px solid ${colors.gray[100]}`}
        borderBottom={isLast ? `1px solid ${colors.gray[100]}` : ""}
      >
        <Box
          bgcolor={colors.gray[30]}
          width={45}
          borderRadius={1}
          height={45}
          display="flex"
          alignItems="center"
          justifyContent="center"
          sx={{ cursor: type === "file" && item.fileUrl ? "pointer" : "default" }}
          onClick={handleDownload}
          flexDirection="column"
        >
          {type === "file" ? <UploadFileRounded style={s} /> : <LinkOutlined style={s} />}
          {type === "file" && item.fileUrl && <Typography variant="littleText">open</Typography>}
        </Box>
        <Box display="flex" flex={1} flexDirection="column" px={1.25}>
          {type === "link" && (
            <>
              {editing ? (
                <Box mb={1} display="flex" gap={1}>
                  <InputWithLabel
                    wrapperStyle={{ flex: 1 }}
                    placeholder="Link Title"
                    id="description"
                    value={description}
                    onChange={handleChangeDescription}
                  />
                  <Button
                    disabled={!description}
                    onClick={handleSave}
                    variant="contained"
                    color="secondary"
                  >
                    Save
                  </Button>
                  <Button onClick={handleCancel} variant="contained" color="secondary">
                    Cancel
                  </Button>
                </Box>
              ) : (
                <Typography mb={0.5} variant="text" component="p" fontWeight={900}>
                  {description}
                </Typography>
              )}
            </>
          )}
          <Typography
            maxWidth={300}
            component="div"
            fontWeight={900}
            variant={type === "file" ? "text" : "smallText"}
            color={type === "file" ? colors.black : colors.info}
            textOverflow="ellipsis"
            overflow="hidden"
            whiteSpace="nowrap"
          >
            {type === "file" ? (
              item?.file?.name || item?.fileName
            ) : (
              <Link
                sx={{ color: colors.info }}
                onClick={() => openInNewTab(item.link)}
                underline={editing ? "none" : "hover"}
              >
                {item.link}
              </Link>
            )}
          </Typography>
          <Box mt={0.5} display="flex" flexDirection="row" gap={1}>
            {item?.date && (
              <Typography color="GrayText" variant="caption">
                {formatDate(item.date.toString())}
              </Typography>
            )}
            {type === "file" && (
              <Typography color="GrayText" variant="caption">
                {item?.size}
              </Typography>
            )}
          </Box>
          {type === "file" && (
            <Box>
              <Typography variant="smallText" color={colors.main}>
                {title}
              </Typography>
              {editing ? (
                <Box mt={0.5} display="flex" gap={1}>
                  <InputWithLabel
                    wrapperStyle={{ flex: 1 }}
                    placeholder={title}
                    id="description"
                    value={description}
                    onChange={handleChangeDescription}
                  />
                  <Button
                    onClick={handleSave}
                    variant="contained"
                    color="secondary"
                  >
                    Save
                  </Button>
                  <Button onClick={handleCancel} variant="contained" color="secondary">
                    Cancel
                  </Button>
                </Box>
              ) : (
                <Typography variant="text" component="p">
                  {description}
                </Typography>
              )}
            </Box>
          )}
        </Box>
        <Box display="flex" flexDirection="row" alignItems="center" gap={2}>
          {!editing && onChange && !item.fileUrl && (
            <Link onClick={() => setEditing(true)} color={colors.gray[40]} fontSize={12}>
              <ModeEdit sx={{ color: colors.black }} />
            </Link>
          )}
          {onDelete && (
            <Link underline="none" onClick={handleOpenModal}>
              <DeleteForever color="error" />
            </Link>
          )}
        </Box>
      </Box>
    </>
  );
};
