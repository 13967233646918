import { Avatar, Box, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { OptionProps } from '.';
import { getHighlightedText } from '../../utils/strings';

type OptionLabel = {
  start: string;
  highlight: string;
  end: string;
};

const defaultValue = {
  start: '',
  highlight: '',
  end: '',
};

export const Option = ({ data, text }: OptionProps) => {
  const [label, setLabel] = useState<OptionLabel>(defaultValue);

  useEffect(() => {
    if (data?.label && text) {
      const l = getHighlightedText(data.label, text);
      setLabel(l);
    } else {
      setLabel({ start: data?.label || '', end: '', highlight: ' ' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, text]);

  return (
    <Box
      style={{
        cursor: 'pointer',
      }}
      display='flex'
      flexDirection="row"
      alignItems='center'
      padding={1}
    >
      <Avatar alt="avatar" src={data?.avatar} />
      <Box paddingLeft={2}>
        <Typography>
          {label.start}
          <Typography component="span" fontWeight={600}>
            {label.highlight}
          </Typography>
          {label.end}
        </Typography>
        <Typography variant="smallText">{data?.email}</Typography>
      </Box>
    </Box>
  );
};
