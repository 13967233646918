import React from "react";
import { Box, Tooltip, Typography } from "@mui/material";

type NameCellProps = {
  row: any;
};

export const NameCell = ({ row }: NameCellProps) => <Box textAlign="center">
  <Tooltip
    title={row?.request_display_name}
    arrow
    placement="top"
  >
    <Typography
      maxWidth="250px"
      noWrap
      overflow="hidden"
      textOverflow="ellipsis"
    >
      {row?.request_display_name || "No name set"}
    </Typography>
  </Tooltip>
  <Typography variant="smallText">{row.request_send_to}</Typography>
</Box>;
