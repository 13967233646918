import { styled, ToggleButton } from "@mui/material";
import { colors } from "../../../theme";
import { LuciNotification } from "./types";

export const formatDate = (d: Date) => {
  return d.toLocaleString(
    "en-US",
    { day: "numeric", month: "numeric", year: "numeric", hour: "numeric", minute: "numeric", hour12: false },
  );
};

export const NotificationsToggleButton = styled(ToggleButton)({
  "textTransform": "none",
  "&:hover": {
    backgroundColor: "transparent",
    borderBottom: `2px solid ${colors.secondary}`,
  },
  "&.MuiToggleButtonGroup-grouped": {
    "&:hover": {
      borderBottom: `2px solid ${colors.secondary}`,
    },
    "border": "none",
    "borderRadius": 0,
    "borderBottom": "2px solid transparent",
    "BorderTop": "2px solid transparent",
  },
  "&.Mui-selected": {
    "&:hover": {
      background: "none",
    },
    "color": colors.gray["600"],
    "fontWeight": 600,
    "borderBottom": `2px solid ${colors.secondary}`,
  },
});

export const createNotifications = (data: Array<Record<string, any>>): Array<LuciNotification> => {
  return data.map((_n: Record<string, any>) => ({
    id: _n.id,
    avatar: _n.avatar || "",
    text: _n.text || "",
    university: _n.university || "",
    time: _n.date
      ? formatDate(new Date(_n.date))
      : formatDate(new Date()),
    name: _n.name || "",
    isRead: Boolean(_n?.is_read),
    letterId: _n.letter_id,
  }));
};
