import { HintSize } from './types';

export const defaultHintSize: HintSize = { width: 0, height: 0, top: 0, left: 0, inputHeight: 0 };

export const HINT_SPACE = 20;
export const TRIANGLE_WIDTH = 15;
export const DEFAULT_HINT_WIDTH = 400;
export const DEFAULT_HINT_HEIGHT = 340;

export const calculateHintSize = (isDesktop: boolean, component: HTMLDivElement): HintSize => {
  const rect = component.getBoundingClientRect();
  const calculatedWidth = document.body.clientWidth - rect.width - rect.left - HINT_SPACE * 2;
  if (isDesktop) {
    return {
      top: 0,
      left: rect.width + HINT_SPACE,
      inputHeight: rect.height,
      height: DEFAULT_HINT_HEIGHT,
      width: calculatedWidth > DEFAULT_HINT_WIDTH ? DEFAULT_HINT_WIDTH : calculatedWidth,
    };
  } else {
    return {
      top: rect.height + HINT_SPACE / 2,
      left: -(rect.width - rect.width) / 2,
      inputHeight: rect.height,
      height: DEFAULT_HINT_HEIGHT,
      width: rect.width,
    };
  }
};
