import React from "react";
import { Box, Typography, Link } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import toast, { resolveValue, Toast, Toaster, ToastType } from "react-hot-toast";
import { colors } from "../../theme";

const handler = (t: Toast) => {
  const type = t.type;

  const getColor = (t: ToastType) => {
    switch (t) {
      case "error":
        return colors.error;
      case "blank":
        return colors.textGray;
      case "success":
        return colors.secondary;
      default:
        return colors.textGray;
    }
  };

  const getTitle = (t: ToastType) => {
    switch (t) {
      case "error":
        return "Error!";
      case "success":
        return "Success";
      case "blank":
        return "";
      default:
        return "";
    }
  };

  return (
    <Box
      position="relative"
      borderRadius={0.5}
      boxShadow="0px 3px 10px 1px #ccc"
      px={2}
      py={1}
      bgcolor="white"
    >
      <Link onClick={() => toast.dismiss(t.id)} underline="none">
        <CloseIcon
          sx={{ fontSize: 14, position: "absolute", top: 8, right: 8, color: colors.textGray }}
        />
      </Link>
      <Box display="flex" flexDirection="column">
        <Typography fontWeight={600} variant="largeText" color={getColor(type)}>
          {getTitle(type)}
        </Typography>
        <Typography variant={"text"} color={getColor(type)}>
          {resolveValue(t.message, t)}
        </Typography>
      </Box>
    </Box>
  );
};

export const AlertHandler = () => {
  return <Toaster position="top-right">{handler}</Toaster>;
};
