import { AccountType } from "../../utils/accountTypes";
import { Profile } from "./types";
import { updateMyProfile } from "../../api/profile";

const getAccountTypeById = (name: string, accountTypes?: AccountType[]) => {
  const type = accountTypes?.find((i) => i.value === name);

  return type ? type : { key: "", value: "", label: "" };
};

export const createDefaultValues = (user?: any, accountTypes?: AccountType[]): Profile => {
  return {
    first_name: user?.user?.first_name || "",
    last_name: user?.user?.last_name || "",
    stealth: user?.stealth || false,
    imageUrl: user?.avatar || "",
    email: user?.user?.email || "",
    accountType: getAccountTypeById(user?.account_type?.account_type_name, accountTypes),
    organization: user?.organization || "",
    current_responsibilities: user?.current_responsibilities || "",
    source: user?.website || "",
    tfa_active: user?.tfa_active || false,
    phone: user?.phone || "",
  };
};

export const updateProfile = async (user: any, profile: Profile) => {
  const body = {
    user: {
      email: profile.email,
      first_name: profile.first_name || "",
      last_name: profile.last_name || "",
    },
    organization: profile.organization || "",
    current_responsibilities: profile.current_responsibilities || "",
    stealth: profile.stealth,
    account_type: {
      account_type_name: profile.accountType.value,
    },
  };

  try {
    return await updateMyProfile(user.id, body);
  } catch (error: unknown) {
    return { error };
  }
};
