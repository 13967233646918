import React, { useState } from "react";
import { Box, Button, TextareaAutosize, Typography } from "@mui/material";
import { RequestMoreInfoModalProps } from "../../../types";
import { colors } from "../../../../../theme";
import { getType } from "../../../../../api/localStorage/type";
import { AccountTypeEnum } from "../../../../../constants";

export const StudentMoreInfoResponse = ({
  request,
  onCancel,
  onSubmit,
}: RequestMoreInfoModalProps) => {
  const [text, setText] = useState(request?.moreInfoResponse || "");
  const predefined = request?.moreInfoResponse;
  const type = getType();

  const handleSubmit = async () => {
    await onSubmit(text, type);
  };

  return (
    <Box display="flex" flexDirection="column">
      <Box display="grid" gridTemplateColumns={"1fr 5fr"}>
        <Typography pr={1}>Applicant: </Typography>
        <Box width="100%" overflow="auto" maxHeight="200px">
          {predefined || type === AccountTypeEnum.Writer ? (
            <Typography
              variant="text"
              sx={{ overflowWrap: "break-word" }}
              color={text ? colors.textGray : colors.gray[400]}
            >
              {text || "The answer has not been provided"}
            </Typography>
          ) : (
            <TextareaAutosize
              placeholder="Enter response(s) here"
              style={{
                borderColor: colors.gray[900],
                fontFamily: "Avenir, Montserrat-Regular",
                padding: 10,
                width: "100%",
                maxWidth: "100%",
                overflow: "auto",
                resize: "none",
                boxSizing: "border-box",
              }}
              minRows={5}
              maxRows={5}
              value={text}
              onChange={(e) => setText(e.target.value)}
            />
          )}
        </Box>
      </Box>
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="flex-end"
        gap={3}
        mt={2}
      >
        {!predefined && type === AccountTypeEnum.Applicant && (
          <>
            <Button onClick={onCancel} variant="text" color="secondary">
              Cancel
            </Button>
            <Button disabled={!text} onClick={handleSubmit} color="secondary" variant="contained">
              Submit
            </Button>
          </>
        )}
      </Box>
    </Box>
  );
};
