import React, { useState } from "react";
import { Box, Typography } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { InputWithLabel } from "../../../components/InputWithLabel";
import { charsWithSpecial, email, required } from "../../../utils/validation";
import { NewLetterControls } from "../Controls";
import { inviteWriter } from "../../../api/profile";

export type InviteWriterForm = {
  firstName: string;
  lastName: string;
  email: string;
};

type InviteWriterProps = {
  onNext: (args?: Record<string, any>) => void;
  onCancel: () => void;
};

const defaultValues = {
  firstName: "",
  lastName: "",
  email: "",
};

export const InviteWriter = ({ onNext, onCancel }: InviteWriterProps) => {
  const [loading, setLoading] = useState(false);

  const {
    control,
    watch,
    formState: { errors, isValid },
  } = useForm<InviteWriterForm>({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues,
  });

  const fields = watch();

  const handleNext = async () => {
    setLoading(true);

    const data = await inviteWriter(fields.firstName, fields.lastName, fields.email);

    if (!data.error) {
      data?.request_id && onNext({ request_id: data.request_id });
    } else {
      toast.error(data?.error?.error || "An error occurred during invite new writer process");
    }

    setLoading(false);
    toast.success(`An invitation to the ${fields.firstName} ${fields.lastName} has been sent`)
  };

  const handleCancel = () => {
    onCancel();
  };

  return (
    <Box display="flex" flexDirection="column" minWidth={300}>
      <Typography variant="title">Invite Writer</Typography>
      <Typography mb={2} variant="text">
        Enter writer’s personal details.
      </Typography>

      <Controller
        name="firstName"
        control={control}
        rules={{
          validate: {
            required,
            charsWithSpecial,
          },
        }}
        render={({ field }) => (
          <InputWithLabel
            errorMessage={errors.firstName?.message}
            {...field}
            label="First name"
            id="firstName"
            placeholder="Enter first name"
          />
        )}
      />
      <Controller
        name="lastName"
        control={control}
        rules={{
          validate: {
            required,
            charsWithSpecial,
          },
        }}
        render={({ field }) => (
          <InputWithLabel
            errorMessage={errors.lastName?.message}
            {...field}
            wrapperStyle={{ mt: 2 }}
            label="Last name"
            id="lastName"
            placeholder="Enter last name"
          />
        )}
      />
      <Controller
        name="email"
        control={control}
        rules={{
          validate: {
            required,
            email,
          },
        }}
        render={({ field }) => (
          <InputWithLabel
            errorMessage={errors.email?.message}
            {...field}
            wrapperStyle={{ mt: 2 }}
            label="Email"
            id="email"
            placeholder="Enter email"
          />
        )}
      />
      <NewLetterControls
        loading={loading}
        disabledNext={!isValid}
        onNext={handleNext}
        onCancel={handleCancel}
        nextTitle="Invite"
      />
    </Box>
  );
};
