import React from 'react';
import { SentimentVeryDissatisfied } from '@mui/icons-material';
import { Avatar, Box, Typography } from '@mui/material';

export type EmptyAutocompleteProps = {
  text: string;
};

export const EmptyAutocomplete = ({ text }: EmptyAutocompleteProps) => {
  return (
    <Box
      width='100%'
      px={2}
      py={1}
      boxSizing="border-box"
      bgcolor="white"
      boxShadow="0px 1px 3px -1px #ccc"
    >
      <Typography>0 results</Typography>
      <Box mt={2} display="flex" flexDirection="row" alignItems="center" gap={2}>
        <Avatar>
          <SentimentVeryDissatisfied />
        </Avatar>
        <Typography>There are no users: {text}</Typography>
      </Box>
    </Box>
  );
};
