/* eslint-disable no-restricted-globals */
import axios, { AxiosRequestConfig, AxiosError } from "axios";
import { BASE_URL } from "../constants";
import { ROUTES } from "../constants/routes";
import { ERROR_CODE } from "./codes";
import { getToken, removeToken } from "./localStorage/token";

const catchForRequests = (error: any) => {
  const code = error?.response?.data?.code;
  if (code === ERROR_CODE.TOKEN_EXPIRED) {
    removeToken();
    window.location.href = location?.pathname + ROUTES.SIGN_IN;
  } else {
    throw error?.response?.data;
  }
}

export const api = axios.create({
  // baseURL: `https://cors-anywhere.herokuapp.com/${BASE_URL}`,
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "application/json",
    "Accept": "application/json",
    "Access-Control-Allow-Origin": "*",
  },
});

export const getRequest = async (url: string) => {
  const token = getToken();
  try {
    return (
      await api.get(url, {
        headers: {
          Authorization: token ? `Bearer ${token}` : "",
        },
      })
    ).data;
  } catch (error: any) {
    catchForRequests(error);
  }
};

export const postRequest = async (url: string, data: unknown, config?: AxiosRequestConfig) => {
  const token = getToken();
  try {
    return (
      await api.post(url, data, {
        headers: {
          Authorization: token ? `Bearer ${token}` : "",
          ...config?.headers,
        },
      })
    ).data;
  } catch (error: any) {
    catchForRequests(error)
  }
};

export const patchRequest = async (url: string, data: unknown, config?: AxiosRequestConfig) => {
  const token = getToken();
  try {
    return (
      await api.patch(url, data, {
        ...config,
        headers: {
          Authorization: token ? `Bearer ${token}` : "",
        },
      })
    ).data;
  } catch (error: any) {
    catchForRequests(error)
  }
};

export const putRequest = async (url: string, data?: unknown, config?: AxiosRequestConfig) => {
  const token = getToken();
  try {
    return (
      await api.put(url, data, {
        ...config,
        headers: {
          Authorization: token ? `Bearer ${token}` : "",
        },
      })
    ).data;
  } catch (error: any) {
    catchForRequests(error)
  }
};

export const deleteRequest = async (url: string) => {
  const token = getToken();
  try {
    return (
      await api.delete(url, {
        headers: {
          Authorization: token ? `Bearer ${token}` : "",
        },
      })
    ).data;
  } catch (error: any) {
    catchForRequests(error)
  }
};
