import React from "react";
import { useSelector } from "react-redux";
import { Box } from "@mui/system";
import { Button, Link, Typography, useMediaQuery } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { LuciButton } from "../LuciButton";
import { colors } from "../../theme";
import LuciAssistant from "./../../assets/images/LUCI_AI.svg";
import { RootState } from "../../redux/reducers";
import { LetterType } from "../../utils/globalLetterTypesCredits";

type LetterCompositionModalProps = {
  onClose: () => void;
  handleChangePageMode?: () => void;
  handleNavigateDefault?: () => void;
  letterType: "recommendation" | "cover";
};

export const LetterCompositionModal = ({
  onClose,
  handleChangePageMode,
  handleNavigateDefault,
  letterType,
}: LetterCompositionModalProps) => {
  const isLarge = useMediaQuery("(max-height:1000px)");
  const isMedium = useMediaQuery("(max-height:650px)");
  const isSmall = useMediaQuery("(max-height:500px)");

  const { user } = useSelector((state: RootState) => state.user);
  const { credit_count } = user;
  let isMinPrice = credit_count < LetterType.recommendation;

  if (letterType === "recommendation") {
    isMinPrice = credit_count < LetterType.recommendation;
  } else if (letterType === "cover") {
    isMinPrice = credit_count < LetterType.cover;
  }

  const changeVertical = () => {
    if (isSmall) return "300px";
    if (isMedium) return "450px";
    if (isLarge) return "700px";
    return "none";
  };

  return (
    <Box
      width="100%"
      height="100%"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Box
        sx={{ overflowY: "auto", overflowX: "hidden" }}
        maxWidth={450}
        bgcolor={colors.white}
        display="flex"
        flexDirection="column"
        alignItems="center"
        paddingTop={2}
        paddingX={8}
        paddingBottom={10}
        maxHeight={changeVertical()}
      >
        <Box width="100%" display="flex" justifyContent="flex-end" mb={2} ml={12}>
          <Link
            onClick={onClose}
            position="fixed"
            display="flex"
            flexDirection="row"
            gap={0.5}
            underline="hover"
            color={colors.gray[600]}
            alignItems="center"
          >
            <Typography variant="smallText">Close</Typography>
            <CloseIcon sx={{ width: 14, height: 14 }} />
          </Link>
        </Box>

        <Box component="img" src={LuciAssistant} mb={4} mt={2} width={200} alignSelf="center" />
        <Box display="flex" flexDirection="column" gap={2}>
          <Typography variant="title" fontWeight={600}>
            {isMinPrice ? "You don't have enough credits! Click the button below to buy more."
              : `Are you ready to use ${letterType === "recommendation" ? 
                LetterType.recommendation : LetterType.cover} credit${LetterType.recommendation > 1 ? "s" : ""}?`}
          </Typography>
        </Box>
        <Box my={4}>
          <LuciButton
            onClick={handleChangePageMode}
            text={`${isMinPrice ? "Get" : "Use"} LUCI`}
          />
        </Box>
        {isMinPrice && letterType === "recommendation"
          &&
          <Button variant="contained" color="secondary" onClick={handleNavigateDefault}>Switch to free writing</Button>}
      </Box>
    </Box>
  );
};
