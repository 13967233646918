import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { AccountTypeEnum } from '../../constants';
import { ROUTES } from '../../constants/routes';

type EmptyDashboardProps = {
  type: AccountTypeEnum | null | string;
};

export const EmptyDashboard = ({ type }: EmptyDashboardProps) => {
  const navigate = useNavigate();

  const requestNewLetter = () => navigate(ROUTES.NEW_LETTER);

  return (
    <Box padding={7} bgcolor="white" display="flex" flexDirection="column" alignItems="center">
      <Typography variant="h5" marginBottom={2}>
        Welcome to Letters
      </Typography>
      <Typography marginBottom={4}>
        {type === AccountTypeEnum.Applicant
          ? 'Let’s get you started on your first letter of recommendation request.'
          : 'You don\'t have any letter requests.'}
      </Typography>
      {type === AccountTypeEnum.Applicant && (
        <Button variant="contained" color="secondary" onClick={requestNewLetter}>
          <Typography variant="text">Request new letter</Typography>
        </Button>
      )}
    </Box>
  );
};
