export const debounce = (f: (args: any) => void, t: number) => {
  let lastCall = Date.now();
  let lastCallTimer: NodeJS.Timeout;
  return (args: any) => {
    let previousCall = lastCall;
    if (previousCall && lastCall - previousCall <= t) {
      clearTimeout(lastCallTimer);
    }
    lastCallTimer = setTimeout(() => f(args), t);
  };
};
