import React from "react";
import { outlinedInputClasses, SimplePaletteColorOptions } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import { TypographyOptions } from "@mui/material/styles/createTypography";

declare module "@mui/material/styles" {
  interface CustomPalette {
    secondaryLight?: SimplePaletteColorOptions;
    buttonWhite?: SimplePaletteColorOptions;
    blackButton?: SimplePaletteColorOptions;
    whiteTextButton?: SimplePaletteColorOptions;
  }
  interface Palette extends CustomPalette {}
  interface PaletteOptions extends CustomPalette {}
}

declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    secondaryLight: true;
    buttonWhite: true;
    blackButton: true;
    whiteTextButton: true;
  }
}

declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    title: true;
    largeText: true;
    text: true;
    smallText: true;
    smallerText: true;
    titleDisabled: true;
    textDisabled: true;
    error: true;
    mediumTitle: true;
    littleText: true;
    hugeTitle: true;
  }
}

interface ExtendedTypographyOptions extends TypographyOptions {
  title: React.CSSProperties;
  largeText: React.CSSProperties;
  text: React.CSSProperties;
  smallText: React.CSSProperties;
  smallerText: React.CSSProperties;
  titleDisabled: React.CSSProperties;
  textDisabled: React.CSSProperties;
  error: React.CSSProperties;
  mediumTitle: React.CSSProperties;
  littleText: React.CSSProperties;
  hugeTitle: React.CSSProperties;
}

export const colors = {
  black: "#000",
  white: "#fff",
  main: "#8CB369",
  secondary: "#5B8E7D",
  secondaryLight: "rgba(91, 142, 125, 0.5)",
  whiteAlpha: "rgba(255, 255, 255, 0.5)",
  error: "#BC4851",
  warning: "#F4A259",
  warningLight: "#F4E285",
  notifyBanner: "#EDFBF7",
  textGray: "#4A4A4A",
  info: "#187EB8",
  infoLight: "rgba(143, 207, 243, 0.5)",
  gray: {
    30: "#F2F2F2",
    40: "#979797",
    50: "#f8f9fa",
    100: "#e9ecef",
    200: "#dee2e6",
    300: "#ced4da",
    400: "#adb5bd",
    500: "#6c757d",
    600: "#495057",
    700: "#343a40",
    800: "#212529",
    900: "#D8D8D8",
  },
};

const theme = createTheme({
  typography: {
    fontFamily: ["Avenir", "Montserrat-Regular", "Roboto"].join(","),
    body1: {
      fontFamily: "Avenir, Montserrat-Regular",
    },

    body2: {
      fontFamily: "Montserrat-Regular",
    },

    title: {
      fontFamily: "Avenir, Montserrat-Regular",
      fontSize: "22px",
    },

    mediumTitle: {
      fontFamily: "Avenir, Montserrat-Regular",
      fontSize: "18px",
    },

    largeText: {
      fontFamily: "Avenir, Montserrat-Regular",
      fontSize: "16px",
    },

    text: {
      fontFamily: "Avenir, Montserrat-Regular",
      fontSize: "14px",
    },

    smallerText: {
      fontFamily: "Avenir, Montserrat-Regular",
      fontSize: "13px",
    },

    smallText: {
      fontFamily: "Avenir, Montserrat-Regular",
      fontSize: "12px",
    },

    littleText: {
      fontFamily: "Avenir, Montserrat-Regular",
      fontSize: "10px",
    },

    titleDisabled: {
      fontFamily: "Avenir, Montserrat-Regular",
      fontSize: "22px",
      color: colors.gray[300],
    },

    textDisabled: {
      fontFamily: "Avenir, Montserrat-Regular",
      fontSize: "14px",
      color: colors.gray[300],
    },

    error: {
      fontSize: 12,
      color: colors.error,
    },
    hugeTitle: {
      fontFamily: "Avenir, Montserrat-Regular",
      fontSize: "34px",
    },
  } as ExtendedTypographyOptions,
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          "paddingTop": 10,
          "paddingLeft": 20,
          "paddingRight": 20,
          "paddingBottom": 10,
          "fontSize": 14,
          "color": colors.gray[600],
          "&::placeholder": {
            fontFamily: "Avenir, Montserrat-Regular",
          },
        },
        root: {
          "fontFamily": "Avenir, Montserrat-Regular",
          "fontSize": 14,
          "&.Mui-focused": {
            [`& .${outlinedInputClasses.notchedOutline}`]: {
              border: `1px solid ${colors.gray["200"]}`,
            },
          },
          "&:hover": {
            [`& .${outlinedInputClasses.notchedOutline}`]: {
              border: `1px solid ${colors.gray["200"]}`,
            },
          },
        },
        notchedOutline: {
          border: `1px solid ${colors.gray["200"]}`,
        },
      },
    },

    MuiInput: {
      styleOverrides: {
        root: {
          "&:hover": {
            "&:not(&.Mui-disabled)": {
              "&:before": {
                borderBottom: `1px solid ${colors.secondaryLight}`,
              },
            },
          },
          "&:before": {
            borderBottom: `1px solid ${colors.gray[200]}`,
          },
        },
      },
    },

    MuiTextField: {
      styleOverrides: {
        root: {},
      },
    },

    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: colors.black,
          // fontSize: 16,
          fontFamily: "Avenir",
          fontWeight: 600,
        },
      },
    },

    MuiButton: {
      styleOverrides: {
        root: {
          boxShadow: "none",
          textTransform: "none",
          borderRadius: 4,
        },
        text: {},
        contained: {
          // 'backgroundColor': colors.secondaryLight,
          "&:hover": {
            // backgroundColor: colors.secondary,
            boxShadow: "none",
          },
        },
      },
    },

    MuiSelect: {
      styleOverrides: {
        icon: {
          color: colors.secondary,
        },
      },
    },

    MuiPaper: {
      styleOverrides: {
        root: {
          "&.MuiPopover-paper": {
            boxShadow: `0px 0px 4px 1px ${colors.gray["300"]}`,
          },
        },
      },
    },

    MuiToggleButton: {
      styleOverrides: {
        root: {
          "textTransform": "none",
          "&:hover": {
            borderRadius: 0,
            backgroundColor: "transparent",
            borderBottom: `4px solid ${colors.error}`,
            marginLeft: "0 !important",
            borderLeft: "none !important",
            background: "none",
          },
          "&.Mui-selected": {
            "&:hover": {
              background: "none",
            },
            "marginLeft": 0,
            "borderLeft": "none",
            "background": "none",
            "color": colors.gray["600"],
            "fontWeight": 600,
            "borderBottom": `4px solid ${colors.error}`,
          },
        },
      },
    },

    MuiLink: {
      styleOverrides: {
        root: {
          cursor: "pointer",
        },
      },
    },

    MuiToggleButtonGroup: {
      styleOverrides: {
        root: {
          gap: 20,
        },
        grouped: {
          border: "none",
          borderRadius: 0,
          borderBottom: "4px solid transparent",
          borderTop: "4px solid transparent",
        },
      },
    },

    MuiTableContainer: {
      styleOverrides: {
        root: {
          boxShadow: "none",
          borderBottom: `none`,
        },
      },
    },

    MuiTableCell: {
      styleOverrides: {
        root: {
          fontFamily: "Avenir, Montserrat-Regular",
        },
      },
    },
  },
  palette: {
    secondaryLight: {
      main: colors.secondaryLight,
      light: colors.secondary,
      dark: colors.secondary,
      contrastText: colors.white,
    },
    buttonWhite: {
      main: colors.secondaryLight,
      light: colors.secondary,
      dark: colors.secondary,
      contrastText: colors.white,
    },
    whiteTextButton: {
      main: colors.white,
    },
    blackButton: {
      main: "rgba(255, 255, 255, 0)",
      dark: colors.whiteAlpha,
      contrastText: colors.black,
    },
    common: {
      black: colors.black,
      white: colors.white,
    },
    primary: {
      main: colors.main,
    },
    success: {
      main: colors.main,
    },
    secondary: {
      main: colors.secondary,
      light: colors.secondaryLight,
    },
    error: {
      main: colors.error,
    },
    warning: {
      main: colors.warning,
      light: colors.warningLight,
    },
    info: {
      main: colors.white,
    },
    grey: {
      50: colors.gray["50"],
      100: colors.gray["100"],
      200: colors.gray["200"],
      300: colors.gray["300"],
      400: colors.gray["400"],
      500: colors.gray["500"],
      600: colors.gray["600"],
      700: colors.gray["700"],
      800: colors.gray["800"],
    },
  },
});

export default theme;
