export const joinFormatDate = (date: Date, options: object[], divider: string) => {
  const format = (m: object) => {
    const f = new Intl.DateTimeFormat('en-US', m);
    return f.format(date);
  };
  return options.map(format).join(divider);
};

export const formatDate = (d: string) => {
  const options = [{ month: "2-digit" }, { day: "2-digit" }, { year: "numeric" }];
  return joinFormatDate(new Date(d), options, "/");
};

export const formatDateObject = (d: string) => {
  const options = [{ year: "numeric" }, { month: "2-digit" }, { day: "2-digit" }];
  return joinFormatDate(new Date(d), options, "-");
};