import { ROUTES } from "../../constants/routes";
import { MobileMenuItem } from "../MobileMenu/types";

export const studentMobileMenuItems = (
  onSignOut: () => void,
  onFeedback: () => void,
  onMyAccount: () => void,
  handleNavigateCoverLettersList: () => void,
): MobileMenuItem[] => [
  {
    name: "Request new letter",
    link: ROUTES.NEW_LETTER,
  },
  {
    name: "Request letter status",
    link: ROUTES.LETTER_STATUS,
  },
  {
    name: "My Account",
    link: ROUTES.MY_ACCOUNT,
    onClick: onMyAccount,
  },
  {
    name: "Cover letter list",
    link: ROUTES.COVER_LETTER_STATUS,
    onClick: handleNavigateCoverLettersList,
  },
  {
    name: "Feedback",
    link: ROUTES.FEEDBACK,
    onClick: onFeedback,
  },
  {
    name: "Sign Out",
    onClick: onSignOut,
  },
];

export const professorMobileMenuItems = (
  onSignOut: () => void,
  onFeedback: () => void,
  onMyAccount: () => void,
  handleNavigateCoverLettersList: () => void,
): MobileMenuItem[] => [
  {
    name: "Dashboard",
    link: ROUTES.DASHBOARD_PROFESSOR,
  },
  {
    name: "Write Letter",
    link: ROUTES.REQUEST_SELF_LETTER,
  },
  {
    name: "Requests list",
    link: ROUTES.LETTER_STATUS,
  },
  {
    name: "My Account",
    link: ROUTES.MY_ACCOUNT,
    onClick: onMyAccount,
  },
  {
    name: "Cover letter list",
    link: ROUTES.COVER_LETTER_STATUS,
    onClick: handleNavigateCoverLettersList,
  },
  {
    name: "Feedback",
    link: ROUTES.FEEDBACK,
    onClick: onFeedback,
  },
  {
    name: "Sign Out",
    onClick: onSignOut,
  },
];
