import urls from "./urls";
import { getRequest, patchRequest, postRequest } from "./index";
import { CoverLetterTextRequest } from "../components/NewCoverLetterComposition/Field/types";
import { CoverLetterRequest } from "../views/CoverLetterStatus/types";
import { SeparateCoverRequest } from "../views/LetterStatus/types";

const { coverLetter } = urls();

export const createCoverLetterRequest = async (body: any): Promise<CoverLetterRequest> => {
  return await postRequest(coverLetter.addCoverLetter, body);
};

export const getCoverLetterQuestions = async (): Promise<Array<Record<string, unknown>>> => {
  return await getRequest(coverLetter.getCoverLetterQuestions);
};

export const getGeneratedCoverLetterText = async (letterId: string, temperature: number): Promise<CoverLetterTextRequest> => {
  return await getRequest(coverLetter.getGeneratedText(letterId, temperature));
};

export const getCoverLetter = async (id: string): Promise<CoverLetterRequest> => {
  return await getRequest(coverLetter.getCoverLetter(id));
};

export const getCoverLetterRequests = async (queryParams?: Record<string, string>): Promise<SeparateCoverRequest> => {
  const params = queryParams ? new URLSearchParams(queryParams).toString() : "";
  return await getRequest(coverLetter.getCoverLetters(params));
};

export const updateCoverLetter = async (id: number, body?: unknown): Promise<CoverLetterRequest> => {
  return await patchRequest(coverLetter.updateCoverRequest(id), body);
};
