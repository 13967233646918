import React, { useCallback, useState } from "react";
import { Box } from "@mui/system";
import { Button, Typography } from "@mui/material";
import { PersonSearch } from "@mui/icons-material";
import { InputWithAutocomplete } from "../../../components/InputWithAutocomplete";
import { EmptyAutocomplete } from "../../../components/InputWithAutocomplete/Empty";
import { Option } from "../../../components/InputWithAutocomplete/Option";
import { InviteWriter } from "../InviteWriter";
import { createOptionsFromUsers } from "./utils";
import NotifyBanner from "../../../components/NotifyBanner";
import { getRequest } from "../../../api";
import { debounce } from "../../../utils/debounce";
import { NewLetterControls } from "../Controls";
import { getType } from "../../../api/localStorage/type";
import { AccountTypeEnum } from "../../../constants";
import { TutorialClasses } from "../../../components/Tutorial/tutorialClasses";
import BannerImage from "../../../assets/images/bannerImg2.jpg";

type SelectWriterProps = {
  onNext: (args?: Record<string, any>) => void;
  onCancel: () => void;
};

const MIN_SEARCH_LENGTH = 3;

export const SelectWriter = ({ onNext, onCancel }: SelectWriterProps) => {
  const [options, setOptions] = useState<Array<any> | []>([]);
  const [loading, setLoading] = useState(false);
  const [isInviting, setIsInviting] = useState(false);
  const [selectedWriter, setSelectedWriter] = useState<Record<string, any> | null>(null);

  const accountType = getType();

  const getWriters = useCallback(async (text: string) => {
    try {
      const users = await getRequest(`/writers/?search=${text}`);
      const _options = createOptionsFromUsers(users);
      setOptions(_options);
    } catch (err: unknown) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  }, []);

  const debounceGetWriters = useCallback(debounce(getWriters, 1000), [getWriters]);

  const handleChangeText = (text: string) => {
    if (!loading) {
      setLoading(true);
    }

    if (selectedWriter) {
      setSelectedWriter(null);
    }

    if (text.length < MIN_SEARCH_LENGTH) {
      setOptions([]);
      return;
    }

    debounceGetWriters(text);
  };

  const handleChangeWriter = (writer: Record<string, any> | null) => {
    if (writer) {
      setSelectedWriter(writer);
      setIsInviting(false);
    }
  };

  const handleInvite = () => {
    setIsInviting(true);
    setSelectedWriter(null);
  };

  const handleCancel = () => {
    if (isInviting) {
      setIsInviting(false);
    } else {
      onCancel();
    }
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      className={accountType === AccountTypeEnum.Applicant ? TutorialClasses.secondTutorialStep : ""}
    >
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        {!isInviting && (
          <>
            <Typography mb={5} variant="h6" fontWeight={500}>
              Select your letter writer
            </Typography>
            <InputWithAutocomplete
              id="professors"
              label="Start typing to search for a writer in the system"
              data={options}
              loading={loading}
              placeholder="Enter at least 3 symbols to search"
              onChange={handleChangeWriter}
              onChangeText={handleChangeText}
              OptionElement={Option}
              EmptyElement={EmptyAutocomplete}
              Icon={() => <PersonSearch color="secondary" />}
            />
          </>
        )}
        {!isInviting && (
          <NewLetterControls
            onNext={() => onNext({ request_id: selectedWriter?.request_id })}
            onCancel={handleCancel}
            disabledNext={!selectedWriter}
            nextTitle="Next Step"
          />
        )}
      </Box>
      {!isInviting ? (
        <Box>
          <NotifyBanner
            showArrow={false}
            bannerTitle="Invite writer"
            text="Can't see your writer? Click invite and your request and invitation to Letters will be sent by email"
            image={BannerImage}
            buttons={[
              <Button onClick={handleInvite} sx={{ width: "50%" }} color="secondary" variant="contained">
                Invite
              </Button>,
            ]}
          />
        </Box>
      ) : (
        <Box mt={5}>
          <InviteWriter onNext={onNext} onCancel={handleCancel} />
        </Box>
      )}
    </Box>
  );
};
