import React from "react";
import { SxProps, Theme } from "@mui/material";
import SchoolIcon from "@mui/icons-material/School";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import WorkIcon from '@mui/icons-material/Work';
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';
import { AccountTypeEnum } from "../../constants";
import { colors } from "../../theme";
import { AccountTypeIconProps } from "./types";

export const AccountTypeIcon: React.FC<AccountTypeIconProps> = ({ accountType, color }) => {
  const styles: SxProps<Theme> = {
    width: 100,
    height: 100,
    color: color,
  };
  if (accountType === AccountTypeEnum.Student) {
    return <SchoolIcon sx={styles} pointerEvents="none" />;
  }

  if (accountType === AccountTypeEnum.Professor) {
    return <BorderColorIcon sx={styles} pointerEvents="none" />;
  }

  return <></>;
};

export const LetterTypeIcon: React.FC<{ letterType: string }> = ({ letterType }) => {
  const styles: SxProps<Theme> = {
    width: 100,
    height: 100,
    color: colors.secondary,
  };
  if (letterType === "recommendation") {
    return <HistoryEduIcon sx={styles} pointerEvents="none" />;
  }

  if (letterType === "cover") {
    return <WorkIcon sx={styles} pointerEvents="none" />;
  }

  return <></>;
};