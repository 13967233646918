import React from "react";
import { Link } from "@mui/material";

type CancelCellProps = {
  row: any;
  onViewDetails?: (row?: any) => void;
};

export const DetailsCell = ({ row, onViewDetails }: CancelCellProps) => {
  return (
    <Link color="secondary" onClick={() => onViewDetails && onViewDetails(row)}>
      Details
    </Link>
  );
};
