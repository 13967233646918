import { RequestStatus } from "../LetterStatus/types";
import { CoverLetterRecipient, LetterRecipient } from "./types";
import { updateLetterRequest, withdrawLuciCredits } from "../../api/letterRequest";
import store from "../../redux";
import { setCreditCount } from "../../redux/actionCreators";
import { AccountTypeEnum } from "../../constants";
import { LetterType } from "../../utils/globalLetterTypesCredits";

const CREDIT_USED = LetterType.recommendation;

export const createShortName = (letterRecipient: LetterRecipient | CoverLetterRecipient | undefined): string => {
  return letterRecipient
    ? letterRecipient?.firstName[0]?.toUpperCase() ||
        "" + letterRecipient?.lastName[0]?.toUpperCase() ||
        ""
    : "NA";
};

export const startUsingLuciAssistantRecommendationLetter = async (
  letterId: string,
  user: Record<string, any>,
  accountType?: AccountTypeEnum,
) => {
  if (!letterId || !user) {
    return;
  }

  try {
    const credit_count = user.credit_count - CREDIT_USED;
    const spend = await withdrawLuciCredits(letterId, CREDIT_USED);
    if (!spend?.error) {
      if (accountType && accountType === AccountTypeEnum.Writer) {
        store.dispatch(setCreditCount(credit_count));
      }
      return await updateLetterRequest(letterId, {
        text: "",
        status: RequestStatus.rd,
        credit_used: spend.credit_used === credit_count ? 0 : CREDIT_USED,
      });
    }
    return { error: "Unknown error", spend };
  } catch (err) {
    return { error: err };
  }
};

export const setStudentAccess = async (letterId: string | undefined, studentAccess?: boolean) => {
  if (!letterId) {
    return;
  }

  try {
    return await updateLetterRequest(letterId, {
      student_access: !studentAccess,
    });
  } catch (err) {
    return { error: err };
  }
};
