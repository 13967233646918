import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";

type LetterTypeCellProps = {
  row: any;
};

type LetterType = "Research" | "Class" | null;

export const LetterTypeCell = ({ row }: LetterTypeCellProps) => {
  const [letterType, setLetterType] = useState<LetterType>(null);

  useEffect(() => {
    switch (row.letterTypeId) {
      case 1:
        setLetterType("Research");
        break;
      case 2:
        setLetterType("Class");
        break;
    }
  }, [letterType])

  return (
  <Box textAlign="center">
    <Typography variant="text">{letterType}</Typography>
  </Box>
  );
}