import React, { useCallback, useEffect, useState } from "react";
import { Box } from "@mui/material";
import { EditableField } from "./EditableField";
import { FinishedField } from "./FinishedField";
import { FieldProps } from "./types";
import { getGeneratedCoverLetterText } from "../../../api/coverLetter";

export const Field: React.FC<FieldProps> = ({
  text,
  temperatureLevel,
  temperature,
  isDisabled,
  isFinished,
  isEditable,
  onTextGeneration,
  onShowFinished,
  onFinishLetter,
  letterId,
}) => {
  const [value, setValue] = useState<string>(text);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<unknown | string>("");

  const onChangeText = (newText: string) => setValue(newText);

  const onFinish = () => {
    onFinishLetter({ text: value, finished: true });
  };

  const pasteText = useCallback((text: string) => {
    setValue("");
    onTextGeneration(true);

    let timerDelay = 0;
    const wordDelay = 100;
    const wordArray = text.split(" ");

    wordArray.forEach((word: string, index: number) => {
      setTimeout(() => {
        setValue((prev: string) => (prev ? prev + " " + word : word));
        if (index === wordArray.length - 1) {
          setIsLoading(false);
          onTextGeneration(false);
        }
      }, timerDelay);
      timerDelay += wordDelay;
    });
  }, []);

  const generateText = async (lvl?: number) => {
    if (lvl === null || lvl === undefined) return;

    try {
      setIsLoading(true);
      setError("");
      const { proposed_text } = await getGeneratedCoverLetterText(letterId, temperatureLevel);

      if (proposed_text) {
        pasteText(proposed_text);
      }
    } catch (error: unknown) {
      setError(error || "Error handle getting Generated cover letter");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (temperatureLevel && !value && !isFinished) {
      generateText(temperatureLevel);
    }
  }, []);

  return (
    <>
      {!isFinished ? (
        <Box mt={2} display="flex" flexDirection="column" gap={2}>
          <EditableField
            text={value}
            isDisabled={isDisabled || isLoading}
            isFinished={isFinished}
            isEditable={isEditable}
            onChangeText={onChangeText}
            onFinish={onFinish}
            temperature={
              temperature && temperature({ generateText: () => generateText(temperatureLevel) })
            }
          />
        </Box>
      ) : (
        <FinishedField text={value} onShowFinished={onShowFinished} />
      )}
    </>
  );
};
