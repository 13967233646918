import { deleteRequest, getRequest, patchRequest, postRequest, putRequest } from ".";
import { LetterRequestDocument } from "../components/FileUploadWithProgress/types";
import urls from "./urls";

const { letterRequest } = urls();

export const getLetterRequest = async (id?: string) => {
  if (!id) {
    return;
  }

  try {
    return await getRequest(letterRequest.getRequest(id));
  } catch (err) {
    return { error: err };
  }
};

export const updateLetterRequest = async (id?: string, body?: any) => {
  if (!id || !body) {
    return;
  }

  try {
    const request = await patchRequest(letterRequest.updateRequest(id), body);
    return request;
  } catch (err) {
    return { error: err };
  }
};

export const getAllLetterRequests = async (queryParams?: Record<string, any>) => {
  try {
    const params = queryParams ? new URLSearchParams(queryParams).toString() : "";
    const _l = await getRequest(letterRequest.getAllRequests(params));
    return _l;
  } catch (err) {
    return { error: err };
  }
};

export const createLetterRequest = async (body: any) => {
  try {
    return await postRequest(letterRequest.createRequest, body);
  } catch (err) {
    return { error: err };
  }
};

export const addLinksToRequest = async (requestId: string, links: Array<LetterRequestDocument>) => {
  if (!requestId.toString()) {
    return;
  }

  const body: any = links.map((l: LetterRequestDocument) => ({
    title: l.description,
    link: l.link,
    letter_request: requestId,
  }));

  try {
    return await postRequest(letterRequest.addRequestLinks, body);
  } catch (error) {
    return { error };
  }
};

export const addFilesToRequest = async (
  requestId: string,
  files: Array<LetterRequestDocument>,
): Promise<any> => {
  if (!requestId.toString()) {
    return;
  }

  const filesFormDataArray: Array<FormData> = files.map((f: LetterRequestDocument, index: number) => {
    const fd = new FormData();
    if (f.file) {
      fd.append("date", f?.date?.toString() || "date");
      fd.append("name", f.file?.name || "file");
      fd.append("description", f.description || "");
      fd.append("size", f.size || "0");
      fd.append("file", f?.file, f.file?.name);
      fd.append("letter_request", requestId);
    }
    return fd;
  });

  const batch = filesFormDataArray.map((fd: FormData) =>
    postRequest(letterRequest.addRequestFiles, fd, {
      headers: { "Content-Type": "multipart/form-data" },
    }),
  );

  try {
    return await Promise.all(batch);
  } catch (error) {
    return { error };
  }
};

export const removeFiles = async (ids: Array<string>) => {
  try {
    return await postRequest(letterRequest.deleteFiles, { ids });
  } catch (error) {
    return { error };
  }
};

export const replaceLinks = async (requestId: string, links: Array<LetterRequestDocument>) => {
  if (!requestId.toString()) {
    return;
  }

  const body: any = links.map((l: LetterRequestDocument) => ({
    title: l.description,
    link: l.link,
    letter_request: requestId,
  }));

  try {
    return await postRequest(letterRequest.replaceRequestLinks, {
      links: body,
      request_id: requestId,
    });
  } catch (error) {
    return { error };
  }
};

export const deleteLetterRequest = async (id: string | undefined) => {
  if (!id) {
    return;
  }

  try {
    return await deleteRequest(letterRequest.updateRequest(id));
  } catch (error) {
    return { error };
  }
};

export const withdrawLuciCredits = async (letterId: string, credits: number) => {
  if (credits === undefined || !letterId) {
    return;
  }

  try {
    return await postRequest(letterRequest.withdrawCredits, {
      count: credits,
      letter_id: letterId,
    });
  } catch (error) {
    return { error };
  }
};

export const createSampleRequest = async (): Promise<string> => {
  return await putRequest(letterRequest.sampleRequest)
}

export const deleteSampleRequest = async (): Promise<string> => {
  return await deleteRequest(letterRequest.sampleRequest)
}