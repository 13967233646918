import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Modal } from "@mui/material";
import HistoryEduIcon from "@mui/icons-material/HistoryEdu";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import CloseIcon from "@mui/icons-material/Close";
import ArchiveIcon from "@mui/icons-material/Archive";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import FormatColorTextIcon from "@mui/icons-material/FormatColorText";
import DoneIcon from "@mui/icons-material/Done";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import LockIcon from "@mui/icons-material/Lock";
import TuneIcon from "@mui/icons-material/Tune";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import { setStudentAccess } from "../../views/LetterComposition/utils";
import { deleteLetterRequest, updateLetterRequest } from "../../api/letterRequest";
import { RequestStatus } from "../../views/LetterStatus/types";
import { ControlPopover } from "./ControlPopover";
import { createRequestsFromLetters } from "../../views/LetterStatus/utils";
import {
  deleteRequest,
  setEditRequest,
  setLetterTemplate,
  updateRequest,
} from "../../redux/actionCreators";
import { ControlProfessorListPopoverProps, PopoverList } from "./types";
import { RootState } from "../../redux/reducers";
import { ConfirmationModal } from "../ConfirmationModal";
import { prepareQuestions } from "../../views/NewLetter/utils";
import { ROUTES } from "../../constants/routes";
import { LetterType } from "../../utils/globalLetterTypesCredits";

const MIN_REQUIRED_CREDITS = LetterType.recommendation;
const ZERO_ELEM = 0;

type ModalStatus = {
  title: string;
  actionButton: string;
  action: () => void;
};

export const ControlProfessorListPopover = ({
  open,
  anchor,
  onClose,
  status,
  questions,
  letterId,
  letterTypeId,
  archived = false,
  isSelfRequest = false,
  onViewDetails,
  studentAccess,
  handleOpenStudentAccessModal,
  handleOpenLetterCompositionModal,
  handleNavigateToWriteLetter,
}: ControlProfessorListPopoverProps) => {
  const [listElements, setListElements] = useState<PopoverList[]>([]);
  const [isOpenMultipurposeModal, setIsOpenMultipurposeModal] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [modalStatus, setModalStatus] = useState<ModalStatus | null>(null);
  const [isOpenArchiveModal, setIsOpenArchiveModal] = useState<boolean>(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useSelector((state: RootState) => state.user);

  const handleOpenMultipurposeModal = () => setIsOpenMultipurposeModal(true);
  const handleCloseMultipurposeModal = () => setIsOpenMultipurposeModal(false);

  const handleOpenArchiveModal = () => setIsOpenArchiveModal(true);
  const handleCloseArchiveModal = () => setIsOpenArchiveModal(false);

  const dynamicHandler = async (body: any, successMessage: string, errorMessage: string) => {
    setIsLoading(true);
    const _request = await updateLetterRequest(letterId, body);
    if (!_request.error) {
      const requestCard = createRequestsFromLetters([_request]);
      dispatch(updateRequest(requestCard[ZERO_ELEM]));
      toast.success(successMessage);
      handleOpenArchiveModal();
    } else {
      toast.error(errorMessage);
    }
    handleCloseMultipurposeModal();
    setIsLoading(false);
  };

  const handleArchive = async () => {
    setIsLoading(true);
    const _request = await updateLetterRequest(letterId, { archived: true });
    if (!_request.error) {
      dispatch(deleteRequest(letterId));
      toast.success("Request was successfully archived");
    } else {
      toast.error("An error occurred during letter archive process");
    }
    onClose();
    setIsLoading(false);
  };

  const handleDecline = () => {
    dynamicHandler(
      { status: RequestStatus.d },
      "Request was successfully declined",
      "An error occurred during letter decline process",
    );
  };

  const handleSetStudentAccess = async () => {
    setIsLoading(true);
    const access = await setStudentAccess(letterId, studentAccess);
    dispatch(updateRequest(access));
    setIsLoading(false);
    handleCloseMultipurposeModal();
    if (!access.error) {
      toast.success(`Student access was successfully ${studentAccess ? "disabled" : "granted"}`);
    } else {
      toast.error(access.error[ZERO_ELEM] || "An error occurred during student access process");
    }

    if (!studentAccess && user.credit_count < MIN_REQUIRED_CREDITS) {
      handleOpenStudentAccessModal();
      return;
    }
  };

  const handleSubmit = async () => {
    dynamicHandler(
      { status: RequestStatus.s },
      "Request was successfully submitted",
      "An error occurred during letter submit process",
    );
  };

  const handleDeleteLetterRequest = async () => {
    setIsLoading(true);
    const deletedRequest = await deleteLetterRequest(letterId);
    if (!deletedRequest.error) {
      toast.success("Request was successfully deleted");
      dispatch(deleteRequest(letterId));
    } else {
      toast.error("An error occurred during delete request process");
    }
    onClose();
    setIsLoading(false);
  };

  const handleRequestSimilar = () => {
    const _questions = prepareQuestions(questions);
    dispatch(setLetterTemplate({ template: _questions, letterTypeId }));
    navigate(`${ROUTES.WRITER_NEW_LETTER}?selfRequest=true`);
    onClose();
  };

  const handleEditMode = () => {
    if (onViewDetails) {
      onViewDetails(letterId);
      dispatch(setEditRequest(true));
    }
    onClose();
  };

  const handleSetModalStatus = (title: string, actionButton: string, action: () => void) => {
    return { title, actionButton, action };
  };

  useEffect(() => {
    if (status !== RequestStatus.a) {
      setListElements([
        { handler: handleOpenLetterCompositionModal!, text: "Write Letter", icon: HistoryEduIcon },
      ]);
    }

    if (status === RequestStatus.r && isSelfRequest) {
      setListElements((prevState: PopoverList[]) => [
        ...prevState,
        ...[
          { handler: handleRequestSimilar, text: "Request Similar", icon: ContentCopyIcon },
          {
            handler: () => {
              setModalStatus(
                handleSetModalStatus(
                  "Do you really want to delete this letter request?",
                  "Delete",
                  handleDeleteLetterRequest,
                ),
              );
              handleOpenMultipurposeModal();
            },
            text: "Delete",
            icon: DeleteIcon,
          },
        ],
      ]);
    }

    if (status !== RequestStatus.d && status !== RequestStatus.s) {
      const options = [
        ...(!isSelfRequest && status !== RequestStatus.a
          ? [
            {
              handler: () => {
                setModalStatus(
                  handleSetModalStatus(
                    `Do you really want to ${studentAccess ? "disable" : "enable"} access for this applicant?`,
                    studentAccess ? "Disable" : "Enable",
                    handleSetStudentAccess,
                  ),
                );
                handleOpenMultipurposeModal();
              },
              text: studentAccess ? "Disable student access" : "Enable student access",
              icon: studentAccess ? LockIcon : LockOpenIcon,
            },
          ]
          : []),
        {
          handler: () => {
            setModalStatus(
              handleSetModalStatus(
                "Do you really want to decline this letter request?",
                "Decline",
                handleDecline,
              ),
            );
            handleOpenMultipurposeModal();
          },
          text: "Decline",
          icon: CloseIcon,
        },
      ];
      setListElements((prevState: PopoverList[]) => [
        ...prevState,
        ...(status !== RequestStatus.mi ? [{
          handler: handleEditMode,
          text: "Edit request",
          icon: FormatColorTextIcon,
        }] : []),
        ...options,
      ]);
    } else {
      if (!archived) {
        setListElements([
          {
            handler: () => {
              setModalStatus(
                handleSetModalStatus(
                  "Do you really want to archive this letter request?",
                  "Archive",
                  handleArchive,
                ),
              );
              handleOpenMultipurposeModal();
            },
            text: "Archive",
            icon: ArchiveIcon,
          },
        ]);
      }
    }

    if (status === RequestStatus.f) {
      setListElements([
        { handler: handleNavigateToWriteLetter!, text: "Edit Letter", icon: EditIcon },
        {
          handler: handleSubmit,
          text: "Mark as submitted",
          icon: DoneIcon,
        },
        { handler: handleEditMode, text: "Edit request", icon: FormatColorTextIcon },
      ]);
    }

    if (status === RequestStatus.rd) {
      setListElements([
        { handler: handleNavigateToWriteLetter!, text: "Continue Letter", icon: EditIcon },
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    setListElements((prevState: PopoverList[]) => [
      ...(onViewDetails ? [{ handler: () => onViewDetails(letterId), text: "View Details", icon: TuneIcon }] : []),
      ...prevState,
    ]);

  }, [status, studentAccess]);

  return (
    <>
      <Modal open={isOpenArchiveModal} onClose={handleCloseArchiveModal}>
        <Box width="100%" height="100%" display="flex" alignItems="center" justifyContent="center">
          <ConfirmationModal
            onSubmit={handleArchive}
            onCancel={handleCloseArchiveModal}
            title="Do you want to archive this letter?"
            actionName="Archive"
            isLoading={isLoading}
          />
        </Box>
      </Modal>
      <Modal open={isOpenMultipurposeModal} onClose={handleCloseMultipurposeModal}>
        <Box width="100%" height="100%" display="flex" alignItems="center" justifyContent="center">
          <ConfirmationModal
            onSubmit={modalStatus?.action!}
            onCancel={handleCloseMultipurposeModal}
            title={modalStatus?.title!}
            actionName={modalStatus?.actionButton!}
            isLoading={isLoading}
          />
        </Box>
      </Modal>
      <ControlPopover open={open} anchor={anchor} onClose={onClose} listElements={listElements} />
    </>
  );
};
