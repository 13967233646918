import React, { useEffect, useState } from "react";
import { Box, Typography, useMediaQuery } from "@mui/material";
import { getRequest } from "../../../api";
import { LetterTypeItem } from "../../../components/LetterType";
import Loader from "../../../components/Loader";

type SelectLetterTypeProps = {
  onNext: (args?: Record<string, any>) => void;
  onCancel: () => void;
};

export type LetterType = {
  id: string;
  name: string;
  description: string;
  enabled: boolean;
};

export const SelectLetterType = ({ onNext, onCancel }: SelectLetterTypeProps) => {
  const [types, setTypes] = useState<Array<LetterType> | [] | null>(null);
  const isDesktop = useMediaQuery("(min-width:900px)");

  const getLetterTypes = async () => {
    try {
      const _types = await getRequest("/letter_types/");
      setTypes(_types?.length ? _types : []);
    } catch (err) {
      console.log(err);
    }
  };

  const handleSelect = (t: LetterType) => {
    onNext({ letterTypeId: t?.id });
  };

  useEffect(() => {
    getLetterTypes();
  }, []);

  return (
    <Box boxSizing="border-box">
      <Box width="100%" textAlign="center" marginY={6}>
        <Typography variant="title" fontWeight={500}>
          How do you know each other?
        </Typography>
      </Box>
      {types !== null ? (
        <Box
          display="grid"
          gridTemplateColumns={isDesktop ? "1fr 1fr" : "1fr"}
          columnGap={5}
          rowGap={5}
        >
          {types && types.length > 0 ? (
            types.map((t: LetterType) => <LetterTypeItem key={t.id} type={t} onSelect={handleSelect} />)
          ) : (
            <Box>
              <Typography>No letter types available</Typography>
            </Box>
          )}
        </Box>
      ) : (
        <Box width="100%" py={5} display="flex" justifyContent="center" alignItems="center">
          <Loader />
        </Box>
      )}
    </Box>
  );
};
