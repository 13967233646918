export const setToken = (token: string) => {
  try {
    localStorage.setItem('token', token);
  } catch (error) {
    console.log(error);
  }
};

export const getToken = () => {
  try {
    const token = localStorage.getItem('token');
    return token;
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const removeToken = () => {
  localStorage.removeItem('token');
};
