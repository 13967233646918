import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Avatar, Box, Button, Modal, Tooltip, Typography } from "@mui/material";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import { DueDatePopover } from "./DueDatePopover";
import { LetterDueDate } from "../LetterDueDate/LetterDueDate";
import { ControlProfessorListPopover } from "./ControlProfessorListPopover";
import { ControlStudentListPopover } from "./ControlStudentListPopover";
import { LetterCompositionModal } from "../LetterCompositionModal/LetterCompositionModal";
import { AccountTypeEnum, LETTER_REQUEST_CARD } from "../../constants";
import { LetterRequestCardProps } from "./types";
import { LetterStatusEnum, RequestStatus } from "../../views/LetterStatus/types";
import { getColorByDateDif } from "../../utils/dates";
import { formatDate } from "./utils";
import { getType } from "../../api/localStorage/type";
import { colors } from "../../theme";
import Loader from "../Loader";
import { ROUTES } from "../../constants/routes";
import { TutorialClasses } from "../Tutorial/tutorialClasses";
import { RootState } from "../../redux/reducers";
import { continueTutorial } from "../../redux/actionCreators";
import { useMutationObserver } from "../../hooks/useMutationObserver";
import { FREE_SAMPLE } from "../Tutorial/constants";

export const LetterRequestCard: React.FC<LetterRequestCardProps> = ({
  avatar,
  name = "Name",
  university = "Writer not in a platform",
  letterId,
  width,
  height,
  requestStatus,
  RemindIcon,
  dueDate = "",
  onViewDetails,
  onPlatform,
  accessStudent,
  creditUsed,
  questions,
  archived = false,
  letterTypeId,
  isSelfRequest = false,
  handleOpenStudentAccessModal,
  handleNavigateLetterComposition,
  handleNavigateDefault,
  requestTime,
}) => {
  const [anchorDueDate, setAnchorDueDate] = React.useState<HTMLButtonElement | null>(null);
  const [anchorMenu, setAnchorMenu] = useState<HTMLButtonElement | null>(null);
  const [isOpenLetterCompositionModal, setIsOpenLetterCompositionModal] = useState(false);
  const [destination, setDestination] = useState("");

  const mutationRef = useRef<HTMLElement>(null);

  const { tourActive } = useSelector((state: RootState) => state.tutorial);

  const openDueDate: boolean = Boolean(anchorDueDate);
  const openMenu: boolean = Boolean(anchorMenu);

  const accountType = getType();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isTourContinue = tourActive && creditUsed === FREE_SAMPLE && requestTime;
  const isApplicant = accountType === AccountTypeEnum.Applicant;
  const isFinishedStatus = requestStatus !== RequestStatus.f && requestStatus !== RequestStatus.s &&  requestStatus !== RequestStatus.d;

  const handleOpenLetterCompositionModal = () => setIsOpenLetterCompositionModal(true);
  const handleCloseLetterCompositionModal = () => setIsOpenLetterCompositionModal(false);

  const handleNavigateToWriteLetter = () => {
    navigate(`${ROUTES.WRITE_LETTER}?letterId=${letterId}&mode=${accessStudent ? "constructor" : destination}`);
  };

  const handleClickMenu = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorMenu(event.currentTarget);
  };

  const handleCloseMenu = (): void => {
    setAnchorMenu(null);
  };

  const handleClickDueDate = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorDueDate(event.currentTarget);
  };

  const handleCloseDueDate = (): void => {
    setAnchorDueDate(null);
  };

  const setLetterColor = (): string => {
    switch (requestStatus) {
      case RequestStatus.s:
        return colors.main;
      case RequestStatus.d:
        return colors.error;
      case RequestStatus.r:
        return colors.warning;
      case RequestStatus.a:
        return colors.info;
      case RequestStatus.ru:
        return colors.secondary;
      default:
        return colors.gray[700];
    }
  };

  useEffect(() => {
    if (accessStudent || creditUsed) {
      const destination = creditUsed || accessStudent ? "constructor" : "default";
      setDestination(destination);
    }
  }, [accessStudent, creditUsed, destination]);

  useEffect(() => {
    if (accountType === AccountTypeEnum.Applicant && accessStudent && isOpenLetterCompositionModal) {
      handleCloseLetterCompositionModal();
    }
  }, [isOpenLetterCompositionModal]);

  useMutationObserver(mutationRef, () => {
    dispatch(continueTutorial());
  });

  useEffect(() => {
    if (isTourContinue) {
      mutationRef.current?.classList.add(TutorialClasses.thirdTutorialStep);
    }
  }, [requestTime]);

  return (
    <>
      <Modal open={isOpenLetterCompositionModal} onClose={handleCloseLetterCompositionModal}>
        <Box width="100%" height="100%" display="flex" alignItems="center" justifyContent="center">
          <LetterCompositionModal
            handleChangePageMode={handleNavigateLetterComposition}
            handleNavigateDefault={handleNavigateDefault}
            onClose={handleCloseLetterCompositionModal}
            letterType="recommendation"
          />
        </Box>
      </Modal>
      <Box
        display="flex"
        justifyContent="flex-start"
        alignItems="center"
        paddingTop={2}
        flexDirection="column"
        position="relative"
        boxSizing="border-box"
        paddingBottom={5}
        paddingX={2}
        width={width || LETTER_REQUEST_CARD.width}
        height={height || LETTER_REQUEST_CARD.height}
        bgcolor="white"
        borderRadius={2}
        borderTop={`6px solid ${getColorByDateDif(dueDate, requestStatus as RequestStatus)}`}
      >
        <Box
          display="flex"
          width="100%"
          flexDirection="row"
          justifyContent="center"
          gap={2.5}
          zIndex={1}
          top={10}
          right={15}
          position="absolute"
        >
          <Typography pl={4} variant="smallerText" color={colors.gray[400]}>
            Due date:{" "}
            <Typography variant="smallerText" color={colors.black}>
              {formatDate(dueDate)}
            </Typography>
          </Typography>
          {isFinishedStatus && (
            <Button
              onClick={handleClickDueDate}
              sx={{ color: colors.black, borderRadius: "50%", minWidth: "auto", padding: "5px" }}
            >
              {openDueDate && (
                <DueDatePopover
                  open={openDueDate}
                  anchor={anchorDueDate}
                  onClose={handleCloseDueDate}
                  icon={<LetterDueDate dueDate={dueDate} status={requestStatus as RequestStatus} />}
                  date={formatDate(dueDate)}
                />
              )}
              <LetterDueDate dueDate={dueDate} status={requestStatus as RequestStatus} />
            </Button>
          )}
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          width="100%"
          alignItems="center"
          position="relative"
          gap={0.5}
          paddingY={2}
        >
          <Avatar
            sx={{ width: 64, height: 64, border: "2px solid orange", opacity: avatar ? 1 : 0.5 }}
            src={avatar}
            alt=""
          />
          <Tooltip
            title={name}
            arrow
            placement="top"
            disableHoverListener={name?.length <= 20}
            componentsProps={{
              tooltip: {
                sx: {
                  display: "flex",
                  p: 1,
                  flexDirection: "column",
                  justifyContent: "center",
                  bgcolor: colors.textGray,
                },
              },
            }}
          >
            <>
              <Typography
                textAlign="center"
                variant="mediumTitle"
                fontWeight={500}
                noWrap
                overflow="hidden"
                width="200px"
                textOverflow="ellipsis"
              >
                {name}
              </Typography>
              {accountType === AccountTypeEnum.Writer && (
                <Typography
                  textAlign="center"
                  variant="smallText"
                  fontWeight={500}
                  noWrap
                  overflow="hidden"
                  width="200px"
                  textOverflow="ellipsis"
                >
                  Letter for {isSelfRequest ? `${questions[0].answer} ${questions[1].answer}` : name}
                </Typography>
              )}
            </>
          </Tooltip>
          <Typography
            textAlign="center"
            variant="smallText"
            color={onPlatform ? colors.black : colors.error}
            noWrap
            overflow="hidden"
            width="100%"
            textOverflow="ellipsis"
          >
            {onPlatform ? university : "Writer not in a platform"}
          </Typography>
        </Box>
        {requestStatus === RequestStatus.a &&
          <Box position="absolute" top={isApplicant ? 150 : 170}>
            <Loader size={20} speedMs={100} />
          </Box>}
        <Typography
          ref={isApplicant ? mutationRef : null}
          mt={isApplicant ? 3 : 1}
          mb={1}
          textAlign="center"
          variant="smallText"
          color={colors.gray[400]}
          className={creditUsed === FREE_SAMPLE
          && isApplicant ? TutorialClasses.thirdTutorialStep : ""}
        >
          Status:
          {requestStatus === RequestStatus.a && accountType === AccountTypeEnum.Writer ? (
            <Tooltip
              title="This letter request will be analyzed using Artificial Intelligence.
          It may analyze up to 20 min, depending on complexity."
              placement="top"
            >
              <Typography pl={0.5} color={setLetterColor} variant="smallText" fontWeight={900}>
                {LetterStatusEnum[requestStatus as keyof typeof LetterStatusEnum]}
              </Typography>
            </Tooltip>
          ) : (
            <Typography pl={0.5} color={setLetterColor} variant="smallText" fontWeight={900}>
              {LetterStatusEnum[requestStatus as keyof typeof LetterStatusEnum]}
            </Typography>)}
        </Typography>
        <Box display="flex" flexDirection="row" gap={1.5} mt={1}>
          <Button variant="outlined" color="secondary" onClick={onViewDetails}>
            <Typography variant="smallText" fontWeight={500}>
              Write letter
            </Typography>
          </Button>
          <Button
            sx={{ minWidth: "auto", padding: "8px" }}
            onClick={handleClickMenu}
            variant="outlined"
            color="secondary"
          >
            <MoreVertOutlinedIcon pointerEvents="none" sx={{ color: colors.black }} />
          </Button>
          {accountType === AccountTypeEnum.Writer ? (
            openMenu && (
              <ControlProfessorListPopover
                open={openMenu}
                anchor={anchorMenu}
                onClose={handleCloseMenu}
                status={requestStatus}
                questions={questions}
                letterId={letterId}
                letterTypeId={letterTypeId}
                archived={archived}
                isSelfRequest={isSelfRequest}
                onViewDetails={onViewDetails}
                studentAccess={accessStudent}
                handleOpenStudentAccessModal={handleOpenStudentAccessModal}
                handleOpenLetterCompositionModal={handleOpenLetterCompositionModal}
                handleNavigateToWriteLetter={handleNavigateToWriteLetter}
              />
            )
          ) : (
            <ControlStudentListPopover
              open={openMenu}
              anchor={anchorMenu}
              onClose={handleCloseMenu}
              letterId={letterId}
              onViewDetails={onViewDetails}
              status={requestStatus}
              accessStudent={accessStudent}
              questions={questions}
              letterTypeId={letterTypeId}
              handleOpenLetterCompositionModal={handleOpenLetterCompositionModal}
              handleNavigateToWriteLetter={handleNavigateToWriteLetter}
            />
          )}
        </Box>
      </Box>
    </>
  );
};
