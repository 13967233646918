import React from "react";
import { Box, Button, Link } from "@mui/material";
import { ContentCopy, DeleteOutline, ModeEdit } from "@mui/icons-material";
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import InfoIcon from '@mui/icons-material/Info';
import { RequestStatus } from "../../views/LetterStatus/types";

type ControlMenuStudentProps = {
  onDelete: () => void;
  onEdit: () => void;
  request: null | Record<string, any>;
  onWriteLetter?: () => void;
  onRequestSimilar: () => void;
  onRequestMoreInfo: () => void;
};

export const ControlMenuStudent: React.FC<ControlMenuStudentProps> = ({
  onDelete,
  onEdit,
  onWriteLetter,
  request,
  onRequestSimilar,
  onRequestMoreInfo,
}) => {
  const status = request?.status;
  const studentAccess = request?.student_access;
  const IS_VALID_FOR_WRITE_LETTER = studentAccess && status !== RequestStatus.d && status !== RequestStatus.s;
  const IS_VALID_FOR_EDIT_REQUEST = !studentAccess && (status === RequestStatus.r || status === RequestStatus.mi);

  return (
    <>
      <Box display="flex" flexDirection="row" gap={4} alignItems="center">
        {IS_VALID_FOR_EDIT_REQUEST && (
          <Box display="flex" alignItems="center" gap={0.5}>
            <ModeEdit fontSize="small" color="secondary" />
            <Link onClick={onEdit} fontSize={14} color="secondary">
              Edit Request
            </Link>
          </Box>
        )}
        {IS_VALID_FOR_WRITE_LETTER && (
          <Button
            disabled={!onWriteLetter}
            onClick={onWriteLetter}
            variant="contained"
            color="secondary"
          >
            Write Letter
          </Button>
        )}
        <Box display="flex" alignItems="center" gap={0.5}>
          <ContentCopy fontSize="small" color="secondary" />
          <Link fontSize={14} color="secondary" onClick={onRequestSimilar}>
            Request Similar Letter
          </Link>
        </Box>
        {status === RequestStatus.mi && (
          <Box display="flex" alignItems="center" gap={0.5}>
            <LibraryBooksIcon fontSize="small" color="secondary" />
            <Link onClick={onRequestMoreInfo} fontSize={14} color="secondary">
              Provide more info
            </Link>
          </Box>
        )}
        {status === RequestStatus.ru && (
            <Box display="flex" alignItems="center" gap={0.5}>
              <InfoIcon fontSize="small" color="secondary" />
              <Link onClick={onRequestMoreInfo} fontSize={14} color="secondary">
                More info
              </Link>
            </Box>
        )}
      </Box>
      {status === RequestStatus.r && !studentAccess && (
        <Box display="flex" alignItems="center" gap={0.5}>
          <DeleteOutline fontSize="small" color="secondary" />
          <Link onClick={onDelete} fontSize={14} color="secondary">
            Delete request
          </Link>
        </Box>
      )}
    </>
  );
};
