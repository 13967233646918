import React from "react";
import { Download } from "@mui/icons-material";
import { Box, Button, Typography } from "@mui/material";

type FinishedLetterProps = {
  text: string;
  onEdit: () => void;
  onDownload: () => void;
};

export const FinishedLetter = ({ text, onDownload, onEdit }: FinishedLetterProps) => {
  return (
    <Box>
      <Typography mb={2}>{text}</Typography>
      <Box
        width="100%"
        display="flex"
        flexDirection="row"
        py={2}
        boxSizing="border-box"
        bgcolor="white"
      >
        <Box display="flex" flexDirection="row" gap={2} py={1} width="100%" alignItems="center">
          <Button color="secondary" variant="outlined" onClick={onEdit}>
            Edit Letter
          </Button>

          <Button variant="outlined" color="secondary" onClick={onDownload}>
            <Download />
            Download TXT
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
