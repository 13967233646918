import React from 'react'
import {
  Box,
  List,
  ListItem,
  ListItemButton,
  Popover,
  SvgIcon,
  Typography
} from "@mui/material";
import { ControlPopoverProps, PopoverList } from "./types";

export const ControlPopover = ({
 open,
 anchor,
 onClose,
 listElements
}: ControlPopoverProps) => {
  return (
    <Popover
      open={open}
      anchorEl={anchor}
      onClose={onClose}
      anchorOrigin={{
        vertical: 50,
        horizontal: -135,
      }}
    >
      {listElements.length ? (
        <List sx={{ display: "flex", width: "160px", flexDirection: "column", justifyContent: "start" }}>
          {listElements.map((el: PopoverList) => {
            return (
              <ListItem key={el.text} disablePadding>
                <ListItemButton onClick={el.handler}>
                  <Box display="flex" alignItems="center" gap={1}>
                    <SvgIcon component={el.icon} fontSize="small" />
                    <Typography variant="smallText">{el.text}</Typography>
                  </Box>
                </ListItemButton>
              </ListItem>
            );
          })}
        </List>
      ) : <Box p={2}>no actions available right now</Box>}
    </Popover>
  )
}