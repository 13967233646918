import React, { useState } from "react";
import { Box, Link, Modal, Typography } from "@mui/material";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import { CoverLetterRequestCardProps } from "./types";
import { FinishedCoverLetterModal } from "../NewCoverLetterComposition/FinishedCoverLetterModal";
import { downloadCoverLetterTxt } from "../LetterCompositionConstructor/utils";
import { COVER_LETTER_REQUEST_CARD } from "../../constants";
import { colors } from "../../theme";
import { CoverLetterCardRow } from "./CoverLetterCardRow";
import { formatDate } from "../../utils/joinFormatDate";

export const  CoverLetterRequestCard: React.FC<CoverLetterRequestCardProps> = ({
  applicant_first_name,
  applicant_last_name,
  employer,
  initial_date,
  letter_id,
  target_role,
  text = "",
  width,
  height,
}) => {
  const [isOpenCoverLetterModal, setIsOpenCoverLetterModal] = useState(false);

  const handleOpenCoverLetterModal = () => setIsOpenCoverLetterModal(true);

  const handleCloseCoverLetterModal = () => setIsOpenCoverLetterModal(false);

  return (
    <>
      <Modal open={isOpenCoverLetterModal} onClose={handleCloseCoverLetterModal}>
        <Box display="flex" width="100%" height="100%" alignItems="center" justifyContent="center">
          <FinishedCoverLetterModal
            text={text as string}
            letterId={letter_id}
            onClose={handleCloseCoverLetterModal}
            onDownload={() => downloadCoverLetterTxt(text as string)}
          />
        </Box>
      </Modal>
      <Box
        display="flex"
        alignItems="flex-start"
        flexDirection="column"
        justifyContent="space-between"
        boxSizing="border-box"
        borderRadius={2}
        p={4}
        gap={2}
        boxShadow="0px 1px 8px rgba(0, 0, 0, 0.18)"
        width={width || COVER_LETTER_REQUEST_CARD.width}
        height={height || COVER_LETTER_REQUEST_CARD.height}
      >
        <Box display="flex" flexDirection="row" alignItems="center" gap={1}>
          <CalendarTodayOutlinedIcon sx={{ color: colors.secondary, width: "13px", height: "13px" }} />
          <Typography variant="smallerText">{formatDate(initial_date)}</Typography>
        </Box>
        <CoverLetterCardRow title="Applied for: " text={employer} />
        <CoverLetterCardRow title="Applicant: " text={`${applicant_first_name} ${applicant_last_name}`} />
        <CoverLetterCardRow title="Role: " text={target_role} />
        <Box display="flex" flexDirection="row" gap={2}>
          <Link onClick={handleOpenCoverLetterModal} color={colors.secondary}>
            <Typography variant="text">
              View letter
            </Typography>
          </Link>
        </Box>
      </Box>
    </>
  );
};