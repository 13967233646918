import { Question } from "../../components/Questionnaire/types";
import { CoverLetterRequest } from "../CoverLetterStatus/types";

export type LetterRequest = {
  id: number;
  avatar: string;
  letter_id: string;
  status: RequestStatus;
  date_requested: string;
  letter_due_date: string;
  opportunity_applied_for?: string;
  request_send_to?: string;
  request_display_name: string;
  target_university: string;
  writer_on_platform: boolean;
  target_submission_instructions: string;
  student_access: boolean;
  credit_used: string;
  questions: Question[];
  archived: boolean;
  letterTypeId: string;
  moreInfoRequest: string;
  moreInfoResponse: string;
  is_self_request: boolean;
};

export type SeparateRecommendationRequest = {
  count: number;
  next: string;
  page_size: number;
  previous: string;
  results: LetterRequest[];
  total_pages: number;
};

export type SeparateCoverRequest = SeparateRecommendationRequest & {
  results: CoverLetterRequest[];
};

export type ModalsType = "details" | "decline" | "delete";

export enum RequestTab {
  "all" = "all",
  "archive" = "archive",
  "draft" = "draft",
}

export enum LetterStatusEnum {
  r = "Request received",
  d = "Request declined",
  s = "Letter submitted",
  f = "Letter Finished",
  rd = "Rough draft",
  mi = "More info required",
  ru = "Request received (Info updated)",
  a = "Request analyzing",
}

export enum RequestStatus {
  "r" = "r",
  "d" = "d",
  "s" = "s",
  "f" = "f",
  "rd" = "rd",
  "mi" = "mi",
  "ru" = "ru",
  "a" = "a",
}
