import React, { useEffect, useState } from "react";
import { Box, Checkbox, FormControlLabel, FormGroup, Typography } from "@mui/material";
import { enableNotifications, getNotificationSettings } from "../../../api/notifications";
import { NotificationsSettingsType } from "./types";
import Loader from "../../../components/Loader";
import { reminderOptions } from "./utils";

export const RemindersAndNotifications = () => {
  const [range, setRange] = useState<Array<number>>([]);
  const [id, setId] = useState<number>(1);
  const [isSendEmailNotifications, setEmailNotifications] = useState<boolean>(false);
  const [isSendDueDateReminder, setDueDateReminder] = useState<boolean>(false);
  const [notificationSettings, setNotificationsSettings] = useState<NotificationsSettingsType>();
  const [isLoading, setLoading] = useState<boolean>(false);

  const handleEnableEmailNotifications = (event: React.SyntheticEvent<Element, Event>, checked: boolean) => {
    setEmailNotifications(checked);
  };

  const updateReminderRange = (v: number) => {
    const _range = [...range];
    const existing = _range.find((day) => day === v);

    if (existing) {
      const _r = _range.filter((day) => day !== v);
      setRange(_r);
    } else {
      _range.push(v);
      setRange(_range);
    }
  };

  const getSettings = async () => {
    try {
      setLoading(true);
      const settings = await getNotificationSettings();
      setNotificationsSettings(settings[settings.length - 1]);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getSettings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      setId(notificationSettings?.id!);
      setEmailNotifications(notificationSettings?.send_email!);
      setDueDateReminder(notificationSettings?.remind_due_date!);
      setRange(notificationSettings?.quantity_days!);
    }
    return () => {
      isMounted = false;
    };
  }, [notificationSettings]);

  useEffect(() => {
    if (notificationSettings) {
      enableNotifications(id, isSendEmailNotifications, isSendDueDateReminder, range);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSendEmailNotifications, isSendDueDateReminder, range]);

  return (
    <Box display="flex" flexDirection="column" width="100%" gap={4}>
      {isLoading ? (
        <Loader size={100} speedMs={200} />
      ) : (
        <>
          <Box width="100%" display="flex" flexDirection="column" alignItems="flex-start" mt={6}>
            <Typography variant="text" fontWeight={900} mb={2}>
              Notifications
            </Typography>

            <FormGroup>
              <FormControlLabel
                checked={isSendEmailNotifications}
                onChange={handleEnableEmailNotifications}
                control={<Checkbox color="secondary" />}
                label={
                  <Typography variant="text">Set to send me notifications to email</Typography>
                }
              />
            </FormGroup>
          </Box>
          <Box width="100%" display="flex" flexDirection="column" alignItems="flex-start">
            <Typography variant="text" fontWeight={900} mb={2}>
              Reminders
            </Typography>

            <FormGroup>
              <Box display="flex" gap={2} flexDirection="column">
                <Typography variant="text">
                  Set to send me reminders to email about due date before
                </Typography>
                <Box display="flex" flexDirection="column">
                  {reminderOptions.map((option) => (
                    <FormControlLabel
                      key={option.title}
                      checked={Boolean(range?.find((d: number) => d === option.value))}
                      onChange={() => updateReminderRange(option.value)}
                      control={<Checkbox color="secondary" />}
                      label={<Typography variant="text">{option.title}</Typography>}
                    />
                  ))}
                </Box>
              </Box>
            </FormGroup>
          </Box>
        </>
      )}
    </Box>
  );
};
