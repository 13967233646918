import React, { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useSelector } from "react-redux";
import { privateRoutes, publicRoutes } from "./constants/routes";
import "./styles/fonts.css";
import "./styles/App.css";
import { PrivateRoute } from "./components/PrivateRoute";
import { PublicRoute } from "./components/PublicRoute";
import { AlertHandler } from "./components/AlertHandler";
import { ApplicantTutorial } from "./components/Tutorial/ApplicantTutorial";
import { getType } from "./api/localStorage/type";
import { AccountTypeEnum } from "./constants";
import { WriterTutorial } from "./components/Tutorial/WriterTutorial";
import { RootState } from "./redux/reducers";

function App() {
  const [type, setType] = useState<string | null>(null);
  const accountType = getType();
  const { user } = useSelector((state: RootState) => state.user);

  useEffect(() => {
    setType(accountType);
  }, [user]);


  return (
    <>
      <BrowserRouter>
        <Routes>
          {privateRoutes.map(({ path, element }) => (
            <Route key={path} path={path} element={<PrivateRoute>{element}</PrivateRoute>} />
          ))}
          {publicRoutes.map(({ path, element }) => (
            <Route key={path} path={path} element={<PublicRoute>{element}</PublicRoute>} />
          ))}
        </Routes>
        {type === AccountTypeEnum.Writer ? <WriterTutorial /> : <ApplicantTutorial />}
      </BrowserRouter>
      <AlertHandler />
    </>
  );
}

export default App;
