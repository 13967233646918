import React from "react";
import { Button, Typography } from "@mui/material";
import { colors } from "../../theme";
import { ReactComponent as Union } from "../../assets/images/Union.svg";

type LuciButtonProps = {
  onClick?: () => void;
  text?: string;
}

export const LuciButton = ({onClick, text}: LuciButtonProps) => {
  return (
    <Button
      onClick={onClick}
      variant="contained"
      startIcon={<Union style={{ width: 32, height: 32 }} />}
      sx={{
        color: colors.white,
        background: "linear-gradient(94.82deg, #F4A259 -18.26%, #BC4B51 127.76%)",
        paddingX: 3,
      }}>
      <Typography variant="text">{text}</Typography>
    </Button>
  )
}