import React, { useState } from "react";
import { Box, Button, Typography, useMediaQuery } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { patchRequest } from "../../api";
import { getType } from "../../api/localStorage/type";
import { InputWithLabel } from "../../components/InputWithLabel";
import PageTemplate from "../../components/PageTemplate/index";
import PasswordHint from "../../components/PasswordHint";
import { ROUTES } from "../../constants/routes";
import { ResetPasswordForm } from "./types";
import {
  includeLoweCaseChar,
  includeNumber,
  includeSpecialChar,
  includeUpperCaseChar,
  minLength,
  required,
  valuesAreEqual,
  valuesAreNotEqual,
} from "../../utils/validation";
import Loader from "../../components/Loader";

export const ResetPassword = () => {
  const {
    control,
    watch,
    setError,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<ResetPasswordForm>({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: {
      old_password: "",
      new_password1: "",
      new_password2: "",
    },
  });

  const [isLoading, setIsLoading] = useState(false);
  const isDesktop = useMediaQuery("(min-width:900px)");
  const navigate = useNavigate();
  const fields = watch();

  const handleChange = async (data: ResetPasswordForm) => {
    setIsLoading(true);
    try {
      const response = await patchRequest("/password_change/", data);
      if (response?.code === 200) {
        navigate(ROUTES.MY_ACCOUNT);
      }
    } catch (err: any) {
      if (err.non_field_errors) {
        const _e = err.non_field_errors.join(" ");

        setError("new_password1", { type: "custom", message: _e });
      } else {
        const keys = Object.keys(err);

        keys.forEach(k => {
          if (err[k]?.length) {
            const _e = err[k].join(", ");
            setError(k as keyof ResetPasswordForm, { type: "custom", message: _e });
          }
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleGoBack = () => {
    navigate(ROUTES.MY_ACCOUNT);
  };

  const onEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.code === "Enter" || e.code === "NumpadEnter") {
      handleSubmit(handleChange)();
    }
  };

  return (
    <PageTemplate type={getType()}>
      <Box
        width="100%"
        height="100%"
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        paddingX={3}
        boxSizing="border-box"
      >
        <Box
          height="100%"
          boxSizing="border-box"
          justifyContent="center"
          display="flex"
          flexDirection="column"
          width={isDesktop ? 360 : "auto"}
        >
          <Box marginBottom={4}>
            <Typography variant="h5">Change Password</Typography>
          </Box>
          <Controller
            control={control}
            name="old_password"
            rules={{
              validate: {
                required,
              },
            }}
            render={({ field }) => (
              <InputWithLabel
                onKeyDown={onEnter}
                errorMessage={errors.old_password?.message}
                label="Old Password"
                type="password"
                id="old-password"
                {...field}
              />
            )}
          />
          <Controller
            control={control}
            name="new_password1"
            rules={{
              validate: {
                required,
                includeNumber: includeNumber("At least one number (0-9)"),
                includeLoweCaseChar: includeLoweCaseChar(
                  "Password should contain at least one lowercase letter (a-z)",
                ),
                includeUpperCaseChar: includeUpperCaseChar(
                  "Password should contain at least one uppercase letter (A-Z)",
                ),
                includeSpecialChar: includeSpecialChar(
                  "Password should contain at least one special character",
                ),
                minLength: minLength(10, "Password should contain at least 10 characters"),
                notSame: valuesAreNotEqual(
                  fields.old_password,
                  "New password can not be same as old",
                ),
              },
            }}
            render={({ field }) => (
              <InputWithLabel
                onKeyDown={onEnter}
                wrapperStyle={{ mt: 2 }}
                passwordHint={<PasswordHint value={field.value} />}
                errorMessage={errors.new_password1?.message}
                label="New Password"
                type="password"
                id="new-password"
                {...field}
              />
            )}
          />
          <Controller
            control={control}
            name="new_password2"
            rules={{
              validate: {
                required,
                valuesAreEqual: valuesAreEqual(
                  fields.new_password1,
                  "New password and Confirm should be same",
                ),
              },
            }}
            render={({ field }) => (
              <InputWithLabel
                wrapperStyle={{ mt: 2 }}
                onKeyDown={onEnter}
                errorMessage={errors.new_password2?.message}
                label="Confirm New Password"
                type="password"
                id="confirm-password"
                {...field}
              />
            )}
          />
          <Box display="flex" justifyContent="flex-start" marginTop={2} gap={3}>
            <Button
              disabled={isLoading}
              variant="contained"
              color={isValid ? "secondary" : "secondaryLight"}
              onClick={handleSubmit(handleChange)}
            >
              {isLoading ? <Loader size={24} /> : "Change Password"}
            </Button>
            <Button
              disabled={isLoading}
              variant="outlined"
              color="secondaryLight"
              onClick={handleGoBack}
            >
              Cancel
            </Button>
          </Box>
        </Box>
      </Box>
    </PageTemplate>
  );
};
