import { getRequest } from "../api";
import { AccountTypeEnum } from "../constants";

export type AccountType = {
  key: string;
  value: AccountTypeEnum;
  label: string;
};

const createAccountTypes = (apiTypes: any): AccountType[] => {
  const data = apiTypes.map((t: any, index: number) => ({
    key: t?.url || index,
    value: t?.account_type_name || "",
    label: t?.account_type_display_name || "",
  }));

  return data;
};

export const getAccountTypes = async (): Promise<AccountType[] | []> => {
  try {
    const types = await getRequest("/account_types/");
    const _accountTypes = createAccountTypes(types);
    return _accountTypes;
  } catch (err: any) {
    console.log("Get types error: ", err?.message);
    return [];
  }
};
