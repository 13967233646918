import React from "react";
import { Box, Typography, useMediaQuery } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { getType } from "../../api/localStorage/type";
import { LuciButton } from "../../components/LuciButton";
import PageTemplate from "../../components/PageTemplate";
import { ROUTES } from "../../constants/routes";
import { colors } from "../../theme";
import { AboutLuciCredits } from "./AboutLuciCredits";
import { ReferralLink } from "./ReferralLink";
import LuciAssistant from './../../assets/images/LUCI_AI.svg'

export const Referral = () => {
  const navigate = useNavigate();
  const isDesktop = useMediaQuery("(min-width:900px)");

  return (
    <PageTemplate type={getType()}>
      <Box width="100%">
        <Box
          justifyContent="center"
          pt={5}
          display="flex"
          flexDirection="column"
          width="100%"
          margin="0 auto"
          maxWidth={1240}
        >
          <Box
            alignItems="center"
            px={isDesktop ? 18 : 3}
            py={5}
            bgcolor={colors.gray[30]}
            display="flex"
            flexDirection="row"
          >
            <Box display="flex" flexDirection="column">
              <Typography mb={2.5} variant="title" fontWeight={500}>
                Meet LUCI
              </Typography>
              <Typography
                mb={5}
                variant="mediumTitle"
                fontWeight={500}
                color={`${colors.gray[40]}`}
                maxWidth={470}
              >
                LUCI is a virtual assistant who will support you in being your most expressive self.
                Think of LUCI as your co-writing partner who is trained in the art of composing the
                most compelling letters of recommendation using the applicant's input.
              </Typography>
              <Box>
                <LuciButton text="Get LUCI" onClick={() => navigate(ROUTES.SUBSCRIPTION)} />
              </Box>
            </Box>
            {isDesktop && (
              <Box ml={24}>
                <img src={LuciAssistant} alt="luci_ref" />
              </Box>
            )}
          </Box>
          <Box
            px={5}
            display="flex"
            flexDirection={isDesktop ? "row" : "column"}
            mt={6}
            gap={6}
          >
            <AboutLuciCredits />
            <ReferralLink />
          </Box>
        </Box>
      </Box>
    </PageTemplate>
  );
};
