import urls from "./urls";
import { AccountType, UserData } from "../views/SignUp/types";
import { postRequest } from "./index";

const { registration } = urls();

export const handleAcceptInvite = async (user: UserData, requestId: string) => {
  if (!user || !requestId) {
    return;
  }

  try {
    const body = {
      request_id: requestId,
      first_name: user.first_name,
      last_name: user.last_name,
      email: user.email,
      password1: user.password,
      password2: user.password,
    };
    return postRequest(registration.acceptInvite, body);
  } catch (error) {
    return { error };
  }
};

export const handleSignUp = async (user: UserData, account_type: AccountType) => {
  if (!user || !account_type) {
    return;
  }

  try {
    return postRequest(registration.signUp, { user, account_type });
  } catch (error) {
    return { error };
  }
};

export const handleSignUpByReferral = async (
  user: UserData,
  account_type: AccountType,
  referralId: string,
) => {
  if (!user || !referralId || !account_type) {
    return;
  }

  try {
    return postRequest(registration.signUpByReferral(referralId), {
      user,
      account_type,
    });
  } catch (error) {
    return { error };
  }
};
