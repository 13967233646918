import { useCallback, useState } from "react";
import { Box } from "@mui/material";
import { getRequest } from "../../api";
import Loader from "../../components/Loader";
import PageTemplate from "../../components/PageTemplate/index";
import { debounce } from "../../utils/debounce";
import { createOptionsFromUsers } from "./utils";

export const Polygon = () => {
  const [options, setOptions] = useState<Array<any> | []>([]);

  const onChangeText = useCallback(async (text: string) => {
    let _options;
    if (!text.length) {
      _options = [...options];
    } else {
      const users = await getRequest(`/writers/?search=${text}`);
      _options = createOptionsFromUsers(users);
    }
    setOptions(_options);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const d = debounce(onChangeText, 500);

  const handleChangeText = (text: string) => {
    d(text);
  };

  const handleSelect = (item: Record<string, any> | null) => console.log(item);

  return (
    <PageTemplate>
      <Box
        width={"100%"}
        height="100%"
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        paddingX={3}
        boxSizing="border-box"
      >
        <Loader size={40} speedMs={300}/>
      </Box>
    </PageTemplate>
  );
};
