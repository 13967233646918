import { useEffect } from "react";
import Joyride, { CallBackProps } from "react-joyride";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { TutorialTooltip } from "../TutorialTooltip";
import { ROUTES } from "../../../constants/routes";
import { getType } from "../../../api/localStorage/type";
import { AccountTypeEnum } from "../../../constants";
import { TutorialClasses } from "../tutorialClasses";
import { RootState } from "../../../redux/reducers";
import {
  completelyStopTutorial,
  continueTutorial,
  setSteps,
  stopTutorial,
} from "../../../redux/actionCreators";
import { deleteSampleRequest } from "../../../api/letterRequest";

export const ApplicantTutorial = () => {

  const { run, stepIndex, steps } = useSelector((state: RootState) => state.tutorial);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const accountType = getType();

  useEffect(() => {
    dispatch(setSteps([
        {
          target: `.${TutorialClasses.firstTutorialStep}`,
          content: "Here you can request new letter",
          disableBeacon: true,
          placement: "bottom-start",
        },
        {
          target: `.${TutorialClasses.secondTutorialStep}`,
          content: "Invite or choose writer from the list",
          disableBeacon: true,
          placement: "right",
        },
        {
          target: `.${TutorialClasses.thirdTutorialStep}`,
          content: "Here you can check status of your request",
          disableBeacon: true,
          placement: "bottom-start",
        },
        {
          target: `.${TutorialClasses.fourthTutorialStep}`,
          content: "Here you can see your requests in the list",
          disableBeacon: true,
          placement: "bottom-start",
        },
        {
          target: `.${TutorialClasses.fifthTutorialStep}`,
          content: "Here you will see notifications",
          disableBeacon: true,
          placement: "bottom-end",
        },
        {
          target: `.${TutorialClasses.sixthTutorialStep}`,
          content: "Here is your account",
          disableBeacon: true,
        },
        {
          target: `.${TutorialClasses.seventhTutorialStep}`,
          content: "Before you get started please include as much information about yourself as possible",
          disableBeacon: true,
          placement: "right",
        },
      ],
    ));
  }, []);

  const handleCallback = async (data: CallBackProps) => {
    const { action, index, type } = data;

    if (action === "prev" || action === "stop") {
      navigate(ROUTES.HOME);
    }

    if (type === "step:after" && action !== "prev") {
      switch (index) {
        case 0: {
          if (action === "next") {
            dispatch(continueTutorial());
            navigate(ROUTES.NEW_LETTER);
          }
          break;
        }
        case 1: {
          dispatch(stopTutorial());
          navigate(accountType === AccountTypeEnum.Writer ? ROUTES.DASHBOARD_PROFESSOR : ROUTES.DASHBOARD_APPLICANT);
          break;
        }
        case 2: {
          dispatch(stopTutorial());
          navigate(ROUTES.LETTER_STATUS);
          break;
        }
        case 3: {
          dispatch(stopTutorial());
          navigate(accountType === AccountTypeEnum.Writer ? ROUTES.DASHBOARD_PROFESSOR : ROUTES.DASHBOARD_APPLICANT);
          break;
        }
        case 4: {
          dispatch(stopTutorial());
          if (action === "next") {
            dispatch(continueTutorial());
          }
          break;
        }
        case 5: {
          dispatch(stopTutorial());
          if(action === "next") {
            dispatch(continueTutorial());
            navigate(ROUTES.MY_ACCOUNT);
          }
          break;
        }
        case 6: {
          dispatch(stopTutorial());
          if (action === "next") {
            dispatch(completelyStopTutorial());
            try {
              await deleteSampleRequest();
            } catch (error: unknown) {
              console.error(error);
            }
          }
          break;
        }
        default:
          dispatch(completelyStopTutorial());
      }
    }
  };

  return (
    <Joyride
      tooltipComponent={TutorialTooltip}
      callback={handleCallback}
      continuous
      run={run}
      stepIndex={stepIndex}
      steps={steps}
      disableCloseOnEsc
      disableOverlayClose
      disableScrolling
      styles={{
        options: {
          arrowColor: "transparent",
        },
      }}
    />
  );
};