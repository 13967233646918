import toast from "react-hot-toast";
import { deleteRequest, getRequest, patchRequest } from ".";
import urls from "./urls";

const { notification } = urls();

export const getAllNotifications = async () => {
  try {
    return await getRequest(notification.getNotifications);
  } catch (err) {
    return { error: err };
  }
};

export const updateNotification = async (id: string, body: Record<string, any>) => {
  if (!id) {
    return;
  }

  try {
    return await patchRequest(notification.updateById(id), body);
  } catch (err) {
    toast.error("An error occurred during mark as read process, please try again later!");
    return { error: err };
  }
};

export const deleteAllNotifications = async () => {
  try {
    return await deleteRequest(notification.deleteNotifications);
  } catch (err) {
    toast.error("An error occurred during clear notifications process, please try again later!");
    return { error: err };
  }
};

export const deleteAllReminders = async () => {
  try {
    return await deleteRequest(notification.deleteReminders);
  } catch (err) {
    toast.error("An error occurred during clear reminders process, please try again later!");
    return { error: err };
  }
};

export const deleteNotificationById = async (id: string) => {
  if (!id) {
    return;
  }

  try {
    return await deleteRequest(notification.deleteById(id));
  } catch (err) {
    toast.error("An error occurred removing notification process, please try again later!");
    return { error: err };
  }
};

export const enableNotifications = async (
  id: number,
  isSendEmailNotifications: boolean,
  isSendDueDateReminder: boolean,
  quantity: Array<number>,
) => {
  try {
    await patchRequest(notification.enableNotificationsSettings(id), {
      send_email: isSendEmailNotifications,
      remind_due_date: isSendDueDateReminder,
      quantity_days: quantity,
    });
  } catch (err) {
    toast.error("An error occurred enabling notifications, please try again later!");
    return { error: err };
  }
};

export const getNotificationSettings = async () => {
  try {
    return await getRequest(notification.getAllNotificationsSettings);
  } catch (err) {
    toast.error("An error occurred getting notifications, please try again later!");
    return { error: err };
  }
};
