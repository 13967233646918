import { patchRequest } from '../../api';

export const deleteAuth = async (user: null | Record<string, any>) => {
  try {
    return await patchRequest(`/profiles/${user?.id}/`, {
      tfa_active: false,
      phone: null,
    });
  } catch (err) {
    console.log(err);
    return {error: err};
  }
};

export const switchAuth = async (user: null | Record<string, any>, enabled?: boolean) => {
  try {
    return await patchRequest(`/profiles/${user?.id}/`, {
      tfa_active: !enabled,
    });
  } catch (err) {
    return err;
  }
};
