import React from "react";
import { RowMenuButton } from "./RowMenuButton";
import { LetterStatusEnum, RequestStatus } from "../../views/LetterStatus/types";
import { DueDateCell } from "./CustomCells/DueDateCell";
import { HeaderCell, TableCellProps } from "./Head/types";
import { NameCell } from "./CustomCells/NameCell";
import { DetailsCell } from "./CustomCells/DetailsCell";
import { LetterTypeCell } from "./CustomCells/LetterTypeCell";

type CreateTableArgs = {
  onViewDetails?: (row?: any) => void;
  onRequestSimilar?: (row?: any) => void;
  onCancel?: (row?: any) => void;
  onRemove?: (row?: any) => void;
  isSuperLargeDesktop?: boolean;
  isLargeDesktop?: boolean;
  allowFilterByStatus?: boolean;
  handleOpenStudentAccessModal: () => void;
};

const SORT_BY = "sort_by";
const STATUS = "status";

export const createTableColumns = (args: CreateTableArgs): HeaderCell[] => {
  const largeDesktopCheck = (value: string) => {
    if (args?.isSuperLargeDesktop) {
      return `${value} `;
    } else {
      return "";
    }
  };

  const columns = [
    {
      key: "request_display_name",
      name: "Name",
      sortMenu: [
        { name: "Alphabetical: A to Z", value: "request_display_name", key: SORT_BY },
        { name: "Alphabetical: Z to A", value: "-request_display_name", key: SORT_BY },
      ],
      Cell: (props: TableCellProps) => <NameCell row={props.row} />,
    },
    {
      key: "date_requested",
      name: "Date Created",
      sortMenu: [
        { name: "Newest to Oldest", key: SORT_BY, value: "-initial_request_date" },
        { name: "Oldest to Newest", key: SORT_BY, value: "initial_request_date" },
      ],
    },
    {
      key: "letter_due_date",
      name: `${largeDesktopCheck("Letter")}Due Date`,
      Cell: (props: TableCellProps) => <DueDateCell row={props.row} />,
      sortMenu: [
        { name: "Newest to Oldest", value: "-target_due_date", key: SORT_BY },
        { name: "Oldest to Newest", value: "target_due_date", key: SORT_BY },
      ],
    },
    {
      key: "opportunity_applied_for",
      name: `${largeDesktopCheck("Opportunity")}Applied For`,
      sortMenu: [
        {
          name: "Alphabetical: A to Z",
          value: "target_university",
          key: SORT_BY,
        },
        {
          name: "Alphabetical: Z to A",
          value: "-target_university",
          key: SORT_BY,
        },
      ],
    },
    {
      key: "target_submission_instructions",
      name: `${largeDesktopCheck("Request")}Send To`,
      sortMenu: [
        { name: "Alphabetical: A to Z", value: "target_submission_instructions", key: SORT_BY },
        { name: "Alphabetical: Z to A", value: "-target_submission_instructions", key: SORT_BY },
      ],
    },
    {
      key: "request_type",
      name: `${largeDesktopCheck("Request")}Type`,
      Cell: (props: TableCellProps) => <LetterTypeCell row={props.row} />,
      sortMenu: [
        { name: "Alphabetical: A to Z", value: "request_type", key: SORT_BY },
        { name: "Alphabetical: Z to A", value: "-request_type", key: SORT_BY },
      ],
    },
    {
      key: STATUS,
      name: `${largeDesktopCheck("Current")}Status`,
      Cell: (props: TableCellProps) => (
        <>{LetterStatusEnum[props.value as keyof typeof LetterStatusEnum]}</>
      ),
      sortMenu: args.allowFilterByStatus
        ? [
          { name: LetterStatusEnum.s, key: STATUS, value: RequestStatus.s },
          { name: LetterStatusEnum.r, key: STATUS, value: RequestStatus.r },
          { name: LetterStatusEnum.rd, key: STATUS, value: RequestStatus.rd },
          { name: LetterStatusEnum.d, key: STATUS, value: RequestStatus.d },
          { name: LetterStatusEnum.f, key: STATUS, value: RequestStatus.f },
          { name: LetterStatusEnum.mi, key: STATUS, value: RequestStatus.mi },
          { name: LetterStatusEnum.ru, key: STATUS, value: RequestStatus.ru },
          { name: LetterStatusEnum.a, key: STATUS, value: RequestStatus.a },
        ]
        : undefined,
    },
    {
      key: "view_details",
      name: "",
      Cell: (props: TableCellProps) => (
        <DetailsCell row={props?.row} onViewDetails={args?.onViewDetails} />
      ),
    },
    {
      key: "dots",
      name: "",
      Cell: (props: TableCellProps) => (
        <RowMenuButton
          props={props}
          onViewDetails={args.onViewDetails}
          handleOpenStudentAccessModal={args.handleOpenStudentAccessModal}
        />
      ),
    },
  ];

  return columns;
};
