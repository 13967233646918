import React from "react";
import { Box } from "@mui/system";
import { AccountTypeEnum } from "../../constants";
import Header from "../Header";

type TemplatePageProps = {
  children: any;
  type?: AccountTypeEnum | null | string;
};

const PageTemplate = ({ children, type }: TemplatePageProps) => {
  return (
    <Box display="flex" flexDirection="column" width="100%" height="100%">
      <Header accountType={type} />
      <Box height={"calc(100% - 60px)"}>{children}</Box>
    </Box>
  );
};

export default PageTemplate;
