import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, ToggleButton, ToggleButtonGroup, useMediaQuery } from "@mui/material";
import PageTemplate from "../../components/PageTemplate";

import { ProfileMenu } from "../../utils/profileMenu";
import { getType } from "../../api/localStorage/type";
import { PersonalInformation } from "./PersonalInformation";
import { RemindersAndNotifications } from "./RemindersAndNotifications";
import { ProfileSubscription } from "./Subscription";
import { getQueryParamFromURL } from "../../utils/getQueryParamFromURL";
import { AccountTypeEnum } from "../../constants";

const TAB_QUERY_PARAM = "tab";

export const MyAccount = () => {
  const [activeMenu, setActiveMenu] = useState<any>(ProfileMenu.profile);
  const accountType = getType();
  const navigate = useNavigate();

  const isDesktop = useMediaQuery("(min-width:900px)");

  const handleNavigateTab = (tab: keyof typeof ProfileMenu) => {
    navigate(`/account/?tab=${tab}`);
    const activeTab = getQueryParamFromURL(TAB_QUERY_PARAM);
    if (activeTab) {
      setActiveMenu(tab);
    }
  };

  useEffect(() => {
    const activeTab = getQueryParamFromURL(TAB_QUERY_PARAM);
    if (activeTab) {
      setActiveMenu(activeTab);
    }
  }, []);

  return (
    <PageTemplate type={getType()}>
      <Box
        width="100%"
        height="100%"
        display="flex"
        justifyContent="flex-start"
        flexDirection="column"
        paddingX={isDesktop ? 8 : 3}
        paddingY={5}
        boxSizing="border-box"
      >
        <ToggleButtonGroup
          style={{ height: 40, marginBottom: 8 }}
          color="secondary"
          value={activeMenu}
          exclusive
        >
          <ToggleButton
            value={ProfileMenu.profile}
            onClick={() => handleNavigateTab(ProfileMenu.profile)}
          >
            Personal Information
          </ToggleButton>
          <ToggleButton
            value={ProfileMenu.notifications}
            onClick={() => handleNavigateTab(ProfileMenu.notifications)}
          >
            Reminders & Notifications
          </ToggleButton>
          {accountType === AccountTypeEnum.Writer && <ToggleButton
            value={ProfileMenu.subscription}
            onClick={() => handleNavigateTab(ProfileMenu.subscription)}
          >
            Subscription
          </ToggleButton>}
        </ToggleButtonGroup>
        <Box>{activeMenu === ProfileMenu.profile && <PersonalInformation />}</Box>
        <Box>{activeMenu === ProfileMenu.notifications && <RemindersAndNotifications />}</Box>
        {accountType === AccountTypeEnum.Writer &&
          <Box>{activeMenu === ProfileMenu.subscription && <ProfileSubscription />}</Box>}
      </Box>
    </PageTemplate>
  );
};
