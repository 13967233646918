import React, { ForwardedRef, HTMLAttributes, useEffect, useState } from "react";
import { Autocomplete, AutocompleteRenderInputParams, Box, InputAdornment, ListItem, Typography } from "@mui/material";
import { InputWithLabel } from "../InputWithLabel";
import { EmptyAutocompleteProps } from "./Empty";

export type OptionData = {
  label: string;
} & Record<string, any>;

export type OptionProps = {
  data?: OptionData;
  text?: string;
};

type InputWithAutocompleteProps = {
  id: string;
  label: string;
  data: Array<OptionData> | [];
  onChange: (d: Record<string, any> | null) => void;
  onChangeText: (v: string) => void;
  placeholder?: string;
  OptionElement: React.FC<OptionProps>;
  EmptyElement?: React.FC<EmptyAutocompleteProps>;
  Icon?: React.FC;
  errorMessage?: string;
  loading?: boolean;
};

export const InputWithAutocomplete = React.forwardRef(
  (
    {
      id,
      data,
      label,
      onChange,
      placeholder,
      onChangeText,
      OptionElement,
      EmptyElement,
      Icon,
      loading,
      errorMessage,
    }: InputWithAutocompleteProps,
    ref: ForwardedRef<unknown>,
  ) => {
    const [open, setOpen] = useState(false);
    const [value, setValue] = useState("");
    const [selected, setSelected] = useState<string>("");

    const handleSelect = (option: OptionData) => {
      setSelected(option.label);
      onChange(option);
      setOpen(false);
    };

    const handleChangeText = (e: React.ChangeEvent<HTMLInputElement>) => {
      const v = e.target?.value;
      setSelected("");
      onChange(null);
      setValue(v);
      onChangeText(v);
    };

    useEffect(() => {
      data.length > 0 ? setOpen(true) : setOpen(false);
    }, [data]);

    return (
      <Autocomplete
        ref={ref}
        open={open}
        freeSolo
        disableClearable
        inputValue={selected ? selected : value}
        getOptionLabel={(option: string | OptionData) => (option as OptionData).label}
        sx={{ minWidth: 300 }}
        autoHighlight
        options={data}
        renderOption={(props: HTMLAttributes<HTMLLIElement>, option: OptionData) => (
          <ListItem
            {...props}
            key={JSON.stringify(option)}
            className="option"
            onClick={() => handleSelect(option)}
          >
            <OptionElement data={option} text={value} />
          </ListItem>
        )}
        renderInput={(params: AutocompleteRenderInputParams) => (
          <Box position="relative">
            <InputWithLabel
              {...params}
              id={id}
              value={value}
              error={Boolean(errorMessage)}
              label={label}
              placeholder={placeholder || label}
              onChange={handleChangeText}
              InputProps={{
                ...params.InputProps,
                endAdornment: Icon && (
                  <InputAdornment position="end">
                    <Icon />
                  </InputAdornment>
                ),
              }}
            />
            {data.length === 0 && value && EmptyElement && !loading && (
              <Box zIndex={1} position="absolute" bottom="auto" width="100%">
                <EmptyElement text={value} />
              </Box>
            )}
            {errorMessage && (
              <Box mt={1} maxWidth={300}>
                <Typography variant="error">{errorMessage}</Typography>
              </Box>
            )}
          </Box>
        )}
      />
    );
  },
);
