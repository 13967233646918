import React from "react";
import { Link, Typography } from "@mui/material";
import { colors } from "../../../theme";

export const createNotificationText = (
  word: string,
  name: string,
  onRequest: () => void,
): string | React.ReactNode => {
  const requestWords = ["request"];
  const nameKey = ":name:";

  if (requestWords.includes(word)) {
    return (
      <Typography key={word} component="span" variant="smallText">
        <Link color={colors.secondary} underline="none" onClick={onRequest}>
          {" "}
          {word}{" "}
        </Link>
      </Typography>
    );
  }

  if (word === nameKey) {
    return (
      <Typography key={word} component="div" variant="smallText" fontWeight={600}>
        {" "}
        {name}{" "}
      </Typography>
    );
  }

  return ` ${word} `;
};
