import { colors } from "../theme";
import { RequestStatus } from "../views/LetterStatus/types";
import { dueDates } from "./../constants/dueDates";

export const getColorByDateDif = (dueDate: string, status?: RequestStatus) => {
  const differenceDay = getDifDay(dueDate);
  const isFinishedStatus = status === RequestStatus.f || status === RequestStatus.s || status === RequestStatus.d

  if (status && isFinishedStatus) {
    return colors.gray[200];
  }

  if (differenceDay <= dueDates.TODAY) return colors.black;
  else if (differenceDay <= dueDates.TWO_DAYS) return colors.error;
  else if (differenceDay <= dueDates.WEEK) return colors.warning;
  return colors.gray[200];
};

export const getDifDay = (dueDate: string | Date) => {
  const due = new Date(dueDate);
  const currentDate = new Date();

  const differenceMillisecond = due.getTime() - currentDate.getTime();
  return Math.floor(differenceMillisecond / dueDates.MS_IN_DAY + dueDates.ONE_DAY);
};
