import { Button, Typography, useMediaQuery } from "@mui/material";
import { Box } from "@mui/system";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { postRequest } from "../../api";
import { InputWithLabel } from "../../components/InputWithLabel";
import PageTemplate from "../../components/PageTemplate/index";
import { ROUTES } from "../../constants/routes";
import { colors } from "../../theme";
import { ForgotPasswordForm } from "./types";
import { required, email } from "../../utils/validation";
import Loader from "../../components/Loader";

export const ForgotPassword = () => {
  const [requestSend, setRequestSend] = useState(false);
  const [loading, setLoading] = useState(false);
  const [apiError, setApiError] = useState<Array<string> | []>([]);

  const isDesktop = useMediaQuery("(min-width:900px)");
  const navigate = useNavigate();
  const {
    control,
    watch,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<ForgotPasswordForm>({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: {
      email: "",
    },
  });

  const handleGoBack = () => {
    navigate(ROUTES.SIGN_IN);
  };

  const handleForgot = async (data: ForgotPasswordForm) => {
    setApiError([]);
    setLoading(true);
    try {
      await postRequest("/password-reset/", { email: data.email });
      setRequestSend(true);
    } catch (err: any) {
      if (err?.email) {
        setApiError(err?.email);
      } else {
        setApiError(["Request was not completed due some issues!"]);
        console.log(err);
      }
    } finally {
      setLoading(false);
    }
  };

  const onEnter = (e: any) => {
    if (e.code === "Enter" || e.code === "NumpadEnter") {
      handleSubmit(handleForgot)();
    }
  };

  return (
    <PageTemplate>
      <Box
        width="100%"
        height="100%"
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        paddingX={3}
        boxSizing="border-box"
      >
        <Box
          minWidth={300}
          height="100%"
          boxSizing="border-box"
          justifyContent="center"
          display="flex"
          flexDirection="column"
          width={isDesktop ? 360 : "auto"}
        >
          {requestSend ? (
            <>
              <Typography marginBottom={4} variant="h6" textAlign="center">
                An email with instructions was sent to {watch()?.email}
              </Typography>
            </>
          ) : (
            <>
              <Typography marginBottom={4} variant="h5">
                Forgot Password
              </Typography>
              <Controller
                control={control}
                name="email"
                rules={{
                  validate: {
                    required,
                    email,
                  },
                }}
                render={({ field }) => (
                  <InputWithLabel
                    errorMessage={errors.email?.message}
                    wrapperStyle={{ mb: 2 }}
                    id="email-input"
                    placeholder="Email"
                    label="Email address"
                    onKeyDown={onEnter}
                    {...field}
                  />
                )}
              />
              <Box display="flex" flexDirection="column" maxWidth={300}>
                {apiError?.length > 0 &&
                  apiError.map((err: string) => (
                    <Box mb={1} key={err}>
                      <Typography color={colors.error}>{err}</Typography>
                    </Box>
                  ))}
              </Box>
              <Box display="flex" justifyContent="flex-start" marginTop={2} gap={3}>
                <Button
                  variant="contained"
                  color={isValid ? "secondary" : "secondaryLight"}
                  onClick={handleSubmit(handleForgot)}
                  fullWidth
                  disabled={loading}
                >
                  {loading ? <Loader size={24} /> : "Next"}
                </Button>
                <Button
                  disabled={loading}
                  variant="outlined"
                  color="secondaryLight"
                  onClick={handleGoBack}
                  fullWidth
                >
                  Cancel
                </Button>
              </Box>
            </>
          )}
        </Box>
      </Box>
    </PageTemplate>
  );
};
