import { LetterRequest, RequestStatus } from "./types";

export const createRequestsFromLetters = (letters: any[]): LetterRequest[] => {
  const data: LetterRequest[] = [];

  if (!letters || !letters.length) {
    return [];
  }

  letters.forEach((l: any) => {
    const row = {
      id: l.id,
      avatar: l.avatar,
      letter_id: l.letter_id,
      status: (l.status as RequestStatus) || RequestStatus.r,
      date_requested: l.initial_request_date,
      letter_due_date: l.target_due_date,
      opportunity_applied_for: l.target_university,
      request_send_to: l.request_display_email,
      request_display_name: l.request_display_name,
      target_university: l.target_university,
      writer_on_platform: Boolean(l.writer_on_platform),
      target_submission_instructions: l.target_submission_instructions,
      student_access: l.student_access,
      credit_used: l.credit_used,
      questions: l.questions,
      archived: l.archived,
      letterTypeId: l.letter_type_id,
      moreInfoRequest: l.more_info_request,
      moreInfoResponse: l.more_info_response,
      is_self_request: l.is_self_request,
    };

    data.push(row);
  });

  return data;
};
