import React, { ChangeEvent } from "react";
import { Box, TextField, Typography, useMediaQuery } from "@mui/material";
import { useNavigate } from "react-router-dom";
import CancelIcon from "@mui/icons-material/Cancel";
import { ControlButton } from "../../LetterCompositionConstructor/Section/ControlButton";
import Loader from "../../Loader";
import { EditableFieldProps } from "./types";
import { ROUTES } from "../../../constants/routes";

export const EditableField: React.FC<EditableFieldProps> = ({
  text,
  isDisabled,
  isFinished,
  isEditable,
  onChangeText,
  temperature,
  onFinish,
  error,
}) => {

  const navigate = useNavigate();

  const isDesktop = useMediaQuery("(min-width:600px)");

  const isLoading = isDisabled || !text;
  const isCanBeEditable = !isFinished && isEditable;

  const handleNavigateDashboard = () => navigate(ROUTES.HOME);

  return (
    <>
      <Box display="grid" gridTemplateColumns={isDesktop ? "5fr 1fr" : "1fr"} width="100%">
        {!isDesktop && <Box mb={2} visibility={isDisabled || !temperature ? "hidden" : "visible"}>{temperature}</Box>}
        <Box display="flex" flexDirection="column" gap={2} width="100%">
          {isLoading && <Loader size={25} speedMs={200} />}
          <TextField
            fullWidth
            value={text}
            onChange={(e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => onChangeText(e.target.value)}
            multiline={true}
            minRows={12}
            disabled={isDisabled}
          />
          <Box display="flex" flexDirection="row" justifyContent="space-between">
            {isCanBeEditable && (
              <Box
                display="flex"
                flex={1}
                flexDirection="row"
                gap={2}
                justifyContent="space-between"
                alignItems="center"
              >
                <Box display="flex" gap={2} alignItems="center">
                  <ControlButton
                    variant="contained"
                    text="Finish letter"
                    onClick={onFinish}
                    disabled={isLoading}
                  />
                  <ControlButton
                    disabled={isDisabled}
                    text="Exit"
                    onClick={handleNavigateDashboard}
                    Icon={<CancelIcon />}
                  />
                  <Box>{error && <Typography variant="error">{error}</Typography>}</Box>
                </Box>
              </Box>
            )}
          </Box>
        </Box>
        {isDesktop && <Box visibility={isDisabled || !temperature ? "hidden" : "visible"}>{temperature}</Box>}
      </Box>
    </>
  );
};