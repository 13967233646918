export const FILE_MAX_SIZE = 8388608;
export const IMAGE_MAX_SIZE = 524288;

export const getFileSize = (f: File) => {
  let size = f.size;
  const fSExt = new Array("Bytes", "KB", "MB", "GB");
  let i = 0;
  while (size > 900) {
    size /= 1024;
    i++;
  }
  const exactSize = Math.round(size * 100) / 100 + " " + fSExt[i];
  return exactSize;
};

export const getFileSizeNumber = (s: number) => {
  const fSExt = new Array("Bytes", "KB", "MB", "GB");
  let i = 0;
  while (s > 900) {
    s /= 1024;
    i++;
  }
  const exactSize = Math.round(s * 100) / 100 + " " + fSExt[i];
  return exactSize;
};
