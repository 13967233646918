import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Box, Button, Link, Modal, Typography } from "@mui/material";
import { RootState } from "../../../redux/reducers";
import { deleteSubscription, getSubscriptions, updatePaymentData } from "../../../api/stripe/stripe";
import { formatDate } from "./utils";
import { convertNumberToWords } from "../../../utils/strings";
import Loader from "./../../../components/Loader/index";
import { ConfirmationModal } from "../../../components/ConfirmationModal";
import { ReactComponent as LuciWithBackground } from "./../../../assets/images/LuciWithBackground.svg";
import { colors } from "../../../theme";
import { LuciButton } from "../../../components/LuciButton";
import { ROUTES } from "../../../constants/routes";

export const ProfileSubscription = () => {
  const [open, setOpen] = useState<boolean>(false);
  const [subscriptions, setSubscriptions] = useState<any>();
  const [subscriptionId, setSubscriptionId] = useState<string>("");
  const [subscriptionsLoading, setSubscriptionsLoading] = useState<boolean>(false);
  const [cancelSubscriptionLoading, setCancelSubscriptionLoading] = useState<boolean>(false);

  const { user } = useSelector((state: RootState) => state.user);
  const navigate = useNavigate();

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const getSubscription = async (): Promise<void> => {
    try {
      setSubscriptionsLoading(true);
      const subscriptions = await getSubscriptions(user);
      if (subscriptions?.data.data) {
        setSubscriptions(subscriptions.data.data);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setSubscriptionsLoading(false);
    }
  };

  const cancelSubscription = async (id: string) => {
    try {
      handleClose();
      setCancelSubscriptionLoading(true);
      await deleteSubscription(id);
      getSubscription();
    } catch (err) {
      console.log(err);
    } finally {
      setCancelSubscriptionLoading(false);
    }
  };

  const handleDelete = (id: string) => {
    handleOpen();
    setSubscriptionId(id);
  };

  const handleNavigateSubscriptions = () => {
    navigate(ROUTES.SUBSCRIPTION);
  };

  const formatEndDate = (el: any) => {
    return formatDate(new Date(+(el.current_period_end + "000")));
  };

  const updateInfo = async () => {
    const s = await updatePaymentData(user?.stripe_id, window.location.href);
    if (s) {
      window.location.replace(s.data.url);
    }
  };

  useEffect(() => {
    getSubscription();
  }, []);

  return (
    <Box display="flex" flexDirection="column" gap={2} paddingY={4}>
      <Modal open={open} onClose={handleClose}>
        <Box width="100%" height="100%" display="flex" alignItems="center" justifyContent="center">
          <ConfirmationModal
            onCancel={handleClose}
            onSubmit={() => cancelSubscription(subscriptionId)}
            title="Are you sure you want to delete this subscription?"
            actionName="Delete subscription"
          />
        </Box>
      </Modal>
      <Typography variant="mediumTitle">Subscription</Typography>
      {subscriptions?.length ? (
        subscriptions.map((el: any) => {
          return (
            <Box
              key={el.id}
              sx={{ border: `1px solid ${colors.gray[400]}` }}
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
              gap={1}
              width="570px"
              height="150px"
              p={2}
              borderRadius="4px"
            >
              <Box
                width="100%"
                display="flex"
                flexDirection="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <Box display="flex" flexDirection="row" gap={1} alignItems="center">
                  <Typography variant="mediumTitle">Pricing Plan: </Typography>
                  <Typography color="secondary">{el.plan.product.name}</Typography>
                  <Typography variant="smallText" sx={{ color: colors.gray[400] }}>
                    till {formatEndDate(el)}
                  </Typography>
                </Box>
                <Link
                  display="flex"
                  flexDirection="row"
                  gap={1}
                  underline="hover"
                  color={colors.info}
                  onClick={() => handleDelete(el.id)}
                >
                  {cancelSubscriptionLoading && el.id === subscriptionId && (
                    <Loader size={20} speedMs={100} />
                  )}
                  <Typography variant="largeText">Cancel</Typography>
                </Link>
              </Box>
              {/* <Typography variant="largeText" color={colors.gray[400]} mt={3}>
                Balance
              </Typography> */}
              <Box
                width="100%"
                display="flex"
                flexDirection="row"
                gap={1}
                alignItems="center"
                justifyContent="space-between"
              >
                <Box display="flex" flexDirection="row" gap={1}>
                  <Box
                    width={30}
                    height={30}
                    bgcolor={colors.infoLight}
                    borderRadius={"50%"}
                    display="flex"
                    alignSelf="center"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Typography variant="text" fontWeight={900} color={colors.info}>
                      {el.plan.product.metadata.credits}
                    </Typography>
                  </Box>
                  <Box alignSelf="center">
                    <Typography variant="text" color={colors.textGray} fontWeight={900}>
                      You will receive {convertNumberToWords(el.plan.product.metadata.credits)} credits
                    </Typography>
                  </Box>
                </Box>
                <Button
                  variant="contained"
                  color="secondary"
                  sx={{ paddingY: 1, paddingX: 6 }}
                  onClick={updateInfo}
                >
                  <Typography variant="text">Update</Typography>
                </Button>
              </Box>
            </Box>
          );
        })
      ) : subscriptionsLoading ? (
        <Loader size={100} speedMs={300} />
      ) : (
        <Box
          width="100%"
          mt={4}
          display="flex"
          flexDirection="column"
          alignItems="center"
          gap={4}
        >
          <LuciWithBackground />
          <Box>
            <Typography>Want to get more from Letters?</Typography>
            <Typography>Upgrade your Subscription plan</Typography>
          </Box>
          <LuciButton text="Select plan" onClick={handleNavigateSubscriptions} />
        </Box>
      )}
    </Box>
  );
};
