import { OptionData } from '../../components/InputWithAutocomplete';

export const createOptionsFromUsers = (data: Array<any>): Array<OptionData> => {
  const options = data.map(user => ({
    label: user.user.first_name + ' ' + user.user.last_name,
    avatar: user.user.avatar,
  }));

  return options;
};
