import { combineReducers } from "redux";
import user from "./user";
import requests from "./requests";
import notification from "./notification";
import tutorial from "./tutorial";

export const rootReducer = combineReducers({
  user,
  requests,
  notification,
  tutorial,
});

export type RootState = ReturnType<typeof rootReducer>;
