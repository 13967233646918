import React, { useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/reducers";
import { setUser } from "../../redux/actionCreators";
import Loader from "../Loader";
import { ActionType } from "./index";
import { deleteAuth, switchAuth } from "./utils";

type AuthenticationModalProps = {
  closeModal: () => void;
  enabled?: boolean;
  type: ActionType;
};

export const AuthenticationModal = ({ enabled, closeModal, type }: AuthenticationModalProps) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const { user } = useSelector((state: RootState) => state.user);
  const dispatch = useDispatch();

  const getButtonTitle = (t: ActionType) => {
    if (t === "switch") {
      return enabled ? "Disable" : "Enable";
    } else return "Delete";
  };

  const getTitle = (t: ActionType) => {
    if (t === "switch") {
      return ` Are you sure you want to ${
        enabled ? "disable" : "enable"
      } Two-Factor authentication?`;
    } else return "Are you sure you want to delete Two-Factor authentication?";
  };

  const handleConfirm = async (e: any) => {
    e.stopPropagation();
    setLoading(true);
    let newProfile;
    if (type === "delete") {
      newProfile = await deleteAuth(user);
      if (newProfile.error) {
        toast.error("An error occurred during Two-Factor Authenticator remove process");
      } else {
        toast.success("Two-Factor Authenticator was successfully removed");
      }
    } else {
      newProfile = await switchAuth(user, enabled);
    }
    setLoading(false);
    if (newProfile) {
      dispatch(setUser(newProfile));
      closeModal();
    } else {
      //TODO: need to handle error
      setError("Request failed try again letter");
    }
  };

  return (
    <Box
      borderRadius={2}
      bgcolor={"white"}
      px={5}
      py={3}
      display="flex"
      flexDirection="column"
      alignItems="center"
    >
      <Typography>{getTitle(type)}</Typography>
      {error && (
        <Typography mt={1} color={"error"}>
          {error}
        </Typography>
      )}
      <Box mt={2} display="flex" gap={2} alignItems="center">
        <Button disabled={loading} variant="contained" color="secondary" onClick={handleConfirm}>
          {loading ? <Loader /> : getButtonTitle(type)}
        </Button>
        <Button variant="outlined" color="secondary" onClick={closeModal}>
          Cancel
        </Button>
      </Box>
    </Box>
  );
};
