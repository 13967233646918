import { Slider, styled } from "@mui/material";
import clone from "just-clone";
import { completeSection, updateSectionText } from "../../api/section";
import { EnthusiasmLevel, Paragraph } from "./types";

export const EnthusiasmSlider = styled(Slider)({
  "height": 5,
  "& .MuiSlider-rail": {
    background: "linear-gradient(270deg, #AF5359 0%, #F2DE94 100%)",
  },

  "& .MuiSlider-thumb": {
    "height": 15,
    "width": 15,
    "backgroundColor": "#fff",
    "border": "2px solid grey",
    "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
      boxShadow: "inherit",
    },
    "&:before": {
      display: "none",
    },
  },
  "& .MuiSlider-valueLabel": {
    display: "none",
  },
  "& .MuiSlider-mark": {
    display: "none",
  },
  "& .MuiSlider-markLabel": {
    fontSize: "12px",
  },
});

export const createNewSection = ({
  id,
  text = "",
  isFinished = false,
  isSkipped = false,
}: {
  id: number;
  text?: string;
  isFinished?: boolean;
  isSkipped?: boolean;
}) => ({
  id,
  text,
  isFinished,
  isEditable: false,
  isDisabled: false,
  isSkipped,
});

export const sectionsFromParagraphs = (paragraphs: Array<string>, maxSections: number) => {
  const sections = paragraphs.map((p: string, index: number) => {
    const isFirst = index === 0;
    const isLast = index + 1 !== maxSections;
    const notEmpty = p.length !== 0;
    const empty = p.length === 0;
    const isFinished = (isFirst && notEmpty) || notEmpty;
    const isSkipped =
      (!isFirst && empty) ||
      (!isLast && empty) ||
      (isFirst && paragraphs.length > 1 && empty) ||
      empty;
    return createNewSection({
      id: index,
      text: p,
      isFinished,
      isSkipped,
    });
  });
  const allFinished = sections.filter((p) => p!.isFinished);

  allFinished &&
    sections.length < maxSections &&
    sections.push(createNewSection({ id: sections.length }));

  return sections;
};

export const acceptSection = async (
  index: number,
  section: Paragraph,
  sections: Array<Paragraph>,
  sectionId: string,
  letterId: string,
  maxSections: number,
  enthusiasm?: EnthusiasmLevel,
): Promise<Array<Paragraph>> => {
  const copy = clone(sections);
  copy[index] = section;

  const res = await completeSection(letterId, {
    paragraphs: copy.map((s: Paragraph) => s.text),
    section_id: sectionId,
    enthusiasm: enthusiasm?.code,
  });

  if (sections.length < maxSections && enthusiasm && !res.error) {
    copy.push(createNewSection({ id: sections.length }));
  }

  if (res.error) {
    window.alert(res.error.detail);
    copy[index].isFinished = false;
    copy[index].isSkipped = false;
  }

  return copy;
};

export const updateSection = async (
  index: number,
  section: Paragraph,
  sections: Array<Paragraph>,
  letterId: string,
  enthusiasm?: EnthusiasmLevel,
): Promise<Array<Paragraph>> => {
  const copy = clone(sections);
  const isEditable = section.isEditable;
  const areEqual = section.text === copy[index].text;
  copy[index] = section;

  let _c = copy.map((s: Paragraph, i: number) => ({
    ...s,
    editable: index === i ? isEditable : false,
    disabled: isEditable,
  }));

  if (!isEditable && !areEqual) {
    const paragraphs = _c.map((s) => s.text);
    const code = enthusiasm?.code;

    const res = await updateSectionText(letterId, {
      paragraphs: paragraphs,
      enthusiasm: code,
    });
    if (res.error) {
      window.alert(res.error.detail);
      _c[index].editable = true;
      _c[index].disabled = false;
      console.log({ updateSectionError: res });
    }
  }

  return _c;
};

export const createFinishedSections = (sections: Array<Paragraph>, maxSections: number): Array<Paragraph> => {
  const finished = sections.map((c: Paragraph) => ({ ...c, isFinished: true, isSkipped: !c?.text.length }));
  if (finished.length < maxSections) {
    const l = maxSections - finished.length;
    for (let i = 1; i <= l; i++) {
      finished.push(
        createNewSection({
          id: l + i,
          text: "",
          isFinished: true,
          isSkipped: true,
        }),
      );
    }
  }

  return finished;
};

export const downloadTxtFile = (sections: Array<Paragraph>): void => {
  const element = document.createElement("a");
  let text: string[] = [];
  sections.map((el: Paragraph, index: number) => text.push(el.text));
  const file = new Blob([text.join("\n\n")], {
    type: "text/plain",
  });
  element.href = URL.createObjectURL(file);
  element.download = "letter.txt";
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
};

export const downloadCoverLetterTxt = (text: string): void => {
  const element = document.createElement("a");
  const file = new Blob([text], {
    type: "text/plain",
  });
  element.href = URL.createObjectURL(file);
  element.download = "coverLetter.txt";
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);

}
