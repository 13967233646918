import React from "react";
import { Button, Link, Paper, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useNavigate } from "react-router-dom";
import PageTemplate from "../../components/PageTemplate";
import { ROUTES } from "../../constants/routes";
import { getType } from "../../api/localStorage/type";
import { BackgroundImage } from "../../assets/images/background";

export const LetterSuccess = () => {
  const navigate = useNavigate();

  const requestNewLetter = () => {
    navigate(ROUTES.NEW_LETTER);
  };

  const checkLetterStatus = () => {
    navigate(ROUTES.LETTER_STATUS);
  };

  const leaveFeedback = () => {
    window.open(ROUTES.FEEDBACK);
  };

  return (
    <PageTemplate type={getType()}>
      <Paper
        style={{
          backgroundImage: `url(${BackgroundImage})`,
          width: "100%",
          height: "100%",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          display: "flex",
          flexDirection: "column",
          paddingTop: 140,
          boxSizing: "border-box",
          alignItems: "center",
        }}
      >
        <Box
          padding={7}
          bgcolor="white"
          display="flex"
          flexDirection="column"
          alignItems="center"
          gap={3}
        >
          <Typography variant="h5">
            Congratulations, your letter request was successfully submitted!
          </Typography>
          <Button variant="contained" color="secondary" size="large" onClick={requestNewLetter}>
            Request another letter
          </Button>
          <Link color="secondary" onClick={checkLetterStatus}>
            Check your request status
          </Link>
          <Link color="secondary" onClick={leaveFeedback}>
            Leave Feedback
          </Link>
        </Box>
      </Paper>
    </PageTemplate>
  );
};
