import React from "react";
import { Box, useMediaQuery } from "@mui/material";
import { dueDates } from "../../../../constants/dueDates";
import { getColorByDateDif, getDifDay } from "../../../../utils/dates";
import { RequestStatus } from "../../../../views/LetterStatus/types";
import { DueDateChip } from "../../../DueDateChip";
import { colors } from "../../../../theme";
import { formatDate } from "../../../../utils/joinFormatDate";

type DueDateProps = {
  row?: any;
};

export const DueDateCell = ({ row }: DueDateProps) => {
  const isDesktop = useMediaQuery("(min-width:900px)");
  const isShowDueDate = row?.status === RequestStatus.f
    || row?.status === RequestStatus.s
    || row?.status === RequestStatus.d;

  if (isShowDueDate) {
    return <Box paddingLeft={isDesktop ? 5 : 0}>{row?.letter_due_date}</Box>;
  }

  const isColored = getDifDay(row?.letter_due_date) <= dueDates.TODAY ? colors.gray[400] : getColorByDateDif(row?.letter_due_date);

  return (
    <DueDateChip
      backgroundColor={isColored}
      paddingLeft={1}
      dueDate={formatDate(row?.letter_due_date)}
      status={row?.status}
    />
  );
};
