import React from "react";
import { TooltipRenderProps } from "react-joyride";
import { Box, Button, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import CloseIcon from '@mui/icons-material/Close';
import { colors } from "../../../theme";
import { completelyStopTutorial } from "../../../redux/actionCreators";
import { deleteSampleRequest } from "../../../api/letterRequest";

export const TutorialTooltip: React.FC<TooltipRenderProps> = ({
  index,
  step,
  tooltipProps,
  continuous,
  closeProps,
  backProps,
  skipProps,
  primaryProps,
  isLastStep,
}) => {
  const dispatch = useDispatch();
  const handleClose = async () => {
    dispatch(completelyStopTutorial());
    await deleteSampleRequest();
  }

  return (
    <Box
      sx={{ backgroundColor: colors.white }}
      width="320px"
      height="150px"
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      gap={2}
      px={2}
      py={4}
      borderRadius="12px"
      {...tooltipProps}
    >
      <Box display="flex" flexDirection="row" justifyContent="space-between">
        <Typography variant="text" color={colors.gray[400]}>Tutorials</Typography>
        <CloseIcon fontSize="small" onClick={handleClose} sx={{ cursor: "pointer" }} />
      </Box>
      <Typography variant="largeText">{step.content}</Typography>
      <Box display="flex" flexDirection="row" justifyContent="space-between">
        <Button color="secondary" variant="outlined" onClick={handleClose}>
          <Typography variant="text">Skip</Typography>
        </Button>
        <Button color="secondary" variant="outlined" {...primaryProps}>
          <Typography variant="text">{isLastStep ? "Finish" : "Next"}</Typography>
        </Button>
      </Box>
    </Box>
  )
}