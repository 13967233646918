import React, { useCallback, useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { FinishedSection } from "./FinishedSection";
import { EditableSection } from "./EditableSection";
import { EnthusiasmLevel, SectionProps } from "../types";
import { getSectionText } from "../../../api/section";

export const Section: React.FC<SectionProps> = ({
  section,
  accept,
  index,
  enthusiasm,
  text,
  isFinished,
  isEditable,
  isDisabled,
  isSkipped,
  updateSection,
  letterId,
  enthusiasmLevel,
  updateSectionId,
  onFinishLetter,
  onTextGeneration,
  maxSections,
}) => {
  const [value, setValue] = useState(text);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [requestTime, setRequestTime] = useState(0);

  const isShowEditableSection = !isFinished && !isSkipped;
  const isShowFinishedSection = isFinished || isSkipped;

  const showTitle = !(isShowFinishedSection && !isEditable);

  const onAccept = () => accept({ ...section, text: value, isFinished: true }, index);

  const onChangeText = (newText: string) => setValue(newText);

  const onEditSection = () =>
    updateSection({ ...section, text: value, isEditable: !isEditable }, index);

  const onFinish = () =>
    onFinishLetter({ ...section, text: value, isFinished: true, isSkipped: false }, index);

  const onSkip = () => {
    if (index + 1 === maxSections) {
      onFinishLetter({ ...section, text: "", isSkipped: true }, index);
    }
    accept({ ...section, text: "", isSkipped: true }, index);
    setValue("");
  };

  const onCompleteEdit = (save: boolean) => {
    const _text = save ? value : section.text;

    updateSection(
      {
        ...section,
        text: _text,
        isEditable: !isEditable,
        isFinished: save ? !!value.length : isFinished,
        isSkipped: save ? !value.length : isSkipped,
      },
      index,
    );
    setValue(save ? value : text);
  };

  const pasteText = useCallback((text: string, sectionId: string) => {
    setValue("");
    updateSectionId(sectionId);
    onTextGeneration(true);

    let timerDelay = 0;
    const wordDelay = 100;
    const wordArray = text.split(" ");

    wordArray.forEach((word: string, index: number) => {
      setTimeout(() => {
        setValue((prev) => (prev ? prev + " " + word : word));
        if (index === wordArray.length - 1) {
          setLoading(false);
          onTextGeneration(false);
        }
      }, timerDelay);
      timerDelay += wordDelay;
    });
  }, []);

  const generateText = async (level?: EnthusiasmLevel) => {
    const requestStartAt = performance.now();
    if (!level) return;

    setLoading(true);
    setError("");
    const generated = await getSectionText(index, level, letterId);

    if (generated?.proposed_text) {
      const { proposed_text, section_id } = generated;
      pasteText(proposed_text, section_id);
    } else {
      setError(generated?.error?.detail || "Unknown error");
      setLoading(false);
    }
    const requestEndAt = performance.now();
    const timeSpent = requestEndAt - requestStartAt;
    setRequestTime(timeSpent)
  };

  useEffect(() => {
    if (enthusiasmLevel && !value && !isSkipped && !isFinished) {
      generateText(enthusiasmLevel);
    }
  }, []);

  return (
    <>
      {showTitle && (
        <Box display="flex" gap={3}>
          <Typography variant="largeText" fontWeight={500}>
            Proposal for next section:
          </Typography>
        </Box>
      )}
      {isShowEditableSection && (
        <EditableSection
          index={index}
          error={error}
          text={value}
          isFinished={isFinished}
          onChangeText={onChangeText}
          isEditable={isEditable}
          isDisabled={isDisabled || loading}
          onAccept={onAccept}
          onSkip={onSkip}
          onFinish={onFinish}
          maxSections={maxSections}
          requestTime={requestTime}
          enthusiasm={
            enthusiasm && enthusiasm({ generateText: () => generateText(enthusiasmLevel) })
          }
        />
      )}
      {isShowFinishedSection && (
        <FinishedSection
          error={error}
          text={value}
          isDisabled={loading}
          isEditable={isEditable}
          isSkipped={isSkipped}
          onClick={onEditSection}
          onChangeText={onChangeText}
          onSave={() => onCompleteEdit(true)}
          onCancel={() => onCompleteEdit(false)}
          enthusiasm={
            enthusiasm && enthusiasm({ generateText: () => generateText(enthusiasmLevel) })
          }
        />
      )}
    </>
  );
};
