import { LetterRequest } from "../LetterStatus/types";
import { DashboardBox } from "./types";

export const stylePropToNumber = (v: string): number => {
  return Number(v.replace("px", ""));
};

export const getElementClearSize = (node: HTMLDivElement): DashboardBox => {
  const _styles = getComputedStyle(node, null);
  const pL = stylePropToNumber(_styles.paddingLeft);
  const pR = stylePropToNumber(_styles.paddingRight);
  const pT = stylePropToNumber(_styles.paddingTop);
  const pB = stylePropToNumber(_styles.paddingBottom);

  return { width: node?.clientWidth - pL - pR, height: node?.clientHeight - pT - pB };
};

export const createPages = (
  count: number | null,
  sortedRequests?: LetterRequest[] | null,
): Array<Array<LetterRequest>> => {
  const pages: Array<Array<LetterRequest>> = [];
  if (!sortedRequests?.length) {
    return pages;
  }
  if (count && count !== 0) {
    const pageCount = Math.ceil(sortedRequests.length / count);
    if (pageCount < 1) {
      pages.push(sortedRequests);
    } else {
      let page: Array<LetterRequest> = [];
      sortedRequests.forEach((r, index) => {
        if ((index + 1) % count !== 0) {
          page.push(r);
          if (index + 1 === sortedRequests.length) {
            pages.push(page);
          }
        } else {
          page.push(r);
          pages.push(page);
          page = [];
        }
      });
    }
  }
  return pages;
};

export const importAllImages = (r: __WebpackModuleApi.RequireContext) => {
  let images: Array<string> = [];
  r.keys().forEach((item: string) => {
    images.push(r(item));
  });
  return images;
};
