import React from "react";
import { Navigate } from "react-router-dom";
import { getToken } from "../../api/localStorage/token";
import { getType } from "../../api/localStorage/type";
import { AccountTypeEnum } from "../../constants";
import { ROUTES } from "../../constants/routes";

export const PrivateRoute: React.FC<any> = ({ children }) => {
  const token = getToken();
  const type = getType();
  const oppositeType =
    type === AccountTypeEnum.Applicant ? AccountTypeEnum.Writer : AccountTypeEnum.Applicant;

  // eslint-disable-next-line no-restricted-globals
  if (
    (token && window.location?.pathname.includes(oppositeType)) ||
    (token && window.location?.pathname === "/")
  ) {
    return <Navigate to={`/${type?.toLowerCase()}/dashboard`} />;
  } else {
    return token ? children : <Navigate to={ROUTES.SIGN_IN} />;
  }
};
