import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { useSelector } from "react-redux";
import PageTemplate from "../../components/PageTemplate";
import { getType } from "../../api/localStorage/type";
import { RootState } from "../../redux/reducers";
import { AccountTypeEnum } from "../../constants";
import { NotFound } from "../NotFound";
import Loader from "../../components/Loader";

const pricingTableId = process.env.REACT_APP_PRICING_TABLE_ID;
const publishableKey = process.env.REACT_APP_PUBLISHABLE_KEY;

export const Subscription = () => {
  const [userEmail, setUserEmail] = useState("");
  const [accountType, setAccountType] = useState<string | null>(null);
  const [userStripeId, setUserStripeId] = useState("");

  const { user } = useSelector((state: RootState) => state.user);

  const embeddedPricingTable =
    userEmail
      ? `<script async src="https://js.stripe.com/v3/pricing-table.js"></script>
  <stripe-pricing-table pricing-table-id="${pricingTableId}"
  publishable-key="${publishableKey}"
  customer-email="${userEmail}"
  </stripe-pricing-table>`
      : userEmail && userStripeId ? `<script async src="https://js.stripe.com/v3/pricing-table.js"></script>
  <stripe-pricing-table pricing-table-id="${pricingTableId}"
  publishable-key="${publishableKey}"
  customer-email="${userEmail}"
  client-reference-id="${userStripeId}">
  </stripe-pricing-table>` : "";

  useEffect(() => {
    if (user) {
      const type = getType();
      setAccountType(type);
      setUserEmail(user.user.email);
      setUserStripeId(user.stripe_id);
    }
  }, [user]);

  return (
    <>
      <PageTemplate type={getType()}>
        <Box width="100%" height="100%">
          {!user ? <Loader /> : (
            <iframe
              frameBorder="none"
              width="100%"
              height="100%"
              srcDoc={embeddedPricingTable}
            ></iframe>
          )}
          {user && accountType && accountType === AccountTypeEnum.Applicant && <NotFound />}
        </Box>
      </PageTemplate>
    </>
  );
};
