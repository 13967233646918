import { Button, Typography, useMediaQuery } from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { postRequest } from "../../api";
import { InputWithLabel } from "../../components/InputWithLabel";
import PageTemplate from "../../components/PageTemplate/index";
import PasswordHint from "../../components/PasswordHint";
import { ROUTES } from "../../constants/routes";
import { colors } from "../../theme";
import { CreateNewPasswordForm } from "./types";
import {
  includeLoweCaseChar,
  includeNumber,
  includeSpecialChar,
  includeUpperCaseChar,
  minLength,
  required,
  valuesAreEqual,
} from "../../utils/validation";
import Loader from "../../components/Loader";
import { getQueryParamFromURL } from "../../utils/getQueryParamFromURL";

const defaultValues = {
  newPassword: "",
  confirmPassword: "",
};

export const CreateNewPassword = () => {
  const [apiError, setApiError] = useState<Array<string> | []>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const isDesktop = useMediaQuery("(min-width:900px)");
  const navigate = useNavigate();
  const token = getQueryParamFromURL("token");

  const {
    control,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm<CreateNewPasswordForm>({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues,
  });

  const handleGoBack = () => {
    navigate(ROUTES.FORGOT_PASSWORD);
  };

  const handleForgot = async (data: CreateNewPasswordForm) => {
    setApiError([]);
    setLoading(true);
    try {
      const response = await postRequest("/password-reset/confirm/", {
        password: data.newPassword,
        token,
      });
      if (response.status === "OK") {
        //TODO: notify
        navigate(ROUTES.SIGN_IN);
      }
    } catch (err: any) {
      if (err?.password) {
        setApiError(err.password);
      } else {
        setApiError(["Request was not completed due some issues!"]);
        console.log(err?.toString());
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <PageTemplate>
      <Box
        width="100%"
        height="100%"
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        paddingX={3}
        boxSizing="border-box"
      >
        <Box
          height="100%"
          boxSizing="border-box"
          justifyContent="center"
          display="flex"
          flexDirection="column"
          width={isDesktop ? 360 : "auto"}
        >
          <Box marginBottom={4}>
            <Typography variant="h5">Forgot Password</Typography>
          </Box>
          <Controller
            control={control}
            name="newPassword"
            rules={{
              validate: {
                required,
                includeNumber: includeNumber("At least one number (0-9)"),
                includeLoweCaseChar: includeLoweCaseChar(
                  "Password should contain at least one lowercase letter (a-z)",
                ),
                includeUpperCaseChar: includeUpperCaseChar(
                  "Password should contain at least one uppercase letter (A-Z)",
                ),
                includeSpecialChar: includeSpecialChar(
                  "Password should contain at least one special character",
                ),
                minLength: minLength(10, "Password should contain at least 10 characters"),
              },
            }}
            render={({ field }) => (
              <InputWithLabel
                passwordHint={<PasswordHint value={field.value} />}
                wrapperStyle={{ mt: 2 }}
                errorMessage={errors.newPassword?.message}
                label="Enter New Password"
                type="password"
                id="new-password"
                {...field}
              />
            )}
          />
          <Controller
            control={control}
            name="confirmPassword"
            rules={{
              validate: {
                required,
                valuesAreEqual: valuesAreEqual(
                  watch()?.newPassword,
                  "New password and confirm password should be the same",
                ),
              },
            }}
            render={({ field }) => (
              <InputWithLabel
                errorMessage={errors.confirmPassword?.message}
                wrapperStyle={{ mt: 2 }}
                label="Confirm Password"
                type="password"
                id="confirm-password"
                {...field}
              />
            )}
          />
          <Box display="flex" flexDirection="column">
            {apiError?.length > 0 &&
              apiError.map((err) => (
                <Box mb={1} key={err}>
                  <Typography color={colors.error}>{err}</Typography>
                </Box>
              ))}
          </Box>
          <Box display="flex" justifyContent="flex-start" marginTop={2} gap={3}>
            <Button
              disabled={loading}
              variant="contained"
              color="secondaryLight"
              onClick={handleSubmit(handleForgot)}
            >
              {loading ? <Loader size={24} /> : "Change Password"}
            </Button>
            <Button
              disabled={loading}
              variant="outlined"
              color="secondaryLight"
              onClick={handleGoBack}
            >
              Cancel
            </Button>
          </Box>
        </Box>
      </Box>
    </PageTemplate>
  );
};
