import axios from "axios";
import { STRIPE_URL } from "../../constants";

const token = process.env.REACT_APP_STRIPE_API_KEY;

export const stripe = axios.create({
  baseURL: STRIPE_URL,
  headers: {
    "Content-Type": "application/json",
    "Accept": "application/json",
    "Access-Control-Allow-Origin": "*",
    "Authorization": `Bearer ${token}`,
  },
});
