import { MoreVertOutlined } from "@mui/icons-material";
import { Box, Button, Modal } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { getType } from "../../../api/localStorage/type";
import { AccountTypeEnum } from "../../../constants";
import { colors } from "../../../theme";
import { ControlProfessorListPopover } from "../../LetterRequestCard/ControlProfessorListPopover";
import { ControlStudentListPopover } from "../../LetterRequestCard/ControlStudentListPopover";
import { TableCellProps } from "../Head/types";
import { LetterCompositionModal } from "../../LetterCompositionModal/LetterCompositionModal";
import { ROUTES } from "../../../constants/routes";
import { RootState } from "../../../redux/reducers";
import { openInNewTab } from "../../../utils/openInNewTab";
import { LetterType } from "../../../utils/globalLetterTypesCredits";

type RowMenuButtonProps = {
  props: TableCellProps;
  onViewDetails?: (row: any) => void;
  handleOpenStudentAccessModal: () => void;
};

export const RowMenuButton = ({ props, onViewDetails, handleOpenStudentAccessModal }: RowMenuButtonProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [isOpenLetterCompositionModal, setIsOpenLetterCompositionModal] = useState<boolean>(false);
  const [destination, setDestination] = useState<string>("");

  const open = Boolean(anchorEl);
  const accountType = getType();
  const navigate = useNavigate();

  const { user } = useSelector((state: RootState) => state.user);
  const { student_access: studentAccess, credit_used: creditUsed } = props.row;

  const mode = user?.credit_count < LetterType.recommendation ? "default" : "constructor";

  const handleOpenLetterCompositionModal = () => setIsOpenLetterCompositionModal(true);
  const handleCloseLetterCompositionModal = () => setIsOpenLetterCompositionModal(false);

  const handleNavigateToWriteLetter = () => {
    navigate(`${ROUTES.WRITE_LETTER}?letterId=${props.row.letter_id}&mode=${destination}`);
  };

  const handleNavigateLetterComposition = (letterId: string, mode: string) => {
    if (user?.credit_count < LetterType.recommendation) {
      openInNewTab(ROUTES.SUBSCRIPTION);
    } else {
      navigate(`${ROUTES.WRITE_LETTER}?letterId=${letterId}&mode=${mode}`);
    }
  };

  const handleClickMenu = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = (): void => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (studentAccess || creditUsed) {
      const destination = creditUsed || studentAccess ? "constructor" : "default";
      setDestination(destination);
    }
  }, [studentAccess, creditUsed, destination]);

  useEffect(() => {
    if (accountType === AccountTypeEnum.Applicant && studentAccess && isOpenLetterCompositionModal) {
      handleCloseLetterCompositionModal();
      setDestination("constructor");
      handleNavigateToWriteLetter();
    }
  }, [isOpenLetterCompositionModal]);

  return (
    <>
      <Modal open={isOpenLetterCompositionModal} onClose={handleCloseLetterCompositionModal}>
        <Box width="100%" height="100%" display="flex" alignItems="center" justifyContent="center">
          <LetterCompositionModal
            handleChangePageMode={() => handleNavigateLetterComposition(props.row.letter_id, mode)}
            handleNavigateDefault={handleNavigateToWriteLetter}
            onClose={handleCloseLetterCompositionModal}
            letterType="recommendation"
          />
        </Box>
      </Modal>
      {!props.row.archived && accountType === AccountTypeEnum.Writer && (
        <Button sx={{ minWidth: "auto", padding: "8px" }} color="secondary" onClick={handleClickMenu}>
          <MoreVertOutlined pointerEvents="none" sx={{ color: colors.black }} />
        </Button>
      )}
      {accountType === AccountTypeEnum.Applicant && (
        <Button sx={{ minWidth: "auto", padding: "8px" }} color="secondary" onClick={handleClickMenu}>
          <MoreVertOutlined pointerEvents="none" sx={{ color: colors.black }} />
        </Button>
      )}
      {open && accountType === AccountTypeEnum.Applicant && (
        <ControlStudentListPopover
          open={open}
          anchor={anchorEl}
          onClose={handleCloseMenu}
          letterId={props?.row?.letter_id}
          onViewDetails={() => {
            onViewDetails && onViewDetails(props.row);
          }}
          status={props.row.status}
          accessStudent={props.row.student_access}
          questions={props.row.questions}
          letterTypeId={props.row.letterTypeId}
          handleOpenLetterCompositionModal={handleOpenLetterCompositionModal}
          handleNavigateToWriteLetter={handleNavigateToWriteLetter}
        />
      )}
      {open && accountType === AccountTypeEnum.Writer && !props.row.archived && (
        <ControlProfessorListPopover
          open={open}
          anchor={anchorEl}
          onClose={handleCloseMenu}
          status={props.row.status}
          questions={props.row.questions}
          letterId={props?.row?.letter_id}
          onViewDetails={() => {
            onViewDetails && onViewDetails(props.row);
          }}
          letterTypeId={props.row.letterTypeId}
          archived={props?.row?.archived}
          isSelfRequest={props?.row?.is_self_request}
          studentAccess={props?.row?.student_access}
          handleOpenStudentAccessModal={handleOpenStudentAccessModal}
          handleOpenLetterCompositionModal={handleOpenLetterCompositionModal}
          handleNavigateToWriteLetter={handleNavigateToWriteLetter}
        />
      )}
    </>
  );
};
