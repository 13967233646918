import clone from "just-clone";
import { Question } from "../../components/Questionnaire/types";
import { LetterRequest } from "../../views/LetterStatus/types";
import {
  DELETE_REQUEST,
  REMOVE_FILTER_VALUE,
  REMOVE_LETTER_TEMPLATE,
  RESET,
  SET_EDIT_REQUEST,
  SET_FILTER_VALUE,
  SET_LETTER_TEMPLATE,
  SET_REQUESTS,
  SET_COVER_REQUESTS,
  SET_SEPARATE_REQUESTS,
  UPDATE_REQUEST,
  SET_SELECTED_REQUEST_ID,
  REMOVE_SELECTED_REQUEST_ID,
} from "../actionTypes";
import { CoverLetterRequest } from "../../views/CoverLetterStatus/types";

type LetterStatus = {
  requests: LetterRequest[] | null;
  coverRequests: CoverLetterRequest[] | null;
  filter: Record<string, any>;
  template: Question[] | null;
  editable: boolean;
  letterTypeId: string;
  selectedRequestId: string | null;
};

const initialState: LetterStatus = {
  requests: [],
  coverRequests: [],
  filter: {},
  template: null,
  editable: false,
  letterTypeId: "",
  selectedRequestId: null,
};

const requests = (state: LetterStatus = initialState, action: { type: string; payload: any }) => {
  switch (action.type) {
    case REMOVE_FILTER_VALUE: {
      const _f = clone(state.filter);
      if (_f[action.payload]) {
        delete _f[action.payload];
      }
      return { ...state, filter: _f };
    }
    case SET_REQUESTS:
      return { ...state, requests: action.payload };

    case SET_COVER_REQUESTS:
      return { ...state, coverRequests: action.payload };

    case SET_SEPARATE_REQUESTS:
      return { ...state, requests: [...state.requests!, ...action.payload] };

    case SET_FILTER_VALUE:
      return { ...state, filter: { ...state.filter, ...action.payload } };

    case SET_LETTER_TEMPLATE:
      return {
        ...state,
        template: action.payload.template,
        letterTypeId: action.payload.letterTypeId,
      };

    case REMOVE_LETTER_TEMPLATE:
      return { ...state, template: null, letterTypeId: null };

    case UPDATE_REQUEST: {
      const _requests = state?.requests && clone(state?.requests);
      const existing = _requests?.find((r) => r.id === action.payload.id);
      const index = existing && _requests?.indexOf(existing);
      if (_requests !== null && index !== undefined) {
        _requests[index] = action.payload;
        return { ...state, requests: _requests };
      }
      return { ...state };
    }

    case DELETE_REQUEST: {
      const _requests = state?.requests && clone(state?.requests);
      const filtered = _requests?.filter((r) => r.letter_id !== action.payload);
      return { ...state, requests: filtered };
    }

    case SET_EDIT_REQUEST: {
      return { ...state, editable: action.payload };
    }

    case SET_SELECTED_REQUEST_ID: {
      return { ...state, selectedRequestId: action.payload };
    }

    case REMOVE_SELECTED_REQUEST_ID: {
      return { ...state, selectedRequestId: null };
    }

    case RESET:
      return initialState;

    default:
      return state;
  }
};

export default requests;
