import { withdrawLuciCredits } from "../../api/letterRequest";
import store from "../../redux";
import { setCreditCount } from "../../redux/actionCreators";
import { LetterType } from "../../utils/globalLetterTypesCredits";

export const startUsingLuciAssistantCoverLetter = async (
  letterId: string,
  user: Record<string, any>,
) => {
  if (!letterId || !user) {
    return;
  }

  try {
    const credit_count = user.credit_count - LetterType.cover;
    const spend = await withdrawLuciCredits(letterId, LetterType.cover);
    if (!spend?.error) {
      store.dispatch(setCreditCount(credit_count));
    }
    return { error: "Unknown error", spend };
  } catch (err) {
    return { error: err };
  }
};