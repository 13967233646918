import React from "react";
import { Box, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import CancelIcon from "@mui/icons-material/Cancel";
import { ROUTES } from "../../../constants/routes";
import { ControlButton } from "../../LetterCompositionConstructor/Section/ControlButton";
import { FinishedFieldProps } from "./types";

export const FinishedField = ({ text, onShowFinished }: FinishedFieldProps) => {

  const navigate = useNavigate();
  const handleNavigateDashboard = () => navigate(ROUTES.HOME);

  return (
    <Box>
      <Box display="grid" gridTemplateColumns="2fr 1fr" width="100%">
        <Typography whiteSpace="pre-wrap">{text}</Typography>
      </Box>
      <Box display="flex" flexDirection="row" mt={4} gap={2}>
        <ControlButton
          variant="contained"
          text="Show finished letter"
          onClick={onShowFinished}
        />
        <ControlButton
          text="Exit"
          onClick={handleNavigateDashboard}
          Icon={<CancelIcon />}
        />
      </Box>
    </Box>
  );
};