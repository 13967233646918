import React, { useEffect, useState } from "react";
import { Box, Button, Modal } from "@mui/material";
import clone from "just-clone";
import { Section } from "./Section/Section";
import { EnthusiasmLevel, LetterCompositionConstructorProps, Paragraph } from "./types";
import { EnthusiasmSliderComponent } from "./EnthusiasmSliderComponent";
import { getEnthusiasmLevels } from "../../api/section";
import {
  acceptSection,
  createFinishedSections,
  createNewSection,
  sectionsFromParagraphs,
  updateSection,
  downloadTxtFile,
} from "./utils";
import { FinishedLetterModal } from "./FInishedLetterModal";
import { updateLetterRequest } from "../../api/letterRequest";
import { getLetterRequest } from "../../api/letterRequest";
import { RequestStatus } from "../../views/LetterStatus/types";

export const LetterCompositionConstructor = ({
  letterId,
  paragraphs,
  enthusiasmLevel,
  letterTypeId,
  maxSections,
}: LetterCompositionConstructorProps) => {
  const [sections, setSections] = useState<Array<Paragraph>>([]);
  const [enthusiasmLevels, setEnthusiasmLevels] = useState<Array<EnthusiasmLevel>>([]);
  const [enthusiasm, setEnthusiasm] = useState<EnthusiasmLevel>();
  const [sectionId, setSectionId] = useState("");
  const [isGenerating, setIsGenerating] = useState(false);
  const [finishedLetter, setFinishedLetter] = useState<Array<Paragraph>>([]);
  const [isShowFinished, setIsShowFinished] = useState(false);

  const getInitialData = async () => {
    const _enthusiasmLevels = await getEnthusiasmLevels(letterTypeId);
    const letterRequest = letterId && (await getLetterRequest(letterId));
    const level = enthusiasmLevel
      ? _enthusiasmLevels.find((l: EnthusiasmLevel) => l.code === enthusiasmLevel)
      : _enthusiasmLevels[0];
    setEnthusiasm(level || _enthusiasmLevels[0]);
    setEnthusiasmLevels(_enthusiasmLevels);

    if (paragraphs.length) {
      const _sections = sectionsFromParagraphs(paragraphs, maxSections);
      const finished = letterRequest.status === RequestStatus.f;
      setSections(_sections);
      if (finished) {
        const finished = createFinishedSections(_sections, maxSections);
        setSections(finished);
        setFinishedLetter(finished?.filter((f: Paragraph) => f.text.length) || []);
      }
    } else {
      const firstSection = createNewSection({ id: 0 });
      setSections([firstSection]);
    }
  };

  const onUpdate = async (section: Paragraph, index: number) => {
    const anyEditing = sections.find((s: Paragraph) => s.isEditable);
    if (!isGenerating) {
      if (!anyEditing || (anyEditing && anyEditing.id === section.id)) {
        const copy = await updateSection(index, section, sections, letterId, enthusiasm);
        setSections(copy);
        if (finishedLetter.length) {
          const finished = createFinishedSections(copy, maxSections);
          setFinishedLetter(finished?.filter((f: Paragraph) => f.text.length) || []);
        }
      }
    }

    return;
  };

  const onAccept = async (
    section: Paragraph,
    index: number,
    finish: boolean = false,
  ): Promise<Array<Paragraph>> => {
    let acceptedSection;
    if (finish) {
      const copy = clone(sections);
      copy[index] = section;
      const finished = createFinishedSections(copy, maxSections);
      acceptedSection = await acceptSection(index, section, finished, sectionId, letterId, maxSections, enthusiasm);
    } else {
      acceptedSection = await acceptSection(index, section, sections, sectionId, letterId, maxSections, enthusiasm);
    }
    setSections(acceptedSection);
    return acceptedSection;
  };

  const onFinish = async (section: Paragraph, index: number) => {
    await updateLetterRequest(letterId, { status: RequestStatus.f });
    const copy = await onAccept(section, index, true);
    const finished = createFinishedSections(copy, maxSections);
    setSections(finished);
    setFinishedLetter(finished?.filter((f: Paragraph) => f.text.length) || []);
    setIsShowFinished(true);
  };

  useEffect(() => {
    getInitialData();
  }, []);

  return (
    <Box
      boxSizing="border-box"
      width="100%"
      mt={4}
      pb={10}
      display="flex"
      flexDirection="column"
      gap={2}
    >
      <Modal open={isShowFinished} onClose={() => setIsShowFinished(false)}>
        <Box display="flex" width="100%" height="100%" alignItems="center" justifyContent="center">
          <FinishedLetterModal
            letterId={letterId}
            onClose={() => setIsShowFinished(false)}
            onDownload={() => downloadTxtFile(sections)}
            paragraphs={finishedLetter}
          />
        </Box>
      </Modal>
      <Box display="flex" flexDirection="column" gap={2}>
        {sections?.map((s: Paragraph, i: number) => (
          <Section
            {...s}
            index={i}
            key={s.id}
            section={s}
            accept={onAccept}
            letterId={letterId}
            updateSection={onUpdate}
            onFinishLetter={onFinish}
            enthusiasmLevel={enthusiasm}
            updateSectionId={(id: string) => setSectionId(id)}
            onTextGeneration={(state: boolean) => setIsGenerating(state)}
            maxSections={maxSections}
            enthusiasm={({ generateText }) => (
              <EnthusiasmSliderComponent
                enthusiasm={enthusiasm}
                levels={enthusiasmLevels}
                onChangeLevel={(lvl: EnthusiasmLevel) => setEnthusiasm(lvl)}
                generateText={generateText}
              />
            )}
          />
        ))}
      </Box>
      <Box>
        {finishedLetter.length > 0 && (
          <Button variant="contained" color="secondary" onClick={() => setIsShowFinished(true)}>
            Show finished letter
          </Button>
        )}
      </Box>
    </Box>
  );
};
