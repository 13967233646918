import React, { useEffect, useRef, useState } from "react";
import { Box, IconButton } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { NotificationsPopover } from "./NotificationsPopover";
import { getAllNotifications } from "../../../api/notifications";
import { RootState } from "../../../redux/reducers";
import { setNotifications, setReminders } from "../../../redux/actionCreators";
import { createNotifications } from "./utils";
import { LuciNotification } from "./types";
import { colors } from "../../../theme";
import { TutorialClasses } from "../../Tutorial/tutorialClasses";
import { getType } from "../../../api/localStorage/type";
import { AccountTypeEnum } from "../../../constants";

const ZERO_ELEM = 0;

export const Notifications = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [requestTime, setRequestTime] = useState(0);
  const { notifications, reminders } = useSelector((state: RootState) => state.notification);
  const { tourActive, stepIndex } = useSelector((state: RootState) => state.tutorial);
  const showBadgeNotifications = notifications?.some((n: LuciNotification) => !n.isRead);
  const showBadgeReminders = reminders?.some((n: LuciNotification) => !n.isRead);
  const dispatch = useDispatch();
  const accountType = getType();

  const isShowBadge = showBadgeNotifications || showBadgeReminders;
  const isTourContinue = tourActive && stepIndex === 3 && accountType === AccountTypeEnum.Writer;
  const isProperAccountClassName = accountType === AccountTypeEnum.Applicant ? TutorialClasses.fifthTutorialStep : TutorialClasses.thirdTutorialStep;

  const anchor = useRef<HTMLButtonElement | null>(null);

  const handleOpen = (): void => setIsOpen(true);

  const handleClose = (): void => setIsOpen(false);

  const getNotifications = async () => {
    const requestStartAt = performance.now();
    const data = await getAllNotifications();
    const notifications = createNotifications(data[ZERO_ELEM].notifications)
      .sort((prev: LuciNotification, next: LuciNotification) => {
        return new Date(next.time).getTime() - new Date(prev.time).getTime();
      });
    const reminders = createNotifications(data[ZERO_ELEM].reminders);
    dispatch(setNotifications(notifications));
    dispatch(setReminders(reminders));
    const requestEndAt = performance.now();
    const timeSpent = requestEndAt - requestStartAt;
    setRequestTime(timeSpent);
  };


  useEffect(() => {
    getNotifications();
    return () => {
    };
  }, []);

  useEffect(() => {
    if (isOpen) {
      getNotifications();
    }
  }, [isOpen]);

  useEffect(() => {
    if (isTourContinue) {
      handleOpen();
    } else {
      handleClose();
    }
  }, [stepIndex]);


  return (
    <Box mr={2}>
      <IconButton onClick={handleOpen} ref={anchor}>
        <NotificationsIcon
          className={isProperAccountClassName}
          sx={{ color: "#979797" }}
          fontSize="small" />
        {isShowBadge && (
          <Box
            width={7}
            height={7}
            borderRadius={50}
            bgcolor={colors.white}
            position="absolute"
            right="30%"
            top="30%"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Box width={5} height={5} borderRadius={50} bgcolor={colors.error} />
          </Box>
        )}
      </IconButton>
      <NotificationsPopover
        isOpen={isOpen}
        anchor={anchor.current}
        onClose={handleClose}
        requestTime={requestTime}
      />
    </Box>
  );
};
