import React from "react";
import { Box, Popover, Typography } from "@mui/material";
import { colors } from "../../theme";

type DueDatePopoverProps = {
  open: boolean;
  onClose: () => void;
  anchor: Element | null;
  date: string;
  icon: React.ReactNode;
};

export const DueDatePopover = ({ open, onClose, anchor, date, icon }: DueDatePopoverProps) => {
  return (
    <Popover
      open={open}
      anchorEl={anchor}
      onBlur={onClose}
      anchorOrigin={{
        vertical: -40,
        horizontal: 30,
      }}
      disableScrollLock={true}
    >
      <Box display="flex" alignItems="center" p={2}>
        {icon}
        <Typography pl={0.5} variant="text" color={colors.gray[400]}>
          Due date:{" "}
          <Typography variant="text" color={colors.black}>
            {date}
          </Typography>
        </Typography>
      </Box>
    </Popover>
  );
};
