import React, { useRef, useState } from "react";
import { Box, Link, Typography } from "@mui/material";
import { LuciCredit } from "../../../assets";
import { LuciCreditPopOver } from "./LuciCreditPopover";
import { getType } from "../../../api/localStorage/type";
import { AccountTypeEnum } from "../../../constants";
import { TutorialClasses } from "../../Tutorial/tutorialClasses";

type LuciCreditsBalanceProps = {
  count: number;
};

export const LuciCreditsBalance = ({ count }: LuciCreditsBalanceProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const luciCreditsRef = useRef(null);
  const open = Boolean(anchorEl);
  const accountType = getType();

  const showPopUp = (): void => {
    setAnchorEl(luciCreditsRef.current);
  };

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  return (
    <>
      <LuciCreditPopOver
        credits={count}
        open={open}
        anchorEl={anchorEl}
        handleClose={handleClose}
      />
      <Box
        className={accountType === AccountTypeEnum.Writer ? TutorialClasses.secondTutorialStep : ""}
        mr={4}
        ref={luciCreditsRef}
      >
        <Link
          onClick={showPopUp}
          display="flex"
          underline="none"
          alignItems="center"
          color="black"
          gap={0.5}
        >
          <img style={{ height: "100%" }} src={LuciCredit} alt="luci-credit" />
          <Typography variant="smallText">{count}</Typography>
        </Link>
      </Box>
    </>
  );
};
