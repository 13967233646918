import React, { useEffect, useState } from "react";
import { Box, Modal, useMediaQuery } from "@mui/material";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import clone from "just-clone";
import { getType } from "../../api/localStorage/type";
import PageTemplate from "../../components/PageTemplate";
import Questionnaire from "../../components/Questionnaire";
import { createCoverLetterRequest, getCoverLetterQuestions } from "../../api/coverLetter";
import { Question } from "../../components/Questionnaire/types";
import { prepareQuestions } from "../NewLetter/utils";
import { RootState } from "../../redux/reducers";
import ProgressBar from "../../components/ProgressBar";
import { NewCoverLetterComposition } from "../../components/NewCoverLetterComposition";
import Loader from "../../components/Loader";
import { LetterCompositionModal } from "../../components/LetterCompositionModal/LetterCompositionModal";
import { ROUTES } from "../../constants/routes";
import { LetterType } from "../../utils/globalLetterTypesCredits";

const STEPS = 2;

enum Steps {
  FIRST_STEP = 1,
  SECOND_STEP,
}

export const NewCoverLetter = () => {
  const [step, setStep] = useState<number>(1);
  const [questions, setQuestions] = useState<Question[] | []>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [coverLetterId, setCoverLetterId] = useState("");
  const [coverId, setCoverId] = useState(0);
  const [isOpenLetterCompositionModal, setIsOpenLetterCompositionModal] = useState(false);

  const { user } = useSelector((state: RootState) => state.user);
  const isDesktop = useMediaQuery("(min-width:600px)");
  const navigate = useNavigate();

  const IS_MIN_CREDITS = user?.credit_count < LetterType.cover;

  const handleOpenLetterCompositionModal = () => setIsOpenLetterCompositionModal(true);
  const handleCloseLetterCompositionModal = () => setIsOpenLetterCompositionModal(false);

  const handleNavigateToBuyLuci = () => navigate(ROUTES.SUBSCRIPTION);

  const getQuestions = async () => {
    if (questions?.length) {
      return;
    }

    try {
      setIsLoading(true);
      const questionsData = await getCoverLetterQuestions();
      const preparedQuestions = prepareQuestions(questionsData, user);
      setQuestions(preparedQuestions);
    } catch (err: unknown) {
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleNext = async () => {
    handleCloseLetterCompositionModal();
    if (step + Steps.FIRST_STEP <= STEPS) {
      setStep(step + Steps.FIRST_STEP);
    }

    let body: any = {};

    questions.forEach((q: Question) => {
      body[q.id] = q.answer;
    });
    setIsLoading(true);
    const result = await createCoverLetterRequest(body);
    setCoverLetterId(result.letter_id);
    setCoverId(result.id);
    setIsLoading(false);
  };

  const handleChangeQuestion = (questionId: string, answer: string) => {
    const questionsCopy: Question[] | [] = clone(questions);
    const _current = questionsCopy?.find((q: Question) => q.id === questionId);
    if (_current) {
      _current.answer = answer;
    }

    setQuestions(questionsCopy);
  };

  useEffect(() => {
    if(user) {
      getQuestions();
    }
  }, [step, user]);

  return (
    <>
      <Modal open={isOpenLetterCompositionModal} onClose={handleCloseLetterCompositionModal}>
        <Box width="100%" height="100%" display="flex" alignItems="center" justifyContent="center">
          <LetterCompositionModal
            handleChangePageMode={IS_MIN_CREDITS ? handleNavigateToBuyLuci : handleNext}
            onClose={handleCloseLetterCompositionModal}
            letterType="cover"
          />
        </Box>
      </Modal>
      <PageTemplate type={getType()}>
        <Box width="100%" height="100%">
          <ProgressBar
            height={40}
            current={step}
            steps={STEPS}
            startColor="#BC4B51"
            endColor="#F4A159"
          />
          <Box
            display="flex"
            width="100%"
            paddingTop={6}
            px={isDesktop ? 3 : 0.5}
            boxSizing="border-box"
            alignItems="center"
            flexDirection="column"
          >
            {isLoading && (
              <Box
                width="100%"
                height="100%"
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <Loader size={50} />
              </Box>
            )}
            {step === Steps.FIRST_STEP && (
              <Questionnaire
                questions={questions}
                onNext={handleOpenLetterCompositionModal}
                loading={isLoading}
                onChangeQuestion={handleChangeQuestion}
              />)}
            {step === Steps.SECOND_STEP && !isLoading && coverLetterId && (
              <NewCoverLetterComposition id={coverId} letterId={coverLetterId} />
            )}
          </Box>
        </Box>
      </PageTemplate>
    </>
  );
};