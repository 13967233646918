import React, { ReactNode } from "react";
import { Box } from "@mui/system";
import { Typography } from "@mui/material";
import { colors } from "../../theme";

type NotifyBannerProps = {
  bannerTitle: string;
  text: string;
  image: string;
  buttons?: Array<React.ReactNode>;
  showArrow?: boolean;
};

const NotifyBanner = ({
  bannerTitle,
  text,
  image,
  buttons,
  showArrow = true,
}: NotifyBannerProps): JSX.Element => {
  return (
    <Box
      marginTop={3}
      display="flex"
      flexDirection="row"
      maxWidth={400}
      boxSizing="border-box"
      border={`1px solid ${colors.gray[300]}`}
      borderRadius="4px"
      position="relative"
      sx={{ backgroundColor: colors.gray[100] }}
    >
      {showArrow && (
        <Box
          sx={{
            transform: "rotate(45deg)",
            backgroundColor: colors.gray[100],
          }}
          position="absolute"
          width={15}
          height={15}
          borderTop={`1px solid ${colors.gray[300]}`}
          borderLeft={`1px solid ${colors.gray[300]}`}
          top={-9}
          left="80%"
        />
      )}
      <Box component="img" sx={{ objectFit: "cover" }} src={image} />
      <Box display="flex" borderRadius={1} alignItems="center" paddingX={4} paddingY={1} gap={2}>
        <Box display="flex" flexDirection="column" gap={1} textAlign="center">
          <Typography variant="mediumTitle">{bannerTitle}</Typography>
          <Typography variant="smallText" color="gray">
            {text}
          </Typography>
          {buttons?.length && (
            <Box paddingY={1}>
              {buttons?.map((ButtonComponent: ReactNode, index: number) => (
                <Box key={index}>{ButtonComponent}</Box>
              ))}
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default NotifyBanner;
