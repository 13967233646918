import React from "react";
import { Box, Button, Link } from "@mui/material";
import { useLocation } from "react-router";
import ArchiveIcon from "@mui/icons-material/Archive";
import SendIcon from "@mui/icons-material/Send";
import InfoIcon from "@mui/icons-material/Info";
import { DeleteOutline, ModeEdit } from "@mui/icons-material";
import { ROUTES } from "../../constants/routes";
import { checkIsValidForWriteLetter } from "../../utils/checkIsValid";
import { RequestStatus } from "../../views/LetterStatus/types";

type ControlMenuProfessorProps = {
  onWriteLetter: () => void;
  request: null | Record<string, any>;
  onArchive: (letterId: string) => void;
  onRequestMoreInfo: () => void;
  onEdit: () => void;
  onDelete: () => void;
};

export const ControlMenuProfessor: React.FC<ControlMenuProfessorProps> = ({
  onWriteLetter,
  request,
  onArchive,
  onRequestMoreInfo,
  onEdit,
  onDelete,
}) => {
  const location = useLocation();
  const status = request?.status;
  const archived = request?.archived;

  return (
    <>
      {checkIsValidForWriteLetter(status) && (
        <Box display="flex" flexDirection="row" gap={4} alignItems="center">
          {location.pathname === ROUTES.WRITE_LETTER ? null : (
            <Button onClick={onWriteLetter} variant="contained" color="secondary">
              Write Letter
            </Button>
          )}
          {!request?.is_self_request && status !== RequestStatus.ru && (
            <Box display="flex" alignItems="center" gap={0.5}>
              {status === RequestStatus.mi ?
                <InfoIcon fontSize="small" color="secondary" /> :
                <SendIcon fontSize="small" color="secondary" />}
              <Link fontSize={14} color="secondary" onClick={onRequestMoreInfo}>
                {status === RequestStatus.mi ? "More info" : "Request more information"}
              </Link>
            </Box>
          )}
          {status === RequestStatus.r && request?.is_self_request && (
            <Box display="flex" alignItems="center" gap={0.5}>
              <DeleteOutline fontSize="small" color="secondary" />
              <Link onClick={onDelete} fontSize={14} color="secondary">
                Delete request
              </Link>
            </Box>
          )}
          {!request?.is_self_request && status === RequestStatus.ru && (
            <Box display="flex" alignItems="center" gap={0.5}>
              <InfoIcon fontSize="small" color="secondary" />
              <Link fontSize={14} color="secondary" onClick={onRequestMoreInfo}>
                More info
              </Link>
            </Box>
          )}
        </Box>
      )}
      {status === RequestStatus.rd && (
        <Button onClick={onWriteLetter} variant="contained" color="secondary">
          Continue Writing
        </Button>
      )}
      {(status === RequestStatus.f || status === RequestStatus.s) && (
        <Button onClick={onWriteLetter} variant="contained" color="secondary">
          View Letter
        </Button>
      )}
      {status !== RequestStatus.s && status !== RequestStatus.d && status !== RequestStatus.mi && (
        <Box display="flex" alignItems="center" gap={0.5}>
          <ModeEdit fontSize="small" color="secondary" />
          <Link onClick={onEdit} fontSize={14} color="secondary">
            Edit Request
          </Link>
        </Box>
      )}
      {!archived && (status === RequestStatus.d || status === RequestStatus.s) && (
        <Box display="flex" alignItems="center" gap={0.5}>
          <ArchiveIcon fontSize="small" color="secondary" />
          <Link fontSize={14} color="secondary" onClick={() => onArchive(request?.letter_id)}>
            Archive
          </Link>
        </Box>
      )}

      <Box display="flex" alignItems="center" gap={0.5}></Box>
    </>
  );
};
