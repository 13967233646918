import React, { useState } from "react";
import { Box, Button, Link, Modal, Typography, useMediaQuery } from "@mui/material";
import { CloseOutlined } from "@mui/icons-material";
import clone from "just-clone";
import toast from "react-hot-toast";
import FileUploaderWithDragAndDrop from "../FileUpload/DragAndDropFiles";
import { PreviewComponent } from "./Preview";
import { colors } from "../../theme";
import { UploadWithLink } from "./UploadWithLink";
import { LetterRequestDocument } from "./types";
import { DocumentItem } from "./DocumentItem";
import { FILE_MAX_SIZE, getFileSize, getFileSizeNumber } from "../../utils/fileSize";
import { joinFormatDate } from "../../utils/joinFormatDate";
import { ConfirmationModal } from "../ConfirmationModal";

const ALLOWED_FILES_FORMATS = [".pdf", ".jpeg", ".jpg", ".png", ".gif", ".doc", ".docx", ".xlsx", "pptx", ".txt", ".rtf"];

type FileUploadWithProgressProps = {
  onClose?: () => void;
  onDocumentsUpdate?: (d: Array<LetterRequestDocument> | []) => void;
  documents: Array<LetterRequestDocument> | [];
  onUpdate: (i: LetterRequestDocument) => void;
  onDelete: (i: LetterRequestDocument) => void;
  tooltipSupportedFormatsTitle?: string;
};

export const FileUploadWithProgress = ({
  onClose,
  onDocumentsUpdate,
  documents = [],
  onUpdate,
  onDelete,
  tooltipSupportedFormatsTitle,
}: FileUploadWithProgressProps) => {
  const [fromUrl, setFromUrl] = useState(false);
  const [isUnsavedDescription, setIsUnsavedDescription] = useState<boolean | null>(false);
  const [isOpenModal, setIsOpenModal] = useState(false);

  const isMobile = useMediaQuery("(max-width:520px)");

  const handleOpenModal = () => setIsOpenModal(true);
  const handleCloseModal = () => setIsOpenModal(false);

  const handleSetIsUnsavedDescription = (isSaved: boolean | null) => {
    setIsUnsavedDescription(isSaved);
  };
  const switchUploadType = () => setFromUrl(!fromUrl);

  const updateFile = (fileList: FileList | null | undefined) => {
    const formatDate = (d: Date) => {
      const options: object[] = [{ year: "numeric" }, { month: "2-digit" }, { day: "2-digit" }];
      return joinFormatDate(new Date(d), options, "-");
    };

    if (fileList) {
      const lastId = documents.length - 1;
      const today = new Date();

      const files: Array<LetterRequestDocument> = Array.from(fileList).map((file: File, index: number) => ({
        id: `${lastId + index + 1 + file.name + file.size}`,
        type: "file",
        file,
        date: formatDate(today),
        size: getFileSize(file),
        description: "",
      }));
      if (files.some((el: LetterRequestDocument) => el?.file?.size! > FILE_MAX_SIZE)) {
        toast.error(`the file size should be lower than ${getFileSizeNumber(FILE_MAX_SIZE)}`);
        return;
      }
      onDocumentsUpdate && onDocumentsUpdate([...documents, ...files]);
    }
  };

  const uploadUrl = (url: string) => {
    const link: LetterRequestDocument = {
      id: `${documents.length + url}`,
      type: "link",
      link: url,
      date: new Date(),
      description: "",
    };
    const docs: Array<LetterRequestDocument> = clone(documents);
    docs.push(link);
    onDocumentsUpdate && onDocumentsUpdate(docs);
  };

  return (
    <>
      <Modal open={isOpenModal} onClose={handleCloseModal}>
        <Box width="100%" height="100%" display="flex" alignItems="center" justifyContent="center">
          <ConfirmationModal
            onSubmit={onClose!}
            onCancel={handleCloseModal}
            title="Do you really want to close this window? Your description data won't be saved"
            actionName="Close"
          />
        </Box>
      </Modal>
      <Box
        minWidth={isMobile ? 320 : 520}
        minHeight={200}
        onClick={(e: React.MouseEvent<HTMLDivElement>) => e.stopPropagation()}
        bgcolor="white"
        borderRadius={1}
        maxHeight="100%"
        overflow="auto"
        py={2}
        boxSizing="content-box"
      >
        <Box
          px={3}
          py={2}
          boxSizing="border-box"
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
          borderBottom={`.5px solid ${colors.gray[100]}`}
        >
          <Typography fontWeight={900} variant="smallText" color={colors.gray[600]}>
            UPLOAD FILES
          </Typography>
          <Link underline="none" onClick={onClose}>
            <CloseOutlined fontSize="small" color="disabled" />
          </Link>
        </Box>
        <Box padding={1}>
          {!fromUrl ? (
            <FileUploaderWithDragAndDrop
              setFile={updateFile}
              allowedFormats={ALLOWED_FILES_FORMATS}
              PreviewComponent={({ onSelectFile }) => (
                <PreviewComponent
                  onSelectFile={onSelectFile}
                  switchUploadType={switchUploadType}
                  tooltipSupportedFormatsTitle={tooltipSupportedFormatsTitle}
                />
              )}
            />
          ) : (
            <UploadWithLink uploadUrl={uploadUrl} switchUploadType={switchUploadType} />
          )}
          {documents.length > 0 && (
            <Box width="auto">
              <Typography px={2} fontWeight={900} variant="smallText" color={colors.gray[600]}>
                FILES & LINKS
              </Typography>
              <Box py={1} overflow="auto" maxHeight={500} boxSizing="border-box">
                {documents.map((item: LetterRequestDocument, index: number) => (
                  <DocumentItem
                    key={item.id + index}
                    item={item}
                    onChange={onUpdate}
                    onDelete={onDelete}
                    isLast={index === documents.length - 1}
                    handleSetIsUnsavedChanges={handleSetIsUnsavedDescription}
                  />
                ))}
              </Box>
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="flex-end"
                mr={2}
                mt={2}
              >
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={isUnsavedDescription ? handleOpenModal : onClose}
                >
                  Done
                </Button>
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
};
