import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { colors, Link, useMediaQuery } from "@mui/material";
import { Box } from "@mui/system";
import { useNavigate } from "react-router-dom";

import { AccountTypeEnum, HEADER_HEIGHT } from "../../constants";
import HeaderMenu from "../HeaderMenu";
import { APPLICANT_TOGGLES, WRITER_TOGGLES } from "./constants";
import { getRequest } from "../../api";
import { RootState } from "../../redux/reducers";
import { setUser } from "../../redux/actionCreators";
// import Logo from "../Logo";
import { ReactComponent as Logo } from '../../assets/images/Logo2.svg';
import { ROUTES } from "../../constants/routes";

type HeaderProps = {
  accountType?: AccountTypeEnum | null | string;
};

const Header = ({ accountType }: HeaderProps) => {
  const [isShowAvatarLoader, setIsShowAvatarLoader] = useState(false);
  const navigate = useNavigate();
  const isDesktop = useMediaQuery("(min-width:900px)");
  const dispatch = useDispatch();
  const { user } = useSelector((state: RootState) => state.user);

  const getUserData = useCallback(async () => {
    try {
      setIsShowAvatarLoader(true);
      const profile = await getRequest("/myprofile/");
      dispatch(setUser(profile));
    } catch (err: unknown) {
      console.error(err);
    } finally {
      setIsShowAvatarLoader(false);
    }
  }, [dispatch]);

  useEffect(() => {
    if (!user && accountType) {
      getUserData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const _toggles = accountType
    ? accountType === AccountTypeEnum.Applicant
      ? APPLICANT_TOGGLES
      : WRITER_TOGGLES
    : undefined;
  return (
    <Box
      height={HEADER_HEIGHT}
      width="100%"
      borderBottom={`1px solid ${colors.grey[300]}`}
      display="flex"
      boxSizing="border-box"
      paddingX={isDesktop ? 8 : 3}
      flexDirection="row"
      alignItems="center"
    >
      <Link href={ROUTES.CHOOSE_LETTER_TYPE}>
        <Logo viewBox="0 0 500 100" width="150px" height="40px"/>
        {/* <Logo /> */}
      </Link>
      {accountType &&
        <HeaderMenu
          accountType={accountType}
          toggles={_toggles}
          getUserData={getUserData}
          isShowAvatarLoader={isShowAvatarLoader}
        />}
    </Box>
  );
};

export default Header;
