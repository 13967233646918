import { FC, useEffect, useState } from "react";
import { Box, Button } from "@mui/material";
import toast from "react-hot-toast";
import FileUploaderWithDragAndDrop from "../FileUpload/DragAndDropFiles";
import { FileChild } from "../FileUpload/FileChild";
import { PreviewComponent } from "./Preview";
import Loader from "../Loader";
import { getFileSizeNumber, IMAGE_MAX_SIZE } from "../../utils/fileSize";

const ALLOWED_AVATAR_FORMATS = ['.jpeg', '.jpg', '.png', '.gif'];

export interface UploadAvatarProps {
  onUpload: (file: File | null | undefined) => void;
}

const UploadAvatar: FC<UploadAvatarProps> = ({ onUpload = () => {} }) => {
  const [file, setFile] = useState<File | null | undefined>(null);
  const [items, setItems] = useState<any>(null);
  const [updating, setUpdating] = useState<boolean>(false);

  const handleUpdateAvatar = async () => {
    setUpdating(true);
    await onUpload(file);
  };

  const updateFile = (f: FileList | null | undefined) => {
    if (f && f[0]?.size < IMAGE_MAX_SIZE) {
      setFile(f[0]);
    } else {
      toast.error(`the file size should be lower than ${getFileSizeNumber(IMAGE_MAX_SIZE)}`)
    }
  };

  useEffect(() => {
    if (!Array.isArray(file) && file) {
      setItems([file]);
    } else if (Array.isArray(file)) {
      setItems(file);
    } else if (!file) {
      setItems(null);
    }
  }, [file]);

  return (
    <Box
      px={6}
      py={4}
      minWidth={350}
      minHeight={200}
      onClick={(e) => e.stopPropagation()}
      bgcolor="white"
      borderRadius={5}
    >
      {items ? (
        items?.map((item: any, index: number) => (
          <Box key={item?.name}>
            <FileChild
              key={item?.name + index}
              item={item}
              handleRemoveFile={() => setFile(null)}
              preview={true}
            />
          </Box>
        ))
      ) : (
        <FileUploaderWithDragAndDrop
          setFile={updateFile}
          allowedFormats={ALLOWED_AVATAR_FORMATS}
          PreviewComponent={PreviewComponent}
        />
      )}
      <Box mt={4}>
        <Button
          variant="contained"
          disabled={!file || updating}
          color="secondary"
          onClick={handleUpdateAvatar}
          fullWidth
        >
          {updating ? <Loader size={24} /> : "Update"}
        </Button>
      </Box>
    </Box>
  );
};

export default UploadAvatar;
