import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Button, Modal, Popover, ToggleButtonGroup, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { NotificationsToggleButton } from "./utils";
import { NotificationsList } from "./NotificationsList";
import { RootState } from "../../../redux/reducers";
import { deleteAllNotifications, deleteAllReminders } from "../../../api/notifications";
import {
  removeAllNotifications,
  removeAllReminders,
  stopTutorial,
} from "../../../redux/actionCreators";
import { NotificationsMenu, NotificationsPopoverProps } from "./types";
import { colors } from "../../../theme";
import { ConfirmationModal } from "../../ConfirmationModal";
import { RemindersList } from "./RemindersList";
import { getType } from "../../../api/localStorage/type";
import { AccountTypeEnum } from "../../../constants";
import { TutorialClasses } from "../../Tutorial/tutorialClasses";
import { useMutationObserver } from "../../../hooks/useMutationObserver";

const NOTIFICATION_POPOVER_WIDTH = 316;

export const NotificationsPopover = ({ isOpen, anchor, onClose, requestTime }: NotificationsPopoverProps) => {
  const [menuActive, setMenuActive] = useState(NotificationsMenu.notifications);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { notifications, reminders } = useSelector((state: RootState) => state.notification);
  const { tourActive } = useSelector((state: RootState) => state.tutorial);
  const dispatch = useDispatch();

  const mutationRef = useRef<HTMLDivElement>(null);

  const accountType = getType();
  const IS_TOUR_CONTINUE = tourActive && accountType === AccountTypeEnum.Writer && requestTime;

  const handleChange = (e: any) => {
    setMenuActive(NotificationsMenu[e.target.value as keyof typeof NotificationsMenu]);
  };

  const handleOpenModal = () => setIsOpenModal(true);
  const handleCloseModal = () => setIsOpenModal(false);

  const clearAllNotifications = async () => {
    setIsLoading(true);
    if (menuActive === NotificationsMenu.notifications) {
      const deleted = await deleteAllNotifications();
      if (!deleted.error) {
        dispatch(removeAllNotifications());
      }
    } else {
      const deleted = await deleteAllReminders();
      if (!deleted.error) {
        dispatch(removeAllReminders());
      }
    }
    setIsLoading(false);
    handleCloseModal();
  };

  useMutationObserver(mutationRef, () => {
    dispatch(stopTutorial());
  });

  useEffect(() => {
    if (IS_TOUR_CONTINUE) {
      mutationRef.current?.classList.add(TutorialClasses.fourthTutorialStep);
    }
  }, [requestTime]);

  return (
    <>
      <Modal open={isOpenModal} onClose={handleCloseModal}>
        <Box width="100%" height="100%" display="flex" alignItems="center" justifyContent="center">
          <ConfirmationModal
            onSubmit={clearAllNotifications}
            onCancel={handleCloseModal}
            title={`Do you really want to clear all 
              ${menuActive === NotificationsMenu.notifications ? "notifications" : "reminders"}?`}
            actionName="Clear"
            isLoading={isLoading}
          />
        </Box>
      </Modal>
      <Popover
        open={isOpen}
        anchorEl={anchor}
        onClose={onClose}
        transformOrigin={{
          vertical: -50,
          horizontal: "right",
        }}
      >
        <Box
          ref={mutationRef}
          paddingX={2}
          pt={2}
          width={NOTIFICATION_POPOVER_WIDTH}
          maxHeight="650px"
          sx={{
            "overflowY": "auto",
            "overflowX": "hidden",
            "&::-webkit-scrollbar": {
              width: 5,
            },
            "&::-webkit-scrollbar-track": {
              backgroundColor: colors.gray[200],
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: colors.secondary,
              borderRadius: 1,
            },
          }}
        >
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <ToggleButtonGroup
              style={{
                height: 40,
                marginBottom: -9,
                zIndex: 1,
              }}
              color="secondary"
              value={menuActive}
              exclusive
              onChange={handleChange}
            >
              <NotificationsToggleButton value={NotificationsMenu.notifications}>
                <Typography sx={{ pointerEvents: "none" }} variant="smallText">
                  NOTIFICATIONS
                </Typography>
              </NotificationsToggleButton>
              <NotificationsToggleButton value={NotificationsMenu.reminders}>
                <Typography sx={{ pointerEvents: "none" }} variant="smallText">
                  REMINDERS
                </Typography>
              </NotificationsToggleButton>
            </ToggleButtonGroup>
            <CloseIcon onClick={onClose} sx={{ cursor: "pointer", width: 14, height: 14 }} />
          </Box>
          <Box>
            {menuActive === NotificationsMenu.notifications && (
              <NotificationsList listElements={notifications} onClose={onClose} />
            )}
          </Box>
          <Box>
            {menuActive === NotificationsMenu.reminders && (
              <RemindersList listElements={reminders} onClose={onClose} />
            )}
          </Box>
          <Box
            sx={{ backgroundColor: "white" }}
            pb={2}
            width="100%"
            position="sticky"
            bottom={-0.5}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            gap={2}
          >
            <Button
              disabled={notifications.length === 0 && reminders.length === 0}
              fullWidth
              color="secondary"
              variant="outlined"
              onClick={handleOpenModal}
            >
              <Typography variant="text">Clear all</Typography>
            </Button>
          </Box>
        </Box>
      </Popover>
    </>
  );
};
