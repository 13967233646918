import { updateLetterRequest } from "../../api/letterRequest";
import { RequestStatus } from "../../views/LetterStatus/types";

export const onSaveToDraft = async (letterId: string, text: string, status: RequestStatus) => {
  if (!text || !letterId) {
    return;
  }

  try {
    const body = status === RequestStatus.rd ? { text } : { text, status: RequestStatus.rd };
    return await updateLetterRequest(letterId, body);
  } catch (err) {
    return { error: err };
  }
};

export const onFinish = async (letterId: string, text: string) => {
  if (!text || !letterId) {
    return;
  }

  try {
    return await updateLetterRequest(letterId, {
      text,
      status: RequestStatus.f,
    });
  } catch (err) {
    return { error: err };
  }
};
