import React from "react";
import { Box, Paper, Table, TableContainer } from "@mui/material";
import { CustomTableBody } from "./Body";
import { CustomTableHead } from "./Head";
import { CustomTableProps } from "./types";

const CustomTable: React.FC<CustomTableProps> = ({ columns, data, OnEmptyComponent, requestTime }) => {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650, borderCollapse: "separate" }} aria-label="simple table">
        <CustomTableHead columns={columns} />
        {data && data?.length > 0 && <CustomTableBody columns={columns} data={data} requestTime={requestTime} />}
      </Table>
      {!data?.length && (
        <Box width="100%" display="flex" justifyContent="center" marginTop={3}>
          {OnEmptyComponent}
        </Box>
      )}
    </TableContainer>
  );
};

export default CustomTable;
