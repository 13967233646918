import { getRequest, postRequest } from ".";
import { EnthusiasmLevel } from "../components/LetterCompositionConstructor/types";

import urls from "./urls";

const { section } = urls();

export const getEnthusiasmLevels = async (letterTypeId: number) => {
  try {
    return await getRequest(section.enthusiasm(letterTypeId));
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const getSectionText = async (
  index: number,
  enthusiasm: EnthusiasmLevel,
  letterId: string,
) => {
  if (!enthusiasm || !letterId) {
    return;
  }

  try {
    return await getRequest(section.generateText(letterId, index, enthusiasm?.code));
  } catch (err) {
    return { error: err };
  }
};

export const completeSection = async (letterId: string, body: any) => {
  if (!body || !letterId) {
    return;
  }

  try {
    return await postRequest(section.accept(letterId), body);
  } catch (err) {
    return { error: err };
  }
};

export const updateSectionText = async (letterId: string, body: any) => {
  if (!body || !letterId) {
    return;
  }

  return await completeSection(letterId, body);
};
