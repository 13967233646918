import React from "react";
import { List, ListItem, ListItemButton, ListItemText, Popover, SvgIcon } from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import FeedbackOutlinedIcon from '@mui/icons-material/FeedbackOutlined';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { HeaderMenuPopoverProps, MenuEl } from "./types";

export const HeaderMenuPopover: React.FC<HeaderMenuPopoverProps> = ({
  open,
  anchor,
  onClose,
  handleMyAccount,
  handleStartTutorial,
  handleFeedback,
  handleSignOut,
}) => {

  const menuElements: MenuEl[] = [
    { handler: handleMyAccount, text: "Profile", icon: PersonIcon },
    { handler: handleFeedback, text: "Feedback", icon: FeedbackOutlinedIcon },
    { handler: handleStartTutorial, text: "Tutorial", icon: HelpOutlineIcon },
    { handler: handleSignOut, text: "Log out", icon: ExitToAppIcon },
  ];

  return (
    <Popover
      open={open}
      anchorEl={anchor}
      onClose={onClose}
      anchorOrigin={{
        vertical: 50,
        horizontal: -120,
      }}
    >
      <List sx={{ display: "flex", width: "160px", flexDirection: "column", justifyContent: "start" }}>
        {menuElements.map((el: MenuEl) => {
          return (
            <ListItem key={el.text} disablePadding>
              <ListItemButton onClick={el.handler} sx={{ gap: 0.5 }}>
                <SvgIcon component={el.icon} />
                <ListItemText primary={el.text} />
              </ListItemButton>
            </ListItem>
          );
        })}
      </List>
    </Popover>
  );
};