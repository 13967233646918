import React, { useCallback, useEffect, useRef, useState } from "react";
import { Box, Button, Modal, Paper, Typography, useMediaQuery } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import PageTemplate from "../../components/PageTemplate";
import { LetterRequestCard } from "../../components/LetterRequestCard";
import { EmptyDashboard } from "../../components/EmptyDashboard";
import { LetterDetails } from "../../components/LetterDetails";
import { RootState } from "../../redux/reducers";
import { getType } from "../../api/localStorage/type";
import { AccountTypeEnum, LETTER_REQUEST_CARD } from "../../constants";
import { LetterRequest, SeparateRecommendationRequest } from "../LetterStatus/types";
import { deleteSampleRequest, getAllLetterRequests } from "../../api/letterRequest";
import { useWindowSize } from "../../hooks/useWindowSize";
import { importAllImages, stylePropToNumber } from "./utils";
import { ReactComponent as LoadMore } from "./../../assets/images/loadMore.svg";
import { createRequestsFromLetters } from "../LetterStatus/utils";
import Loader from "../../components/Loader";
import {
  continueTutorial,
  removeSelectedRequestId,
  selectiveRequests,
  setEditRequest, stopTutorial,
} from "../../redux/actionCreators";
import { getQueryParamFromURL } from "../../utils/getQueryParamFromURL";
import { BackgroundImage } from "../../assets/images/background";
import { ConfirmationModal } from "../../components/ConfirmationModal";
import { ROUTES } from "../../constants/routes";
import { openInNewTab } from "../../utils/openInNewTab";
import { FromMobile } from "../../components/FromMobileModal";
import { isMobileDevice } from "../../utils/mobileDevice";
import { LetterType } from "../../utils/globalLetterTypesCredits";
import { TutorialClasses } from "../../components/Tutorial/tutorialClasses";
import { useMutationObserver } from "../../hooks/useMutationObserver";

const GAP = 20;
const LETTER_ID_QUERY_PARAM = "letterId";

const state = {
  mounted: false,
};

const images = importAllImages(
  require.context("../../assets/images/background", false, /\.(png|jpe?g|svg)$/),
);

export const Dashboard = () => {
  const [buttonLoading, setButtonLoading] = useState(false);
  const [mainLoading, setMainLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedLetterId, setSelectedLetterId] = useState("");
  const [perSection, setPerSection] = useState(0);
  const [separateData, setSeparateData] = useState<SeparateRecommendationRequest | null>(null);
  const [page, setPage] = useState(1);
  const [bg, setBg] = useState("");
  const [isOpenStudentAccessModal, setIsOpenStudentAccessModal] = useState(false);
  const [showFromMobile, setShowFromMobile] = useState(false);
  const [requestTime, setRequestTime] = useState(0);

  const [width, height] = useWindowSize();

  const boxRef = useRef<any>(null);
  const isDesktop = useMediaQuery("(min-width:900px)");
  const dispatch = useDispatch();

  const mutationRef = useRef<HTMLButtonElement>(null);

  const navigate = useNavigate();
  const accountType = getType();

  const { requests, selectedRequestId } = useSelector((state: RootState) => state.requests);
  const { user } = useSelector((state: RootState) => state.user);
  const { tourActive } = useSelector((state: RootState) => state.tutorial!);

  const IS_MIN_PRICE = user?.credit_count < LetterType.recommendation;
  const IS_RELOAD_REQUESTS = accountType === AccountTypeEnum.Writer && tourActive;

  const mode = IS_MIN_PRICE ? "default" : "constructor";

  const handleOpenStudentAccessModal = () => setIsOpenStudentAccessModal(true);
  const handleCloseStudentAccessModal = () => setIsOpenStudentAccessModal(false);

  const handleNavigateLetterRequest =
    () => navigate(accountType === AccountTypeEnum.Applicant ? ROUTES.NEW_LETTER : `${ROUTES.WRITER_NEW_LETTER}?selfRequest=true`);

  const handleNavigateLetterStatus = () => navigate(ROUTES.LETTER_STATUS);

  const getRequests = async () => {
    const requestStartAt = performance.now();
    if (state.mounted) {
      setButtonLoading(true);
      const _requests = await getAllLetterRequests({
        page,
        page_size: perSection,
        sort_by: "target_due_date",
      });
      if (!_requests.error && state.mounted) {
        dispatch(selectiveRequests(page, createRequestsFromLetters(_requests?.results)));
        setSeparateData(_requests);
      }
      state.mounted && setButtonLoading(false);
      state.mounted && setMainLoading(false);
    }
    const requestEndAt = performance.now();
    const timeSpent = requestEndAt - requestStartAt;
    setRequestTime(timeSpent);
  };

  const onViewDetails = (id: string, isSelfRequest?: boolean) => {
    navigate(`/${accountType}/dashboard/?letterId=${id}&isSelfRequest=${isSelfRequest}`);
    const lId = getQueryParamFromURL(LETTER_ID_QUERY_PARAM);
    if (lId) {
      setIsOpen(true);
    }
    setSelectedLetterId(id);
  };

  const handleClose = () => {
    navigate(`/${accountType}/dashboard/`);
    setSelectedLetterId("");
    dispatch(setEditRequest(false));
    const lId = getQueryParamFromURL(LETTER_ID_QUERY_PARAM);
    if (!lId) {
      setIsOpen(false);
    }
    if (selectedLetterId) {
      dispatch(removeSelectedRequestId());
    }
  };

  const handleNavigateDefault = (letterId: string, mode: string) => {
    navigate(`${ROUTES.WRITE_LETTER}?letterId=${letterId}&mode=${mode}`);
  };

  const handleNavigateLetterComposition = (letterId: string, mode: string) => {
    if (user?.credit_count < LetterType.recommendation) {
      openInNewTab(ROUTES.SUBSCRIPTION);
    } else {
      navigate(`${ROUTES.WRITE_LETTER}?letterId=${letterId}&mode=${mode}`);
    }
  };

  const handleOpenNewSubscriptionTab = () => {
    openInNewTab(ROUTES.SUBSCRIPTION);
    handleCloseStudentAccessModal();
  };

  const onDeleteLetterRequest = async () => {
    await getRequests();
  };

  const handleSetPage = () => {
    setPage((prevState) => prevState + 1);
  };

  const checkOpenFromMobile = async () => {
    const mobile = isMobileDevice();
    if (mobile) {
      const isMobile = await localStorage.getItem("isMobile");
      if (isMobile === null) {
        await localStorage.setItem("isMobile", "true");
        setShowFromMobile(true);
      }
    }
  };

  useEffect(() => {
    state.mounted = true;
    setMainLoading(true);

    const randIndex =
      images.length ? Math.floor(Math.random() * (images.length - 1 + 1)) : null;
    const _bg = randIndex ? `${images[randIndex]}` : BackgroundImage;

    setBg(_bg);

    return () => {
      state.mounted = false;
    };
  }, []);

  useEffect(() => {
    if (perSection && page) {
      getRequests();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [perSection, page]);

  useEffect(() => {
    if (!tourActive) {
      (async () => {
        await deleteSampleRequest();
      })();
    }
    if (IS_RELOAD_REQUESTS) {
      getRequests();
    }
  }, [tourActive]);

  useEffect(() => {
    if (width && height) {
      const paddingLeft = window
        .getComputedStyle(boxRef.current, null)
        .getPropertyValue("padding-left");
      const paddingRight = window
        .getComputedStyle(boxRef.current, null)
        .getPropertyValue("padding-right");
      const pL = stylePropToNumber(paddingLeft);
      const pR = stylePropToNumber(paddingRight);
      const byWidth = Math.floor((width - (pL + pR)) / (LETTER_REQUEST_CARD.width + GAP));
      const byHeight = Math.floor(height / (LETTER_REQUEST_CARD.height + GAP));
      const perSection = byHeight * byWidth;
      setPerSection(perSection);
    }
  }, [height, width]);

  useEffect(() => {
    if (selectedRequestId) {
      onViewDetails(selectedRequestId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRequestId]);

  useEffect(() => {
    const lId = getQueryParamFromURL(LETTER_ID_QUERY_PARAM);
    if (lId) {
      onViewDetails(lId);
    }
    checkOpenFromMobile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useMutationObserver(mutationRef, () => {
    dispatch(stopTutorial());
  });

  useEffect(() => {
    if (tourActive && requestTime && accountType === AccountTypeEnum.Applicant) {
      mutationRef.current?.classList.add(TutorialClasses.firstTutorialStep);
    }
  }, [requestTime, tourActive]);

  return (
    <>
      <Modal open={isOpenStudentAccessModal} onClose={handleCloseStudentAccessModal}>
        <Box width="100%" height="100%" display="flex" alignItems="center" justifyContent="center">
          <ConfirmationModal
            onSubmit={handleOpenNewSubscriptionTab}
            onCancel={handleCloseStudentAccessModal}
            title="You don't have enough LUCI, do you want to open the subscription page to buy?"
            actionName="Buy"
          />
        </Box>
      </Modal>
      <Modal open={isOpen} onClose={() => setIsOpen(false)}>
        <Box width="100%" height="100%" display="flex" alignItems="center" justifyContent="center">
          <LetterDetails
            id={selectedLetterId}
            onClose={handleClose}
            onDelete={onDeleteLetterRequest}
            handleNavigateLetterComposition={() =>
              handleNavigateLetterComposition(selectedLetterId, mode)
            }
            handleNavigateDefault={() => handleNavigateDefault(selectedLetterId, mode)}
          />
        </Box>
      </Modal>
      <FromMobile isOpen={showFromMobile} onClose={() => setShowFromMobile(false)} />
      <PageTemplate type={getType()}>
        <Box width="100%" height="100%" position="relative" overflow="auto">
          <Paper
            ref={boxRef}
            style={{
              position: "absolute",
              width: "100%",
              height: "100%",
              backgroundImage: `url(${bg})`,
              backgroundPosition: "center",
              backgroundAttachment: "fixed",
              backgroundSize: "cover",
              opacity: mainLoading ? 0.3 : 1,
              zIndex: 1,
            }}
          >
            <Box
              className={accountType === AccountTypeEnum.Writer ? TutorialClasses.firstTutorialStep : ""}
              mt={14}
              mx={4}
              height={LETTER_REQUEST_CARD.height + (GAP / 2)}
            />
          </Paper>
          <Paper
            ref={boxRef}
            style={{
              zIndex: 1,
              background: "transparent",
              width: "auto",
              height: "auto",
              paddingTop: 40,
              paddingBottom: 20,
              paddingLeft: isDesktop ? 40 : 20,
              paddingRight: isDesktop ? 40 : 20,
              boxSizing: "border-box",
              position: "relative",
              minHeight: "100%",
              maxHeight: "100%",
              overflow: "auto",
            }}
          >
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              gap={2}
              mb={5}
            >
              <Button
                ref={mutationRef}
                disabled={mainLoading && tourActive}
                variant="contained"
                color="secondary"
                size="large"
                onClick={handleNavigateLetterRequest}
              >
                <Typography variant="text">{accountType === AccountTypeEnum.Applicant ? "Request new letter" : "Write Letter"}</Typography>
              </Button>
              <Button
                variant="contained"
                disabled={mainLoading && tourActive}
                color="secondary"
                size="large"
                onClick={handleNavigateLetterStatus}
              >
                <Typography variant="text">View as table</Typography>
              </Button>
            </Box>
            {mainLoading ? (
              <Box display="flex" py={20} justifyContent="center" alignItems="center">
                <Loader size={100} />
              </Box>
            ) : (
              <>
                <Box
                  width="auto"
                  flexWrap="wrap"
                  height="auto"
                  gap={`${GAP}px`}
                  display="flex"
                  justifyContent={isDesktop ? "flex-start" : "center"}
                  alignContent="flex-start"
                  flexDirection="row"
                >
                  {requests?.map((p: LetterRequest) => (
                    <LetterRequestCard
                      key={p.id}
                      name={p.request_display_name}
                      avatar={p.avatar}
                      university={p.target_university}
                      onPlatform={p.writer_on_platform}
                      dueDate={p.letter_due_date}
                      requestStatus={p.status}
                      onViewDetails={() => onViewDetails(p.letter_id, p.is_self_request)}
                      letterId={p.letter_id}
                      accessStudent={p.student_access}
                      creditUsed={p.credit_used}
                      questions={p.questions}
                      archived={p.archived}
                      letterTypeId={p.letterTypeId}
                      isSelfRequest={p.is_self_request}
                      handleOpenStudentAccessModal={handleOpenStudentAccessModal}
                      handleNavigateLetterComposition={() =>
                        handleNavigateLetterComposition(p.letter_id, mode)
                      }
                      handleNavigateDefault={() => handleNavigateDefault(p.letter_id, mode)}
                      requestTime={requestTime}
                    />
                  ))}
                </Box>
                {!requests?.length && <EmptyDashboard type={getType()} />}
              </>
            )}
            <Box display="flex" alignItems="flex-end" mt={5} justifyContent="center">
              {!mainLoading && separateData?.next && (
                <Button
                  startIcon={buttonLoading ? <Loader size={24} /> : <LoadMore />}
                  variant="contained"
                  color="secondary"
                  onClick={handleSetPage}
                >
                  Load more
                </Button>
              )}
            </Box>
          </Paper>
        </Box>
      </PageTemplate>
    </>
  );
};
