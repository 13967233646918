import React, { useEffect, useState } from "react";
import { Box, Button, Chip, Modal, Typography, useMediaQuery } from "@mui/material";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import DoneIcon from '@mui/icons-material/Done';
import PageTemplate from "../../components/PageTemplate";
import { LetterCompositionDefault } from "../../components/LetterCompositionDefault";
import { LetterCompositionConstructor } from "../../components/LetterCompositionConstructor";
import { Recipient } from "./Recipient";
import Loader from "../../components/Loader";
import { LetterDetails } from "../../components/LetterDetails";
import { getType } from "../../api/localStorage/type";
import { getLetterRequest } from "../../api/letterRequest";
import { RootState } from "../../redux/reducers";
import { setStudentAccess, startUsingLuciAssistantRecommendationLetter } from "./utils";
import { RequestStatus } from "../LetterStatus/types";
import { AccountTypeEnum } from "../../constants";
import { colors } from "../../theme";
import { getQueryParamFromURL } from "../../utils/getQueryParamFromURL";
import { checkIsValidForWriteLetter } from "../../utils/checkIsValid";
import { ROUTES } from "../../constants/routes";
import { LetterType } from "../../utils/globalLetterTypesCredits";
import { TutorialClasses } from "../../components/Tutorial/tutorialClasses";
import { FREE_SAMPLE } from "../../components/Tutorial/constants";

export const LetterComposition = () => {
  const [isLetterDetailsOpen, setIsLetterDetailsOpen] = useState(false);
  const [letterId, setLetterId] = useState<any>();
  const [paragraphs, setParagraphs] = useState<Array<string>>([]);
  const [enthusiasmLevel, setEnthusiasmLevel] = useState(null);
  const [isEnableConstructor, setIsEnableConstructor] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isFinished, setIsFinished] = useState(false);
  const [request, setRequest] = useState<Record<string, any> | null>(null);
  const [denyAccess, setDenyAccess] = useState(false);
  const [letterCompositionMode, setLetterCompositionMode] = useState("");
  const [maxSections, setMaxSections] = useState(0);

  const accountType = getType();
  const navigate = useNavigate();
  const { user } = useSelector((state: RootState) => state.user);
  const isDesktop = useMediaQuery("(min-width:900px)");

  const handleLetterDetailsOpen = () => setIsLetterDetailsOpen(true);
  const handleLetterDetailsClose = () => setIsLetterDetailsOpen(false);

  const handleChangePageMode = async () => {
    setIsLoading(true);
    if (user?.credit_count || request?.credit_used || request?.student_access) {
      if (checkIsValidForWriteLetter(request?.status) && request?.credit_used !== FREE_SAMPLE) {
          const res = await startUsingLuciAssistantRecommendationLetter(letterId, user, accountType as AccountTypeEnum);
        if (!res?.error) {
          setParagraphs([]);
        }
      }
      setIsEnableConstructor(true);
      setIsLoading(false);
    }
  };

  const getInitialData = async () => {
    setIsLoading(true);
    const lId = getQueryParamFromURL("letterId");
    const mode = getQueryParamFromURL("mode");
    const letterRequest = lId && (await getLetterRequest(lId));

    if (mode) {
      setLetterCompositionMode(mode);
    }

    if (
      (accountType === AccountTypeEnum.Applicant && !letterRequest?.student_access) ||
      letterRequest.error
    ) {
      setDenyAccess(true);
      return;
    }

    const letterTypeId = letterRequest.letter_type_id;
    const num_sections = letterRequest.num_sections;

    setMaxSections(num_sections);

    const _paragraphs = letterRequest.paragraphs;
    const enthusiasm = letterRequest?.enthusiasm;

    const empty = _paragraphs?.length === 1 && !_paragraphs[0]?.length;

    setRequest(letterRequest);

    if (_paragraphs?.length && !empty) {
      setParagraphs(_paragraphs);
    }

    if (enthusiasm) {
      setEnthusiasmLevel(enthusiasm);
    }

    setLetterId(lId);
    setIsFinished(letterRequest.status === RequestStatus.f);
    setIsLoading(false);
  };

  const onSaveFinished = (t: string) => {
    setParagraphs([t]);
  };

  const onFinish = async () => {
    await getInitialData();
  };

  const handleNavigateToDashboard = () => navigate(ROUTES.HOME);

  const enableAccess = async () => {
    setIsLoading(true);
    const res = await setStudentAccess(letterId);
    await getInitialData();
    setIsLoading(false);
    if(!res.error) {
      toast.success("Student access was successfully granted");
    } else {
      toast.error("An error occurred during enable student access process");
    }
  };

  useEffect(() => {
    if (letterCompositionMode === "constructor" && letterId && request) {
      handleChangePageMode();
    } else {
      setIsEnableConstructor(false);
    }
  }, [letterCompositionMode, letterId, request]);

  useEffect(() => {
    getInitialData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  if (denyAccess) {
    return (
      <PageTemplate type={getType()}>
        <Box
          width="100%"
          height="100%"
          display="flex"
          paddingX={8}
          paddingY={5}
          marginBottom={10}
          alignItems="center"
          justifyContent="center"
          boxSizing="border-box"
          flexDirection="column"
        >
          <Typography variant="largeText" mb={2}>
            {" "}
            You have no access to write this letter{" "}
          </Typography>
          <Button variant="contained" color="secondary" onClick={handleNavigateToDashboard}>
            Back to dashboard
          </Button>
        </Box>
      </PageTemplate>
    );
  }

  return (
    <>
      <Modal open={isLetterDetailsOpen} onClose={handleLetterDetailsClose}>
        <Box width="100%" height="100%" display="flex" alignItems="center" justifyContent="center">
          <LetterDetails id={letterId} onClose={handleLetterDetailsClose} isShowOptionsPanel={false} />
        </Box>
      </Modal>
      <PageTemplate type={getType()}>
        <Box
          width="100%"
          height="100%"
          display="flex"
          paddingX={isDesktop ? 8 : 1}
          paddingY={5}
          marginBottom={10}
          boxSizing="border-box"
          flexDirection="column"
        >
          <Box
            width="100%"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            height="8%"
            paddingBottom={6}
            borderBottom={!isFinished && !isLoading ? `1px solid ${colors.gray[500]}` : ""}
          >
            <Recipient letterId={letterId} title={isFinished ? "Letter for:" : "Draft letter for:"} />
            {!isLoading && !isFinished && !request?.student_access &&
              user?.credit_count >= LetterType.recommendation && (
              <Button
                className={TutorialClasses.tenthTutorialStep}
                variant="outlined"
                color="secondary"
                sx={{ maxHeight: "100px" }}
                onClick={enableAccess}
              >
                <Typography variant="text">Enable applicant access</Typography>
              </Button>
            )}
            {request?.student_access &&
              <Chip
                color="secondary"
                icon={<DoneIcon />}
                label="Applicant access was granted"
                sx={{ p: 1 }}
              />}
          </Box>
          {isLoading && (
            <Box
              width="100%"
              height="100%"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Loader size={24} />
            </Box>
          )}
          {!isEnableConstructor && !isLoading && letterId && (
            <LetterCompositionDefault
              text={paragraphs[0] || ""}
              letterId={letterId}
              finished={isFinished}
              onSave={onSaveFinished}
              handleChangePageMode={handleChangePageMode}
              handleLetterDetailsOpen={handleLetterDetailsOpen}
              onFinishLetter={onFinish}
              status={request?.status as RequestStatus}
            />
          )}
          {isEnableConstructor && !isLoading && letterId && (
            <LetterCompositionConstructor
              letterId={letterId}
              paragraphs={paragraphs}
              enthusiasmLevel={enthusiasmLevel}
              letterTypeId={request?.letter_type_id}
              maxSections={maxSections}
            />
          )}
        </Box>
      </PageTemplate>
    </>
  );
};
