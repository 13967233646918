import { useEffect, useState } from "react";
import { ToggleButton, ToggleButtonGroup, useMediaQuery } from "@mui/material";
import { Box } from "@mui/system";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Notifications } from "./Notifications/Notifications";
import { removeToken } from "../../api/localStorage/token";
import { removeType } from "../../api/localStorage/type";
import { AccountTypeEnum, HEADER_HEIGHT } from "../../constants";
import { ROUTES } from "../../constants/routes";
import MobileMenu from "../MobileMenu";
import { professorMobileMenuItems, studentMobileMenuItems } from "./constants";
import { LuciCreditsBalance } from "./LuciCredit/LuciCreditBalance";
import { AccountMenu } from "./AccountMenu";
import { RootState } from "../../redux/reducers";
import { reset, startTutorial } from "../../redux/actionCreators";
import { HeaderMenuProps } from "./types";
import { ProfileMenu } from "../../utils/profileMenu";
import { createSampleRequest } from "../../api/letterRequest";

const HeaderMenu = ({ toggles, accountType, getUserData, isShowAvatarLoader }: HeaderMenuProps) => {
  const [menuActive, setMenuActive] = useState("");
  const isDesktop = useMediaQuery("(min-width:900px)");
  const { user } = useSelector((state: RootState) => state.user);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleStartTutorial = async () => {
    const routes = window.location.pathname.split("/");
    const isNotDashboard = routes[routes.length - 1] !== 'dashboard';
    const isTimeoutEnable = accountType === AccountTypeEnum.Student && isNotDashboard;

    if(isNotDashboard) {
      navigate(ROUTES.HOME);
    }
    setTimeout(async () => {
      dispatch(startTutorial());
      try {
        await createSampleRequest();
      } catch (error: unknown) {
        console.error(error);
      }
    }, isTimeoutEnable ? 500 : 0);
  };

  const handleChange = (e: any) => {
    navigate(e.target.value);
  };

  const handleMyAccount = () => {
    navigate(`${ROUTES.MY_ACCOUNT}/?tab=${ProfileMenu.profile}`);
  };

  const handleNavigateCoverLettersList = () => navigate(ROUTES.COVER_LETTER_STATUS);

  const handleFeedback = () => window.open(ROUTES.FEEDBACK);

  const handleSignOut = () => {
    dispatch(reset());
    removeToken();
    removeType();
    navigate(ROUTES.SIGN_IN);
  };

  useEffect(() => {
    // eslint-disable-next-line no-restricted-globals
    setMenuActive(location?.pathname);
  }, []);

  useEffect(() => {
    getUserData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.credit_count]);

  return (
    <Box
      display="flex"
      flexDirection="row"
      alignItems="center"
      justifyContent="space-between"
      flex={1}
    >
      {isDesktop ? (
        <>
          <Box marginLeft={5}>
            <ToggleButtonGroup
              style={{ height: HEADER_HEIGHT }}
              color="secondary"
              value={menuActive}
              exclusive
              onChange={handleChange}
            >
              {toggles?.map((t) => (
                <ToggleButton
                  key={t.route}
                  value={t.route}
                >
                  {t.name}
                </ToggleButton>
              ))}
            </ToggleButtonGroup>
          </Box>
          <Box display="flex" flexDirection="row" alignItems="center">
            <LuciCreditsBalance count={user?.credit_count || 0} />
            <Notifications />
            <AccountMenu
              handleMyAccount={handleMyAccount}
              handleFeedback={handleFeedback}
              handleStartTutorial={handleStartTutorial}
              handleSignOut={handleSignOut}
              isShowAvatarLoader={isShowAvatarLoader}
            />
          </Box>
        </>
      ) : (
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="flex-end"
          flex={1}
        >
          <LuciCreditsBalance count={user?.credit_count || 0} />
          <Notifications />
          <MobileMenu
            items={
              accountType && accountType === AccountTypeEnum.Applicant
                ? studentMobileMenuItems(
                  handleSignOut,
                  handleFeedback,
                  handleMyAccount,
                  handleNavigateCoverLettersList,
                ) : professorMobileMenuItems(
                  handleSignOut,
                  handleFeedback,
                  handleMyAccount,
                  handleNavigateCoverLettersList,
                )
            }
          />
        </Box>
      )}
    </Box>
  );
};

export default HeaderMenu;
