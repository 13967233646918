import { Paper } from '@mui/material';
import React from 'react';

type ProgressBarProps = {
  steps: number;
  current: number;
  height?: number;
  startColor?: string;
  endColor?: string;
};

const ProgressBar = ({
  height = 20,
  steps,
  current,
  startColor = '#ccc',
  endColor = '#fff',
}: ProgressBarProps) => {
  const containerStyle = {
    borderRadius: 0,
    backgroundImage: `linear-gradient(to right, ${endColor}, ${startColor})`,
    position: 'relative',
    boxShadow: 'none',
    borderBottom: `1px solid #e0e0e0`,
    height: height,
    width: `100%`,
  };

  const childStyle = {
    position: 'absolute',
    height: height,
    transition: 'width .5s',
    right: 0,
    top: 0,
    width: `${100 - (100 / steps) * current}%`,
    borderRadius: 0,
    opacity: '90%',
    background: 'white',
    boxShadow: 'none',
    border: 'none',
  };
  return (
    <Paper style={{...containerStyle as React.CSSProperties}}>
      <Paper style={{...childStyle as React.CSSProperties}}></Paper>
    </Paper>
  );
};

export default ProgressBar;
