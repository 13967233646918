import React, { useCallback, useEffect, useState } from "react";
import { Typography, useMediaQuery } from "@mui/material";
import { Box } from "@mui/system";
import QuestionField from "../QuestionFIeld";
import { NewLetterControls } from "../../views/NewLetter/Controls";
import { validateQuestion } from "./utils";
import Loader from "../Loader";
import { colors } from "../../theme";
import { Question, QuestionnaireProps } from "./types";

const Questionnaire = ({
  questions,
  onNext,
  onCancel,
  current,
  steps,
  title,
  onChangeQuestion,
  editable = true,
  loading = false,
  isValidProp,
  handleSetIsValidProp,
}: QuestionnaireProps) => {
  const isDesktop = useMediaQuery("(min-width:900px)");
  const [isValid, setIsValid] = useState(false);
  const [errors, setErrors] = useState<Record<string, any>>({});

  const validateQuestionForm = useCallback(() => {
    const _errors: Record<string, any> = {};

    questions?.forEach((q: Question) => {
      _errors[q.id] = validateQuestion(q);
    });

    setErrors(_errors);

    const invalid = Object.values(_errors).filter((f) => typeof f === "string");

    if ((isValidProp ? isValidProp : isValid) !== !Boolean(invalid?.length)) {
      handleSetIsValidProp ? handleSetIsValidProp(!Boolean(invalid?.length)) : setIsValid(!Boolean(invalid?.length));
    }
  }, [questions, isValid]);

  const handleChangeQuestion = (qId: string, answer: string) => {
    onChangeQuestion && onChangeQuestion(qId, answer);
  };

  const handleNext = () => {
    onNext && onNext({ questions });
  };

  useEffect(() => {
    validateQuestionForm();
  }, [questions, validateQuestionForm]);

  useEffect(() => {
    if (isValidProp && handleSetIsValidProp) {
      handleSetIsValidProp(isValid);
    }
  }, [isValid]);

  return (
    <Box width="100%" maxWidth={1000} display="flex" flexDirection="column" paddingX={3}>
      {steps && current && title && (
        <Box display="flex" flexDirection="row" justifyContent="space-between" marginBottom={3}>
          <Typography component="span" fontWeight={500}>
            Step {current} of {steps}
            <Typography component="span"> - {title}</Typography>
          </Typography>
          <Typography component="span">
            Required
            <Typography color={colors.error} component="span">
              *
            </Typography>
          </Typography>
        </Box>
      )}
      {questions.length > 0 ? (
        <Box display="grid" gridTemplateColumns={isDesktop ? "1fr 1fr" : "1fr"} columnGap={10}>
          {questions?.map((q: Question) => (
            <QuestionField
              onChange={handleChangeQuestion}
              key={q.id}
              question={q}
              editable={editable}
              error={errors[q.id]}
            />
          ))}
        </Box>
      ) : loading ? (
        <Box width="100%" py={5} display="flex" justifyContent="center" alignItems="center">
          <Loader />
        </Box>
      ) : (
        <Box>
          <Typography>
            There is a problem with getting questions data, please try again letter
          </Typography>
        </Box>
      )}
      <NewLetterControls
        disabledNext={isValidProp ? !isValidProp : !isValid || loading || !questions.length}
        loading={loading}
        onNext={onNext && handleNext}
        onCancel={onCancel && onCancel}
      />
    </Box>
  );
};

export default Questionnaire;
