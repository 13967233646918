import { useEffect } from "react";
import Joyride, { CallBackProps } from "react-joyride";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { TutorialTooltip } from "../TutorialTooltip";
import { ROUTES } from "../../../constants/routes";
import { TutorialClasses } from "../tutorialClasses";
import { RootState } from "../../../redux/reducers";
import {
  completelyStopTutorial,
  continueTutorial, setStepIndex,
  setSteps,
  stopTutorial,
} from "../../../redux/actionCreators";
import { deleteSampleRequest } from "../../../api/letterRequest";
import { FREE_SAMPLE } from "../constants";
import { LetterRequest } from "../../../views/LetterStatus/types";
import { LetterType } from "../../../utils/globalLetterTypesCredits";

export const WriterTutorial = () => {

  const { run, stepIndex, steps } = useSelector((state: RootState) => state.tutorial);
  const { requests } = useSelector((state: RootState) => state.requests);
  const { user } = useSelector((state: RootState) => state.user);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  useEffect(() => {
    dispatch(setSteps([
        {
          target: `.${TutorialClasses.firstTutorialStep}`,
          content: "Here on the dashboard you will see incoming letter requests",
          disableBeacon: true,
          placement: "bottom-end",
        },
        {
          target: `.${TutorialClasses.secondTutorialStep}`,
          content: "You can see how manu Luci credits you have here. Click to purchase more.",
          disableBeacon: true,
          placement: "bottom-end",
        },
        {
          target: `.${TutorialClasses.thirdTutorialStep}`,
          content: "A red dot on the bell icon indicates you have unread notifications or reminders.",
          disableBeacon: true,
          placement: "bottom-end",
        },
        {
          target: `.${TutorialClasses.fourthTutorialStep}`,
          content: "Click to see all of your notifications and reminders",
          disableBeacon: true,
          placement: "left",
        },
        {
          target: `.${TutorialClasses.fifthTutorialStep}`,
          content: "Click here to edit your personal info, notifications settings, subscriptions and more.",
          disableBeacon: true,
          placement: "bottom-end",
        },
        {
          target: `.${TutorialClasses.sixthTutorialStep}`,
          content: "Before you get started please include as much information about yourself as possible",
          disableBeacon: true,
          placement: "right",
        },
        {
          target: `.${TutorialClasses.seventhTutorialStep}`,
          content: "Click on View as table to view your requests in a sortable and searchable table.",
          disableBeacon: true,
          placement: "bottom-end",
        },
        {
          target: `.${TutorialClasses.eighthTutorialStep}`,
          content: "There are also some additional actions available in this table.",
          disableBeacon: true,
        },
        {
          target: `.${TutorialClasses.ninthTutorialStep}`,
          content: "Lets start writing a letter! Text for the first section will automatically appear.",
          disableBeacon: true,
          placement: "bottom-end",
        },
        {
          target: `.${TutorialClasses.tenthTutorialStep}`,
          content: "Click here if you would like to allow the applicant to help write the letter.",
          disableBeacon: true,
          placement: "bottom-end",
        },
        {
          target: `.${TutorialClasses.eleventhTutorialStep}`,
          content: "Use the slider to select enthusiasm levels for each section or generate a new proposal.",
          disableBeacon: true,
        },
      ],
    ));
  }, []);

  const handleCallback = async (data: CallBackProps) => {
    const { action, index, type } = data;

    if (action === "prev" || action === "stop") {
      dispatch(completelyStopTutorial());
      navigate(ROUTES.HOME);
    }


    if (type === "step:after") {
      switch (index) {
        case 0:
        case 1:
        case 2:
        case 6:
        case 9: {
          dispatch(stopTutorial());
          if (action === "next") {
            dispatch(continueTutorial());
          }
          break;
        }
        case 3: {
          if (action === "next") {
            dispatch(continueTutorial());
          }
          break;
        }
        case 4: {
          dispatch(stopTutorial());
          if (action === "next") {
            dispatch(continueTutorial());
            navigate(ROUTES.MY_ACCOUNT);
          }
          break;
        }
        case 5: {
          dispatch(stopTutorial());
          if (action === "next") {
            navigate(ROUTES.LETTER_STATUS);
          }
          break;
        }
        case 7: {
          dispatch(stopTutorial());
          const freeSample = requests.find((req: LetterRequest) => req.credit_used === FREE_SAMPLE);
          navigate(`${ROUTES.WRITE_LETTER}?letterId=${freeSample.letter_id}&mode=constructor`);
          break;
        }
        case 8: {
          dispatch(stopTutorial());
          if (action === "next" && user.credit_count >= LetterType.recommendation) {
            dispatch(continueTutorial());
          } else {
            dispatch(setStepIndex(index + 2));
          }
          break;
        }
        case 10: {
          dispatch(stopTutorial());
          if (action === "next") {
            dispatch(completelyStopTutorial());
            try {
              await deleteSampleRequest();
            } catch (error: unknown) {
              console.error(error);
            }
          }
          break;
        }
        default:
          dispatch(completelyStopTutorial());
      }
    }
  };

  return (
    <Joyride
      tooltipComponent={TutorialTooltip}
      callback={handleCallback}
      continuous
      run={run}
      stepIndex={stepIndex}
      steps={steps}
      disableCloseOnEsc
      disableOverlayClose
      disableScrolling
      styles={{
        options: {
          zIndex: 10000,
          arrowColor: "transparent",
        },
      }}
    />
  );
};