import React from "react";
import { UploadFileOutlined } from "@mui/icons-material";
import { Box, Link, Typography } from "@mui/material";
import { PreviewComponentProps } from "../FileUpload/DragAndDropFiles";
import { colors } from "../../theme";
import { IMAGE_MAX_SIZE, getFileSizeNumber } from "../../utils/fileSize";

export const PreviewComponent = ({ onSelectFile }: PreviewComponentProps) => {
  const onSelect = () => {
    onSelectFile();
  };
  return (
    <Box
      border={`2px dashed ${colors.gray[100]}`}
      display="flex"
      minWidth={380}
      minHeight={230}
      flex={1}
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
    >
      <UploadFileOutlined color="secondary" style={{ fontSize: 150 }} />
      <Typography fontWeight={500}>
        Drag and drop, or{" "}
        <Link underline="none" onClick={onSelect} color="secondary">
          browse
        </Link>{" "}
        your files
      </Typography>
      <Box display="flex" flexDirection="column" textAlign="center" color={colors.gray[400]}>
        <Typography variant="smallText">
          The file size should be lower than {getFileSizeNumber(IMAGE_MAX_SIZE)}
        </Typography>
        <Typography variant="smallText">
          The acceptable file format should be JPEG, PNG, GIF
        </Typography>
      </Box>
    </Box>
  );
};
