export enum NotificationsMenu {
  "notifications" = "notifications",
  "reminders" = "reminders",
}

export type NotificationsPopoverProps = {
  isOpen: boolean;
  anchor: HTMLButtonElement | null;
  onClose: () => void;
  requestTime: number;
};

export type LuciNotification = {
  id: string;
  avatar: string;
  text: string;
  university: string;
  time: string;
  name: string;
  isRead: boolean;
  letterId: string;
};
