import React from "react";
import { Link, Typography, useMediaQuery, useTheme } from "@mui/material";
import { Box } from "@mui/system";
import CloseIcon from "@mui/icons-material/Close";
import { colors } from "../../theme";

type CancelSignUpModalProps = {
  cancelNavigation: () => void;
  confirmNavigation: () => void;
  title?: string;
};

export const CancelSignUpModal = React.forwardRef(
  ({ cancelNavigation, confirmNavigation, title }: CancelSignUpModalProps, ref?: React.Ref<any>) => {
    const theme = useTheme();
    const smallScreen = useMediaQuery(theme.breakpoints.down("sm"));

    return (
      <Box
        ref={ref}
        width="100%"
        height="100%"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Box
          maxWidth={600}
          bgcolor="white"
          display="flex"
          flexDirection="column"
          paddingTop={2}
          paddingRight={2}
          paddingLeft={smallScreen ? 2 : 10}
          paddingBottom={5}
          position="relative"
        >
          <Typography pt={6} fontWeight={600} variant="title" mb={5} paddingRight={smallScreen ? 2 : 12}>
            {title}
          </Typography>
          <Box display="flex" flexDirection="column" mb={10} gap={1}>
            <Typography variant="mediumTitle">Are you sure you wish to cancel?</Typography>
            <Typography variant="mediumTitle">All data will be lost</Typography>
          </Box>
          <Box
            display="flex"
            position="absolute"
            top="75%"
            left={0}
            right={0}
            height="1px"
            bgcolor={colors.gray[200]}
          />
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="center"
            gap={4}
            pr={10}
          >
            <Link
              onClick={confirmNavigation}
              display="flex"
              flexDirection="row"
              color={colors.error}
              alignItems="center"
              underline="hover"
            >
              <CloseIcon sx={{ width: 30, height: 30 }} />
              <Typography>Cancel Registration</Typography>
            </Link>
            <Link
              onClick={cancelNavigation}
              underline="hover"
              color={colors.secondary}
              alignItems="center"
            >
              <Typography>Go Back</Typography>
            </Link>
          </Box>
        </Box>
      </Box>
    );
  },
);
