import React from 'react';
import { Box } from '@mui/material';
import { HintProps } from '../types';
import { TRIANGLE_WIDTH, DEFAULT_HINT_WIDTH, DEFAULT_HINT_HEIGHT } from '../utils';

export const Hint: React.FC<HintProps> = ({
  visible,
  children,
  width = DEFAULT_HINT_WIDTH,
  height = DEFAULT_HINT_HEIGHT,
  left = 0,
  top = 0,
  inputHeight,
}) => {
  return (
    <Box
      width={width}
      height={height}
      border="1px solid #ccc"
      bgcolor="white"
      style={{
        visibility: visible ? 'visible' : 'hidden',
        opacity: visible ? '1' : '0',
        transition: 'all 0.2s',
      }}
      position="absolute"
      zIndex={1}
      left={left}
      top={top}
    >
      <>
        {top !== 0 ? (
          <Box
            position="absolute"
            left={width / 2 - TRIANGLE_WIDTH}
            top={-Math.floor(TRIANGLE_WIDTH / 2) - 2}
            width={TRIANGLE_WIDTH}
            height={TRIANGLE_WIDTH}
            bgcolor="white"
            borderTop="1px solid #ccc"
            borderLeft="1px solid #ccc"
            style={{
              transform: "rotate(45deg)",
            }}
          />
        ) : (
          <Box
            position="absolute"
            left={-Math.floor(TRIANGLE_WIDTH / 2) - 2}
            top={inputHeight / 2 - TRIANGLE_WIDTH / 2}
            width={TRIANGLE_WIDTH}
            height={TRIANGLE_WIDTH}
            bgcolor="white"
            borderBottom="1px solid #ccc"
            borderLeft="1px solid #ccc"
            style={{
              transform: "rotate(45deg)",
            }}
          />
        )}
        {children}
      </>
    </Box>
  );
};
