import React from 'react';
import { TableBody } from '@mui/material';
import { CustomTableRow } from '../Row';
import { CustomTableBodyProps } from './types';

export const CustomTableBody: React.FC<CustomTableBodyProps> = ({ data, columns, requestTime }) => {
  return (
    <TableBody>
      {data?.map((row: Record<string, any>) => (
        <CustomTableRow key={row.id} row={row} columns={columns} requestTime={requestTime} />
      ))}
    </TableBody>
  );
};
