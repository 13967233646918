import { FC, useEffect, useState } from 'react';
import { Box, Button, Typography, useMediaQuery } from "@mui/material";
import { colors } from '../../theme';

interface FileChildProps {
  item: File;
  handleRemoveFile: () => void;
  preview: boolean;
}

export const FileChild: FC<FileChildProps> = ({ item, handleRemoveFile, preview }) => {
  const [imgPreview, setImgPreview] = useState<string>();

  const isLarge = useMediaQuery('(max-height:1000px)');
  const isMedium = useMediaQuery('(max-height:750px)');

  const changeVertical = () => {
    if (isMedium) return '300px'
    if(isLarge) return '500px'
    return 'none'
  }

  useEffect(() => {
    if (item != null && preview) {
      convertFileToURL(item);
    }
  }, [item, preview]);

  const convertFileToURL = (image: File) => {
    const reader = new FileReader();

    reader.onload = function (e) {
      const result = e.target?.result;
      if (result) setImgPreview(result as string);
    };

    reader.readAsDataURL(image);
  };

  return (
    <Box>
      <Button onClick={handleRemoveFile} variant="contained" color="secondary">
        Remove
      </Button>
      <Box mt={2} px={2} py={2} border={`2px dashed ${colors.gray[300]}`}>
        {preview ? (
          <Box width={'100%'} height="auto">
            <img
              style={{
                width: '100%',
                height: 'auto',
                maxWidth: '400px',
                maxHeight: changeVertical(),
              }}
              src={imgPreview}
              alt=""
            />
          </Box>
        ) : (
          <Typography>{item.name}</Typography>
        )}
        <Typography
          width="100%"
          textAlign={'center'}
          textOverflow={'ellipsis'}
          whiteSpace="nowrap"
          overflow="hidden"
        >
          {item?.name || `file`}
        </Typography>
      </Box>
    </Box>
  );
};
