const join = (date: Date, options: object[], divider: string) => {
  const format = (m: object) => {
    const f = new Intl.DateTimeFormat('en-US', m);
    return f.format(date);
  };
  return options.map(format).join(divider);
};

export const formatDate = (d: string | Date) => {
  const options: object[] = [{ month: 'long' }, { day: 'numeric' }, { year: 'numeric' }];

  return join(new Date(d), options, ', ');
};