import React, { useState } from "react";
import { Avatar, Box, Button, Modal } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import { patchRequest } from "../../../api/index";
import { RootState } from "../../../redux/reducers";
import { setUser } from "../../../redux/actionCreators";
import UploadAvatar from "../../../components/UploadAvatar";
import Loader from "../../../components/Loader";
import { ProfileImageProps } from "../types";

export const ProfileImage = React.forwardRef(
  ({ value, wrapperStyle, loading }: ProfileImageProps, ref?: any) => {
    const [open, setOpen] = useState(false);
    const { user } = useSelector((state: RootState) => state.user);
    const dispatch = useDispatch();

    const closeModal = () => setOpen(false);

    const onChangeImg = async (f?: File | null) => {
      if (!f) return;

      const fd = new FormData();
      fd.append("avatar", f);

      const data = await patchRequest(`/profiles/${user.id}/`, fd, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      dispatch(setUser(data));
      closeModal();
    };

    return (
      <>
        <Modal open={open} onClose={closeModal}>
          <Box
            onClick={closeModal}
            width="100%"
            height="100%"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <UploadAvatar onUpload={onChangeImg} />
          </Box>
        </Modal>
        <Box {...wrapperStyle} display="flex" alignItems="center" gap={4}>
          <Avatar
            sx={{
              width: 80,
              height: 80,
              borderWidth: 1,
              borderColor: "gray",
              borderStyle: "solid",
            }}
            src={value}
            alt=""
          >
            {loading && <Loader />}
          </Avatar>
          <Button
            onClick={() => setOpen(true)}
            variant="contained"
            component="label"
            color="secondary"
            disabled={loading}
          >
            Change Photo
          </Button>
        </Box>
      </>
    );
  },
);
