import { InputLabel, LinearProgress, MenuItem, Select, Box, Typography } from "@mui/material";
import React from "react";
import { SelectWithLabelProps } from "./types";

export const SelectWithLabel = React.forwardRef(
  (
    {
      id,
      items,
      value = null,
      onChange = () => {},
      placeholder = "Select",
      wrapperStyle,
      errorMessage,
      loading,
      label,
    }: SelectWithLabelProps,
    ref: any,
  ) => {
    return (
      <Box {...wrapperStyle}>
        {label && (
          <InputLabel shrink htmlFor={id}>
            <Typography variant="body1" fontWeight={600}>
              {label}
            </Typography>
          </InputLabel>
        )}
        {loading && (
          <Box width={"100%"}>
            <LinearProgress color="secondary" />
          </Box>
        )}
        <Select
          disabled={loading}
          error={Boolean(errorMessage)}
          fullWidth
          displayEmpty
          labelId={id}
          value={value ? value.value : ""}
          onChange={onChange}
        >
          <MenuItem disabled value="">
            <em>{placeholder}</em>
          </MenuItem>
          {items.map((t, index: number) => (
            <MenuItem disabled={t?.disabled} key={t.key} value={t.value}>
              {t?.label || t.value}
            </MenuItem>
          ))}
        </Select>
        {errorMessage && (
          <Box marginTop={1}>
            <InputLabel error={Boolean(errorMessage)} shrink htmlFor={id}>
              {errorMessage}
            </InputLabel>
          </Box>
        )}
      </Box>
    );
  },
);
