import { getRequest, patchRequest, postRequest } from ".";
import urls from "./urls";

const { profile } = urls();

export const updateMyProfile = async (id: string, body: any) => {
  if (!id || !body) {
    return;
  }
  return await patchRequest(profile.updateProfile(id), body);
};

export const inviteWriter = async (firstName: string, lastName: string, email: string) => {
  if (!firstName || !lastName || !email) {
    return;
  }

  try {
    const data = await postRequest(profile.inviteWriter, {
      first_name: firstName,
      last_name: lastName,
      email: email,
    });

    return !data.error ? data : { error: data.error.error };
  } catch (err) {
    return { error: err };
  }
};

export const showDisclaimer = async () => {
  try {
    return await getRequest(profile.showDisclaimer);
  } catch (err) {
    return { error: err };
  }
};
