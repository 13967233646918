import urls from "./../urls";
import { stripe } from "./index";

const { stripeURL } = urls();

export const getSubscriptions = async (user: null | Record<string, any>) => {
  if (!user) {
    return;
  }

  try {
    if (user?.stripe_id) {
      return await stripe.get(stripeURL.getSubscriptionsWithProducts(user?.stripe_id));
    }
  } catch (err) {
    console.log(err);
  }
};

export const deleteSubscription = async (id: string) => {
  if (!id) {
    return;
  }

  try {
    return await stripe.delete(stripeURL.cancelSubscription(id));
  } catch (err) {
    console.log(err);
  }
};

export const getProducts = async () => {
  try {
    return await stripe.get(stripeURL.getProductsWithPrice);
  } catch (err) {
    console.log(err);
  }
};

export const updatePaymentData = async (customerId?: string, returnUrl?: string) => {
  if (!customerId || !returnUrl) {
    return;
  }

  try {
    const params = `?customer=${customerId}&return_url=${returnUrl}`;
    return await stripe.post(stripeURL.updatePaymentInfo(params));
  } catch (err) {
    console.log(err);
  }
};
