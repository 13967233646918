import React, { useEffect, useState } from "react";
import { ValidationRule } from "react-hook-form";
import { LinearProgress, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { calculateStrength, initialStrength, rules, validatePassword } from "./utils";
import { RuleBox } from "./RuleBlock";
import { Strength, ValidationRules } from "./types";

type PasswordHintProps = {
  value: string;
};

const PasswordHint = ({ value = "" }: PasswordHintProps) => {
  const [validation, setValidation] = useState<ValidationRules>(validatePassword(value));
  const [strength, setStrength] = useState<Strength>(initialStrength);

  useEffect(() => {
    const _rules = validatePassword(value);
    const _strength = calculateStrength(_rules);
    setValidation(_rules);
    setStrength(_strength);
  }, [value]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      width="100%"
      height="100%"
      justifyContent="center"
      px={2}
      py={2}
      boxSizing="border-box"
    >
      <Box overflow="auto" height="100%" width="100%">
        <Box mb={3}>
          <Typography fontWeight={500}>Password Requirements</Typography>
        </Box>
        <Box mb={2}>
          <Typography>The password must contain: </Typography>
        </Box>
        {rules.map(r => (
          <RuleBox
            key={r.key}
            title={r.title}
            valid={validation?.[r.key as keyof ValidationRule]}
          />
        ))}
        <Box>
          <Typography>Strength: </Typography>
          <Box width="100%" display="flex" alignItems="center">
            <Box width="100%" height={10}>
              <LinearProgress
                style={{ height: "inherit", borderRadius: 5 }}
                valueBuffer={100}
                variant="buffer"
                color={strength.color}
                value={strength.value}
              />
            </Box>
            <Typography paddingLeft={2} fontWeight={600}>
              {strength.state}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default PasswordHint;
