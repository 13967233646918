import React from "react";
import { Box, Button, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/reducers";
import { colors } from "../../theme";
import { convertNumberToWords } from "../../utils/strings";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../constants/routes";

export const AboutLuciCredits = () => {
  const { user } = useSelector((state: RootState) => state.user);
  const navigate = useNavigate();

  return (
    <Box display="flex" flexDirection="column" flex={1}>
      <Typography mb={5} variant="title" fontWeight={500}>
        About LUCI credits
      </Typography>
      <Typography mb={2.5} fontWeight={500} color={colors.textGray} variant="mediumTitle">
        Credits allow you to collaborate with the Lucinetic AI writing assistant to compose successful
        career documents 90% faster with more creativity and joy.
      </Typography>
      <Typography variant="largeText" mb={2} color={colors.gray[40]}>
        Balance
      </Typography>
      <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
        <Box display="flex" flexDirection="row" alignItems="center">
          <Box
            width={30}
            height={30}
            mr={2.5}
            bgcolor={colors.infoLight}
            borderRadius="50%"
            display="flex"
            alignSelf="center"
            alignItems="center"
            justifyContent="center"
          >
            <Typography variant="text" fontWeight={900} color={colors.info}>
              {user?.credit_count || 0}
            </Typography>
          </Box>
          <Typography variant="text" color={colors.textGray} fontWeight={900}>
            You have {convertNumberToWords(user?.credit_count || 0)} credits
          </Typography>
        </Box>
        <Button variant="contained" color="secondary" onClick={() => navigate(ROUTES.SUBSCRIPTION)}>
          Add Credits
        </Button>
      </Box>
    </Box>
  );
};
