import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Avatar, Box, Link, ListItem, Tooltip, Typography } from "@mui/material";
import { CloseOutlined } from "@mui/icons-material";
import CircleIcon from "@mui/icons-material/Circle";
import { deleteNotificationById, updateNotification } from "../../../api/notifications";
import { reminderIsRead, removeReminder, setSelectedRequestId } from "../../../redux/actionCreators";
import { getType } from "../../../api/localStorage/type";
import { AccountTypeEnum } from "../../../constants";
import { ROUTES } from "../../../constants/routes";
import { createNotificationText } from "./NotificationText";
import { LuciNotification } from "./types";
import { colors } from "../../../theme";

type ReminderItemProps = {
  reminder: LuciNotification;
  onClose?: () => void;
};

export const ReminderItem = ({ reminder, onClose }: ReminderItemProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleMarkAsRead = async (
    e:
      | React.MouseEvent<HTMLSpanElement, MouseEvent>
      | React.MouseEvent<HTMLAnchorElement, MouseEvent>,
  ) => {
    e.stopPropagation();
    const read = await updateNotification(reminder.id, { is_read: true });
    if (!read.error) {
      dispatch(reminderIsRead(reminder.id));
    }
  };

  const onRequest = () => {
    dispatch(setSelectedRequestId(reminder.letterId));
    const accountType = getType();
    const route =
      accountType === AccountTypeEnum.Applicant ? ROUTES.DASHBOARD_APPLICANT : ROUTES.DASHBOARD_PROFESSOR;
    navigate(route);
    onClose && onClose();
  };

  const deleteNotification = async () => {
    const deleted = await deleteNotificationById(reminder.id);
    if (!deleted.error) {
      dispatch(removeReminder(reminder.id));
    }
  };

  return (
    <>
      <ListItem disablePadding>
        <Box
          display="flex"
          flexDirection="row"
          width="100%"
          gap={1}
          py={1}
          alignItems="center"
          position="relative"
        >
          <Link position="absolute" right={0} top={0} onClick={deleteNotification}>
            <CloseOutlined sx={{ fontSize: 12, color: colors.gray[40] }} />
          </Link>
          <CircleIcon
            sx={{
              width: 10,
              color: reminder.isRead ? "transparent" : colors.secondary,
            }}
          />
          <Avatar src={reminder.avatar} />
          <Box width="100%" display="flex" flexDirection="column" gap={0.5}>
            <Typography
              component="span"
              variant="smallText"
              fontWeight={reminder.isRead ? 400 : 600}
            >
              {reminder.text
                .split(" ")
                .map((w: string) => createNotificationText(w, reminder.name, onRequest))}
            </Typography>
            <Box display="flex" gap={0.5} flexDirection="row">
              <Typography variant="littleText">{reminder.time} /</Typography>
              <Tooltip
                title={reminder.university}
              >
                <Typography
                  maxWidth="155px"
                  variant="littleText"
                  textOverflow="ellipsis"
                  noWrap
                  overflow="hidden"
                >
                  {reminder.university}
                </Typography>
              </Tooltip>
            </Box>
            {!reminder.isRead && (
              <Link
                color="secondary"
                alignSelf="flex-end"
                onClick={handleMarkAsRead}
                underline="hover"
              >
                <Typography variant="littleText">Mark as read</Typography>
              </Link>
            )}
          </Box>
        </Box>
      </ListItem>
      <Box width="100%" borderBottom={`1px solid ${colors.gray[300]}`} />
    </>
  );
};