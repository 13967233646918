import React, { useEffect, useState } from "react";
import { Box, Modal, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { TypeButton } from "./TypeButton";
import { TypeButtonText } from "./TypeButton";
import PageTemplate from "../PageTemplate";
import { RootState } from "../../redux/reducers";
import { createAccountTypeButtons, ButtonType } from "./utils";
import { AccountTypeEnum } from "../../constants";
import { ROUTES } from "../../constants/routes";
import { getType } from "../../api/localStorage/type";
import { ChooseTypeProps, ChooseLetterType } from "./types";
import { ConfirmationModal } from "../ConfirmationModal";
import { startTutorial } from "../../redux/actionCreators";
import { createSampleRequest } from "../../api/letterRequest";

export const ChooseType: React.FC<ChooseTypeProps> = ({
  accountTypeValue,
  handleChangeAccountType,
  isShowLetterTypeButtons = true,
}) => {
  const [accountTypeButtons, setAccountTypeButtons] = useState<ButtonType[] | []>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { accountTypes, user } = useSelector((state: RootState) => state.user);
  const accountType = getType();
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const mediumScreen = useMediaQuery(theme.breakpoints.down("md"));
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const routeByAccountType = accountType === AccountTypeEnum.Writer ? ROUTES.DASHBOARD_PROFESSOR : ROUTES.DASHBOARD_APPLICANT;

  const handleStartTutorial = async () => {
    navigate(routeByAccountType);
    setTimeout(async () => {
      dispatch(startTutorial());
      try {
        await createSampleRequest();
      } catch (error: unknown) {
        console.error(error);
      }
    }, 500)
  };

  const handleOpenTutorialModal = () => setIsModalOpen(true);
  const handleCloseTutorialModal = () => {
    setIsModalOpen(false);
    navigate(routeByAccountType);
  };

  const letterTypes = [
    {
      key: 0,
      value: "recommendation",
      title: `${accountType === AccountTypeEnum.Writer ? "" : ""} letters of recommendation`,
      action: () => {
        if (user.login_count <= 2) {
          handleOpenTutorialModal();
        } else {
          navigate(routeByAccountType);
        }
      },
    },
    {
      key: 1,
      value: "cover",
      title: "cover letters",
      action: () => navigate(ROUTES.COVER_LETTER_STATUS),
    },
  ];

  useEffect(() => {
    if (accountTypes.length) {
      const buttons = createAccountTypeButtons(accountTypes);
      setAccountTypeButtons(buttons);
    }
  }, [accountTypes]);

  return (
    <>
      <Modal open={isModalOpen} onClose={handleCloseTutorialModal}>
        <Box width="100%" height="100%" display="flex" alignItems="center" justifyContent="center">
          <ConfirmationModal
            onSubmit={handleStartTutorial}
            onCancel={handleCloseTutorialModal}
            title="Do you want to start tutorial?"
            actionName="Start"
          />
        </Box>
      </Modal>
      <PageTemplate type={getType()}>
        <Box
          width="100%"
          height="100%"
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          paddingX={3}
          boxSizing="border-box"
        >
          <Typography
            marginTop={10}
            marginBottom={smallScreen ? 5 : 10}
            fontSize={smallScreen ? 20 : 44}
            textAlign={mediumScreen ? "center" : "left"}
          >
            {!isShowLetterTypeButtons ? "Welcome to Lucinetic" : ""}
          </Typography>
          <Box
            width="70%"
            height="100%"
            display="flex"
            flexDirection={smallScreen ? "column" : "row"}
            justifyContent="center"
            gap={smallScreen ? 0 : 10}
            boxSizing="border-box"
            flexWrap="wrap"
          >
            {!isShowLetterTypeButtons ? accountTypeButtons?.map((t: ButtonType) => (
              <TypeButton
                key={t.value}
                selected={accountTypeValue === t.value}
                type={t}
                onSelectAccountType={handleChangeAccountType!}
              />
            )) : letterTypes.map((t: ChooseLetterType) => (
              <TypeButton key={t.key} type={t} isShowLetterTypeButtons={true} />
            ))}
          </Box>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            minWidth="100%"
            marginBottom={smallScreen ? "none" : 15}
          ></Box>
        </Box>
      </PageTemplate>
    </>
  );
};
