export const getGender = (gender: string | undefined) => {
  switch (gender) {
    case "he, him, his":
      return "M"
    case "she, her, hers":
      return  "W"
    case "they, them, theirs":
      return  "N"
    default:
      return gender
  }
}