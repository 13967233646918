import { Check } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import React from 'react';
import { colors } from '../../../theme';

type RuleBoxProps = {
  title: string;
  valid: boolean;
};

export const RuleBox = ({ title, valid = false }: RuleBoxProps) => {
  const color = valid ? colors.main : colors.gray[300];
  return (
    <Box width={'100%'} mb={1} display="flex" flexDirection="row" alignItems="center">
      <Check style={{ color: color }} fontSize="small" />
      <Typography color={color} paddingLeft={1}>
        {title}
      </Typography>
    </Box>
  );
};
