import React from "react";
import { Box, Button, Tooltip, Typography } from "@mui/material";
import { LinkOutlined } from "@mui/icons-material";
import { PreviewComponentProps } from "../FileUpload/DragAndDropFiles";
import { colors } from "../../theme";
import { FILE_MAX_SIZE, getFileSizeNumber } from "../../utils/fileSize";

type AdditionalInformationPreview = {
  switchUploadType?: () => void;
  tooltipSupportedFormatsTitle?: string;
} & PreviewComponentProps;

export const PreviewComponent = ({
  onSelectFile,
  switchUploadType,
  tooltipSupportedFormatsTitle,
}: AdditionalInformationPreview) => {
  const onSelect = () => {
    onSelectFile();
  };
  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <Typography fontWeight={500} variant="mediumTitle" pt={3}>
        Drag & drop your files here
      </Typography>
      <Box display="flex" flexDirection="column" mt={2} textAlign="center" color={colors.gray[400]}>
        <Typography variant="caption">
          The file size should be lower than {getFileSizeNumber(FILE_MAX_SIZE)}
        </Typography>
        <Typography display="flex" flexDirection="row" gap={0.5} variant="caption">
          Files supported:
          <Tooltip
            title={tooltipSupportedFormatsTitle as string}
            arrow
          >
            <Typography variant="caption" sx={{ cursor: "pointer" }}>PDF, JPEG, PNG, DOCX...</Typography>
          </Tooltip>
        </Typography>
      </Box>
      <Typography variant="largeText" my={2} fontWeight={600}>
        or
      </Typography>
      <Box display="flex" flexDirection="row" justifyContent="center" alignItems="center" gap={3}>
        <Button variant="contained" color="secondary" onClick={onSelect}>
          Browse Files
        </Button>
        <Button onClick={switchUploadType && switchUploadType} variant="outlined" color="secondary">
          <LinkOutlined fontSize="small" />
          <Typography variant="text" fontWeight={900}>
            Upload from URL
          </Typography>
        </Button>
      </Box>
    </Box>
  );
};
