import { OptionData } from "../../../components/InputWithAutocomplete";

export const createOptionsFromUsers = (data: Array<any>): Array<OptionData> => {
  const options = data.map(user => ({
    label: user.user.first_name + " " + user.user.last_name,
    avatar: user.avatar,
    request_id: user?.request_id || "",
    email: user?.user.email || "",
  }));

  return options;
};
