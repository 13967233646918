import React from "react";
import { Box, Divider, Link, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import Loader from "../Loader";
import { colors } from "../../theme";

type DisclaimerProps = {
  children?: React.ReactNode;
  onSubmit: () => void;
  onCancel: () => void;
  title: string;
  actionName: string;
  isLoading?: boolean;
}

export const Disclaimer = React.forwardRef(
  ({ children, onSubmit, onCancel, title, actionName, isLoading }: DisclaimerProps, ref: React.Ref<any>) => {
    return (
      <Box ref={ref} width="100%" height="100%" display="flex" alignItems="center" justifyContent="center">
        <Box
          maxWidth={500}
          minHeight={300}
          bgcolor="white"
          display="flex"
          flexDirection="column"
          gap={3}
          alignItems="start"
          paddingX={7}
          paddingY={9}
          position="relative"
        >
          <Box
            position="absolute"
            top={12}
            right={16}
            display="flex"
            flexDirection="row"
            alignItems="center"
            gap={0.5}
            onClick={onCancel}
          >
            <Link variant="text" color={colors.black} underline="hover">
              <Typography variant="smallText">Go back</Typography>
            </Link>
            <CloseIcon fontSize="small" cursor="pointer" sx={{ width: 12 }} />
          </Box>
          <Typography variant="title" mb={2}>
            {title}
          </Typography>
          {children && (
            <Typography variant="largeText" textAlign="start" color={colors.textGray} lineHeight={1.5}>
              {children}
            </Typography>
          )}
          <Divider sx={{ width: "100%" }} color={colors.gray[100]} />
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="center"
            gap={0.5}
            sx={{ cursor: "pointer" }}
            onClick={onSubmit}
          >
            <CheckIcon fontSize="small" color="secondary" />
            <Link color="secondary" underline="hover">
              {isLoading ? <Loader size={25} speedMs={200} /> : actionName}
            </Link>
          </Box>
        </Box>
      </Box>
    );
  },
);