import React from "react";
import { TableHead, TableRow } from "@mui/material";
import { HeaderCell, TableHeadProps } from "./types";
import { HeadCell } from "../HeadCell";

export const CustomTableHead = ({ columns }: TableHeadProps) => {
  return (
    <TableHead
      sx={{
        position: "sticky",
        top: 0,
        background: "white",
        zIndex: 1,
        borderBottom: "1px solid black",
      }}
    >
      <TableRow>
        {columns?.map((col: HeaderCell) => (
          <HeadCell key={col.key} column={col} />
        ))}
      </TableRow>
    </TableHead>
  );
};
