import {
  AuthenticationSetup,
  CreateNewPassword,
  Dashboard,
  ForgotPassword,
  LetterStatus,
  LetterSuccess,
  MyAccount,
  NewLetter,
  NotFound,
  Polygon,
  Referral,
  ResetPassword,
  SignIn,
  SignUp,
  Subscription,
} from "../views";
import { LetterComposition } from "../views/LetterComposition";
import { NewCoverLetter } from "../views/NewCoverLetter";
import { CoverLetterStatus } from "../views/CoverLetterStatus";
import { ChooseType } from "../components/ChooseType/ChooseType";

export enum ROUTES {
  // COMMON
  NOT_FOUND = "*",
  HOME = "/",
  LUCINETIC = "https://www.lucinetic.com",
  SIGN_IN = "/signin",
  SIGN_UP = "/signup",
  TERMS_OF_SERVICE = "https://accordion-elk-bnnc.squarespace.com/terms-of-use",
  PRIVACY_POLICY = "https://accordion-elk-bnnc.squarespace.com/privacy-policy",
  FEEDBACK = "mailto:hello@lucinetic.com",
  FORGOT_PASSWORD = "/forgot-password",
  CREATE_NEW_PASSWORD = "/password-reset",
  MY_ACCOUNT = "/account",
  RESET_PASSWORD = "/reset-password",
  TWO_FACTOR_SETUP = "/setup-auth",
  SUBSCRIPTION = "/subscription",
  REFERRAL = "/referral",
  LETTER_STATUS = "/letter-status",
  COVER_LETTER_STATUS = "/cover-letter-status",
  WRITE_LETTER = "/write-letter/",
  COVER_LETTER = "/cover-letter/",
  CHOOSE_LETTER_TYPE = "/choose-letter-type/",

  // APPLICANT
  DASHBOARD_APPLICANT = "/applicant/dashboard",
  NEW_LETTER = "/applicant/new-letter",
  LETTER_SUCCESS = "/applicant/letter-success",
  REQUEST_SELF_LETTER = "/writer/new-letter?selfRequest=true",

  // WRITER
  DASHBOARD_PROFESSOR = "/writer/dashboard",
  WRITER_NEW_LETTER = "/writer/new-letter",

  //TEST
  POLYGON = "/polygon",
}

export const privateRoutes = [
  { path: ROUTES.DASHBOARD_APPLICANT, element: <Dashboard /> },
  { path: ROUTES.DASHBOARD_PROFESSOR, element: <Dashboard /> },
  { path: ROUTES.NEW_LETTER, element: <NewLetter /> },
  { path: ROUTES.WRITER_NEW_LETTER, element: <NewLetter /> },
  { path: ROUTES.COVER_LETTER, element: <NewCoverLetter /> },
  { path: ROUTES.LETTER_STATUS, element: <LetterStatus /> },
  { path: ROUTES.COVER_LETTER_STATUS, element: <CoverLetterStatus />},
  { path: ROUTES.NOT_FOUND, element: <NotFound /> },
  { path: ROUTES.LETTER_SUCCESS, element: <LetterSuccess /> },
  { path: ROUTES.MY_ACCOUNT, element: <MyAccount /> },
  { path: ROUTES.RESET_PASSWORD, element: <ResetPassword /> },
  { path: ROUTES.TWO_FACTOR_SETUP, element: <AuthenticationSetup /> },
  { path: ROUTES.POLYGON, element: <Polygon /> },
  { path: ROUTES.SUBSCRIPTION, element: <Subscription /> },
  { path: ROUTES.REFERRAL, element: <Referral /> },
  { path: ROUTES.WRITE_LETTER, element: <LetterComposition /> },
  { path: ROUTES.CHOOSE_LETTER_TYPE, element: <ChooseType /> },
];

export const publicRoutes = [
  { path: ROUTES.SIGN_UP, element: <SignUp /> },
  { path: ROUTES.SIGN_IN, element: <SignIn /> },
  { path: ROUTES.FORGOT_PASSWORD, element: <ForgotPassword /> },
  { path: ROUTES.CREATE_NEW_PASSWORD, element: <CreateNewPassword /> },
];
