import React, { useEffect, useState } from "react";
import { Box } from "@mui/system";
import { FirstFrame, SecondFrame, ThirdFrame } from "../../assets";

const frames = [FirstFrame, SecondFrame, ThirdFrame];

type LoaderProps = {
  size?: number;
  speedMs?: number;
};

const Loader = ({ size = 50, speedMs = 500 }: LoaderProps) => {
  const [frame, setFrame] = useState(0);

  useEffect(() => {
    const id = setInterval(() => {
      if (frame !== frames.length - 1) {
        setFrame(frame + 1);
      } else {
        setFrame(0);
      }
    }, speedMs);

    return () => {
      clearInterval(id);
    };
  });

  return (
    <Box display="flex" alignItems="center" justifyContent="center">
      <img width={size} height={size} src={frames[frame]} alt="" />
    </Box>
  );
};

export default Loader;
