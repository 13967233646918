import React from 'react';
import { Navigate } from 'react-router-dom';
import { getToken } from '../../api/localStorage/token';
import { getType } from '../../api/localStorage/type';

export const PublicRoute: React.FC<any> = ({ children }) => {
  const token = getToken();
  const type = getType();
  return !token ? children : <Navigate to={`/${type?.toLowerCase()}/dashboard`} />;
};
