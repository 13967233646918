import React from "react";
import NotificationsIcon from "@mui/icons-material/Notifications";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import { SvgIconComponent } from "@mui/icons-material";
import { dueDates } from "../../constants/dueDates";
import { getColorByDateDif } from "../../utils/dates";
import { ReactComponent as ExpiredClock } from "./../../assets/images/ExpiredClock.svg";
import { RequestStatus } from "../../views/LetterStatus/types";

type LetterDueDateProps = {
  dueDate: string;
  RemindIcon?: SvgIconComponent;
  status?: RequestStatus;
};

export const LetterDueDate = ({ dueDate, RemindIcon, status }: LetterDueDateProps) => {
  const due = new Date(dueDate);
  const currentDate = new Date();

  const differenceMillisecond = due.getTime() - currentDate.getTime();
  const differenceDay = Math.floor(differenceMillisecond / dueDates.MS_IN_DAY);

  const color = getColorByDateDif(dueDate, status);
  if (RemindIcon) {
    return <RemindIcon style={{ color }} />;
  }

  return differenceDay > dueDates.WEEK ? (
    <NotificationsIcon fontSize="small" pointerEvents="none" style={{ color }} />
  ) : differenceDay < dueDates.TODAY ? (
    <ExpiredClock pointerEvents="none" />
  ) : (
    <NotificationsActiveIcon fontSize="small" pointerEvents="none" style={{ color }} />
  );
};
