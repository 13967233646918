import React, { useEffect, useRef, useState } from "react";
import { ArrowDropDown, ArrowDropUp, FilterAlt, Sort } from "@mui/icons-material";
import { Button, Checkbox, FormControlLabel, IconButton, Menu, MenuItem, TableCell, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useDispatch, useSelector } from "react-redux";
import { HeaderCell, SortMenu } from "../Head/types";
import { defineFilterValue } from "../../../redux/actionCreators";
import { RootState } from "../../../redux/reducers";
import { colors } from "../../../theme";

export const HeadCell = ({ column }: { column: HeaderCell }) => {
  const [open, setOpen] = useState<boolean>(false);
  const [selected, setSelected] = useState<string | null>(null);
  const [sortingBy, setSortingBy] = useState<boolean>(false);
  const [filteringBy, setFilteringBy] = useState<boolean>(false);
  const [isStatus, setIsStatus] = useState<boolean | undefined>(false);
  const [selectedStatuses, setSelectedStatuses] = useState<Array<SortMenu>>([]);
  const { filter } = useSelector((state: RootState) => state.requests);

  const menu = useRef(null);

  const handleOpen = () => setOpen(!open);
  const handleClose = () => setOpen(false);
  const dispatch = useDispatch();

  const handleClick = async (item: SortMenu) => {
    const remove = selected && item.value === selected;
    setSelected(remove ? null : item.value);
    dispatch(defineFilterValue(remove, item));
    handleClose();
  };

  const handleChooseStatus = (items: SortMenu[]) => {
    const remove: boolean = !selectedStatuses.length;
    dispatch(defineFilterValue(remove, items));
    handleClose();
  };

  const handleCheckboxChange = (newValue: SortMenu) => {
    setSelectedStatuses((selected: SortMenu[]) =>
      selected.some((el: SortMenu) => el.value === newValue.value)
        ? selected.filter((f: SortMenu) => f.value !== newValue.value)
        : [...selected, newValue],
    );
  };

  useEffect(() => {
    const sortMenuValues = column?.sortMenu?.map((m: SortMenu) => m.value);
    if (sortMenuValues?.includes(filter.sort_by)) {
      setSelected(filter.sort_by || null);
      setSortingBy(true);
    } else {
      setSortingBy(false);
    }

    if (filter[column.key]) {
      setSelected(filter[column.key]);
      setFilteringBy(true);
      if (filter.status) {
        filter.status.split(",").forEach((elem: string) => {
          const result = column.sortMenu?.find((el: SortMenu) => el.value === elem)
          if (result && !selectedStatuses.length) {
            setSelectedStatuses((selected: SortMenu[]) => [
              ...selected, result
            ]);
          }
        });
      }
    } else {
      setFilteringBy(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  useEffect(() => {
    setIsStatus(column?.sortMenu?.every((el: SortMenu) => el.key === "status"));
  }, [open]);

  return (
    <TableCell style={{ padding: 0, fontWeight: 600, borderBottomColor: colors.black }}>
      <Box display="flex" flexDirection="row" alignItems="center" justifyContent="center">
        <Box display="flex" flexDirection="row" alignItems="center" gap={0.5}>
          {sortingBy && (
            <Sort
              sx={{
                fontSize: 20,
                color: colors.secondary,
                transform: `rotate${selected?.includes("-") ? "(180deg)" : "(0deg)"}`,
              }}
            />
          )}
          {filteringBy && (
            <FilterAlt
              sx={{
                fontSize: 20,
                color: colors.info,
              }}
            />
          )}
          {column.name}
        </Box>
        {column?.sortMenu && (
          <>
            <IconButton ref={menu} onClick={handleOpen}>
              {open ? <ArrowDropDown /> : <ArrowDropUp />}
            </IconButton>
            <Menu
              id="basic-menu"
              anchorEl={menu.current}
              open={open}
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
              transformOrigin={{ vertical: "top", horizontal: "right" }}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              {isStatus ? column?.sortMenu?.map((item: SortMenu, index: number) => (
                <MenuItem key={item.name + index}>
                  <Typography fontWeight={selected === item.value ? 600 : 400}>
                    <FormControlLabel control={
                      <Checkbox
                        checked={selectedStatuses.some((el: SortMenu) => el.value === item.value)}
                        size="small"
                        color="secondary"
                        onChange={() => handleCheckboxChange(item)}
                      />
                    } label={item.name} />
                  </Typography>
                </MenuItem>
              )) : column?.sortMenu?.map((item: SortMenu, index: number) => (
                <MenuItem key={item.name + index} onClick={() => handleClick(item)}>
                  <Typography fontWeight={selected === item.value ? 600 : 400}>
                    {item.name}
                  </Typography>
                </MenuItem>
              ))}
              {isStatus && (
                <Box
                  display="flex"
                  justifyContent="center"
                  p={1}
                  borderTop={`1px solid ${colors.gray[200]}`}
                >
                  <Button
                    fullWidth
                    variant="contained"
                    color="secondary"
                    onClick={() => handleChooseStatus(selectedStatuses)}
                  >
                    Done
                  </Button>
                </Box>)}
            </Menu>
          </>
        )}
      </Box>
    </TableCell>
  );
};
