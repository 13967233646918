import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Box, Link, Popover, Typography } from "@mui/material";
import { Close } from "@mui/icons-material";
import { LuciButton } from "../../LuciButton";
import { convertNumberToWords } from "../../../utils/strings";
import { ROUTES } from "../../../constants/routes";
import { getSubscriptions } from "../../../api/stripe/stripe";
import { ProfileMenu } from "../../../utils/profileMenu";
import { colors } from "../../../theme";
import { LuciCredit } from "../../../assets";
import { RootState } from "../../../redux/reducers";

type LuciCreditPopOverProps = {
  open: boolean;
  anchorEl: HTMLElement | null;
  handleClose: () => void;
  credits: number;
};

const POPUP_WIDTH = 300;
const PRICING_CARD_WIDTH = 230;
const SINGLE_ARRAY = 0;

export const LuciCreditPopOver = ({
  open,
  anchorEl,
  handleClose,
  credits = 0,
}: LuciCreditPopOverProps) => {
  const [subscriptions, setSubscriptions] = useState<any>();

  const navigate = useNavigate();

  const { user } = useSelector((state: RootState) => state.user);

  const getSubscription = async () => {
    const subscriptions = await getSubscriptions(user);
    if (subscriptions?.data.data) {
      setSubscriptions(subscriptions.data.data);
    }
  };

  const addLuciCredits = () => {
    navigate(ROUTES.SUBSCRIPTION);
    handleClose();
  };

  const whatIsLuciCredits = () => navigate(ROUTES.REFERRAL);

  const handleNavigateManage = () => {
    navigate(`${ROUTES.MY_ACCOUNT}/?tab=${ProfileMenu.subscription}`);
  };

  useEffect(() => {
    getSubscription();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [credits, open]);

  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 20,
        horizontal: -POPUP_WIDTH + 20,
      }}
    >
      <Box display="flex" flexDirection="column" width={POPUP_WIDTH}>
        <Box padding={2.25} display="flex" alignItems="center" justifyContent="space-between">
          <Box display="flex" alignItems="center" gap={1.25}>
            <img src={LuciCredit} style={{ width: 16, height: 16 }} alt="logo" />
            <Typography variant="littleText" color={colors.textGray}>
              LUCI CREDITS
            </Typography>
          </Box>
          <Link underline="none" onClick={handleClose}>
            <Close sx={{ width: 15, height: 15, color: colors.gray[400] }} />
          </Link>
        </Box>
        <Box
          borderTop={`1px solid ${colors.gray[100]}`}
          borderBottom={`1px solid ${colors.gray[100]}`}
          display="grid"
          gridTemplateColumns="4fr"
          px={2.5}
        >
          <Box
            sx={{ backgroundColor: colors.gray[100] }}
            width={PRICING_CARD_WIDTH}
            display="flex"
            flexDirection="row"
            alignItems="center"
            height="48px"
            mt={2}
            mb={2}
            px={2}
            borderRadius="4px"
            justifyContent="space-between"
          >
            {subscriptions?.length ? (
              <>
                <Typography
                  variant="text"
                  display="flex"
                  flexDirection="row"
                  gap={1}
                  color={colors.textGray}
                >
                  Pricing Plan:
                  <Typography variant="text" color="secondary">
                    {subscriptions.length === 1 ?
                      subscriptions[SINGLE_ARRAY].plan.product.name : subscriptions.length}
                  </Typography>
                </Typography>
                <Link underline="hover" color={colors.info}>
                  <Typography variant="text" fontWeight={900} onClick={handleNavigateManage}>
                    Manage
                  </Typography>
                </Link>
              </>) : <Typography variant="smallerText">You don't yet have a subscription</Typography>}
          </Box>
          <Box
            display="grid"
            gridTemplateColumns="1fr 4fr"
          >
            <Box
              width={30}
              height={30}
              bgcolor={colors.infoLight}
              borderRadius="50%"
              display="flex"
              alignSelf="center"
              alignItems="center"
              justifyContent="center"
            >
              <Typography variant="text" fontWeight={900} color={colors.info}>
                {credits}
              </Typography>
            </Box>
            <Box alignSelf="center">
              <Typography mb={1} variant="text" color={colors.textGray} fontWeight={900}>
                You have {convertNumberToWords(credits)} LUCI credits
              </Typography>
            </Box>
            <Box></Box>
            <Box display="flex" flexDirection="column">
              <Typography mb={2.5} variant="smallText" color={colors.gray[400]}>
                Credits allow you to collaborate with the Lucinetic AI writing assistant to compose career
                letters.
              </Typography>
              <Link underline="hover" mb={2.5} color={colors.info} onClick={whatIsLuciCredits}>
                <Typography variant="text">What are credits?</Typography>
              </Link>
            </Box>
          </Box>
        </Box>
        <Box py={2.5} display="flex" alignItems="center" justifyContent="center">
          <LuciButton text="GET LUCI" onClick={addLuciCredits} />
        </Box>
      </Box>
    </Popover>
  );
};
