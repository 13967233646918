import React from "react";
import { Box, Typography } from "@mui/material";
import { ReactComponent as EmbarrassedLuci } from "../../assets/images/EmbarrassedLuci.svg";

export const NotFound = () => {
  return (
    <Box
      width="100%"
      height="100%"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <EmbarrassedLuci />
      <Typography variant="h2">404 Page Not Found</Typography>
    </Box>
  );
};
