import React from "react";
import { Box, Button, TextField, Typography, useMediaQuery } from "@mui/material";
import { ControlButton } from "./ControlButton";
import { colors } from "../../../theme";
import { FinishedSectionProps } from "../types";

export const FinishedSection: React.FC<FinishedSectionProps> = ({
  onClick,
  text,
  isEditable,
  onChangeText,
  onSave,
  onCancel,
  enthusiasm,
  error,
  isDisabled,
  isSkipped,
}) => {
  const isDesktop = useMediaQuery("(min-width:900px)");

  return (
    <Box display="grid" gridTemplateColumns={isDesktop ? "5fr 1fr" : "1fr"} width="100%">
      {isEditable ? (
        <Box width="100%" display="flex" flexDirection="column" gap={2}>
          <TextField
            fullWidth
            value={text}
            onChange={(e) => onChangeText(e.target.value)}
            multiline={true}
            minRows={3}
          />

          <Box display="flex" gap={2} alignItems="center">
            <ControlButton
              disabled={isDisabled}
              text="Save changes"
              onClick={onSave}
              variant="contained"
            />
            <ControlButton disabled={isDisabled} text="Cancel" onClick={onCancel} variant="text" />
            <Box>{error && <Typography variant="error">{error}</Typography>}</Box>
          </Box>
        </Box>
      ) : !isSkipped ? (
        <Box
          boxSizing="border-box"
          width="100%"
          p={1}
          borderRadius={2}
          onClick={onClick}
          sx={{
            "wordWrap": "break-word",
            "&:hover": {
              border: `2px solid ${colors.secondary}`,
            },
            "border": "2px solid transparent",
          }}
        >
          <Typography variant="largeText">
            {text ? text : "This section is empty may be it was skipped"}
          </Typography>
        </Box>
      ) : (
        <Box
          alignSelf="center"
          px={2.5}
          py={2}
          border={`2px solid ${colors.gray[200]}`}
          display="flex"
          alignItems="center"
          gap={2}
        >
          <Typography fontWeight={500}>Skipped section</Typography>
          <Button color="primary" onClick={onClick}>
            Continue
          </Button>
        </Box>
      )}

      <Box visibility={isEditable && !isDisabled && enthusiasm ? "visible" : "hidden"}>
        {enthusiasm}
      </Box>
    </Box>
  );
};
