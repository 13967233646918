import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Button,
  InputAdornment,
  Link,
  Modal,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Box } from "@mui/system";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { SearchRounded } from "@mui/icons-material";
import PageTemplate from "../../components/PageTemplate";
import CustomTable from "../../components/Table";
import { createTableColumns } from "../../components/Table/constants";
import { LetterDetails } from "../../components/LetterDetails";
import { DeclineLetterModal } from "../../components/LetterDetails/components/DeclineLetterModal";
import { LuciPagination } from "../../components/Table/Pagination";
import { InputWithLabel } from "../../components/InputWithLabel";
import Loader from "../../components/Loader";
import { ConfirmationModal } from "../../components/ConfirmationModal";
import { ROUTES } from "../../constants/routes";
import { RootState } from "../../redux/reducers";
import { getType } from "../../api/localStorage/type";
import {
  deleteLetterRequest,
  getAllLetterRequests,
  updateLetterRequest,
} from "../../api/letterRequest";
import { LetterRequest, ModalsType, RequestStatus, RequestTab, SeparateRecommendationRequest } from "./types";
import { setEditRequest, setRequests } from "../../redux/actionCreators";
import { AccountTypeEnum } from "../../constants";
import { createRequestsFromLetters } from "./utils";
import { debounce } from "../../utils/debounce";
import { openInNewTab } from "../../utils/openInNewTab";
import { ReactComponent as EmptyBox } from "./../../assets/images/EmptyBox.svg";
import { TutorialClasses } from "../../components/Tutorial/tutorialClasses";
import { formatDate } from "../../utils/joinFormatDate";
import { getQueryParamFromURL } from "../../utils/getQueryParamFromURL";
import { LetterType } from "../../utils/globalLetterTypesCredits";

const state = {
  mounted: false,
};

type EmptyTableProps = {
  text: string;
};

const EmptyTable = ({ text }: EmptyTableProps) => {
  const navigation = useNavigate();
  const accountType = getType();

  return (
    <Box display="flex" flexDirection="row" gap={2}>
      {accountType === AccountTypeEnum.Applicant ? (
        <Box display="flex" flexDirection="column" gap={4} alignItems="center">
          <EmptyBox />
          <Box display="flex" flexDirection="row" gap={1}>
            <Typography>You have not requested any recommendation letters.</Typography>
            <Link color="secondary" onClick={() => navigation(ROUTES.NEW_LETTER)}>
              Request new letter
            </Link>
          </Box>
        </Box>
      ) : (
        <Box display="flex" flexDirection="column" gap={4} alignItems="center">
          <EmptyBox />
          <Typography>{text}</Typography>
        </Box>
      )}
    </Box>
  );
};

const LETTER_ID_QUERY_PARAM = "letterId";

export const LetterStatus = () => {
  const [letterId, setLetterId] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [modalName, setModalName] = useState<ModalsType | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [activeTab, setActiveTab] = useState(RequestTab.all);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [separateData, setSeparateData] = useState<SeparateRecommendationRequest | null>(null);
  const [search, setSearch] = useState("");
  const [emptyText, setEmptyText] = useState("");
  const [isOpenStudentAccessModal, setIsOpenStudentAccessModal] = useState(false);
  const [requestTime, setRequestTime] = useState(0);

  const { requests, filter } = useSelector((state: RootState) => state.requests);
  const { user } = useSelector((state: RootState) => state.user);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isDesktop = useMediaQuery("(min-width:820px)");


  const formattedRequests = requests.map((request: LetterRequest) => {
    return {
      ...request,
      date_requested: formatDate(request.date_requested),
      letter_due_date: formatDate(request.letter_due_date),
    };
  });

  const accountType = useMemo(() => getType(), []);

  const IS_MIN_PRICE = user?.credit_count < LetterType.recommendation;

  const mode = IS_MIN_PRICE ? "default" : "constructor";

  const routeByAccountTypeNewLetter = accountType === AccountTypeEnum.Applicant ? ROUTES.NEW_LETTER : ROUTES.WRITER_NEW_LETTER;
  const routeByAccountTypeDashboard = accountType === AccountTypeEnum.Applicant ? ROUTES.DASHBOARD_APPLICANT : ROUTES.DASHBOARD_PROFESSOR;

  const isSuperLargeDesktop: boolean = useMediaQuery("(min-width:1475px)");
  const isLargeDesktop: boolean = useMediaQuery("(min-width:1400px)");
  const isProfessor = useMemo(() => getType() === AccountTypeEnum.Writer, []);

  const handleOpenStudentAccessModal = () => setIsOpenStudentAccessModal(true);
  const handleCloseStudentAccessModal = () => setIsOpenStudentAccessModal(false);

  const handleOpenNewSubscriptionTab = () => {
    openInNewTab(ROUTES.SUBSCRIPTION);
    handleCloseStudentAccessModal();
  };

  const handleCancelDelete = () => handleClose();

  const handleChange = (e: any) => {
    setActiveTab(RequestTab[e.target.value as keyof typeof RequestTab]);
  };

  const handleClose = () => {
    setModalName(null);
    dispatch(setEditRequest(false));
    setLetterId("");
  };

  const onViewDetails = (row?: any) => {
    navigate(`${ROUTES.LETTER_STATUS}/?letterId=${row.letter_id}&isSelfRequest=${row.is_self_request}`);
    const lId = getQueryParamFromURL(LETTER_ID_QUERY_PARAM);
    if (lId) {
      setModalName("details");
    }
    setLetterId(row.letter_id);
  };

  const handleConfirmDelete = async () => {
    setModalName(null);
    letterId && (await deleteLetterRequest(letterId));
    await getLetters();
  };

  const handleDeclineAndArchive = async () => {
    await updateLetterRequest(letterId, { status: RequestStatus.d, archived: true });
    getLetters();
    handleClose();
  };

  const handleDecline = async () => {
    await updateLetterRequest(letterId, { status: RequestStatus.d });
    getLetters();
    handleClose();
  };

  const onSearch = (event: React.ChangeEvent<HTMLInputElement>) => setSearch(event.target.value);

  const handleNavigateLetterComposition = (letterId: string, mode: string) => {
    if (user?.credit_count < LetterType.recommendation) {
      openInNewTab(ROUTES.SUBSCRIPTION);
    } else {
      navigate(`${ROUTES.WRITE_LETTER}?letterId=${letterId}&mode=${mode}`);
    }
  };

  const handleNavigateDefault = (letterId: string, mode: string) => {
    navigate(`${ROUTES.WRITE_LETTER}?letterId=${letterId}&mode=${mode}`);
  };

  const getLetters = useCallback(async (f?: Record<string, any>) => {
    const requestStartAt = performance.now();
    if (state.mounted) {
      setIsLoading(true);
      const _requests = await getAllLetterRequests(f);
      state.mounted && setIsLoading(false);
      if (!_requests.error && state.mounted) {
        dispatch(setRequests(createRequestsFromLetters(_requests?.results)));
        setSeparateData(_requests);
      } else {
        toast.error("An error occurred during getting request list process");
      }
    }
    const requestEndAt = performance.now();
    const timeSpent = requestEndAt - requestStartAt;
    setRequestTime(timeSpent);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const debounceGetRequests = useCallback(debounce(getLetters, 500), [getLetters]);

  const handleChangeCurrentPage = (p: number) => setPage(p);

  const handleChangePerPage = (perPage: number) => setPerPage(perPage);

  useEffect(() => {
    state.mounted = true;

    return () => {
      state.mounted = false;
    };
  }, []);

  useEffect(() => {
    if (activeTab === RequestTab.all) {
      debounceGetRequests({
        sort_by: "target_due_date",
        page,
        page_size: perPage,
        search,
        ...filter,
      });
      setEmptyText("You don't have any recommendation letters");
    }

    if (activeTab === RequestTab.archive) {
      debounceGetRequests({
        archived: "True",
        search,
        page,
        page_size: perPage,
        ...filter,
      });
      setEmptyText("You have no archived recommendation letters");
    }

    if (activeTab === RequestTab.draft) {
      debounceGetRequests({
        status: RequestStatus.rd,
        search,
        page,
        page_size: perPage,
        ...filter,
      });
      setEmptyText("You have no draft recommendation letters");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter, activeTab, page, perPage, search]);

  useEffect(() => {
    if (page !== 1) {
      setPage(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  useEffect(() => {
    setPage(1);
    setSearch("");
  }, [activeTab]);

  useEffect(() => {
    setIsLoading(true);
  }, [activeTab, page, perPage]);

  useEffect(() => {
    setIsOpen(Boolean(modalName));
  }, [modalName]);

  return (
    <>
      <Modal open={isOpenStudentAccessModal} onClose={handleCloseStudentAccessModal}>
        <Box width="100%" height="100%" display="flex" alignItems="center" justifyContent="center">
          <ConfirmationModal
            onSubmit={handleOpenNewSubscriptionTab}
            onCancel={handleCloseStudentAccessModal}
            title="You don't have enough credits, do you want to open the subscription page to buy?"
            actionName="Buy"
          />
        </Box>
      </Modal>
      <Modal open={isOpen} onClose={() => setIsOpen(false)}>
        <>
          {modalName === "details" && (
            <Box
              width="100%"
              height="100%"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <LetterDetails
                id={letterId}
                onClose={handleClose}
                onDelete={getLetters}
                handleNavigateLetterComposition={() => handleNavigateLetterComposition(letterId, mode)}
                handleNavigateDefault={() => handleNavigateDefault(letterId, mode)}
              />
            </Box>
          )}
          {modalName === "delete" && (
            <ConfirmationModal
              onSubmit={handleConfirmDelete}
              onCancel={handleCancelDelete}
              title="Do you really want to delete this letter request?"
              actionName="Delete"
            />
          )}
          {modalName === "decline" && (
            <DeclineLetterModal
              onClose={handleClose}
              onDecline={handleDecline}
              onArchive={handleDeclineAndArchive}
            />
          )}
        </>
      </Modal>
      <PageTemplate type={accountType}>
        <Box width="100%" height="100%">
          <Box
            display="flex"
            margin="0 auto"
            maxWidth={isSuperLargeDesktop ? 1500 : 1240}
            width="100%"
            height="100%"
            flexDirection="column"
            paddingTop={6}
            paddingX={2}
            boxSizing="border-box"
          >
            <Box
              className={TutorialClasses.eighthTutorialStep}
              display="flex"
              flexDirection={isDesktop ? "row" : "column"}
              gap={isDesktop ? 0 : 2}
              alignItems="center"
              justifyContent="space-between"
            >
              <Box>
                <ToggleButtonGroup
                  style={{ height: 40, marginBottom: 8 }}
                  color="secondary"
                  value={activeTab}
                  exclusive
                  onChange={handleChange}
                >
                  <ToggleButton value={RequestTab.all}>All</ToggleButton>
                  {isProfessor && <ToggleButton value={RequestTab.archive}>Archive</ToggleButton>}
                  {isProfessor && <ToggleButton value={RequestTab.draft}>Drafts</ToggleButton>}
                </ToggleButtonGroup>
              </Box>
              <Box display="flex" gap={2.5} flexDirection={isDesktop ? "row" : "column"}>
                <InputWithLabel
                  id="search"
                  placeholder="Search by name"
                  onChange={onSearch}
                  value={search}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <SearchRounded />
                      </InputAdornment>
                    ),
                  }}
                />

                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={() => navigate(routeByAccountTypeNewLetter)}
                >
                  <Typography
                    variant="text">{accountType === AccountTypeEnum.Applicant ? "Request New Letter" : "Write letter"}</Typography>
                </Button>

                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={() => navigate(routeByAccountTypeDashboard)}
                >
                  <Typography variant="text">View as grid</Typography>
                </Button>

              </Box>
            </Box>
            <Box marginTop={3} flex={1} overflow="auto">
              {isLoading ? (
                <Box
                  width="100%"
                  height="100%"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  py={2}
                >
                  <Loader size={50} />
                </Box>
              ) : (
                <Box display="flex" flexDirection="column" height="100%">
                  <Box display="flex" flex={1} overflow="auto">
                    <CustomTable
                      columns={createTableColumns({
                          onViewDetails,
                          isSuperLargeDesktop,
                          isLargeDesktop,
                          handleOpenStudentAccessModal,
                          allowFilterByStatus: activeTab === RequestTab.all,
                        },
                      )}
                      data={formattedRequests}
                      requestTime={requestTime}
                      OnEmptyComponent={<EmptyTable text={emptyText} />}
                    />
                  </Box>
                  {separateData && (
                    <LuciPagination
                      count={separateData?.total_pages}
                      currentPage={page}
                      rowsPerPage={perPage}
                      onChangeCurrentPage={handleChangeCurrentPage}
                      onChangeRowPerPage={handleChangePerPage}
                      isDesktop={isDesktop}
                    />
                  )}
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </PageTemplate>
    </>
  );
};
