import React from "react";
import { Box, Button, Link, Typography, useMediaQuery } from "@mui/material";
import { CloseOutlined, Download } from "@mui/icons-material";
import TextsmsIcon from "@mui/icons-material/Textsms";
import { FinishedCoverLetterModalProps } from "./types";
import { Recipient } from "../../../views/LetterComposition/Recipient";
import { ROUTES } from "../../../constants/routes";

export const FinishedCoverLetterModal = React.forwardRef((
    {
      onDownload,
      text,
      onClose,
      letterId,
    }: FinishedCoverLetterModalProps,
    ref: React.Ref<any>,
  ) => {
    const isDesktop = useMediaQuery("(min-width:900px)");

    const isLarge = useMediaQuery("(max-height:1000px)");
    const isMedium = useMediaQuery("(max-height:750px)");
    const isSmall = useMediaQuery("(max-height:500px)");

    const changeVertical = () => {
      if (isSmall) return "300px";
      if (isMedium) return "500px";
      if (isLarge) return "700px";
      return "none";
    };

    const handleLeaveFeedback = () => window.open(ROUTES.FEEDBACK);

    return (
      <>
        <Box
          ref={ref}
          bgcolor="white"
          display="flex"
          alignItems="center"
          flexDirection="column"
          maxHeight={changeVertical()}
          maxWidth={1040}
          overflow="auto"
          justifyContent="center"
        >
          <Box
            height={60}
            width="100%"
            display="flex"
            alignItems="center"
            justifyContent="flex-end"
            paddingX={2}
            py={1}
            boxSizing="border-box"
          >
            <Button variant="text" color="secondary" onClick={onClose}>
              <Typography>Close</Typography>
              <CloseOutlined fontSize="small" />
            </Button>
          </Box>
          <Box width="100%" overflow="auto" px={isDesktop ? 7 : 2} boxSizing="border-box">
            <Typography variant="title" fontWeight={500}>
              Letter
            </Typography>
            <Box mb={6} mt={4}>
              <Recipient isCoverLetter={true} letterId={letterId} />
            </Box>
            <Typography mb={10} whiteSpace="pre-wrap">
              {text || "The letter is empty"}
            </Typography>
          </Box>
          <Box
            width="100%"
            borderTop="1px solid #ccc"
            display="flex"
            flexDirection="row"
            paddingX={3}
            py={2}
            boxSizing="border-box"
            bgcolor="white"
            justifyContent="space-between"
          >
            <Box
              display="flex"
              flexDirection="row"
              gap={2}
              px={5}
              py={1}
              width="100%"
              alignItems="center"
              justifyContent="space-between"
            >
              <Link display="flex" color="secondary" onClick={handleLeaveFeedback} alignItems="center">
                <TextsmsIcon />
                Leave feedback
              </Link>
              {text && (
                <Link
                  variant="text"
                  underline="none"
                  color="secondary"
                  onClick={onDownload}
                  alignItems="center"
                  display="flex"
                >
                  <Download />
                  Download TXT
                </Link>
              )}
            </Box>
          </Box>
        </Box>
      </>
    );
  },
);