import React, { useEffect, useState } from "react";
import { Box, Modal, useMediaQuery } from "@mui/material";
import { useSelector } from "react-redux";
import { Recipient } from "../../views/LetterComposition/Recipient";
import { TemperatureSliderComponent } from "./TemperatureSliderComponent";
import { Field } from "./Field/Field";
import { CoverLetter } from "./Field/types";
import { downloadCoverLetterTxt } from "../LetterCompositionConstructor/utils";
import { FinishedCoverLetterModal } from "./FinishedCoverLetterModal";
import { RootState } from "../../redux/reducers";
import { startUsingLuciAssistantCoverLetter } from "./utils";
import { updateCoverLetter } from "../../api/coverLetter";
import { NewCoverLetterCompositionProps } from "./types";

export const NewCoverLetterComposition: React.FC<NewCoverLetterCompositionProps> = ({ id, letterId }) => {
  const [temperatureLevel, setTemperatureLevel] = useState(1);
  const [finishedLetter, setFinishedLetter] = useState<CoverLetter | null>(null);
  const [isShowFinished, setIsShowFinished] = useState(false);
  const [isFinished, setIsFinished] = useState(false);
  const [isGenerating, setIsGenerating] = useState(false);
  const [finishedCoverLetterText, setFinishedCoverLetterText] = useState("");

  const { user } = useSelector((state: RootState) => state.user);
  const isDesktop = useMediaQuery("(min-width:900px)");

  const handleShowFinished = () => setIsShowFinished(true);

  const onFinish = async (letter: CoverLetter) => {
    setFinishedLetter(letter);

    try {
      await updateCoverLetter(id, { text: letter.text });
      setFinishedCoverLetterText(letter.text);
      setIsFinished(true);
      setIsShowFinished(true);
    } catch (error: unknown) {
      console.error(error);
    }
  };

  const withdrawPointsOnGenerate = async () => {
    if (user?.credit_count) {
      const res = await startUsingLuciAssistantCoverLetter(letterId, user);
      if (res?.error) {
        setFinishedLetter(null);
      }
    }
  };

  useEffect(() => {
    withdrawPointsOnGenerate();
  }, [letterId]);

  return (
    <>
      <Modal open={isShowFinished} onClose={() => setIsShowFinished(false)}>
        <Box display="flex" width="100%" height="100%" alignItems="center" justifyContent="center">
          <FinishedCoverLetterModal
            text={finishedCoverLetterText}
            letterId={letterId}
            onClose={() => setIsShowFinished(false)}
            onDownload={() => downloadCoverLetterTxt(finishedCoverLetterText)}
          />
        </Box>
      </Modal>
      <Box
        width="100%"
        height="100%"
        display="flex"
        paddingX={isDesktop ? 8 : 0}
        paddingY={5}
        marginBottom={10}
        boxSizing="border-box"
        flexDirection="column"
      >
        <Box
          width="100%"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          height="8%"
          paddingBottom={6}
        >
          <Recipient letterId={letterId} isCoverLetter={true} />
        </Box>
        <Box display="flex" flexDirection="row" alignItems="center">
          <Field
            text={finishedLetter?.text!}
            isDisabled={isGenerating}
            isEditable={!isGenerating}
            isFinished={isFinished}
            letterId={letterId}
            temperatureLevel={temperatureLevel}
            onFinishLetter={onFinish}
            onShowFinished={handleShowFinished}
            onTextGeneration={(state: boolean) => setIsGenerating(state)}
            temperature={({ generateText }) => (
              <TemperatureSliderComponent
                temperatureLevel={temperatureLevel}
                onChangeLevel={(lvl: number) => setTemperatureLevel(lvl)}
                generateText={generateText}
              />
            )}
          />
        </Box>
      </Box>
    </>
  );
};