import React from "react";
import { Box, List, Typography } from "@mui/material";
import { colors } from "../../../theme";
import { LuciNotification } from "./types";
import { NotificationItem } from "./NotificationItem";

export type NotificationsListProps = {
  listElements?: Array<LuciNotification>;
  onClose?: () => void;
};

export const NotificationsList = ({ listElements, onClose }: NotificationsListProps) => {
  return (
    <>
      {listElements?.length ? (
        <List>
          <Box width="100%" borderBottom={`1px solid ${colors.gray[300]}`} />
          {listElements.map((el: LuciNotification) => (
            <NotificationItem notification={el} key={el.id} onClose={onClose} />
          ))}
        </List>
      ) : (
        <Box py={3} display="flex" justifyContent="center">
          <Typography variant="text">There are no new notifications</Typography>
        </Box>
      )}
    </>
  );
};
