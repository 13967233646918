const LETTER_REQUEST_URL = "/letter-request/"
const COVER_LETTERS_URL = "/cover-letters/";
const NOTIFICATIONS_URL = "/notifications/"

const urls = () => ({
  letterRequest: {
    getRequest: (id?: string) => `${LETTER_REQUEST_URL}${id ? `${id}/` : ""}`,
    updateRequest: (id: string) => `${LETTER_REQUEST_URL}${id}/`,
    createRequest: LETTER_REQUEST_URL,
    getAllRequests: (params?: string) => {
      let url = LETTER_REQUEST_URL;
      if (params?.length) {
        url = url + `?${params}`;
      }
      url = params?.length ? url : url + "/";
      return url;
    },
    withdrawCredits: `/withdraw-points/`,
    addRequestLinks: `/letter-request-links/`,
    addRequestFiles: `/letter-request-files/`,
    replaceRequestFiles: `/letter-request-files/update-files/`,
    replaceRequestLinks: `/letter-request-links/update-links/`,
    deleteFiles: "/letter-request-files/delete-files/",
    sampleRequest: "/sample_request/",
  },

  section: {
    enthusiasm: (letterType: number) => `/enthusiasm/?letter_type=${letterType}`,
    generateText: (letterId: string, index: number, code: string) =>
      `/letter-section/${letterId}/${index}/${code}`,
    accept: (id: string) => `/letter-section/${id}`,
  },

  coverLetter: {
    getCoverLetterQuestions: "/cover_letter_form/",
    addCoverLetter: COVER_LETTERS_URL,
    getCoverLetters: (params?: string) => {
      let url = COVER_LETTERS_URL;
      if (params) {
        url = url + `?${params}`;
      }
      url = params?.length ? url : `${url}/`;
      return url;
    },
    getCoverLetter: (id: string) => `${COVER_LETTERS_URL}${id}/`,
    updateCoverRequest: (id: number) => `${COVER_LETTERS_URL}${id}/`,
    getGeneratedText: (letterId: string, temperature: number) =>
      `/cover_letter_text/${letterId}/${temperature}`,

  },

  registration: {
    acceptInvite: "/accept_invite/",
    signUp: "/signup/",
    signUpByReferral: (referralId: string) => `/signup/?referral_id=${referralId}`,
  },

  profile: {
    updateProfile: (id: string) => `/profiles/${id}/`,
    inviteWriter: "/invite/",
    showDisclaimer: "/disclaimer/",
  },

  notification: {
    getNotifications: NOTIFICATIONS_URL,
    updateById: (id: string) => `${NOTIFICATIONS_URL}${id}/`,
    deleteById: (id: string) => `${NOTIFICATIONS_URL}${id}/`,
    deleteNotifications: "notifications/clear-notifications/",
    deleteReminders: "notifications/clear-reminders/",
    enableNotificationsSettings: (id: number) => `/notification-settings/${id}/`,
    getAllNotificationsSettings: "/notification-settings/",
  },

  stripeURL: {
    getSubscriptionsWithProducts: (stripeId: string) =>
      `/subscriptions?customer=${stripeId}&expand[]=data.plan.product`,
    getProductsWithPrice: "products?expand[]=data.default_price",
    cancelSubscription: (id: string) => `/subscriptions/${id}`,
    updatePaymentInfo: (params: string) => `billing_portal/sessions${params}`,
  },
});

export default urls;
