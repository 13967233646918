import { AccountTypeEnum } from '../../constants';
import { AccountType } from '../../utils/accountTypes';

export type ButtonType = {
  key: string;
  value: AccountTypeEnum;
  title: string;
};

const getTypeButtonTitle = (t: AccountTypeEnum) => {
  switch (t) {
    case AccountTypeEnum.Student: {
      return 'I need to request a letter';
    }

    case AccountTypeEnum.Professor: {
      return 'I need to write a letter';
    }

    default:
      return t;
  }
};

export const createAccountTypeButtons = (accountTypes: AccountType[]): ButtonType[] => {
  return accountTypes.map((t: AccountType) => {
    return {
      key: t.key,
      value: AccountTypeEnum[t.value as keyof typeof AccountTypeEnum],
      title: getTypeButtonTitle(t.value),
    };
  });
};
