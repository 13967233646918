import { email, numberOnly, required, isDate, maxLength } from "../../utils/validation";
import { Question } from "./types";

const validateByType = (
  type: "text" | "date" | "number" | "text-area" | "email" | "select" | undefined = "text",
  _required: boolean | undefined = false,
  answer: string | undefined = "",
): string | boolean => {
  switch (type) {
    case "text": {
      return _required ? required(answer) : true;
    }

    case "email": {
      return _required ? email(answer) : true;
    }

    case "number": {
      return _required ? numberOnly(answer) : true;
    }

    case "date": {
      return _required ? isDate(answer, true) : true;
    }

    case "select": {
      return _required ? required(answer) : true;
    }

    default:
      return _required ? required(answer) : true;
  }
};

export const validateQuestion = (q: Question): string | boolean => {
  const answer = q?.answer || "";
  const validationByType = validateByType(q.type, q.required, answer);
  const validationByLength = q?.max_length ? maxLength(q.max_length)(answer) : true;

  const error = [validationByType, validationByLength]
    .filter((t) => typeof t === "string")
    ?.join(" ");

  return error.length > 0 ? error : true;
};
