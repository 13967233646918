import React, { useCallback, useEffect, useState } from "react";
import { Box, Button, Modal, Typography, useMediaQuery } from "@mui/material";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { CloseOutlined } from "@mui/icons-material";
import clone from "just-clone";
import toast from "react-hot-toast";
import Questionnaire from "../Questionnaire";
import Loader from "../Loader";
import { ControlMenuStudent } from "./controlMenuStudent";
import { ControlMenuProfessor } from "./controlMenuProfessor";
import { LetterDetailsProps } from "./types";
import { Question } from "../Questionnaire/types";
import { getType } from "../../api/localStorage/type";
import { AccountTypeEnum } from "../../constants";
import {
  getFilesToRemove,
  getLeftFiles,
  prepareFiles,
  prepareLinks,
  prepareQuestions,
} from "../../views/NewLetter/utils";
import {
  addFilesToRequest,
  deleteLetterRequest,
  getLetterRequest,
  removeFiles,
  replaceLinks,
  updateLetterRequest,
} from "../../api/letterRequest";
import { ROUTES } from "../../constants/routes";
import { createRequestsFromLetters } from "../../views/LetterStatus/utils";
import { RootState } from "../../redux/reducers";
import { deleteRequest, setEditRequest, setLetterTemplate, updateRequest } from "../../redux/actionCreators";
import { colors } from "../../theme";
import { RequestMoreInfoModal } from "./components/RequestMoreInfoModal";
import { RequestStatus } from "../../views/LetterStatus/types";
import { LetterRequestDocument } from "../FileUploadWithProgress/types";
import { AdditionalInformation } from "../../views/NewLetter/AdditionalInformation";
import { LetterCompositionModal } from "../LetterCompositionModal/LetterCompositionModal";
import { formatDate } from "../QuestionFIeld/utils";
import { getGender } from "../../utils/switches";
import { ConfirmationModal } from "../ConfirmationModal";
import { checkIsValidForWriteLetter } from "../../utils/checkIsValid";

type ModalType = "delete" | "moreInfo" | "archive" | null;

export const LetterDetails = React.forwardRef(
  (
    {
      id,
      onClose,
      onSave,
      onCancelSave,
      onEdit,
      onDelete,
      isShowOptionsPanel = true,
      handleNavigateLetterComposition,
      handleNavigateDefault,
    }: LetterDetailsProps,
    ref?: React.Ref<any>,
  ) => {
    const [showModal, setShowModal] = useState(false);
    const [modal, setModal] = useState<ModalType>(null);
    const [questions, setQuestions] = useState<Array<Question> | []>([]);
    const [tempQuestions, setTempQuestions] = useState<Array<Question> | []>([]);
    const [request, setRequest] = useState<any>(null);
    const [loading, setLoading] = useState(false);
    const [documents, setDocuments] = useState<Array<LetterRequestDocument>>([]);
    const [isOpenLetterCompositionModal, setIsOpenLetterCompositionModal] = useState(false);
    const [destination, setDestination] = useState("");
    const [isValid, setIsValid] = useState(false);
    const [isOpenUnsavedDescriptionModal, setIsOpenUnsavedDescriptionModal] = useState(false);
    const [isUnsavedDescription, setIsUnsavedDescription] = useState<boolean | null>(false);

    const { editable } = useSelector((state: RootState) => state.requests);

    const accountType = getType();
    const navigate = useNavigate();
    const isDesktop = useMediaQuery("(min-width:900px)");
    const dispatch = useDispatch();

    const handleSetIsValidProp = (isValid: boolean) => {
      setIsValid(isValid);
    };

    const handleSetIsUnsavedDescription = (isSaved: boolean | null) => {
      setIsUnsavedDescription(isSaved);
    };

    const handleOpenUnsavedDescriptionModal = () => setIsOpenUnsavedDescriptionModal(true);
    const handleCloseUnsavedDescriptionModal = () => setIsOpenUnsavedDescriptionModal(false);

    const handleOpenLetterCompositionModal = () => setIsOpenLetterCompositionModal(true);
    const handleCloseLetterCompositionModal = () => setIsOpenLetterCompositionModal(false);

    const handleNavigateToWriteLetter = (customDestination?: string) => {
      navigate(`${ROUTES.WRITE_LETTER}?letterId=${id}&mode=${customDestination && destination}`);
    };

    const handleChangeQuestion = (qId: string, answer: string) => {
      const questionsCopy: Question[] | [] = clone(tempQuestions);
      const _current = questionsCopy?.find((q: Question) => q.id === qId);
      if (_current) {
        _current.answer = answer;
      }

      setTempQuestions(questionsCopy);
    };

    const handleStartEdit = () => {
      dispatch(setEditRequest(true));
      onEdit && onEdit();
    };

    const handleRequestSimilar = () => {
      dispatch(setLetterTemplate({ template: questions, letterTypeId: request?.letter_type_id }));
      navigate(ROUTES.NEW_LETTER);
    };

    const handleCancelEdit = () => {
      dispatch(setEditRequest(false));
      setTempQuestions(clone(questions));
      const _links = prepareLinks(clone(request?.links));
      const _files = prepareFiles(clone(request?.files));
      onCancelSave && onCancelSave();
      setDocuments([..._links, ..._files]);
    };

    const handleConfirmEdit = async () => {
      handleCloseUnsavedDescriptionModal();
      const questionsCopy: Question[] | [] = clone(tempQuestions);
      const dateCopy = questionsCopy.find((q: Question) => q.id === "target_due_date");
      const genderCopy = questionsCopy.find((q: Question) => q.id === "student_gender");
      const updatedDueDate = { ...dateCopy, answer: formatDate(new Date(dateCopy?.answer!)) };
      const gender = getGender(genderCopy?.answer);
      const updatedGender = { ...genderCopy, answer: gender };
      const updatedIndexDate = questionsCopy.findIndex((el: Question) => el.id === "target_due_date");
      const updateIndexGender = questionsCopy.findIndex((el: Question) => el.id === "student_gender");
      questionsCopy[updatedIndexDate] = updatedDueDate as Question;
      questionsCopy[updateIndexGender] = updatedGender as Question;
      dispatch(setEditRequest(false));
      const newLinks = documents.filter((d: LetterRequestDocument) => d.type === "link");
      const newFiles = documents.filter((d: LetterRequestDocument) => d.type === "file" && !d.fileUrl);
      const filesToRemove = getFilesToRemove(request.files, documents);
      const leftFiles = getLeftFiles(request.files, documents);
      const _request = await updateLetterRequest(id, { questions: questionsCopy });
      const replacedLinks = await replaceLinks(_request.id, newLinks);
      await removeFiles(filesToRemove.map((f: Record<string, any>) => f.id));
      const replacedFiles = newFiles.length ? await addFilesToRequest(_request.id, newFiles) : [];
      if (!_request.error && !replacedLinks.error) {
        const requestCard = createRequestsFromLetters([_request]);
        dispatch(updateRequest(requestCard[0]));
        onSave && onSave(tempQuestions);
        const _links = replacedLinks.length ? prepareLinks(replacedLinks) : [];
        const _files = replacedFiles.length
          ? prepareFiles([...replacedFiles, ...leftFiles])
          : prepareFiles(leftFiles);
        setDocuments([..._links, ..._files]);
        await getDetails(true);
        toast.success("Request was successfully updated");
      } else {
        toast.error("An error occurred during update request process");
      }
    };

    const handleArchive = async (letterId: string) => {
      const _request = await updateLetterRequest(letterId, { archived: true });
      if (!_request.error) {
        dispatch(deleteRequest(letterId));
        toast.success("Request was successfully archived");
      } else {
        toast.error("An error occurred during letter archive process");
      }
      onClose && onClose();
    };

    const handleConfirmDelete = async () => {
      const deleted = id && (await deleteLetterRequest(id));
      if (!deleted.error) {
        onDelete && (await onDelete());
        onClose && onClose();
        setModal(null);
        toast.success("Request was successfully deleted");
      } else {
        toast.error("An error occurred during delete request process");
      }
    };

    const getDetails = useCallback(async (disableLoader: boolean) => {
      if (!disableLoader) {
        setLoading(true);
      }
      const request = await getLetterRequest(id);
      if (request?.questions) {
        const _questions = prepareQuestions(request?.questions);
        const _links = prepareLinks(clone(request?.links));
        const _files = prepareFiles(clone(request?.files));
        const documents = [..._links, ..._files];
        setQuestions(_questions);
        setDocuments(documents);
        setRequest(request);
      } else {
        toast.error("An error occurred during getting request details process");
      }
      setLoading(false);
    }, [id]);

    const closeModal = () => setModal(null);

    const handleRequestMoreInfo = () => setModal("moreInfo");

    const handleSubmitMoreInfo = async (text: string, type: string | null) => {
      const body =
        type === AccountTypeEnum.Writer
          ? {
            more_info_request: text,
            status: RequestStatus.mi,
          }
          : {
            more_info_response: text,
            status: RequestStatus.ru,
          };
      const _request = await updateLetterRequest(id, body);
      if (!_request.error) {
        const requestCard = createRequestsFromLetters([_request]);
        dispatch(updateRequest(requestCard[0]));
        toast.success("Request was successfully updated");
      } else {
        toast.error("An error occurred during requesting more information process");
      }
    };

    useEffect(() => {
      setTempQuestions(clone(questions));
    }, [questions]);

    useEffect(() => {
      if (id) {
        getDetails(false);
      }
    }, [id, getDetails]);

    useEffect(() => {
      setShowModal(modal !== null);
    }, [modal]);

    useEffect(() => {
      if (request) {
        const destination = request.credit_used || request.student_access ? "constructor" : "default";
        setDestination(destination);
      }
    }, [request]);

    useEffect(() => {
      if (accountType === AccountTypeEnum.Applicant && request?.student_access) {
        handleCloseLetterCompositionModal();
        handleNavigateToWriteLetter("constructor");
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpenLetterCompositionModal]);

    return (
      <>
        <Modal open={isOpenUnsavedDescriptionModal} onClose={handleCloseUnsavedDescriptionModal}>
          <Box width="100%" height="100%" display="flex" alignItems="center" justifyContent="center">
            <ConfirmationModal
              onSubmit={handleConfirmEdit}
              onCancel={handleCloseUnsavedDescriptionModal}
              title="Do you really want to confirm? Your description data won't be saved"
              actionName="Confirm"
            />
          </Box>
        </Modal>
        <Modal open={isOpenLetterCompositionModal} onClose={handleCloseLetterCompositionModal}>
          <Box width="100%" height="100%" display="flex" alignItems="center" justifyContent="center">
            <LetterCompositionModal
              handleChangePageMode={handleNavigateLetterComposition!}
              handleNavigateDefault={handleNavigateDefault}
              onClose={handleCloseLetterCompositionModal}
              letterType="recommendation"
            />
          </Box>
        </Modal>
        {!loading ? (
          <Box
            ref={ref}
            bgcolor="white"
            display="flex"
            flexDirection="column"
            maxWidth={1040}
            maxHeight="100%"
            overflow="auto"
            justifyContent="center"
          >
            <Modal open={showModal} onClose={closeModal}>
              <>
                {modal === "delete" && (
                  <ConfirmationModal
                    onSubmit={handleConfirmDelete}
                    onCancel={closeModal}
                    title="Do you really want to delete this letter request?"
                    actionName="Delete"
                  />
                )}
                {modal === "archive" && (
                  <ConfirmationModal
                    onSubmit={() => handleArchive(request.letter_id)}
                    onCancel={closeModal}
                    title="Do you really want to archive this letter request?"
                    actionName="Archive"
                  />
                )}
                {modal === "moreInfo" && (
                  <RequestMoreInfoModal
                    request={request}
                    onCancel={closeModal}
                    onSubmit={handleSubmitMoreInfo}
                  />
                )}
              </>
            </Modal>
            <Box
              height={60}
              width="100%"
              display="flex"
              alignItems="center"
              justifyContent="flex-end"
              paddingX={2}
              py={1}
              boxSizing="border-box"
            >
              <Button variant="text" color="secondary" onClick={onClose}>
                <Typography>Close</Typography>
                <CloseOutlined fontSize="small" />
              </Button>
            </Box>
            {questions.length > 0 && (
              <>
                <Box
                  width="100%"
                  overflow="auto"
                  sx={{ overflowX: "hidden" }}
                  px={isDesktop ? 7 : 2}
                  boxSizing="border-box"
                >
                  <Box
                    px={3}
                    paddingBottom={1}
                    mb={4}
                    borderBottom={`1px solid ${colors.gray[100]}`}
                  >
                    <Typography variant="title" fontWeight={700}>
                      Request Information
                    </Typography>
                  </Box>
                  <Questionnaire
                    onChangeQuestion={handleChangeQuestion}
                    questions={tempQuestions}
                    editable={editable}
                    isValidProp={isValid}
                    handleSetIsValidProp={handleSetIsValidProp}
                  />
                  {(documents?.length > 0 || editable) && (
                    <AdditionalInformation
                      letterDocuments={documents}
                      editable={editable}
                      onUpdateDocuments={(d) => {
                        setDocuments(d);
                      }}
                      handleSetIsUnsavedChanges={handleSetIsUnsavedDescription}
                    />
                  )}
                </Box>
                {isShowOptionsPanel && (
                  <Box
                    width="100%"
                    borderTop="1px solid #ccc"
                    display="flex"
                    flexDirection="row"
                    paddingX={3}
                    py={2}
                    boxSizing="border-box"
                    bgcolor={editable ? colors.secondary : "white"}
                    justifyContent="flex-start"
                    gap={2}
                  >
                    {editable ? (
                      <Box
                        display="flex"
                        flexDirection="row"
                        gap={2}
                        width="100%"
                        alignItems="center"
                        justifyContent="center"
                      >
                        <Button variant="text" color="whiteTextButton" onClick={handleCancelEdit}>
                          Cancel
                        </Button>
                        <Button
                          variant="contained"
                          color="blackButton"
                          disabled={!isValid}
                          onClick={isUnsavedDescription ? handleOpenUnsavedDescriptionModal : handleConfirmEdit}
                        >
                          Confirm Edit
                        </Button>
                      </Box>
                    ) : accountType === AccountTypeEnum.Applicant ? (
                      <ControlMenuStudent
                        onDelete={() => setModal("delete")}
                        onEdit={handleStartEdit}
                        onRequestSimilar={handleRequestSimilar}
                        onWriteLetter={checkIsValidForWriteLetter(request?.status) ?
                          handleOpenLetterCompositionModal :
                          handleNavigateToWriteLetter
                        }
                        request={request}
                        onRequestMoreInfo={handleRequestMoreInfo}
                      />
                    ) : (
                      <ControlMenuProfessor
                        request={request}
                        onWriteLetter={checkIsValidForWriteLetter(request?.status) ?
                          handleOpenLetterCompositionModal :
                          handleNavigateToWriteLetter
                        }
                        onArchive={() => setModal("archive")}
                        onRequestMoreInfo={handleRequestMoreInfo}
                        onEdit={handleStartEdit}
                        onDelete={() => setModal("delete")}
                      />
                    )}
                  </Box>
                )}
              </>
            )}
            {questions.length === 0 && (
              <Box px={5} py={4}>
                <Typography>No data was loaded please try again letter</Typography>
              </Box>
            )}
          </Box>
        ) : (
          <Loader size={100} />
        )}
      </>
    );
  },
);
