import React, { useEffect, useState } from "react";
import { Avatar, Box, IconButton, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import MenuIcon from "@mui/icons-material/Menu";
import { RootState } from "../../redux/reducers";
import { colors } from "../../theme";
import { HeaderMenuPopover } from "./HeaderMenuPopover";
import Loader from "../Loader";
import { TutorialClasses } from "../Tutorial/tutorialClasses";
import { getType } from "../../api/localStorage/type";
import { AccountTypeEnum } from "../../constants";

type AccountMenuProps = {
  handleMyAccount: () => void;
  handleFeedback: () => void;
  handleStartTutorial: () => void;
  handleSignOut: () => void;
  isShowAvatarLoader: boolean;
};

export const AccountMenu: React.FC<AccountMenuProps> = ({
   handleMyAccount,
   handleFeedback,
   handleStartTutorial,
   handleSignOut,
   isShowAvatarLoader
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);
  const accountType = getType();

  const { user } = useSelector((state: RootState) => state.user);
  const { tourActive } = useSelector((state: RootState) => state.tutorial);

  const createShortName = (user: { first_name: string; last_name: string }): string => {
    return user
      ? (user?.first_name[0]?.toUpperCase() || "") + (user?.last_name[0]?.toUpperCase() || "")
      : "NA";
  };

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if(tourActive) {
      handleClose();
    }
  }, [tourActive])

  return (
    <Box
      className={accountType === AccountTypeEnum.Applicant ? TutorialClasses.sixthTutorialStep : TutorialClasses.fifthTutorialStep}
      bgcolor={open ? colors.gray[100] : "none"}
      borderRadius={30}
      p={0.6}
    >
      <Box display="flex" gap={1} alignItems="center">
        <Avatar
          src={user?.avatar}
          sx={{
            width: 35,
            height: 35,
            cursor: "pointer",
          }}
          onClick={handleMyAccount}
        >
          {isShowAvatarLoader ? <Loader size={20} speedMs={100} /> : !user?.user &&
            <Typography>{createShortName(user?.user)}</Typography>}
        </Avatar>
        <IconButton onClick={handleOpen}>
          <MenuIcon />
        </IconButton>
        {open && (
          <HeaderMenuPopover
            open={open}
            anchor={anchorEl}
            onClose={handleClose}
            handleMyAccount={handleMyAccount}
            handleStartTutorial={handleStartTutorial}
            handleFeedback={handleFeedback}
            handleSignOut={handleSignOut}
          />
        )}
      </Box>
    </Box>
  );
};
