import React, { useEffect, useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import { Mark } from "@mui/base";
import ChangeCircleOutlinedIcon from "@mui/icons-material/ChangeCircleOutlined";
import { EnthusiasmSlider } from "./utils";
import { EnthusiasmLevel, SliderProps } from "./types";
import { TutorialClasses } from "../Tutorial/tutorialClasses";

export const EnthusiasmSliderComponent: React.FC<SliderProps> = ({
  levels,
  onChangeLevel,
  enthusiasm,
  generateText,
}) => {
  const [marks, setMarks] = useState<Array<Mark>>([]);
  const [angle, setAngle] = useState(0);

  const onGenerate = () => {
    setAngle(angle + 180);
    generateText();
  };

  const handleChange = (d: any) => {
    const selected = d.target.value;
    if (selected !== enthusiasm?.id) {
      const lvl = levels.find((l: EnthusiasmLevel) => l.id === selected);
      lvl && onChangeLevel(lvl);
    }
  };

  useEffect(() => {
    if (levels && !marks.length) {
      const m = levels?.map((l: EnthusiasmLevel) => ({ label: l.display, value: l.id } as Mark));
      setMarks(m);
    }
  }, [levels]);

  return (
    <Box
      className={TutorialClasses.eleventhTutorialStep}
      ml={8}
      mr={5}
      display="flex"
      flexDirection="column"
      alignItems="center"
      gap={2}
    >
      <Typography variant="text">Enthusiasm level</Typography>
      <Box width={250}>
        <EnthusiasmSlider
          min={marks[0]?.value}
          max={marks[marks?.length - 1]?.value}
          track={false}
          value={enthusiasm?.id ? enthusiasm.id : 0}
          onChange={handleChange}
          defaultValue={enthusiasm?.id}
          marks={marks}
        />
      </Box>
      <Button
        size="large"
        variant="outlined"
        color="secondary"
        startIcon={
          <ChangeCircleOutlinedIcon
            style={{ transform: `rotate(${angle}deg)`, transition: "all 0.4s linear" }}
          />
        }
        onClick={onGenerate}
      >
        <Typography variant="text">Generate text</Typography>
      </Button>
    </Box>
  );
};
