import { AccountType } from "../../utils/accountTypes";
import { RESET, SET_ACCOUNT_TYPES, SET_CREDIT_COUNT, SET_USER } from "../actionTypes";

export type User = {
  user: null | Record<string, any>;
  accountTypes: [] | AccountType[];
};

const initialState: User = {
  user: null,
  accountTypes: [],
};

const user = (state = initialState, action: { type: string; payload: any }) => {
  switch (action.type) {
    case SET_ACCOUNT_TYPES: {
      return { ...state, accountTypes: action.payload };
    }
    case SET_USER: {
      return { ...state, user: action.payload };
    }
    case SET_CREDIT_COUNT: {
      return { ...state, user: { ...user, credit_count: action.payload } };
    }
    case RESET:
      return initialState;
    default:
      return state;
  }
};

export default user;
