import React from "react";
import { Box, Button, Link, Typography, useMediaQuery } from "@mui/material";
import { FinishedLetterModalProps } from "../types";
import { CloseOutlined, Download } from "@mui/icons-material";
import EditIcon from '@mui/icons-material/Edit';
import TextsmsIcon from '@mui/icons-material/Textsms';
import { Recipient } from "../../../views/LetterComposition/Recipient";
import { ROUTES } from "../../../constants/routes";
import { openInNewTab } from "../../../utils/openInNewTab";

export const FinishedLetterModal = React.forwardRef(
  (
    { onEdit, onDownload, paragraphs, onClose, letterId }: FinishedLetterModalProps,
    ref: React.Ref<any>,
  ) => {
    const isDesktop = useMediaQuery("(min-width:900px)");

    const isLarge = useMediaQuery("(max-height:1000px)");
    const isMedium = useMediaQuery("(max-height:750px)");
    const isSmall = useMediaQuery("(max-height:500px)");

    const changeVertical = () => {
      if (isSmall) return "300px";
      if (isMedium) return "500px";
      if (isLarge) return "700px";
      return "none";
    };

    const handleLeaveFeedback = () => window.open(ROUTES.FEEDBACK);

    return (
      <>
        <Box
          ref={ref}
          bgcolor={"white"}
          display={"flex"}
          alignItems="center"
          flexDirection="column"
          maxHeight={changeVertical()}
          maxWidth={1040}
          overflow="auto"
          justifyContent="center"
        >
          <Box
            height={60}
            width="100%"
            display="flex"
            alignItems={"center"}
            justifyContent="flex-end"
            paddingX={2}
            py={1}
            boxSizing="border-box"
          >
            <Button variant="text" color="secondary" onClick={onClose}>
              <Typography>Close</Typography>
              <CloseOutlined fontSize={"small"} />
            </Button>
          </Box>
          <Box width="100%" overflow={"auto"} px={isDesktop ? 7 : 2} boxSizing="border-box">
            <Box mb={6} mt={4}>
              <Recipient title="Letter for:" letterId={letterId} />
            </Box>
            <Box mb={10}>
              {paragraphs?.map((p, index) => {
                return p.text ? (
                  <Typography key={"paragraph_" + index} mb={2}>
                    {p.text}
                  </Typography>
                ) : null;
              })}
              {paragraphs.length === 0 && (
                <Typography mb={2}>
                  The letter is empty please return to edit mode to add paragraphs
                </Typography>
              )}
            </Box>
          </Box>
          <Box
            width="100%"
            borderTop="1px solid #ccc"
            display="flex"
            flexDirection="row"
            paddingX={3}
            py={2}
            boxSizing="border-box"
            bgcolor="white"
            justifyContent="space-between"
          >
            <Box
              display="flex"
              flexDirection="row"
              gap={2}
              px={5}
              py={1}
              width="100%"
              alignItems="center"
              justifyContent="space-between"
            >
              <Link display="flex" color="secondary" onClick={onClose} alignItems="center">
                <EditIcon />
                Edit Letter
              </Link>
              <Box display="flex" flexDirection="row" gap={2}>
                <Link display="flex" color="secondary" onClick={handleLeaveFeedback} alignItems="center">
                  <TextsmsIcon />
                  Leave feedback
                </Link>
                {paragraphs.length !== 0 && (
                  <Link
                    variant="text"
                    underline="none"
                    color="secondary"
                    onClick={onDownload}
                    alignItems="center"
                    display="flex"
                  >
                    <Download />
                    Download TXT
                  </Link>
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      </>
    );
  },
);
