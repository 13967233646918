import React, { useEffect, useRef, useState } from "react";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Box,
  IconButton,
  InputAdornment,
  InputLabel,
  LinearProgress,
  OutlinedInput,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Hint } from "./Hint";
import { HintSize, InputWithLabelProps } from "./types";
import { calculateHintSize, defaultHintSize } from "./utils";

export const InputWithLabel = React.forwardRef(
  (
    {
      label,
      placeholder,
      id,
      name,
      errorMessage,
      value,
      onChange,
      type = "text",
      RightLabelComponent,
      wrapperStyle,
      onFocus,
      onBlur,
      passwordHint,
      loading,
      ...inputProps
    }: InputWithLabelProps,
    ref?: React.Ref<any>,
  ) => {
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [hintVisible, setHintVisible] = useState(false);
    const [hintSize, setHintSize] = useState<HintSize>(defaultHintSize);
    const componentRef = useRef<HTMLDivElement>(null);
    const isDesktop = useMediaQuery("(min-width:900px)");

    const handleFocus = (
      event: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement, Element>,
    ) => {
      onFocus && onFocus(event);
      setHintVisible(true);
    };

    const handleBlur = (
      event: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement, Element>,
    ) => {
      onBlur && onBlur(event);
      setHintVisible(false);
    };

    useEffect(() => {
      if (componentRef && componentRef?.current) {
        const size = calculateHintSize(isDesktop, componentRef.current);
        setHintSize(size);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [componentRef.current, hintVisible]);

    return (
      <Box ref={componentRef} {...wrapperStyle} position="relative">
        {passwordHint && (
          <Hint
            width={hintSize.width}
            height={hintSize.height}
            inputHeight={hintSize.inputHeight}
            top={hintSize.top}
            left={hintSize.left}
            visible={hintVisible}
          >
            {passwordHint}
          </Hint>
        )}
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <InputLabel shrink htmlFor={id}>
            <Typography variant="body1" fontWeight={600}>
              {label}
            </Typography>
          </InputLabel>
          {RightLabelComponent}
        </Box>
        {loading && (
          <Box width="100%">
            <LinearProgress color="secondary" />
          </Box>
        )}
        {type === "password" ? (
          <OutlinedInput
            inputRef={ref}
            error={Boolean(errorMessage)}
            {...inputProps}
            onChange={onChange}
            fullWidth
            disabled={loading}
            onBlur={handleBlur}
            onFocus={handleFocus}
            id={id}
            value={value}
            size="small"
            placeholder="Password"
            type={passwordVisible ? "text" : "password"}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => {
                    setPasswordVisible(!passwordVisible);
                  }}
                  edge="end"
                >
                  {passwordVisible ? (
                    <Visibility color={errorMessage ? "error" : "secondary"} />
                  ) : (
                    <VisibilityOff color={errorMessage ? "error" : "secondary"} />
                  )}
                </IconButton>
              </InputAdornment>
            }
          />
        ) : (
          <TextField
            disabled={loading}
            {...inputProps}
            error={Boolean(errorMessage)}
            onBlur={handleBlur}
            onFocus={handleFocus}
            value={value}
            inputRef={ref}
            onChange={onChange}
            fullWidth
            id={id}
            size="small"
            variant="outlined"
            placeholder={placeholder}
          />
        )}
        {errorMessage && (
          <Box mt={1} maxWidth={300}>
            <Typography variant="error">{errorMessage}</Typography>
          </Box>
        )}
      </Box>
    );
  },
);
