import React, { ChangeEvent } from "react";
import { Button, Pagination, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { colors } from "../../../theme";

type LuciPaginationProps = {
  count: number;
  currentPage: number;
  onChangeCurrentPage: (p: number) => void;
  rowsPerPage: number;
  onChangeRowPerPage: (r: number) => void;
  isShadow?: boolean,
  isDesktop?: boolean,
};

const perPage = [5, 10, 20, 50];

export const LuciPagination = ({
  count,
  currentPage,
  onChangeCurrentPage,
  rowsPerPage,
  onChangeRowPerPage,
  isShadow = true,
  isDesktop,
}: LuciPaginationProps) => <Box
  width="100%"
  pb={8}
  pt={3}
  boxShadow={isShadow ? `0px 0px 10px 5px ${colors.gray[100]}` : "none"}
  display="flex"
  flexDirection="row"
  alignItems="baseline"
  justifyContent={isDesktop ? "space-between" : "center"}
>
  <Pagination
    count={count}
    page={currentPage}
    color="secondary"
    shape="rounded"
    onChange={(event: ChangeEvent<unknown>, page: number) => {
      onChangeCurrentPage(page);
    }}
  />
  {isDesktop && <Box display="flex" flexDirection="row" alignItems="center" gap={1.25}>
    <Typography color={colors.textGray} variant="text">
      Show on screen
    </Typography>
    <Box gap={1} display="flex" flexDirection="row" alignItems="center">
      {perPage.map((p: number) => (
        <Button
          key={p}
          onClick={() => onChangeRowPerPage(p)}
          size="small"
          color="secondary"
          variant="contained"
          sx={{
            paddingRight: 1,
            minWidth: 0,
            height: 20,
            paddingLeft: 1,
            background: p === rowsPerPage ? colors.secondary : colors.gray[50],
          }}
        >
          <Typography
            variant="smallText"
            color={p === rowsPerPage ? colors.white : colors.black}
          >
            {p}
          </Typography>
        </Button>
      ))}
    </Box>
  </Box>}
</Box>;
