import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import { colors } from '../../theme';
import { LetterType } from '../../views/NewLetter/SelectLetterType';

type LetterTypeProps = {
  type: LetterType;
  onSelect: (t: LetterType) => void;
  selected?: boolean;
};

export const LetterTypeItem = ({ type, selected, onSelect }: LetterTypeProps) => {
  const { name, description, enabled } = type;
  return (
    <Box
      minHeight={220}
      maxWidth={500}
      boxSizing="border-box"
      border={`1px solid ${selected ? colors.secondary : colors.gray[300]}`}
      display="flex"
      borderRadius={1}
      padding={4}
      flexDirection="column"
    >
      <Typography variant={enabled ? 'text' : 'textDisabled'} mb={1}>
        {name}
      </Typography>
      <Typography variant={enabled ? 'title' : 'titleDisabled'} fontWeight={500} flex={1}>
        {description}
      </Typography>
      <Box width={130} mt={1}>
        <Button
          fullWidth
          onClick={() => onSelect(type)}
          variant="contained"
          color="secondary"
          disabled={!enabled}
        >
          {enabled ? 'Select' : 'Soon'}
        </Button>
      </Box>
    </Box>
  );
};
