import React from 'react';
import { TextareaAutosize, TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { colors } from '../../theme';
import { Question } from '../Questionnaire/types';

type QuestionSentenceProps = {
  question: Question;
  subQuestion: Question;
  onChange?: (q: Question, value: string) => void;
  editable?: boolean;
};

const SubQuestion = ({ question, subQuestion, editable, onChange }: QuestionSentenceProps) => {
  const handleChangeSentence = (e: any) => {};

  return (
    <Box display={'flex'} flex={1} flexDirection="column" gap={1} marginBottom={1}>
      <Typography variant="body1" fontSize={14} color={colors.gray[600]}>
        {subQuestion.question}
        {question?.required && editable && (
          <Typography component="span" color={colors.error}>
            *
          </Typography>
        )}{' '}
      </Typography>
      {!editable ? (
        <Typography paddingLeft={2}>{subQuestion?.answer || ''}</Typography>
      ) : subQuestion.type === 'text-area' ? (
        <TextareaAutosize
          disabled={!editable}
          onChange={handleChangeSentence}
          minRows={8}
          maxRows={15}
          placeholder={subQuestion?.placeholder || ''}
          style={{ width: '100%' }}
          value={subQuestion?.answer || ''}
        />
      ) : (
        <TextField
          disabled={!editable}
          onChange={handleChangeSentence}
          placeholder={subQuestion?.placeholder || ''}
          variant="standard"
          color="secondary"
          type={subQuestion.type}
          value={subQuestion?.answer || ''}
        />
      )}
    </Box>
  );
};

export default SubQuestion;
