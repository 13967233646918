import { Step } from 'react-joyride';
import {
  COMPLETELY_STOP_TUTORIAL,
  CONTINUE_TUTORIAL,
  SET_STEP_INDEX,
  SET_STEPS,
  START_TUTORIAL,
  STOP_TUTORIAL,
} from "../actionTypes";

export type Tour = {
  run: boolean;
  stepIndex: number;
  steps: Step[];
  tourActive: boolean;
}

const initialState: Tour = {
  run: false,
  stepIndex: 0,
  steps: [],
  tourActive: false,
};

const tutorial = (state: Tour = initialState, action: { type: string; payload: unknown }) => {
  switch (action.type) {
    case START_TUTORIAL: {
      return { ...state, run: true, tourActive: true }
    }

    case CONTINUE_TUTORIAL: {
      return { ...state, run: true, stepIndex: ++state.stepIndex }
    }

    case STOP_TUTORIAL: {
      return { ...state, run: false }
    }

    case COMPLETELY_STOP_TUTORIAL: {
      return { ...state, run: false, tourActive: false, stepIndex: 0 }
    }

    case SET_STEPS: {
      return { ...state, steps: action.payload }
    }

    case SET_STEP_INDEX: {
      return { ...state, stepIndex: action.payload }
    }

    default:
      return state
  }
}

export default tutorial;

