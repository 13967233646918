import { Button, Modal, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";

type FromMobileProps = {
  isOpen: boolean;
  onClose: () => void;
};

export const FromMobile = ({ isOpen, onClose }: FromMobileProps) => {
  return (
    <Modal open={isOpen} onClose={onClose}>
      <Box width="100%" height="100%" display="flex" alignItems="center" justifyContent="center">
        <Box
          maxWidth={600}
          bgcolor="white"
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          paddingX={7}
          paddingY={9}
        >
          <Typography variant="h6" mb={2}>
            Letters is best operated in a desktop environment. Some features may not be
            available on smaller screens
          </Typography>
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="center"
            gap={3}
          >
            <Button onClick={onClose} color="secondary" variant="contained">
              Confirm
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};
