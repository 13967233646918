export const HEADER_HEIGHT = 60;
export const BASE_URL = process.env.REACT_APP_LUCI_API_URL;
export const LETTER_REQUEST_CARD = { width: 230, height: 300 };
export const COVER_LETTER_REQUEST_CARD = { width: 400, height: 260 };
export const STRIPE_URL = "https://api.stripe.com/v1/";

export enum AccountTypeEnum {
  Student = "Student",
  Professor = "Professor",
  Writer = "writer",
  Applicant = "applicant",
}
