
import { Rule, Strength, ValidationRules } from './types';

export const rules: Array<Rule> = [
  {
    key: 'length',
    title: 'At least 10 characters',
  },
  {
    key: 'lowerCase',
    title: 'At least one lowercase letter (a-z)',
  },
  {
    key: 'upperCase',
    title: 'At least one uppercase letter (A-Z)',
  },
  {
    key: 'number',
    title: 'At least one number (0-9)',
  },
  {
    key: 'specialCharacter',
    title: 'At least one special character',
  },
];

export const initialStrength: Strength = { value: 33.3, state: 'Weak', color: 'error' };

export const validatePassword = (value: string): ValidationRules => {
  return {
    length: value.length >= 10,
    lowerCase: value.search(/[a-z]/) !== -1,
    upperCase: value.search(/[A-Z]/) !== -1,
    number: value.search(/[0-9]/) !== -1,
    // eslint-disable-next-line no-useless-escape
    specialCharacter: value.search(/[!"`'#%&,:;<>=@{}~\$\(\)\*\+\/\\\?\[\]\^\|]+/) !== -1,
  };
};

export const calculateStrength = (rules: ValidationRules): Strength => {
  const strength = Object.values(rules).filter(s => s).length;
  if (strength < 3) {
    return initialStrength;
  } else if (strength >= 3 && strength < 5) {
    return { value: 66.6, state: 'Medium', color: 'warning' };
  } else {
    return { value: 100, state: 'Strong', color: 'success' };
  }
};
