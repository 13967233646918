import React from "react";
import { Box, Typography } from "@mui/material";
import { colors } from "../../theme";
import { CoverLetterCardRowProps } from "./types";

export const CoverLetterCardRow: React.FC<CoverLetterCardRowProps> =
  (
    { title, text },
  ) => <Box
    display="flex" flexDirection="row" alignItems="center" gap={1}>
    <Typography variant="text" color={colors.gray[400]}>{title} </Typography>
    <Typography variant="text">{text}</Typography>
  </Box>;