import React, { useState } from "react";
import { Button, Typography, useMediaQuery } from "@mui/material";
import { Box } from "@mui/system";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import toast from "react-hot-toast";
import { getRequest, postRequest } from "../../api";
import { setToken } from "../../api/localStorage/token";
import { getType, setType } from "../../api/localStorage/type";
import { InputWithLabel } from "../../components/InputWithLabel";
import PageTemplate from "../../components/PageTemplate/index";
import NotifyBanner from "../../components/NotifyBanner";
import { ROUTES } from "../../constants/routes";
import { required, numberOnly, maxLength } from "../../utils/validation";
import { AccountTypeEnum } from "../../constants";
import { setUser } from "../../redux/actionCreators";
import Loader from "../../components/Loader";
import { SignInFormData } from "./types";
import { colors } from "../../theme";
import BannerImage from "./../../assets/images/bannerImg1.jpg";

const defaultValues: SignInFormData = {
  username: "",
  password: "",
  tfa_code: "",
};

interface YouTubeVideoEmbedProps {
  videoId: string;
}

const YouTubeVideoEmbed: React.FC<YouTubeVideoEmbedProps> = ({ videoId }) => (
  <Box
    display="flex"
    justifyContent="center"
    alignItems="center"
    width="100%"
    height="0"
    paddingBottom="56.25%" /* for 16:9 aspect ratio */
    position="relative"
    marginBottom={2}
  >
    <iframe
      width="100%"
      height="100%"
      style={{ position: 'absolute', left: 0, top: -15 }}
      src={`https://www.youtube.com/embed/?autoplay=1&loop=1&playlist=${videoId}`}
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      title="Tutorial"
    />
  </Box>
);

export const SignIn = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [tfaEnabled, setTfaEnabled] = useState<boolean>(false);
  const [signInVisible, setSignInVisible] = useState<boolean>(true);
  const dispatch = useDispatch();
  const isDesktop = useMediaQuery("(min-width:1060px)");

  const {
    control,
    handleSubmit,
    formState: { isValid, errors },
  } = useForm<SignInFormData>({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: defaultValues,
  });

  const navigate = useNavigate();

  const handleCreateAccount = () => {
    navigate(ROUTES.SIGN_UP);
  };

  const handleSignIn = async (data: SignInFormData) => {
    setLoading(true);
    setError(null);
    try {
      const body: any = {
        username: data.username,
        password: data.password,
      };
      if (tfaEnabled) {
        body.tfa_code = data.tfa_code;
      }
      const response = await postRequest("/login/", body);
      if (response?.error) {
        toast.error(response.error, { duration: 3000 });
        return;
      } else if (response?.access) {
        await setToken(response?.access);
        await setType(
          response.type === AccountTypeEnum.Student
            ? AccountTypeEnum.Applicant
            : AccountTypeEnum.Writer,
        );
        const profile = await getRequest("/myprofile/");
        navigate(ROUTES.CHOOSE_LETTER_TYPE);
        dispatch(setUser(profile));
        setSignInVisible(false);
      } else {
        setTfaEnabled(true);
      }
    } catch (err: any) {
      setError(err.detail);
    } finally {
      setLoading(false);
    }
  };

  const handleForgotPassword = () => {
    navigate(ROUTES.FORGOT_PASSWORD);
  };

  const onEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.code === "Enter" || e.code === "NumpadEnter") {
      handleSubmit(handleSignIn)();
    }
  };

  return (
    <PageTemplate type={getType()}>
      {signInVisible && (
        <Box width="100%" paddingY={4} paddingX={4} boxSizing="border-box">
          <Box display="flex" width="100%" justifyContent="center" alignItems={isDesktop ? "normal" : "center"} height="auto" flexDirection={isDesktop ? "row" : "column"}>
            <Box maxWidth={500} paddingX={isDesktop ? 12 : 0} justifyContent="center" display="flex" flexDirection="column" mb={isDesktop ? 0 : 4}>
              <YouTubeVideoEmbed videoId="tVmv4mSSTKA" />
              <Typography textAlign="left" variant={isDesktop ? "h5" : "mediumTitle"} mb={2}>Crafting the perfect letter of recommendation or cover letter for a job can be a daunting task.</Typography>
              <Typography textAlign="left" variant={isDesktop ? "body1" : "text"} mb={2}>We leverage the power of AI to make writing cover letters and letters of recommendation easier, faster and better.</Typography>
              <Typography textAlign="left" variant={isDesktop ? "body1" : "text"}>Sign up for free and get your first three letters on us, only $9 per month after that.</Typography>
            </Box>
            <Box maxWidth={360} justifyContent="flex-start" display="flex" flexDirection="column">
              <Box marginBottom={4}>
                <Typography variant="h5">Sign in to Letters</Typography>
              </Box>
              <Controller
                name="username"
                control={control}
                rules={{
                  validate: {
                    required,
                  },
                }}
                render={({ field }) => (
                  <InputWithLabel
                    onKeyDown={onEnter}
                    errorMessage={errors.username?.message}
                    {...field}
                    wrapperStyle={{ mb: 2 }}
                    id="email-input"
                    label="Email"
                    placeholder="Email"
                  />
                )}
              />
              <Controller
                name="password"
                control={control}
                rules={{
                  validate: {
                    required,
                  },
                }}
                render={({ field }) => (
                  <InputWithLabel
                    errorMessage={errors.password?.message}
                    {...field}
                    onKeyDown={onEnter}
                    id="password-input"
                    label="Password"
                    placeholder="Password"
                    type="password"
                    RightLabelComponent={
                      <>
                        <Button variant="text" color="secondary" onClick={handleForgotPassword}>
                          Forgot password?
                        </Button>
                      </>
                    }
                  />
                )}
              />
              {tfaEnabled && (
                <Controller
                  name="tfa_code"
                  control={control}
                  rules={{
                    validate: {
                      required,
                      numberOnly,
                      maxLength: maxLength(6),
                    },
                  }}
                  render={({ field }) => (
                    <InputWithLabel
                      onKeyDown={onEnter}
                      errorMessage={errors.tfa_code?.message}
                      {...field}
                      wrapperStyle={{ mt: 2 }}
                      id="code-input"
                      label="Code"
                      placeholder="Enter the code"
                    />
                  )}
                />
              )}
              <Box maxWidth={300}>
                {error && (
                  <Typography width="inherit" marginTop={2} color={colors.error}>
                    {error}
                  </Typography>
                )}
              </Box>
              <Box display="flex" justifyContent="center" marginTop={5}>
                <Button
                  disabled={!isValid || loading}
                  variant="contained"
                  color="secondaryLight"
                  onClick={handleSubmit(handleSignIn)}
                >
                  {loading ? <Loader size={24} /> : tfaEnabled ? "Continue" : "Sign In"}
                </Button>
              </Box>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                marginTop={5}
                gap={5}
              >
                <Typography color="secondary">Don't have an account?</Typography>
                <Button variant="outlined" color="secondary" onClick={handleCreateAccount}>
                  Sign Up
                </Button>
              </Box>
              <NotifyBanner
                bannerTitle="Start Saving Time Today"
                text="Sign up in less than a minute and get your first three letters free."
                image={BannerImage}
              />
            </Box>
          </Box>
        </Box>)}
    </PageTemplate>
  );
};

export default SignIn;
