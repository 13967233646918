import React, { useEffect, useState } from "react";
import { Box } from "@mui/system";
import { Modal } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import clone from "just-clone";
import PageTemplate from "../../components/PageTemplate";
import ProgressBar from "../../components/ProgressBar";
import Questionnaire from "../../components/Questionnaire";
import { ROUTES } from "../../constants/routes";
import { Question } from "../../components/Questionnaire/types";
import { getType } from "../../api/localStorage/type";
import { SelectWriter } from "./SelectWriter";
import { SelectLetterType } from "./SelectLetterType";
import { getRequest } from "../../api";
import { prepareQuestions } from "./utils";
import { addFilesToRequest, addLinksToRequest, createLetterRequest } from "../../api/letterRequest";
import { RootState } from "../../redux/reducers";
import { removeLetterTemplate } from "../../redux/actionCreators";
import { AdditionalInformation } from "./AdditionalInformation";
import { AccountTypeEnum } from "../../constants";
import { ConfirmationModal } from "../../components/ConfirmationModal";
import { getQueryParamFromURL } from "../../utils/getQueryParamFromURL";

const APPLICANT_STEPS = 4;
const WRITER_STEPS = 3;

enum Steps {
  FIRST_STEP = 1,
  SECOND_STEP,
  THIRD_STEP,
  FOURTH_STEP,
}

enum LetterTypes {
  RESEARCH = 1,
  CLASS = 2,
}

const SELF_REQUEST_QUERY_PARAM = "selfRequest";

export const NewLetter = () => {
  const [step, setStep] = useState(1);
  const [loading, setLoading] = useState(false);
  const [requestData, setRequestData] = useState<Record<string, any>>({});
  const [questions, setQuestions] = useState<Question[] | []>([]);
  const [isOpenResearchWarningModal, setIsOpenResearchWarningModal] = useState(false);
  const [typeId, setTypeId] = useState<number | null>(null);

  const { user } = useSelector((state: RootState) => state.user);
  const { template, letterTypeId } = useSelector((state: RootState) => state.requests);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isWriterCheck = getType() === AccountTypeEnum.Writer;
  const isOpenResearchWarningModalCheck = requestData.letterTypeId === LetterTypes.RESEARCH &&
    (isWriterCheck ? step === Steps.SECOND_STEP : step === Steps.THIRD_STEP);

  const handleOpenResearchWarningModal = () => setIsOpenResearchWarningModal(true);
  const handleCloseResearchWarningModal = () => setIsOpenResearchWarningModal(false);

  const handleChangeQuestion = (questionId: string, answer: string) => {
    const questionsCopy: Question[] | [] = clone(questions);
    const _current = questionsCopy?.find((q: Question) => q.id === questionId);
    if (_current) {
      _current.answer = answer;
    }

    setQuestions(questionsCopy);
  };

  const handleNext = async (args?: Record<string, any>) => {
    const _data = {
      ...requestData,
      ...args,
    };
    if (isWriterCheck) _data.request_id = user.request_id;
    args && setRequestData(_data);
    if (step === Steps.FIRST_STEP && requestData.letterTypeId) {
      setStep(isWriterCheck ? Steps.SECOND_STEP : Steps.THIRD_STEP);
      return;
    }
    if (step + Steps.FIRST_STEP <= (isWriterCheck ? WRITER_STEPS : APPLICANT_STEPS)) {
      setStep(step + Steps.FIRST_STEP);
    } else {
      setLoading(true);
      const result = await createLetterRequest({
        request_id: _data.request_id,
        request_type_id: _data.letterTypeId || typeId,
        questions: _data.questions.map((q: Question) => ({ id: q.id, answer: q?.answer || "" })),
        is_self_request: _data.request_id === user.request_id,
      });

      if (!result.error) {
        await addLinksToRequest(result.id, _data.links);
        await addFilesToRequest(result.id, _data.files);
        navigate(ROUTES.LETTER_SUCCESS);
      }

      setLoading(false);
    }
    if (isOpenResearchWarningModalCheck) {
      handleOpenResearchWarningModal();
    }
  };

  const handleCancel = () => {
    if (step === Steps.THIRD_STEP) {
      setQuestions(questions);
    }

    if (step - Steps.FIRST_STEP >= Steps.FIRST_STEP) {
      setStep(step - Steps.FIRST_STEP);
    } else {
      navigate(ROUTES.DASHBOARD_APPLICANT);
      setQuestions([]);
    }
  };

  const getQuestions = async () => {
    try {
      setLoading(true);
      const questionsData = await getRequest(`/questions/${requestData.letterTypeId}/`);
      const _questions = prepareQuestions(questionsData, user);
      setQuestions(_questions);
    } catch (err: unknown) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (requestData?.letterTypeId && step === (isWriterCheck ? Steps.SECOND_STEP : Steps.THIRD_STEP) && !template) {
      getQuestions();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step, requestData?.letterTypeId]);

  useEffect(() => {
    if (template && letterTypeId) {
      setQuestions(template);
      setRequestData({ ...requestData, letterTypeId });
    }
    return () => {
      dispatch(removeLetterTemplate());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [template]);

  useEffect(() => {
    if (step === (isWriterCheck ? Steps.THIRD_STEP : Steps.FOURTH_STEP)) {
      setTypeId(requestData.letterTypeId);
      setRequestData(prevState => {
        return {
          ...prevState,
          letterTypeId: null,
        };
      });
    }
  }, [step]);

  useEffect(() => {
    if (isWriterCheck && getQueryParamFromURL(SELF_REQUEST_QUERY_PARAM) && letterTypeId) {
      setStep(Steps.SECOND_STEP);
    }
  }, []);

  return (
    <>
      <Modal open={isOpenResearchWarningModal} onClose={handleCloseResearchWarningModal}>
        <Box width="100%" height="100%" display="flex" alignItems="center" justifyContent="center">
          <ConfirmationModal
            onSubmit={handleCloseResearchWarningModal}
            title="This letter request will be analyzed using Artificial Intelligence. It may analyze up to 20 min, depending on complexity."
            actionName="Got it!"
          />
        </Box>
      </Modal>
      <PageTemplate type={getType()}>
        <Box width="100%" height="100%">
          <ProgressBar
            height={40}
            current={step}
            steps={isWriterCheck ? WRITER_STEPS : APPLICANT_STEPS}
            startColor="#BC4B51"
            endColor="#F4A159"
          />
          {isWriterCheck ? (
            <Box
              display="flex"
              width="100%"
              paddingTop={6}
              px={3}
              boxSizing="border-box"
              alignItems="center"
              flexDirection="column"
            >
              {step === Steps.FIRST_STEP && <SelectLetterType onNext={handleNext} onCancel={handleCancel} />}
              {step === Steps.SECOND_STEP && (
                <Questionnaire
                  onChangeQuestion={handleChangeQuestion}
                  onCancel={handleCancel}
                  onNext={handleNext}
                  questions={questions}
                  loading={loading}
                />
              )}
              {step === Steps.THIRD_STEP &&
                <AdditionalInformation onNext={handleNext} onCancel={handleCancel} loading={loading} />}
            </Box>) : (
            <Box
              display="flex"
              width="100%"
              paddingTop={6}
              px={3}
              boxSizing="border-box"
              alignItems="center"
              flexDirection="column"
            >
              {step === Steps.FIRST_STEP && <SelectWriter onNext={handleNext} onCancel={handleCancel} />}
              {step === Steps.SECOND_STEP && <SelectLetterType onNext={handleNext} onCancel={handleCancel} />}
              {step === Steps.THIRD_STEP && (
                <Questionnaire
                  onChangeQuestion={handleChangeQuestion}
                  onCancel={template ? null : handleCancel}
                  onNext={handleNext}
                  questions={questions}
                  loading={loading}
                />
              )}
              {step === Steps.FOURTH_STEP && (
                <AdditionalInformation onNext={handleNext} onCancel={handleCancel} loading={loading} />
              )}
            </Box>)}
        </Box>
      </PageTemplate>
    </>
  );
};
