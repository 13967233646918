import React, { useEffect, useState } from "react";
import { Avatar, Box, Typography } from "@mui/material";
import Loader from "../../components/Loader";
import { getLetterRequest } from "../../api/letterRequest";
import { getCoverLetter } from "../../api/coverLetter";
import { CoverLetterRecipient, LetterRecipient } from "./types";
import { createShortName } from "./utils";
import { formatDate } from "../../utils/joinFormatDate";

type RecipientProps = {
  letterId?: string;
  title?: string;
  isCoverLetter?: boolean;
};

export const Recipient: React.FC<RecipientProps> = ({ letterId, title, isCoverLetter }) => {
  const [isLoading, setLoading] = useState(false);
  const [letterRecipient, setLetterRecipient] = useState<LetterRecipient | CoverLetterRecipient>();

  const getDetails = async () => {
    try {
      setLoading(true);

      if (isCoverLetter) {
        const { applicant_first_name, applicant_last_name, employer } = await getCoverLetter(letterId!);
        setLetterRecipient({
          firstName: applicant_first_name,
          lastName: applicant_last_name,
          employer: employer,
        });
      } else {
        const { student_first_name, student_last_name, target_university, initial_request_date } = await getLetterRequest(letterId);
          setLetterRecipient({
            firstName: student_first_name,
            lastName: student_last_name,
            university: target_university,
            requestDate: initial_request_date,
          });
      }
    } catch (error: unknown) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    letterId && !letterRecipient && getDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [letterId]);

  return (
    <>
      {isLoading ? (
        <Loader size={30} />
      ) : (
        letterRecipient && (
          <Box display="flex" gap={1}>
            <Avatar sx={{ width: 35, height: 35 }}>
              <Typography>{createShortName(letterRecipient)}</Typography>
            </Avatar>
            <Box display="flex" flexDirection="column">
              <Typography variant="title">
                {title} {`${letterRecipient?.firstName} ${letterRecipient?.lastName}`}
              </Typography>
              <Typography variant="smallText">
                {!isCoverLetter &&
                "university" in letterRecipient && letterRecipient.university &&
                "requestDate" in letterRecipient ?
                  `Created on: ${formatDate(letterRecipient?.requestDate.toString())}` :
                  "employer" in letterRecipient && letterRecipient.employer}
              </Typography>
            </Box>
          </Box>
        )
      )}
    </>
  );
};
