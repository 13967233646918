import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Modal } from "@mui/material";
import HistoryEduIcon from "@mui/icons-material/HistoryEdu";
import DeleteIcon from "@mui/icons-material/Delete";
import FormatColorTextIcon from "@mui/icons-material/FormatColorText";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { useDispatch } from "react-redux";
import toast from "react-hot-toast";
import TuneIcon from "@mui/icons-material/Tune";
import { deleteLetterRequest } from "../../api/letterRequest";
import { RequestStatus } from "../../views/LetterStatus/types";
import { deleteRequest, setEditRequest, setLetterTemplate } from "../../redux/actionCreators";
import { ROUTES } from "../../constants/routes";
import { ControlPopover } from "./ControlPopover";
import { prepareQuestions } from "../../views/NewLetter/utils";
import { ControlStudentListPopoverProps, PopoverList } from "./types";
import { ConfirmationModal } from "../ConfirmationModal";

type ModalStatus = {
  title: string;
  actionButton: string;
  action: () => void;
}

export const ControlStudentListPopover = ({
  open,
  anchor,
  onClose,
  letterId,
  onViewDetails,
  status,
  accessStudent,
  questions,
  letterTypeId,
  handleNavigateToWriteLetter,
}: ControlStudentListPopoverProps) => {
  const [listElements, setListElements] = useState<PopoverList[]>([]);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [modalStatus, setModalStatus] = useState<ModalStatus | null>(null);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleOpen = () => setIsOpen(true);
  const handleClose = () => setIsOpen(false);

  const handleDeleteLetterRequest = async () => {
    setIsLoading(true)
    const deletedRequest = await deleteLetterRequest(letterId);
    if (!deletedRequest.error) {
      toast.success("Request was successfully deleted");
      dispatch(deleteRequest(letterId));
    } else {
      toast.error("An error occurred during delete request process");
    }
    onClose();
    setIsLoading(false);
  };

  const handleRequestSimilar = () => {
    const _questions = prepareQuestions(questions);
    dispatch(setLetterTemplate({ template: _questions, letterTypeId }));
    navigate(ROUTES.NEW_LETTER);
    onClose();
  };

  const handleEditMode = () => {
    if (onViewDetails) {
      onViewDetails(letterId);
      dispatch(setEditRequest(true));
    }
    onClose();
  };

  const handleSetModalStatus = (title: string, actionButton: string, action: () => void) => {
    return { title, actionButton, action };
  };


  useEffect(() => {
    setListElements([
      ...(onViewDetails ? [ { handler: () => onViewDetails(letterId), text: "View Details", icon: TuneIcon } ] : []),
      { handler: handleRequestSimilar, text: "Request Similar", icon: ContentCopyIcon },
    ]);

    if (status === RequestStatus.r) {
      setListElements((prevState: PopoverList[]) => [
        ...prevState,
        ...[
          {
            handler: () => {
              setModalStatus(handleSetModalStatus(
                "Do you really want to delete this letter request?",
                "Delete",
                handleDeleteLetterRequest,
              ));
              handleOpen();
            },
            text: "Delete",
            icon: DeleteIcon,
          },
          { handler: handleEditMode, text: "Edit request", icon: FormatColorTextIcon },
        ],
      ]);
    }

    if (status === RequestStatus.mi) {
      setListElements((prevState: PopoverList[]) => [
        ...prevState,
        { handler: handleEditMode, text: "Edit request", icon: FormatColorTextIcon },
      ]);
    }

    if (accessStudent && handleNavigateToWriteLetter && status !== RequestStatus.s) {
      setListElements((prevState: PopoverList[]) => [
        ...prevState,
        ...[
          {
            handler: () => handleNavigateToWriteLetter("constructor"),
            text: "WriteLetter",
            icon: HistoryEduIcon,
          },
        ],
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  return (
    <>
      <Modal open={isOpen} onClose={handleClose}>
        <Box width="100%" height="100%" display="flex" alignItems="center" justifyContent="center">
          <ConfirmationModal
            onSubmit={modalStatus?.action!}
            onCancel={handleClose}
            title={modalStatus?.title!}
            actionName={modalStatus?.actionButton!}
            isLoading={isLoading}
          />
        </Box>
      </Modal>
      <ControlPopover open={open} anchor={anchor} onClose={onClose} listElements={listElements} />
    </>
  );
};
