import React, { useEffect, useState } from "react";
import { Button, Link, Modal, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { InputWithLabel } from "../../components/InputWithLabel";
import PageTemplate from "../../components/PageTemplate/index";
import { ChooseType } from "../../components/ChooseType/ChooseType";
import { CancelSignUpModal } from "../../components/CancelSignUpModal/CancelSignUpModal";
import PasswordHint from "../../components/PasswordHint";
import { RootState } from "../../redux/reducers";
import { setAccountTypes } from "../../redux/actionCreators";
import { AccountType, getAccountTypes } from "../../utils/accountTypes";
import {
  charsWithSpecial,
  email,
  includeLoweCaseChar,
  includeNumber,
  includeSpecialChar,
  includeUpperCaseChar,
  minLength,
  required,
} from "../../utils/validation";
import { useNavigatingAway } from "../../hooks/useNavigatingAway";
import { getRequest } from "../../api";
import { ROUTES } from "../../constants/routes";
import { SignUpFormData, UserData } from "./types";
import { AccountTypeEnum } from "../../constants";
import { handleAcceptInvite, handleSignUp, handleSignUpByReferral } from "../../api/registration";
import { getQueryParamFromURL } from "../../utils/getQueryParamFromURL";
import { ConfirmationModal } from "../../components/ConfirmationModal";

const DEFAULT_CANCEL_SIGNUP_TITLE = "We are sorry to see you go";

export const SignUp = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [canShowDialogLeavingPage, setCanShowDialogLeavingPage] = useState<boolean>(false);
  const [signUpVisible, setSignUpVisible] = useState<boolean>(false);
  const [showDialogLeavingPage, confirmNavigation, cancelNavigation] =
    useNavigatingAway(canShowDialogLeavingPage);
  const [requestId, setRequestId] = useState<string>("");
  const [referralId, setReferralId] = useState<string>("");
  const [cancelSignUpModalTitle, setCancelSignUpModalTitle] = useState(DEFAULT_CANCEL_SIGNUP_TITLE);
  const [isBackToChooseAccountModal, setIsBackToChooseAccountModal] = useState(false);

  const { accountTypes }: { accountTypes: AccountType[] | [] } = useSelector(
    (state: RootState) => state.user,
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const {
    control,
    handleSubmit,
    setValue,
    setError,
    formState: { errors, isSubmitSuccessful, isValid },
  } = useForm<SignUpFormData>({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: {
      email: "",
      first_name: "",
      last_name: "",
      account_type: {
        key: "",
        value: "",
      },
      password: "",
    },
  });

  const handleNavigate = (path: string) => {
    (confirmNavigation as Function)();
    navigate(path);
  };

  const handleNavigateSingInLink = () => {
    setCancelSignUpModalTitle("Are you sure you want to leave sign up?");
    navigate(ROUTES.SIGN_IN);
  };

  const handleOpenBackToChooseAccountModal = () => {
    setIsBackToChooseAccountModal(true);
  };

  const handleCloseBackToChooseAccountModal = () => {
    setIsBackToChooseAccountModal(false);
  };

  const handleBackToChooseAccountAction = () => {
    setSignUpVisible(false);
    handleCloseBackToChooseAccountModal();
  };

  const handleCancelNavigation = () => {
    cancelNavigation();
    setCancelSignUpModalTitle(DEFAULT_CANCEL_SIGNUP_TITLE);
  };

  const handleCreateAccount = async (data: SignUpFormData) => {
    try {
      setLoading(true);

      const user: UserData = {
        email: data.email,
        first_name: data.first_name,
        last_name: data.last_name,
        password: data.password,
      };

      const account_type = {
        account_type_name: data.account_type.value,
      };

      if (referralId) {
        await handleSignUpByReferral(user, account_type, referralId);
      } else if (requestId) {
        await handleAcceptInvite(user, requestId);
      } else {
        await handleSignUp(user, account_type);
      }
    } catch (err: any) {
      toast.error("Sign Up error" && err?.user?.email[0]);
    } finally {
      setLoading(false);
    }
  };

  const handleChangeAccountType = (type: AccountTypeEnum) => {
    const item = accountTypes?.find((i: AccountType) => i?.value === type);
    if (item) {
      setValue("account_type", item);
      setError("account_type", {});
      setSignUpVisible(true);
    }
  };

  const getTypes = async () => {
    const types = await getAccountTypes();
    dispatch(setAccountTypes(types));
  };

  useEffect(() => {
    getTypes();

    const listener = (event: any) => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        handleSubmit(handleCreateAccount)();
      }
    };
    document.addEventListener("keydown", listener);

    return () => {
      document.removeEventListener("keydown", listener);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setCanShowDialogLeavingPage(true);

    return () => {
      setCanShowDialogLeavingPage(false);
    };
  }, [location.pathname]);

  useEffect(() => {
    if (isSubmitSuccessful) {
      handleNavigate(ROUTES.SIGN_IN);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmitSuccessful]);

  useEffect(() => {
    const requestId = getQueryParamFromURL("request_id");
    const referralId = getQueryParamFromURL("referral_id");
    if (referralId) {
      setReferralId(referralId);
    }
    if (requestId) {
      setRequestId(requestId);
      setSignUpVisible(true);
      (async () => {
        try {
          const data = await getRequest(`/accept_invite/?request_id=${requestId}`);
          setValue("email", data.user.email);
          setValue("first_name", data.user.first_name);
          setValue("last_name", data.user.last_name);
        } catch (err) {
          console.log("Accept invite request error: ", err);
        }
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Modal open={isBackToChooseAccountModal} onClose={setIsBackToChooseAccountModal}>
        <Box width="100%" height="100%" display="flex" alignItems="center" justifyContent="center">
          <ConfirmationModal
            onSubmit={handleBackToChooseAccountAction}
            onCancel={handleCloseBackToChooseAccountModal}
            title="Do you really want to go back to choosing an account type?"
            actionName="Back"
          />
        </Box>
      </Modal>
      <Modal open={showDialogLeavingPage} onClose={setCanShowDialogLeavingPage}>
        <CancelSignUpModal
          confirmNavigation={confirmNavigation}
          cancelNavigation={handleCancelNavigation}
          title={cancelSignUpModalTitle}
        />
      </Modal>
      {signUpVisible ? (
        <PageTemplate>
          <Box width="100%" paddingY={4} paddingX={3} boxSizing="border-box">
            <Box display="flex" width="100%" justifyContent="center" height="auto">
              <Box
                paddingX={1}
                maxWidth={360}
                justifyContent="flex-start"
                display="flex"
                flexDirection="column"
              >
                <Box marginBottom={6}>
                  <Typography marginBottom={2} variant="h5">
                    Create your Letters account
                  </Typography>
                  <Typography>
                    Already have a Letters account?{" "}
                    <Link onClick={handleNavigateSingInLink} color="secondary">
                      Sign In
                    </Link>
                  </Typography>
                </Box>

                <Controller
                  control={control}
                  name="email"
                  rules={{
                    validate: {
                      required,
                      email,
                    },
                  }}
                  render={({ field }) => (
                    <InputWithLabel
                      {...field}
                      errorMessage={errors.email?.message}
                      wrapperStyle={{ mb: 4 }}
                      id="email-input"
                      label="Email address"
                    />
                  )}
                />

                <Controller
                  control={control}
                  name="first_name"
                  rules={{
                    validate: {
                      required,
                      charsWithSpecial,
                    },
                  }}
                  render={({ field }) => (
                    <InputWithLabel
                      errorMessage={errors.first_name?.message}
                      {...field}
                      wrapperStyle={{ mb: 4 }}
                      id="first-name-input"
                      label="First name"
                    />
                  )}
                />

                <Controller
                  control={control}
                  name="last_name"
                  rules={{
                    validate: {
                      required,
                      charsWithSpecial,
                    },
                  }}
                  render={({ field }) => (
                    <InputWithLabel
                      errorMessage={errors.last_name?.message}
                      {...field}
                      wrapperStyle={{ mb: 4 }}
                      id="last-name-input"
                      label="Last name"
                    />
                  )}
                />

                <Controller
                  control={control}
                  name="password"
                  rules={{
                    validate: {
                      required,
                      includeNumber: includeNumber("At least one number (0-9)"),
                      includeLoweCaseChar: includeLoweCaseChar(
                        "Password should contain at least one lowercase letter (a-z)",
                      ),
                      includeUpperCaseChar: includeUpperCaseChar(
                        "Password should contain at least one uppercase letter (A-Z)",
                      ),
                      includeSpecialChar: includeSpecialChar(
                        "Password should contain at least one special character",
                      ),
                      minLength: minLength(10, "Password should contain at least 10 characters"),
                    },
                  }}
                  render={({ field }) => (
                    <InputWithLabel
                      passwordHint={<PasswordHint value={field.value} />}
                      {...field}
                      errorMessage={errors.password?.message}
                      wrapperStyle={{ mb: 4 }}
                      id="password-input"
                      label="Password"
                      placeholder="Password"
                      type="password"
                      autoComplete="new-password"
                    />
                  )}
                />
                <Box display="flex" justifyContent="space-around" marginTop={5}>
                  {!requestId && (
                    <Button
                      variant="contained"
                      color="secondaryLight"
                      onClick={handleOpenBackToChooseAccountModal}
                    >
                      Back
                    </Button>
                  )}
                  <Button
                    disabled={loading}
                    variant="contained"
                    color={isValid ? "secondary" : "secondaryLight"}
                    onClick={handleSubmit(handleCreateAccount)}
                  >
                    Create Account
                  </Button>
                </Box>
              </Box>
            </Box>
            <Box paddingY={4} display="flex" alignItems="center" justifyContent="center">
              <Typography>
                By signing up I agree to Lucinetic's{" "}
                <Link href={ROUTES.TERMS_OF_SERVICE} color="secondary">
                  Terms of Service
                </Link>{" "}
                and{" "}
                <Link href={ROUTES.PRIVACY_POLICY} color="secondary">
                  Privacy Policy
                </Link>
              </Typography>
            </Box>
          </Box>
        </PageTemplate>
      ) : (
        <Controller
          control={control}
          name="account_type"
          render={({ field: { value } }) => (
            <ChooseType
              isShowLetterTypeButtons={false}
              handleChangeAccountType={handleChangeAccountType}
              accountTypeValue={value.value}
            />
          )}
        />
      )}
    </>
  );
};
