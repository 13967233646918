import React, { useCallback, useEffect, useRef, useState } from "react";
import { Box, Button, Checkbox, Divider, FormControlLabel, Typography, useMediaQuery } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import { ProfileImage } from "../ProfileImage";
import { InputWithLabel } from "../../../components/InputWithLabel";
import { ROUTES } from "../../../constants/routes";
import { RootState } from "../../../redux/reducers";
import { getAccountTypes } from "../../../utils/accountTypes";
import { setAccountTypes, setUser } from "../../../redux/actionCreators";
import { getRequest } from "../../../api";
import AuthenticationButton from "../../../components/AuthenticationButton";
import { createDefaultValues, updateProfile } from "../utils";
import { Profile } from "../types";
import { ReferralLink } from "../../Referral/ReferralLink";
import { getType } from "../../../api/localStorage/type";
import { AccountTypeEnum } from "../../../constants";
import { charsWithSpecial, required } from "../../../utils/validation";
import { TutorialClasses } from "../../../components/Tutorial/tutorialClasses";

export const PersonalInformation = () => {
  const [loading, setLoading] = useState(false);
  const [requestTime, setRequestTime] = useState(0);
  const { accountTypes, user } = useSelector((state: RootState) => state.user);
  const { tourActive } = useSelector((state: RootState) => state.tutorial);
  const dispatch = useDispatch();
  const mutationRef = useRef<HTMLDivElement>(null);

  const {
    handleSubmit,
    control,
    reset,
    formState: { isDirty, errors },
  } = useForm<Profile>({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: createDefaultValues(),
  });

  const navigate = useNavigate();
  const accountType = getType();
  const isDesktop = useMediaQuery("(min-width:900px)");

  const handleReset = () => {
    reset(createDefaultValues(user, accountTypes));
  };

  const handleChangePassword = () => {
    navigate(ROUTES.RESET_PASSWORD);
  };

  const onSubmit = async (data: Profile) => {
    const newProfile = await updateProfile(user, data);
    if (!newProfile.error) {
      reset(createDefaultValues(newProfile, accountTypes));
      dispatch(setUser(newProfile));
      toast.success("Profile data was successfully saved");
    } else {
      toast.error("An error occurred during profile save process");
    }
  };

  const getData = useCallback(async () => {
    const requestStartAt = performance.now();
    setLoading(true);
    try {
      const types = await getAccountTypes();
      const profile = await getRequest("/myprofile/");
      dispatch(setAccountTypes(types));
      dispatch(setUser(profile));
      reset(createDefaultValues(profile, types));
    } catch (err: unknown) {
      console.error(err);
    } finally {
      setLoading(false);
      const requestEndAt = performance.now();
      const timeSpent = requestEndAt - requestStartAt;
      setRequestTime(timeSpent);
    }
  }, [dispatch, reset]);

  useEffect(() => {
    if (user && accountTypes?.length) {
      reset(createDefaultValues(user, accountTypes));
      return;
    }
    getData();
  }, [user, accountTypes, getData, reset]);

  return (
    <Box display="flex" flexDirection="column" width="100%" pb={5}>
      <Box width="100%" display="flex" justifyContent="flex-end" alignItems="center">
        <Box
          visibility={isDirty ? "visible" : "hidden"}
          flex={isDesktop ? 0.3 : 1}
          flexDirection="row"
          display="flex"
          gap={2}
        >
          <Button
            disabled={!isDirty}
            onClick={handleReset}
            fullWidth
            variant="outlined"
            color="secondary"
          >
            Cancel
          </Button>
          <Button
            disabled={!isDirty}
            fullWidth
            variant="contained"
            color="secondary"
            onClick={handleSubmit(onSubmit)}
          >
            Save Changes
          </Button>
        </Box>
      </Box>
      <Box display="flex" flexDirection="column" alignItems={isDesktop ? "flex-start" : "center"}>
        <Controller
          name="imageUrl"
          control={control}
          render={({ field }) => (
            <ProfileImage loading={loading} wrapperStyle={{ mt: 3 }} {...field} />
          )}
        />
        <Box display="flex" flexDirection="column">
          <Box className={accountType === AccountTypeEnum.Applicant ? TutorialClasses.seventhTutorialStep : TutorialClasses.sixthTutorialStep}>
            <Controller
              name="first_name"
              control={control}
              rules={{
                validate: {
                  required,
                  charsWithSpecial,
                },
              }}
              render={({ field }) => (
                <InputWithLabel
                  loading={loading}
                  {...field}
                  errorMessage={errors?.first_name?.message}
                  wrapperStyle={{ mt: 3 }}
                  label="First name"
                  id="first-name-input"
                />
              )}
            />

            <Controller
              name="last_name"
              control={control}
              rules={{
                validate: {
                  required,
                  charsWithSpecial,
                },
              }}
              render={({ field }) => (
                <InputWithLabel
                  loading={loading}
                  {...field}
                  errorMessage={errors?.last_name?.message}
                  wrapperStyle={{ mt: 3 }}
                  label="Last name"
                  id="last-name-input"
                />
              )}
            />

            <Divider sx={{ marginTop: 4 }} />

            <Controller
              name="organization"
              control={control}
              render={({ field }) => (
                <InputWithLabel
                  loading={loading}
                  {...field}
                  errorMessage={errors?.organization?.message}
                  wrapperStyle={{ mt: 3 }}
                  label="Current organization"
                  id="organization-input"
                  multiline
                />
              )}
            />

            <Controller
              name="current_responsibilities"
              control={control}
              render={({ field }) => (
                <InputWithLabel
                  loading={loading}
                  {...field}
                  errorMessage={errors?.current_responsibilities?.message}
                  wrapperStyle={{ mt: 3 }}
                  label="Current job responsibilities"
                  id="responsibilities-input"
                  multiline
                />
              )}
            />
          </Box>

          {accountType === AccountTypeEnum.Writer && (
            <FormControlLabel
              control={
                <Controller
                  name="stealth"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <Checkbox
                      onChange={onChange}
                      checked={value}
                      color="secondary"
                    />
                  )}
                />
              }
              label={<Typography variant="text">Anonymous profile</Typography>}
            />
          )}


          <Box mt={6} display="flex" flexDirection="column">
            <Typography mb={3} variant="h6" fontWeight={500}>
              Login Details
            </Typography>
            <Controller
              name="email"
              control={control}
              render={({ field }) => (
                <InputWithLabel
                  loading={loading}
                  wrapperStyle={{ mb: 2 }}
                  label="Email address"
                  id="email"
                  {...field}
                />
              )}
            />
            <Box mb={2}>
              <Button
                disabled={loading}
                fullWidth
                variant="contained"
                color="secondary"
                onClick={handleChangePassword}
              >
                Change Password
              </Button>
            </Box>
            <AuthenticationButton
              loading={loading}
              authenticated={user?.phone}
              authenticationEnabled={user?.tfa_active}
            />
          </Box>
          <Box mt={6} display="flex" flexDirection="row" gap={1} alignItems="center">
            <Typography fontWeight={600}>Account Type:</Typography>
            <Controller
              name="accountType"
              control={control}
              render={({ field: { value } }) => (
                <Typography>{value.label || value.value}</Typography>
              )}
            />
          </Box>
        </Box>
      </Box>
      {accountType === AccountTypeEnum.Writer && (<Box mt={5}>
        <ReferralLink />
        <Button
          variant="contained"
          color="secondary"
          onClick={() => window.open("https://billing.stripe.com/p/login/14k03W9di1lwdhKbII")}
        >
          Customer Portal
        </Button>
      </Box>)}
    </Box>
  );
};
