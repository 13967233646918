import { Step } from "react-joyride";
import {
  CLEAR_ALL_NOTIFICATIONS,
  CLEAR_ALL_REMINDERS,
  COMPLETELY_STOP_TUTORIAL,
  CONTINUE_TUTORIAL,
  DELETE_REQUEST,
  REMOVE_FILTER_VALUE,
  REMOVE_LETTER_TEMPLATE,
  REMOVE_NOTIFICATION,
  REMOVE_REMINDER,
  REMOVE_SELECTED_REQUEST_ID,
  RESET,
  SET_ACCOUNT_TYPES,
  SET_COVER_REQUESTS,
  SET_CREDIT_COUNT,
  SET_EDIT_REQUEST,
  SET_FILTER_VALUE,
  SET_LETTER_TEMPLATE,
  SET_NOTIFICATION_IS_READ,
  SET_NOTIFICATIONS,
  SET_REMINDER_IS_READ,
  SET_REMINDERS,
  SET_REQUESTS,
  SET_SELECTED_REQUEST_ID,
  SET_SEPARATE_REQUESTS,
  SET_STEP_INDEX,
  SET_STEPS,
  SET_USER, START_TUTORIAL,
  UPDATE_REQUEST,
} from "../actionTypes";
import { LetterRequest } from "../../views/LetterStatus/types";
import { FilterValue, LetterTemplate } from "./types";
import { SortMenu } from "../../components/Table/Head/types";
import { AccountType } from "../../utils/accountTypes";
import { CoverLetterRequest } from "../../views/CoverLetterStatus/types";

export const reset = () => ({ type: RESET });

export const setRequests = (payload: LetterRequest[]) => ({ type: SET_REQUESTS, payload });
export const setCoverRequests = (payload: CoverLetterRequest[]) => ({ type: SET_COVER_REQUESTS, payload });

export const removeFilterValue = (payload: string) => ({ type: REMOVE_FILTER_VALUE, payload });

export const setFilterValue = (payload: FilterValue) => ({ type: SET_FILTER_VALUE, payload });

export const removeLetterTemplate = () => ({ type: REMOVE_LETTER_TEMPLATE });

export const updateRequest = (payload: LetterRequest) => ({ type: UPDATE_REQUEST, payload });

export const deleteRequest = (payload: string | undefined) => ({ type: DELETE_REQUEST, payload });

export const setEditRequest = (payload: boolean) => ({ type: SET_EDIT_REQUEST, payload });

export const setUser = (payload: any) => ({ type: SET_USER, payload });

export const setCreditCount = (payload: number) => ({ type: SET_CREDIT_COUNT, payload });

export const removeAllNotifications = () => ({ type: CLEAR_ALL_NOTIFICATIONS });
export const removeAllReminders = () => ({ type: CLEAR_ALL_REMINDERS });

export const removeNotification = (id: string) => ({ type: REMOVE_NOTIFICATION, payload: id });
export const removeReminder = (id: string) => ({ type: REMOVE_REMINDER, payload: id });

export const removeSelectedRequestId = () => ({ type: REMOVE_SELECTED_REQUEST_ID });

export const setSelectedRequestId = (id: string) => ({
  type: SET_SELECTED_REQUEST_ID,
  payload: id,
});

export const setSeparateRequests = (payload: LetterRequest[]) => ({
  type: SET_SEPARATE_REQUESTS,
  payload,
});

export const setLetterTemplate = (payload: LetterTemplate) => ({
  type: SET_LETTER_TEMPLATE,
  payload,
});

export const setAccountTypes = (payload: AccountType[] | []) => ({
  type: SET_ACCOUNT_TYPES,
  payload,
});

export const setNotifications = (payload: Array<Record<string, any>>) => ({
  type: SET_NOTIFICATIONS,
  payload,
});

export const setReminders = (payload: Array<Record<string, any>>) => ({
  type: SET_REMINDERS,
  payload,
});

export const notificationIsRead = (payload: string) => ({
  type: SET_NOTIFICATION_IS_READ,
  payload,
});

export const reminderIsRead = (payload: string) => ({
  type: SET_REMINDER_IS_READ,
  payload,
});

export const selectiveRequests = (page: number, payload: LetterRequest[]) => {
  return {
    type: page > 1 ? SET_SEPARATE_REQUESTS : SET_REQUESTS,
    payload,
  };
};

export const defineFilterValue = (remove: string | boolean | null, item: SortMenu | SortMenu[]) => {
  let statuses: string[] = [];
  if (Array.isArray(item)) {
    statuses = item.map((el: SortMenu) => el.value);
  }
  return {
    type: remove ? REMOVE_FILTER_VALUE : SET_FILTER_VALUE,
    payload: Array.isArray(item) ?
      remove ?
        "status" : { [item[0].key]: statuses.join() } :
      remove ?
        item?.key : { [item.key]: item.value },
  };
};

export const startTutorial = () => ({ type: START_TUTORIAL });

export const continueTutorial = () => ({ type: CONTINUE_TUTORIAL });

export const setSteps = (payload: Array<Step>) => ({ type: SET_STEPS, payload });

export const stopTutorial = () => ({ type: START_TUTORIAL });

export const completelyStopTutorial = () => ({ type: COMPLETELY_STOP_TUTORIAL });

export const setStepIndex = (payload: number) => ({ type: SET_STEP_INDEX, payload });
