import { useState } from 'react';
import { Button, Checkbox, Modal, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../constants/routes';
import { AuthenticationModal } from './AuthenticationModal';

type AuthenticationButtonProps = {
  loading?: boolean;
  authenticated?: boolean;
  authenticationEnabled?: boolean;
};

export type ActionType = null | 'delete' | 'switch';

const AuthenticationButton = ({
  authenticated,
  loading,
  authenticationEnabled,
}: AuthenticationButtonProps) => {
  const [modalType, setModalType] = useState<ActionType>(null);
  const navigate = useNavigate();

  const handleRemove = () => setModalType('delete');

  const handleTwoFactor = () => navigate(ROUTES.TWO_FACTOR_SETUP);

  const closeModal = () => setModalType(null);

  const handleSwitchAuthState = () => setModalType('switch');

  return (
    <>
      <Modal open={modalType !== null} onClose={closeModal}>
        <Box
          onClick={closeModal}
          width="100%"
          height="100%"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <AuthenticationModal
            type={modalType}
            closeModal={closeModal}
            enabled={authenticationEnabled}
          />
        </Box>
      </Modal>
      {authenticated ? (
        <Box display="flex" flexDirection="row" alignItems="center">
          <Checkbox
            checked={authenticationEnabled}
            onClick={handleSwitchAuthState}
            color="secondary"
          />
          <Typography mr={3} flex={1}>Two-Factor Authentication</Typography>
          <Button variant="contained" color="secondary" onClick={handleRemove}>
            Remove Authentication (2FA)
          </Button>
        </Box>
      ) : (
        <Button
          disabled={loading}
          fullWidth
          variant="contained"
          color="secondary"
          onClick={handleTwoFactor}
        >
          Set up Two-Factor Authentication
        </Button>
      )}
    </>
  );
};

export default AuthenticationButton;
