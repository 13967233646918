import React, { useRef, useState } from "react";
import { MenuOpen, MenuSharp } from "@mui/icons-material";
import { Box, IconButton, Menu, MenuItem } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { MobileMenuItem, MobileMenuProps } from "./types";

const MobileMenu = ({ items }: MobileMenuProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const menu = useRef(null);

  const handleClose = () => setIsOpen(false);
  const handleOpen = () => items.length > 0 && setIsOpen(!isOpen);

  const handleClick = (item: MobileMenuItem) => {
    if (item.onClick) {
      item.onClick();
      handleClose();
      return;
    }

    if (item?.link) {
      navigate(item.link);
      handleClose();
      return;
    }

    return;
  };

  return (
    <Box display="flex" justifyContent="flex-end">
      <IconButton ref={menu} onClick={handleOpen}>
        {isOpen ? <MenuOpen /> : <MenuSharp />}
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={menu.current}
        open={isOpen}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {items?.map((item: MobileMenuItem, index) => (
          <MenuItem key={item.name + index} onClick={() => handleClick(item)}>{item.name}</MenuItem>
        ))}
      </Menu>
    </Box>
  );
};

export default MobileMenu;
