import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Box, Button, InputAdornment, Typography } from "@mui/material";
import { SearchRounded } from "@mui/icons-material";
import { getCoverLetterRequests } from "../../api/coverLetter";
import { setCoverRequests } from "../../redux/actionCreators";
import { RootState } from "../../redux/reducers";
import { getType } from "../../api/localStorage/type";
import PageTemplate from "../../components/PageTemplate";
import { CoverLetterRequestCard } from "../../components/CoverLetterRequestCard";
import { CoverLetterRequest } from "./types";
import { useWindowSize } from "../../hooks/useWindowSize";
import { stylePropToNumber } from "../Dashboard/utils";
import { COVER_LETTER_REQUEST_CARD } from "../../constants";
import { LuciPagination } from "../../components/Table/Pagination";
import { SeparateCoverRequest } from "../LetterStatus/types";
import Loader from "../../components/Loader";
import { ROUTES } from "../../constants/routes";
import { InputWithLabel } from "../../components/InputWithLabel";

const GAP = 20;

export const CoverLetterStatus = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [perPage, setPerPage] = useState(0);
  const [page, setPage] = useState(1);
  const [separateData, setSeparateData] = useState<SeparateCoverRequest | null>(null);
  const [search, setSearch] = useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { coverRequests } = useSelector((state: RootState) => state.requests);
  const [width, height] = useWindowSize();

  const isCoverRequestsExists = coverRequests.length;

  const boxRef = useRef<any>(null);

  const handleChangeCurrentPage = (p: number) => setPage(p);

  const handleChangePerPage = (perPage: number) => setPerPage(perPage);

  const handleNavigateToCoverRequest = () => navigate(ROUTES.COVER_LETTER);

  const onSearch = (event: React.ChangeEvent<HTMLInputElement>) => setSearch(event.target.value);

  const getCoverLetters = async () => {
    try {
      setIsLoading(true);
      const request = await getCoverLetterRequests({
        page: page.toString(),
        page_size: perPage.toString(),
        sort_by: "initial_date",
        applicant_first_name: search,
        applicant_last_name: search,
      });


      dispatch(setCoverRequests(request.results));
      setSeparateData(request);
    } catch (error: unknown) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (width && height && coverRequests.length) {
      const paddingLeft = window
        .getComputedStyle(boxRef.current, null)
        .getPropertyValue("padding-left");
      const paddingRight = window
        .getComputedStyle(boxRef.current, null)
        .getPropertyValue("padding-right");
      const pL = stylePropToNumber(paddingLeft);
      const pR = stylePropToNumber(paddingRight);
      const byWidth = Math.floor((width - (pL + pR)) / (COVER_LETTER_REQUEST_CARD.width + GAP));
      const byHeight = Math.floor(height / (COVER_LETTER_REQUEST_CARD.height + GAP));
      const perPage = byHeight * byWidth;
      setPerPage(perPage);
    }
  }, [height, width]);

  useEffect(() => {
    getCoverLetters();
  }, [perPage, page, search]);

  return (
    <>
      <PageTemplate type={getType()}>
        <Box
          ref={boxRef}
          display="flex"
          margin="0 auto"
          width="100%"
          height="100%"
          flexDirection="column"
          paddingTop={6}
          paddingX={2}
          boxSizing="border-box"
        >
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            px={13}
          >
            <Typography variant="title">
              Cover letter list
            </Typography>
            <Box display="flex" flexDirection="row" gap={3}>
              <InputWithLabel
                id="search"
                placeholder="Search by name"
                onChange={onSearch}
                value={search}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <SearchRounded />
                    </InputAdornment>
                  ),
                }}
              />
              <Button onClick={handleNavigateToCoverRequest} color="secondary" variant="outlined" size="large">
                <Typography variant="text">New Cover Letter</Typography>
              </Button>
            </Box>
          </Box>
          {isCoverRequestsExists ? (
            <Box
              mt={4}
              width="auto"
              flexWrap="wrap"
              height="auto"
              gap={`${GAP}px`}
              display="flex"
              justifyContent="center"
              alignContent="flex-start"
              flexDirection="row"
            >
              {isLoading ? (
                <Box
                  width="100%"
                  height="100%"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  py={2}
                >
                  <Loader size={50} />
                </Box>
              ) : (
                <>
                  {coverRequests.map((card: CoverLetterRequest) => (
                    <CoverLetterRequestCard
                      key={card.id}
                      {...card}
                      text={card.text!}
                    />
                  ))}
                  {separateData && (
                    <LuciPagination
                      count={separateData?.total_pages}
                      currentPage={page}
                      rowsPerPage={perPage}
                      onChangeCurrentPage={handleChangeCurrentPage}
                      onChangeRowPerPage={handleChangePerPage}
                      isShadow={false}
                    />
                  )}
                </>
              )}
            </Box>) : <Box
            display="flex"
            alignItems="center"
            gap={4}
            flexDirection="column"
            width="100%"
            height="100%"
            paddingTop={12}
          >
            <Typography variant="title">
              You haven't written any cover letters yet
            </Typography>
            <Button
              onClick={handleNavigateToCoverRequest}
              size="large"
              color="secondary"
              variant="outlined"
            >
              Create new cover letter
            </Button>
          </Box>}
        </Box>
      </PageTemplate>
    </>
  );
};