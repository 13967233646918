import { FC, useEffect, useRef } from "react";
import { Box, BoxTypeMap } from "@mui/material";
import { BaseProps } from "@mui/material/OverridableComponent";
import toast from "react-hot-toast";
import { FILE_MAX_SIZE, getFileSizeNumber } from "../../utils/fileSize";

export type PreviewComponentProps = {
  onSelectFile: () => void;
};

interface FileUploaderWithDragAndDropProps {
  setFile: (file: FileList | null | undefined) => void;
  PreviewComponent?: React.FC<PreviewComponentProps>;
  wrapperStyles?: BaseProps<BoxTypeMap>;
  allowedFormats?: string[];
}

const FileUploaderWithDragAndDrop: FC<FileUploaderWithDragAndDropProps> = ({
  setFile,
  PreviewComponent,
  wrapperStyles,
  allowedFormats,
}) => {
  const dropRef = useRef<HTMLDivElement>(null);
  const inputFileRef = useRef<HTMLInputElement>(null);

  const onSelect = () => {
    inputFileRef?.current?.click();
  };

  const handleDrag = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDragIn = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDragOut = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e: any) => {
    e.preventDefault();
    e.stopPropagation();

    const { files = [] } = e.dataTransfer;
    const { size, name } = files[0];
    const isAllowedFormat = allowedFormats?.some((el: string) => name.toLowerCase().endsWith(el));

    if (files.length) {
      if (size < FILE_MAX_SIZE && isAllowedFormat) {
        setFile(files);
      } else if (size > FILE_MAX_SIZE) {
        toast.error(`the file size should be lower than ${getFileSizeNumber(FILE_MAX_SIZE)}`);
      } else if (!isAllowedFormat) {
        toast.error(`choose the correct file format`);
      }
      e.dataTransfer.clearData();
    }
  };

  useEffect(() => {
    let div = dropRef?.current;
    if (!div) {
      return;
    }

    div.addEventListener("dragenter", handleDragIn);
    div.addEventListener("dragleave", handleDragOut);
    div.addEventListener("dragover", handleDrag);
    div.addEventListener("drop", handleDrop);
    return () => {
      if (!div) {
        return;
      }
      div.removeEventListener("dragenter", handleDragIn);
      div.removeEventListener("dragleave", handleDragOut);
      div.removeEventListener("dragover", handleDrag);
      div.removeEventListener("drop", handleDrop);
    };
  });

  return (
    <Box boxSizing="border-box" ref={dropRef} {...wrapperStyles}>
      <input
        type="file"
        accept={allowedFormats?.toString()}
        hidden
        ref={inputFileRef}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          setFile(e.target.files);
        }}
      />
      {PreviewComponent && <PreviewComponent onSelectFile={onSelect} />}
    </Box>
  );
};

export default FileUploaderWithDragAndDrop;
