import React from "react";
import { alpha, Chip } from "@mui/material";
import { DueDateChipProps } from "./types";
import { LetterDueDate } from "../LetterDueDate/LetterDueDate";

export const DueDateChip = ({ backgroundColor, paddingLeft, dueDate, status }: DueDateChipProps) => (
  <Chip
    sx={{
      backgroundColor: alpha(backgroundColor, 0.2),
      paddingLeft: paddingLeft,
    }}
    icon={<LetterDueDate dueDate={dueDate} status={status} />}
    label={dueDate}
  />
);

