import { Box, Button, InputLabel, Typography, useMediaQuery } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import MuiPhoneNumber from "material-ui-phone-number";
import PageTemplate from "../../components/PageTemplate";
import { InputWithLabel } from "../../components/InputWithLabel";
import { ROUTES } from "../../constants/routes";
import { getType } from "../../api/localStorage/type";
import { getRequest, postRequest } from "../../api";
import { setUser } from "../../redux/actionCreators";
import Loader from "../../components/Loader";
import { Location } from "./types";

export const AuthenticationSetup = () => {
  const isDesktop = useMediaQuery("(min-width:900px)");
  const [location, setLocation] = useState<Location>({ code: "us" });
  const [codeSend, setCodeSend] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [number, setNumber] = useState("");
  const [code, setCode] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const getLocale = async () => {
    const onSuccess = async (p: GeolocationPosition) => {
      try {
        setLoading(true);
        const { latitude, longitude } = p.coords;
        const d = await getRequest(`/region/?lat=${latitude}&lon=${longitude}`);
        setLocation({ code: d.cc });
      } catch (err: unknown) {
        //TODO: handle error
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    const onError = async () => {
      setLoading(false);
      setLocation({ code: "us" });
    };

    navigator.geolocation.getCurrentPosition(onSuccess, onError);
  };

  const handleCancelTwoFactorAuth = () => navigate(ROUTES.MY_ACCOUNT);

  const handleChangeNumber = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | string,
  ) => {
    setNumber((e as string).replace(/\D/g, ""));
  };

  const handleChangeCode = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCode(e.target.value as string);
  };

  const getCode = async () => {
    try {
      setLoading(true);
      await postRequest("/get-code/", {
        phone: `+${number}`,
      });
      setCodeSend(true);
    } catch (err: unknown) {
      //TODO: handle error needed
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const enableTwoFactorAuth = async () => {
    try {
      setLoading(true);
      const profile = await postRequest("/enable-tfa/", {
        phone: `+${number}`,
        tfa_code: code,
      });
      dispatch(setUser(profile));
      navigate(ROUTES.MY_ACCOUNT);
    } catch (err: any) {
      setError(err?.error);
    } finally {
      setLoading(false);
    }
  };

  const handleNext = async () => {
    if (!codeSend) {
      await getCode();
    } else {
      enableTwoFactorAuth();
    }
  };

  useEffect(() => {
    getLocale();
  }, []);

  return (
    <PageTemplate type={getType()}>
      <Box
        width="100%"
        height="100%"
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        paddingX={3}
        boxSizing="border-box"
      >
        <Box
          height="100%"
          boxSizing="border-box"
          justifyContent="center"
          display="flex"
          flexDirection="column"
          width={isDesktop ? 360 : "auto"}
        >
          <InputLabel shrink htmlFor="phone-input">
            <Typography variant="body1" fontWeight={600}>
              Phone number
            </Typography>
          </InputLabel>
          <MuiPhoneNumber
            id="phone-input"
            variant="outlined"
            defaultCountry={location?.code.toLocaleLowerCase()}
            countryCodeEditable={false}
            onChange={handleChangeNumber}
            disabled={loading}
          />
          {codeSend && (
            <InputWithLabel
              loading={loading}
              wrapperStyle={{ mt: 2 }}
              id="code-input"
              label="Code"
              placeholder="Code"
              errorMessage={error}
              onChange={handleChangeCode}
            />
          )}
          <Box display="flex" justifyContent="flex-start" marginTop={2} gap={3}>
            <Button
              disabled={loading}
              variant="contained"
              color="secondary"
              fullWidth
              onClick={handleNext}
            >
              {loading ? <Loader size={24} /> : codeSend ? "Confirm" : "Next"}
            </Button>
            <Button
              variant="outlined"
              color="secondary"
              fullWidth
              onClick={handleCancelTwoFactorAuth}
            >
              Cancel
            </Button>
          </Box>
        </Box>
      </Box>
    </PageTemplate>
  );
};
