import React from "react";
import { Box, Button, Typography } from "@mui/material";
import Loader from "../Loader";

type CancelSubscriptionModalProps = {
  onSubmit: () => void;
  onCancel?: () => void;
  title: string;
  actionName: string;
  isLoading?: boolean;
}

export const ConfirmationModal = React.forwardRef(
  ({ onSubmit, onCancel, title, actionName, isLoading }: CancelSubscriptionModalProps, ref: React.Ref<any>) => {
    return (
      <Box ref={ref} width="100%" height="100%" display="flex" alignItems="center" justifyContent="center">
        <Box
          maxWidth={600}
          bgcolor="white"
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          paddingX={7}
          paddingY={9}
          textAlign="center"
        >
          <Typography variant="h6" mb={2}>
            {title}
          </Typography>
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="center"
            gap={3}
          >
            {onCancel && <Button onClick={onCancel} variant="text" color="secondary">
              Cancel
            </Button>}
            <Button onClick={onSubmit} color="secondary" variant="contained">
              {isLoading ? <Loader size={25} speedMs={200} /> : actionName}
            </Button>
          </Box>
        </Box>
      </Box>
    );
  },
);