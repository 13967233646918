import { Button, Typography } from "@mui/material";
import React from "react";

type ControlButtonProps = {
  text: string;
  onClick: () => void;
  Icon?: React.ReactNode;
  variant?: "outlined" | "contained" | "text";
  color?: "secondary" | "primary" | "secondaryLight";
  disabled?: boolean;
  isFullWidth?: boolean;
};

export const ControlButton = ({
  text,
  onClick,
  Icon,
  variant = "outlined",
  disabled = false,
  color = "secondary",
  isFullWidth = false,
}: ControlButtonProps) => {
  return (
    <Button
      fullWidth={isFullWidth}
      disabled={disabled}
      onClick={onClick}
      size="large"
      variant={variant}
      color={color}
      startIcon={Icon}
    >
      <Typography variant="text">{text}</Typography>
    </Button>
  );
};
