import React from "react";
import { Box, Button, Typography, useMediaQuery, useTheme } from "@mui/material";
import { colors } from "../../theme";
import { AccountTypeButtonProps } from "./types";
import { AccountTypeIcon, LetterTypeIcon } from "./Icons";

export const TypeButton: React.FC<AccountTypeButtonProps> = ({
  type,
  onSelectAccountType,
  selected,
  isShowLetterTypeButtons = false,
}) => {
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const onNextStep = () => {
    if(onSelectAccountType) {
      onSelectAccountType(type.value);
    }
  };

  return (
    <>
      <Box key={type.key} marginBottom={smallScreen ? 5 : "none"}>
        <Button
          sx={{
            "display": "flex",
            "borderRadius": "50%",
            "width": 200,
            "height": 200,
            "margin": "auto",
            "borderColor": selected ? colors.secondary : colors.secondaryLight,
            ":hover": {
              backgroundColor: colors.gray[50],
            },
          }}
          color={selected ? "secondary" : "secondaryLight"}
          onClick={!isShowLetterTypeButtons ? onNextStep : type.action}
          variant="outlined"
        >
          {!isShowLetterTypeButtons ? (
          <AccountTypeIcon
            color={selected ? colors.secondary : colors.secondaryLight}
            accountType={type.value}
          />
            ) : <LetterTypeIcon letterType={type.value} />}
        </Button>
        <Typography
          variant={smallScreen ? "text" : "title"}
          display="flex"
          justifyContent="center"
          marginTop={4}
          textAlign="center"
        >
          {type.title}
        </Typography>
      </Box>
    </>
  );
};



export const TypeButtonText: React.FC<AccountTypeButtonProps> = ({
  type,
  onSelectAccountType,
  selected,
  isShowLetterTypeButtons = false,
}) => {
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const onNextStep = () => {
    if(onSelectAccountType) {
      onSelectAccountType(type.value);
    }
  };

  return (
    <>
      <Box key={type.key} marginBottom={smallScreen ? 5 : "none"}>
        <Button
          sx={{
            "width": "auto",  // Adjust width as necessary
            "height": "auto",  // Adjust height as necessary
            "margin": "auto",
            "borderColor": selected ? colors.secondary : colors.secondaryLight,
            ":hover": {
              backgroundColor: colors.gray[50],
            },
          }}
          color={selected ? "secondary" : "secondaryLight"}
          onClick={!isShowLetterTypeButtons ? onNextStep : type.action}
          variant="outlined"
        >
          <Typography
            variant={smallScreen ? "body1" : "h5"}  // Adjust variant as necessary
            display="flex"
            justifyContent="center"
            color='secondary'
          >
            {type.title}
          </Typography>
        </Button>
      </Box>
    </>
  );
};
