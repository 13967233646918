import React, { useEffect, useState } from "react";
import { Box, Button, Link, TextField, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { LuciButton } from "../LuciButton";
import { LetterCompositionDefaultProps } from "./types";
import { onFinish, onSaveToDraft } from "./utils";
import { getLetterRequest } from "../../api/letterRequest";
import { FinishedLetter } from "./FinishedLetter";
import { downloadTxtFile } from "../LetterCompositionConstructor/utils";
import { getType } from "../../api/localStorage/type";
import { ROUTES } from "../../constants/routes";

export const LetterCompositionDefault = ({
  text = "",
  letterId,
  finished = false,
  handleLetterDetailsOpen,
  handleChangePageMode,
  onSave,
  onFinishLetter,
  status,
}: LetterCompositionDefaultProps) => {
  const [value, setValue] = useState<string>(text);
  const [isEditable, setIsEditable] = useState(false);

  const navigate = useNavigate();
  const accountType = getType();

  const isFinishedLetter = finished && !isEditable;

  const handleNavigateSubscription = () => window.open(ROUTES.SUBSCRIPTION);

  const handleNavigateToLetterRequestModal = () => {
    navigate(`/${accountType}/dashboard/?letterId=${letterId}`);
  };

  const getInitialData = async () => {
    const letterRequest = letterId && (await getLetterRequest(letterId));
    setValue(letterRequest.text);
  };

  const onChangeText = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
    setValue(event.target.value);

  const saveDraft = async () => {
    value !== text && (await onSaveToDraft(letterId, value, status));
    onSave(value);
    toast.success("Draft was successfully saved");
  };

  const finish = async () => {
    value && (await onFinish(letterId, value));
    onFinishLetter();
  };

  const onDownload = () =>
    downloadTxtFile([
      {
        id: 0,
        text: value,
        isFinished: true,
        isDisabled: false,
        isEditable: false,
        isSkipped: false,
      },
    ]);

  const onEdit = () => setIsEditable(true);

  const onCancelEdit = () => {
    setIsEditable(false);
    setValue(text);
  };

  const onSaveFinished = async () => {
    await onFinish(letterId, value);
    setIsEditable(false);
    onSave(value);
  };

  useEffect(() => {
    getInitialData();
  }, []);

  return (
    <>
      {isFinishedLetter ? (
        <FinishedLetter text={value} onEdit={onEdit} onDownload={onDownload} />
      ) : (
        <>
          <Box mt={2} display="flex" flexDirection="column" gap={2}>
            <Typography variant="mediumTitle">Type a letter</Typography>
            <TextField
              fullWidth
              multiline={true}
              minRows={12}
              value={value}
              onChange={onChangeText}
            />
          </Box>
          {!isEditable ? (
            <Box mt={4} display="flex" flexDirection="row" justifyContent="space-between">
              <Box display="flex" flexDirection="row" gap={3}>
                <Button
                  size="large"
                  variant="outlined"
                  color="secondary"
                  onClick={saveDraft}
                  disabled={value === text}
                >
                  <Typography variant="text">Save to drafts</Typography>
                </Button>
                <Button
                  disabled={!value}
                  variant="contained"
                  color="secondary"
                  sx={{ paddingX: 5 }}
                  onClick={finish}
                >
                  <Typography variant="text">Finish</Typography>
                </Button>
                <Link
                  display="flex"
                  flexDirection="row"
                  color="secondary"
                  alignItems="center"
                  underline="hover"
                  onClick={handleNavigateToLetterRequestModal}
                >
                  <CloseIcon sx={{ width: 20, height: 20 }} />
                  <Typography variant="text">Cancel</Typography>
                </Link>
              </Box>
              <Box display="flex" flexDirection="row" gap={3}>
                <LuciButton
                  onClick={handleNavigateSubscription}
                  text="Get LUCI"
                />
                <Button onClick={handleLetterDetailsOpen} variant="outlined" color="secondary">
                  <Typography variant="text">View request</Typography>
                </Button>
              </Box>
            </Box>
          ) : (
            <Box display="flex" flexDirection="row" gap={3} mt={2}>
              <Button
                size="large"
                variant="outlined"
                color="secondary"
                onClick={onSaveFinished}
                disabled={value === text}
              >
                <Typography variant="text">Save</Typography>
              </Button>
              <Link
                display="flex"
                flexDirection="row"
                color="secondary"
                alignItems="center"
                underline="hover"
                onClick={onCancelEdit}
              >
                <CloseIcon sx={{ width: 20, height: 20 }} />
                <Typography variant="text">Cancel</Typography>
              </Link>
            </Box>
          )}
        </>
      )}
    </>
  );
};
