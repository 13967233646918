import React, { ChangeEvent, useEffect, useState } from "react";
import { SelectChangeEvent, TextareaAutosize, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { SelectWithLabel } from "../SelectWithLabel";
import SubQuestion from "../SubQuestion";
import { QuestionFieldProps } from "./types";
import { Question, QuestionOption } from "../Questionnaire/types";
import { formatDate } from "./utils";
import { colors } from "../../theme";
import { getType } from "../../api/localStorage/type";
import { AccountTypeEnum } from "../../constants";
import { getQueryParamFromURL } from "../../utils/getQueryParamFromURL";
import { formatDateObject } from "../../utils/joinFormatDate";

const STUDENT_FIRST_NAME = "student_first_name";
const STUDENT_LAST_NAME = "student_last_name";
const STUDENT_GENDER = "student_gender";
const STUDENT_EMAIL = "student_email";
const SELF_REQUEST_TRUE = "true";
const SELF_REQUEST_FALSE = "false";

const QuestionField = ({ question, onChange, editable, error }: QuestionFieldProps) => {
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [isSelfRequest, setIsSelfRequest] = useState<string | null>("");
  const [isSelfRequestCheck, setIsSelfRequestCheck] = useState<string | null>("");
  const [filledValue, setFilledValue] = useState("");

  const isNameCheck = question.id !== STUDENT_FIRST_NAME && question.id !== STUDENT_LAST_NAME;
  const isFieldCheck = question.id !== STUDENT_GENDER && question.id !== STUDENT_EMAIL;
  const isFieldRequired = question?.required && editable && isNameCheck;
  const today = new Date();
  const accountType = getType();

  const handleDateChange = (newValue: Date | null) => {
    onChange && onChange(question?.id, formatDate(newValue));
  };

  const handleDateOnChange = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    handleDateChange(new Date(event.target.value));
  };

  const getSelectValue = (q: Question) => {
    const v = question?.options?.find((o) => o.value === question.answer);
    return v ? { key: v.display, value: v.display } : null;
  };

  const handleChange = (e: any) => {
    const newValue = e.target.value;
    setFilledValue(newValue);
    onChange && onChange(question?.id, newValue);
  };

  const onChangeSelect = (event?: SelectChangeEvent) => {
    const selected = question?.options?.find((o) => o.display === event?.target.value);
    if (selected && onChange) {
      onChange(question?.id, selected.value);
    }
  };

  const showTextInput = () =>
    question.type !== "date" && question.type !== "text-area" && question.type !== "select";

  useEffect(() => {
    if (typeof error === "string") {
      setErrorMessage(error);
    } else {
      setErrorMessage("");
    }
  }, [error]);

  const handleChangeSubQuestion = (q: Question, subAnswer: string) => {
  };

  useEffect(() => {
    const selfRequest = getQueryParamFromURL("selfRequest");
    const isSelfRequestCheck = getQueryParamFromURL("isSelfRequest");
    setIsSelfRequest(selfRequest);
    setIsSelfRequestCheck(isSelfRequestCheck);
  }, []);

  return (
    <Box display="flex" flexDirection="column" flex={1} marginBottom={5}>
      <Typography variant="title" color={colors.textGray} component="span" fontWeight={500}>
        {question.question}{" "}
        {isFieldRequired && (
          <Typography component="span" color={colors.error}>
            *
          </Typography>
        )}{" "}
      </Typography>
      {question?.subQuestions &&
        question?.subQuestions?.map((subQuestion: Question, _index: number) => (
          <SubQuestion
            onChange={handleChangeSubQuestion}
            editable={editable}
            key={subQuestion.id}
            question={question}
            subQuestion={subQuestion}
          />
        ))}
      {!editable && (
        <Typography
          sx={{ overflowWrap: "break-word" }}
          maxWidth="400px"
          pt={1}
          variant="body1"
          color={question.answer ? colors.textGray : colors.gray[400]}
        >
          {question.answer || "No field info"}
        </Typography>
      )}
      {question.type === "text-area" && editable && (
        <TextareaAutosize
          disabled={!editable}
          onChange={handleChange}
          minRows={6}
          maxRows={6}
          value={question?.answer || ""}
          placeholder={question?.placeholder || ""}
          style={{
            width: "100%",
            fontFamily: "Avenir",
            fontSize: "1rem",
            borderColor: errorMessage.length > 0 ? colors.error : colors.gray[300],
            outline: "none",
          }}
        />
      )}
      {question.type === "date" && editable && (
        <TextField
          onChange={handleDateOnChange}
          error={Boolean(errorMessage)}
          disabled={!editable}
          placeholder={question?.placeholder || ""}
          variant="standard"
          color="secondary"
          type={question?.type}
          defaultValue={question?.answer ? formatDateObject(question?.answer!) : ""}
          inputProps={{
            min: formatDate(today),
          }}
        />
      )}
      {question.type === "select" && question?.options && editable
        && (accountType === AccountTypeEnum.Writer
        && !isSelfRequest && isSelfRequestCheck === SELF_REQUEST_FALSE ? isFieldCheck : true) && (
          <Box mt={1}>
            <SelectWithLabel
              id="select"
              placeholder={question.answer || question.placeholder}
              value={getSelectValue(question)}
              items={question.options.map((o: QuestionOption) => ({
                value: o.display,
                key: o.display,
                disabled: o.disabled || false,
              }))}
              onChange={onChangeSelect}
            />
          </Box>
        )}
      {showTextInput() && editable && isNameCheck
      && (accountType === AccountTypeEnum.Writer
      && !isSelfRequest && isSelfRequestCheck === SELF_REQUEST_FALSE ? isFieldCheck : true) ? (
        <TextField
          multiline
          error={Boolean(errorMessage)}
          onChange={handleChange}
          disabled={!editable}
          placeholder={question?.placeholder || ""}
          variant="standard"
          color="secondary"
          type={question?.type}
          value={question?.answer || ""}
        />
      ) : (isSelfRequestCheck === SELF_REQUEST_TRUE || isSelfRequest === SELF_REQUEST_TRUE)
        && !isNameCheck && editable && (
          <TextField
            multiline
            error={Boolean(errorMessage)}
            onChange={handleChange}
            disabled={!editable}
            placeholder={question?.placeholder || ""}
            variant="standard"
            color="secondary"
            type={question?.type}
            value={(!isSelfRequest && question?.answer) || filledValue}
          />
        )}
      {(question.id === STUDENT_FIRST_NAME || question.id === STUDENT_LAST_NAME) && editable
        && !(isSelfRequestCheck === SELF_REQUEST_TRUE || isSelfRequest === SELF_REQUEST_TRUE) && (
          <Typography mt={2}>
            {question?.answer || ""}
          </Typography>
        )}
      {(question.id === STUDENT_GENDER || question.id === STUDENT_EMAIL)
        && isSelfRequestCheck === SELF_REQUEST_FALSE && !isSelfRequest && accountType === AccountTypeEnum.Writer && editable && (
          <Typography mt={2}>
            {question?.answer || ""}
          </Typography>
        )}
      {errorMessage && editable && (
        <Typography variant="error" mt={1}>
          {errorMessage}
        </Typography>
      )}
    </Box>
  );
};

export default QuestionField;
