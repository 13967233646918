import React, { useState } from "react";
import { Box, Button, Chip, Typography } from "@mui/material";
import ChangeCircleOutlinedIcon from "@mui/icons-material/ChangeCircleOutlined";
import { EnthusiasmSlider } from "../LetterCompositionConstructor/utils";
import { colors } from "../../theme";

type TemperatureSliderComponentProps = {
  temperatureLevel: number,
  onChangeLevel: (lvl: number) => void;
  generateText: () => void;
}

const marks = [
  {
    value: 0,
    label: "Structured",
  },
  {
    value: 1,
    label: "Creative",
  },
];

export const TemperatureSliderComponent: React.FC<TemperatureSliderComponentProps> = ({
  temperatureLevel,
  onChangeLevel,
  generateText,
}) => {
  const [angle, setAngle] = useState(0);

  const onGenerate = () => {
    setAngle(angle + 180);
    generateText();
  };

  const handleChange = (d: any) => onChangeLevel(d.target.value);

  return (
    <Box ml={8} mr={5} display="flex" flexDirection="column" alignItems="center" gap={2}>
      <Typography variant="text">Temperature level</Typography>
      <Box width={250}>
        <EnthusiasmSlider
          min={marks[0]?.value}
          max={marks[marks?.length - 1]?.value}
          track={false}
          value={temperatureLevel ? temperatureLevel : 0}
          onChange={handleChange}
          defaultValue={marks[0]?.value}
          marks={marks}
        />
      </Box>
      <Button
        size="large"
        variant="outlined"
        color="secondary"
        startIcon={
          <ChangeCircleOutlinedIcon
            style={{ transform: `rotate(${angle}deg)`, transition: "all 0.4s linear" }}
          />
        }
        onClick={onGenerate}
      >
        <Typography variant="text">Generate text</Typography>
      </Button>
      <Chip
        label="Click on the button to generate a new text"
        sx={{ backgroundColor: colors.secondary, color: colors.white }}
      />
    </Box>);
};