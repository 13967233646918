import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import { Box, Link, Modal, TextField, Typography, useMediaQuery } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import DoubleArrowRoundedIcon from "@mui/icons-material/DoubleArrowRounded";
import CancelIcon from "@mui/icons-material/Cancel";
import { ControlButton } from "./ControlButton";
import { EditableSectionProps } from "../types";
import { ROUTES } from "../../../constants/routes";
import { Disclaimer } from "../../Disclaimer";
import { DisclaimerData } from "../../Disclaimer/types";
import { showDisclaimer } from "../../../api/profile";
import Loader from "../../Loader";
import { TutorialClasses } from "../../Tutorial/tutorialClasses";
import { useMutationObserver } from "../../../hooks/useMutationObserver";
import { continueTutorial } from "../../../redux/actionCreators";
import { RootState } from "../../../redux/reducers";

export const EditableSection: React.FC<EditableSectionProps> = ({
  index,
  text,
  isDisabled,
  isFinished,
  isEditable,
  onChangeText,
  onAccept,
  enthusiasm,
  onSkip,
  onFinish,
  error,
  maxSections,
  requestTime,
}) => {
  const [isOpenDisclaimer, setIsOpenDisclaimer] = useState(false);
  const [disclaimerData, setDisclaimerData] = useState<DisclaimerData>({
    is_first: false,
    is_letter_exists: false,
  });

  const isDesktop = useMediaQuery("(min-width:900px)");

  const { tourActive } = useSelector((state: RootState) => state.tutorial);
  const mutationRef = useRef<HTMLDivElement>(null)
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const isLoading = isDisabled || !text;
  const isCanBeEditable = !isFinished && !isEditable;

  const getShowDisclaimer = async () => {
    const data = await showDisclaimer();
    setDisclaimerData(data);
  };

  const handleOpenDisclaimer = () => setIsOpenDisclaimer(true);
  const handleCloseDisclaimer = () => {
    setIsOpenDisclaimer(false);
    navigate(ROUTES.HOME);
  };

  const handleNavigateDashboard = () => navigate("/");
  const handleExitAndAccept = () => {
    onAccept();
    handleNavigateDashboard();
  }

  const handleExitAndFinish = () => {
    onFinish();
    handleNavigateDashboard();
  }

  useEffect(() => {
    getShowDisclaimer();
  }, []);

  useMutationObserver(mutationRef, () => {
    dispatch(continueTutorial());
  });

  useEffect(() => {
    if (tourActive && requestTime) {
      mutationRef.current?.classList.add(TutorialClasses.ninthTutorialStep);
    }
  }, [requestTime]);

  return (
    <>
      {!disclaimerData?.is_letter_exists && (
        <Modal open={isOpenDisclaimer} onClose={handleCloseDisclaimer}>
          <Box width="100%" height="100%" display="flex" alignItems="center" justifyContent="center">
            <Disclaimer
              onSubmit={onFinish}
              onCancel={handleCloseDisclaimer}
              title="Disclaimer"
              actionName="I agree"
            >
              You are interacting with a sophisticated Artificial Intelligence, ethically
              and meticulously trained by our team to deliver accurate and specific suggested text
              based on input provided by the applicant. Despite this, there may be unavoidable instances of incorrect,
              concerning, or nonspecific suggestions beyond our control. It is important that as the writer,
              you take great care in thoroughly reading and editing the proposed text to provide the applicant with a
              personalized and high quality letter and notify us of any inappropriate suggestions.
              By using Letters, you agree that you will not hold Lucinetic liable for the suggested text or final
              letter and that you read and agree to the <Link color="secondary" underline="hover"
                                                              onClick={() => window.open(ROUTES.PRIVACY_POLICY)}>
              Privacy Policy</Link> and <Link color="secondary" underline="hover"
                                              onClick={() => window.open(ROUTES.TERMS_OF_SERVICE)}>Terms of
              Use</Link>
            </Disclaimer>
          </Box>
        </Modal>
      )}
      <Box
        display="grid"
        gridTemplateColumns={isDesktop ? "5fr 1fr" : "1fr"}
        gap={isDesktop ? 0 : 2}
        width="100%"
      >
        <Box ref={mutationRef} display="flex" flexDirection="column" gap={2} width="100%">
          <TextField
            value={text}
            onChange={(e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => onChangeText(e.target.value)}
            multiline={true}
            minRows={3}
            disabled={isDisabled}
          />
          {isLoading && <Loader size={25} speedMs={200} />}
          <Box display="flex" flexDirection="row" justifyContent="space-between">
            {isCanBeEditable && (
              <Box
                display="flex"
                flex={1}
                flexDirection={isDesktop ? "row" : "column"}
                gap={2}
                justifyContent="space-between"
                alignItems="center"
              >
                <Box display="flex" gap={2} flexDirection={isDesktop ? "row" : "column"} alignItems="center">
                  <ControlButton
                    isFullWidth={!isDesktop}
                    variant="contained"
                    text={index + 1 !== maxSections ? "Accept Section" : "Finish letter"}
                    onClick={index + 1 !== maxSections ? onAccept : onFinish}
                    disabled={isDisabled}
                  />
                  <ControlButton
                    isFullWidth={!isDesktop}
                    disabled={isDisabled}
                    text={
                      index + 1 !== maxSections
                        ? "Skip"
                        : "Skip and Finish"
                    }
                    onClick={onSkip}
                    Icon={<DoubleArrowRoundedIcon />}
                  />
                  <ControlButton
                    isFullWidth={!isDesktop}
                    disabled={isDisabled}
                    text="Save & Exit"
                    onClick={index + 1 !== maxSections ? handleExitAndAccept : handleExitAndFinish}
                    Icon={<CancelIcon />}
                  />
                  <Box>{error && <Typography variant="error">{error}</Typography>}</Box>
                </Box>
                <Box display="flex" gap={2}>
                  {index + 1 !== maxSections && (
                    <ControlButton
                      isFullWidth={!isDesktop}
                      disabled={isDisabled}
                      text="Finish"
                      onClick={!disclaimerData?.is_letter_exists ? handleOpenDisclaimer : onFinish}
                      variant="contained"
                    />
                  )}
                </Box>
              </Box>
            )}
          </Box>
        </Box>
        <Box visibility={(isDisabled || !enthusiasm) && !tourActive ? "hidden" : "visible"}>{enthusiasm}</Box>
      </Box>
    </>
  );
};
