import React from "react";
import { Box, IconButton, InputAdornment, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";
import { FileCopySharp } from "@mui/icons-material";
import { RootState } from "../../redux/reducers";
import { colors } from "../../theme";
import { InputWithLabel } from "../../components/InputWithLabel";

export const ReferralLink = () => {
  const { user } = useSelector((state: RootState) => state.user);
  const location = window.location;
  const url =
    user?.referral_id &&
    `${location.protocol + "//" + location.host}/signup/?referral_id=${user?.referral_id}`;

  const copyValue = async () => {
    toast.success("You have successfully copied the referral link to your clipboard")
    await navigator.clipboard.writeText(url);
  };

  return (
    <Box display="flex" flexDirection="column" flex={1} mb={4}>
      <Typography mb={5} variant="title" fontWeight={500}>
        My Referral Link
      </Typography>
      <Typography mb={2.5} fontWeight={500} color={colors.textGray} variant="mediumTitle">
        Share your referral link with friends and colleagues and receive 3 LUCI credits for each
        new user who will make a purchase with us.
      </Typography>
      <Box maxWidth={400}>
        <InputWithLabel
          id="ref_link"
          disabled={!url}
          value={url || ""}
          placeholder="preparing link..."
          label="Referral link"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton aria-label="ref_link" onClick={copyValue} edge="end">
                  <FileCopySharp style={{ color: colors.textGray, fontSize: 16 }} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Box>
    </Box>
  );
};
