import React from "react";
import { Controller, useForm } from "react-hook-form";
import { Box, Button, Link, Typography } from "@mui/material";
import { KeyboardArrowLeftOutlined } from "@mui/icons-material";
import { InputWithLabel } from "../InputWithLabel";
import { isLink, required } from "../../utils/validation";
import { UploadWithLinkForm, UploadWithLinkProps } from "./types";

export const UploadWithLink = ({ uploadUrl, switchUploadType }: UploadWithLinkProps) => {
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<UploadWithLinkForm>({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: {
      uploadLink: "",
    },
  });

  const onUpload = (data: UploadWithLinkForm) => {
    uploadUrl(data.uploadLink);
    reset();
  };

  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <Typography fontWeight={500} variant="mediumTitle" pt={3}>
        File from the web
      </Typography>
      <Typography variant="caption" color="GrayText" mb={4}>
        Grab any off the web. Just provide the link
      </Typography>
      <Box display="flex" flexDirection="row" gap={2} mb={5}>
        <Controller
          control={control}
          name="uploadLink"
          rules={{
            validate: {
              required,
              isLink,
            },
          }}
          render={({ field }) => (
            <>
              <InputWithLabel
                {...field}
                errorMessage={errors?.uploadLink?.message}
                wrapperStyle={{ minWidth: 280 }}
                id="link"
                placeholder="Paste link here"
              />
              <Button
                onClick={handleSubmit(onUpload)}
                variant="contained"
                color="secondary"
                sx={{ maxHeight: 40 }}
              >
                Upload
              </Button>
            </>
          )}
        />
      </Box>
      <Link
        color="secondary"
        onClick={switchUploadType}
        underline="none"
        alignItems="center"
        display="flex"
        mb={2}
      >
        <KeyboardArrowLeftOutlined color="secondary" fontSize="medium" />
        <Typography variant="text" fontWeight={900}>
          Back to local upload
        </Typography>
      </Link>
    </Box>
  );
};
