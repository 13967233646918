export const setType = (type: string) => {
  try {
    localStorage.setItem('type', type);
  } catch (error) {
    console.log(error);
  }
};

export const getType = () => {
  try {
    const type = localStorage.getItem('type');
    return type;
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const removeType = () => {
  localStorage.removeItem('type');
};
