export const GET_DATA = "GET_DATA";
export const ADD_LETTER = "ADD_LETTER";
export const UPDATE_REQUEST = "UPDATE_REQUEST";
export const DELETE_REQUEST = "DELETE_REQUEST";
export const SET_ACCOUNT_TYPES = "SET_ACCOUNT_TYPES";
export const SET_LETTERS = "SET_LETTERS";

export const SET_REQUESTS = "SET_REQUESTS";
export const SET_COVER_REQUESTS = "SET_COVER_REQUESTS";
export const SET_SEPARATE_REQUESTS = "SET_SEPARATE_REQUESTS";

export const SET_EDIT_REQUEST = "SET_EDIT_REQUEST";

export const SET_USER = "SET_USER";
export const SET_MENU_ACTIVE = "SET_MENU_ACTIVE";
export const RESET = "RESET";

export const SET_FILTER_VALUE = "SET_FILTER_VALUE";
export const REMOVE_FILTER_VALUE = "REMOVE_FILTER_VALUE";

export const SET_LETTER_TEMPLATE = "SET_LETTER_TEMPLATE";
export const REMOVE_LETTER_TEMPLATE = "REMOVE_LETTER_TEMPLATE";
export const SET_CREDIT_COUNT = "SET_CREDIT_COUNT";

export const SET_NOTIFICATIONS = "SET_NOTIFICATIONS";
export const SET_REMINDERS = "SET_REMINDERS";
export const REMOVE_NOTIFICATION = "REMOVE_NOTIFICATION";
export const REMOVE_REMINDER = "REMOVE_REMINDER";
export const SET_NOTIFICATION_IS_READ = "SET_NOTIFICATION_IS_READ";
export const SET_REMINDER_IS_READ = "SET_REMINDER_IS_READ";
export const CLEAR_ALL_NOTIFICATIONS = "CLEAR_ALL_NOTIFICATIONS";
export const CLEAR_ALL_REMINDERS = "CLEAR_ALL_REMINDERS";

export const SET_SELECTED_REQUEST_ID = "SET_SELECTED_REQUEST_ID";
export const REMOVE_SELECTED_REQUEST_ID = "REMOVE_SELECTED_REQUEST_ID,";

export const START_TUTORIAL = "START_TUTORIAL";
export const CONTINUE_TUTORIAL = "CONTINUE_TUTORIAL";
export const SET_STEPS = "SET_STEPS";
export const STOP_TUTORIAL = "STOP_TUTORIAL";
export const COMPLETELY_STOP_TUTORIAL = "COMPLETELY_STOP_TUTORIAL";
export const SET_STEP_INDEX = "SET_STEP_INDEX";