import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TableCell, TableRow } from "@mui/material";
import { CustomTableRowProps } from "./types";
import { HeaderCell } from "../Head/types";
import { TutorialClasses } from "../../Tutorial/tutorialClasses";
import { continueTutorial } from "../../../redux/actionCreators";
import { RootState } from "../../../redux/reducers";
import { useMutationObserver } from "../../../hooks/useMutationObserver";
import { getType } from "../../../api/localStorage/type";
import { AccountTypeEnum } from "../../../constants";
import { FREE_SAMPLE } from "../../Tutorial/constants";

export const CustomTableRow = ({ row, columns, requestTime }: CustomTableRowProps) => {
  const { tourActive } = useSelector((state: RootState) => state.tutorial);
  const dispatch = useDispatch();
  const accountType = getType();
  const mutationRef = useRef<HTMLTableRowElement>(null);
  const IS_TOUR_CONTINUE = tourActive && row.credit_used === FREE_SAMPLE && requestTime;

  useMutationObserver(mutationRef, () => {
    dispatch(continueTutorial());
  });

  useEffect(() => {
    if (IS_TOUR_CONTINUE) {
      const tutorialClass = accountType === AccountTypeEnum.Applicant ? TutorialClasses.fourthTutorialStep : TutorialClasses.seventhTutorialStep;
      mutationRef.current?.classList.add(tutorialClass);
    }
  }, [requestTime]);

  return (
    <TableRow ref={mutationRef}>
      {columns?.map((column: HeaderCell) =>
        column?.Cell ? (
          <TableCell key={column.key + column.name}>
            {column.Cell({
              value: row?.[column.key],
              row,
            })}
          </TableCell>
        ) : (
          <TableCell
            sx={{ textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden", maxWidth: "200px" }}
            key={column.key + column.name}
          >
            {row?.[column.key]}
          </TableCell>
        ),
      )}
    </TableRow>
  );
};
