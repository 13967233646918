import React from "react";
import { Box, Button, useMediaQuery } from "@mui/material";
import Loader from "../../../components/Loader";

type NewLetterControlsProps = {
  onCancel?: (() => void) | null;
  onNext?: () => void;
  disabledNext?: boolean;
  disabledCancel?: boolean;
  nextTitle?: string;
  cancelTitle?: string;
  loading?: boolean;
};

export const NewLetterControls = ({
  onCancel,
  onNext,
  disabledNext = false,
  disabledCancel = false,
  nextTitle,
  cancelTitle,
  loading = false,
}: NewLetterControlsProps) => {
  const isDesktop = useMediaQuery("(min-width:900px)");

  return (
    <Box
      width={isDesktop ? 300 : "inherit"}
      display="flex"
      flexDirection="row"
      marginY={4}
      alignItems="center"
      justifyContent="center"
    >
      {onCancel && (
        <Box flex={1} mr={2}>
          <Button
            fullWidth
            disabled={disabledCancel || loading}
            variant="outlined"
            color="secondary"
            onClick={onCancel}
          >
            {cancelTitle || "Cancel"}
          </Button>
        </Box>
      )}

      {onNext && (
        <Box flex={1}>
          <Button
            fullWidth
            disabled={disabledNext || loading}
            variant="contained"
            color="secondary"
            onClick={onNext}
          >
            {loading ? <Loader size={24} /> : nextTitle || "Next"}
          </Button>
        </Box>
      )}
    </Box>
  );
};
