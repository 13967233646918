import { LetterRequestDocument } from "../../components/FileUploadWithProgress/types";
import { Question } from "../../components/Questionnaire/types";

const getPredefinedAnswer = (q: Record<string, any>, user?: Record<string, any>) => {
  switch (q.id) {
    case "student_first_name":
    case "applicant_first_name": {
      return user?.user?.first_name || q.answer || "";
    }
    case "student_last_name":
    case "applicant_last_name": {
      return user?.user?.last_name || q.answer || "";
    }
    case "student_email": {
      return user?.user?.email || q.answer || "";
    }
    case "current_employer":
    case "class_institution": {
      return user?.organization || q.answer || "";
    }
    case "current_responsibilities": {
      return user?.current_responsibilities || q.answer || "";
    }
    default:
      return q.answer || "";
  }
};

export const prepareQuestions = (
  data: Array<Record<string, any>>,
  user?: Record<string, any>,
): Question[] => {
  const questions = data.map((q) => {
    const subQuestions = q?.subQuestions ? prepareQuestions(q.subQuestions) : undefined;
    const question: Question = {
      id: q.id,
      question: q.question || null,
      placeholder: q.placeholder || "" ,
      type: q.input_type,
      required: q.required || false,
      answer: getPredefinedAnswer(q, user),
      subQuestions: subQuestions,
      options: q?.options || undefined,
      max_length: q?.max_length || null,
    };
    return question;
  });

  return questions?.filter((i) => i.question !== null);
};

export const prepareLinks = (data: Array<Record<string, any>>): Array<LetterRequestDocument> => {
  return data.map((l, index) => ({
    id: l.id,
    type: "link",
    link: l?.link || "",
    description: l?.title || "",
  }));
};

export const prepareFiles = (data: Array<Record<string, any>>): Array<LetterRequestDocument> => {
  return data.map((l: Record<string, any>, index: number) => ({
    id: l.id,
    type: "file",
    fileUrl: l?.file || "",
    description: l?.description || "",
    fileName: l.name || `File_${index}`,
    size: l.size || "",
    date: new Date(l.date),
  }));
};

export const getFilesToRemove = (
  files: Array<Record<string, any>>,
  documents: Array<LetterRequestDocument>,
) => {
  const docFiles = documents.filter((d) => d.type === "file");
  const filesToRemove = files.filter((f) => !docFiles.find((df) => df.id === f.id));

  return filesToRemove;
};

export const getLeftFiles = (
  files: Array<Record<string, any>>,
  documents: Array<LetterRequestDocument>,
) => {
  const docFiles = documents.filter((d) => d.type === "file");
  const filesToLeave = files.filter((f) => docFiles.find((df) => df.id === f.id));

  return filesToLeave;
};
