import React, { useCallback, useEffect, useState, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { UNSAFE_NavigationContext } from 'react-router-dom';

type Location = {
  location?: any;
};

type Navigator = {
  block?: any;
};

type NavigatingAway = [
  showDialogPrompt: boolean,
  confirmNavigation: () => void,
  cancelNavigation: () => void,
];

export function NavigationBlocker(navigationBlockerHandler: any, canShowDialogPrompt: boolean) {
  const navigator = useContext(UNSAFE_NavigationContext).navigator as Navigator;

  useEffect(() => {
    if (!canShowDialogPrompt) return;

    const unblock = navigator?.block((tx: any) => {
      const autoUnblockingTx = {
        ...tx,
        retry() {
          unblock();
          tx.retry();
        },
      };

      navigationBlockerHandler(autoUnblockingTx);
    });

    return unblock;
  });
}

export const useNavigatingAway = (canShowDialogPrompt: boolean): NavigatingAway => {
  const navigate = useNavigate();
  const currentLocation = useLocation();
  const [showDialogPrompt, setShowDialogPrompt] = useState<boolean>(false);
  const [wantToNavigateTo, setWantToNavigateTo] = useState<Location>({});
  const [isNavigationConfirmed, setIsNavigationConfirmed] = useState<boolean>(false);

  const handleNavigationBlocking = useCallback(
    locationToNavigateTo => {
      if (
        !isNavigationConfirmed &&
        locationToNavigateTo.location.pathname !== currentLocation.pathname
      ) {
        setShowDialogPrompt(true);
        setWantToNavigateTo(locationToNavigateTo);
        return false;
      }
      return true;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isNavigationConfirmed],
  );

  const cancelNavigation = useCallback(() => {
    setIsNavigationConfirmed(false);
    setShowDialogPrompt(false);
  }, []);

  const confirmNavigation = useCallback(() => {
    setIsNavigationConfirmed(true);
    setShowDialogPrompt(false);
  }, []);

  useEffect(() => {
    if (isNavigationConfirmed && wantToNavigateTo) {
      navigate(wantToNavigateTo.location.pathname);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isNavigationConfirmed, wantToNavigateTo]);

  NavigationBlocker(handleNavigationBlocking, canShowDialogPrompt);

  return [showDialogPrompt, confirmNavigation, cancelNavigation];
};
