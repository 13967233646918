import React from "react";
import { Box, Button, Typography } from "@mui/material";
import { DeclineLetterModalProps } from "../../types";

export const DeclineLetterModal = React.forwardRef(
  ({ onDecline, onClose, onArchive }: DeclineLetterModalProps, ref: React.Ref<any>) => {
    return (
      <Box
        ref={ref}
        width="100%"
        height="100%"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Box
          maxWidth={600}
          bgcolor="white"
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          paddingX={7}
          paddingY={9}
        >
          <Typography variant="h6" mb={2}>
            Are you sure you want to <b>decline</b> this letter request?
          </Typography>
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="center"
            gap={3}
          >
            <Button onClick={onDecline} color="secondary" variant="contained">
              Decline Request
            </Button>
            <Button onClick={onArchive} color="secondary" variant="contained">
              Decline and Archive Request
            </Button>
            <Button onClick={onClose} variant="text" color="secondary">
              Cancel
            </Button>
          </Box>
        </Box>
      </Box>
    );
  },
);
